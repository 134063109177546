.custom-dropdown-toggler {
  width: 200px;
  background: #f7f7f7;
  padding: 6px 12px;
  color: black;
  border: 1px solid #ececec;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}

.custom-dropdown-toggler:active {
  opacity: 0.5;
}

.custom-dropdown-menu {
  display: flex;
  align-items: center;

  &:after, &:before {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:before {
    border-color: rgba(255,255,255,0);
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px;
  }
  &:after {
    border-color: rgba(255,255,255,0);
    border-bottom-color: #fff;
    border-width: 4px;
    margin-left: -4px;
  }
}
