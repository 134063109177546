/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../fonts/Flaticon.eot");
	src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Flaticon.woff2") format("woff2"),
	url("../fonts/Flaticon.woff") format("woff"),
	url("../fonts/Flaticon.ttf") format("truetype"),
	url("../fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-dots-menu:before { content: "\f100"; }
.flaticon-curve-arrow:before { content: "\f101"; }
.flaticon-curve-arrow-1:before { content: "\f102"; }
.flaticon-community:before { content: "\f103"; }
.flaticon-volunteer:before { content: "\f104"; }
.flaticon-money-bag:before { content: "\f105"; }
.flaticon-volunteer-1:before { content: "\f106"; }
.flaticon-goal:before { content: "\f107"; }
.flaticon-goal-1:before { content: "\f108"; }
.flaticon-right:before { content: "\f109"; }
.flaticon-right-arrow:before { content: "\f10a"; }
.flaticon-left-arrow:before { content: "\f10b"; }
.flaticon-money-bag-1:before { content: "\f10c"; }
.flaticon-money-bag-2:before { content: "\f10d"; }
.flaticon-leak:before { content: "\f10e"; }
.flaticon-favela:before { content: "\f10f"; }
.flaticon-donation:before { content: "\f110"; }
.flaticon-business-and-finance:before { content: "\f111"; }
.flaticon-study:before { content: "\f112"; }
.flaticon-give:before { content: "\f113"; }
.flaticon-dollar:before { content: "\f114"; }
.flaticon-vision:before { content: "\f115"; }
.flaticon-call:before { content: "\f116"; }
.flaticon-envelope:before { content: "\f117"; }
.flaticon-left-quotes-sign:before { content: "\f118"; }
.flaticon-star:before { content: "\f119"; }
.flaticon-plus:before { content: "\f11a"; }
.flaticon-minus:before { content: "\f11b"; }
.flaticon-facebook:before { content: "\f11c"; }
.flaticon-twitter:before { content: "\f11d"; }
.flaticon-instagram:before { content: "\f11e"; }
.flaticon-linkedin:before { content: "\f11f"; }
