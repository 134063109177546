$main-color: #037695;
.owl-carousel .owl-stage-outer .owl-stage .owl-item:first-child {
  display: block;
}

.image-requirements {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 30px auto 0;
  p {
   margin: 0 !important;
  }
}

.responsive {
  width: 100%;
  height: auto;
}

.team-item .image {
  height: 400px;
}

.team-item .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.blog-slider .image {
  height: 250px;
}
.blog-slider .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.home-item {
  position: relative;
  height: 100vh;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  z-index: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}
/***spinner****/
.custom-spinner {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-spinner .spinner-border {
  margin: 200px 0px 50px;
  width: 5rem;
  height: 5rem;
  border: 0.25em solid #a986fc;
  border-right-color: transparent !important;
}
@media only screen and (max-width: 1024px) {
  .custom-spinner .spinner-border {
    margin: 130px 0px 10px;
  }
}
.blog-details-area
  .blog-details-desc
  .article-footer
  .article-share
  .social
  li
  button {
  display: block;
  color: #ffffff;
  width: 32px;
  height: 32px;
  line-height: 33px;
  border-radius: 50%;
  background-color: #037695 !important;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;

  i {
    color: #fff;
  }

  &:hover {
    animation: rubberBand 1s linear;
  }
}
.loading-carousel {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter-section .remove-before span {
  margin-left: 0px;
}
.home-slider {
  .swiper-button-prev:after, .swiper-button-next:after {
    font-family: Flaticon !important;
    font-style: normal;
    color: #fff;
    font-size: 25px !important;
  }
  .swiper-button-prev:after {
    content: "\f10a" !important;
  }
  .swiper-button-next:after {
    content: "\f10b" !important;
  }
}
.events-image-slider {
  .swiper-button-prev, .swiper-button-next {
    bottom: 0;
    top: revert;
    left: revert;
    background: #96f;
    width: 40px;
    height: 40px;
  }
  .swiper-button-prev {
    right: 40px;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-style: normal;
    color: #fff;
    font-size: 15px !important;
  }
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #037695;
  display: inline-block;
  border-radius: 50%;
  transition: .5s;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 10px;
  background-color: #bdbdbd;
}
.team-slider {
  padding-bottom: 20px;
}
.chat-wrapper {
  .chat-header {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .chat-close {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      margin-right: 8px;
      padding: 0 3px 0 5px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      color: #444;

      .feather.feather-close {
        width: 20px;
        height: 20px;
        fill: #444;
      }
    }
  }
}
#cometchat_embed_synergy_container {
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;

  .loading-div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
