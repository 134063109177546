.tweeter-widget {
  height: 400px;
  overflow-y: auto;
}
iframe {
  border:none;
  overflow:hidden
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li button {
  display: block;
  color: #ffffff;
  width: 32px;
  height: 32px;
  line-height: 33px;
  border-radius: 50%;
  background-color: #037695 !important;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;

  i {
    color: #fff;
  }

  &:hover {
    animation: rubberBand 1s linear;
  }
}

.blog-details-area .blog-details-desc .article-footer .article-share {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.blog-details-area .blog-details-desc .article-footer .article-tags {
  flex: 0 0 80% !important;
  max-width: 80% !important;
}

@media only screen and (max-width: 991px) {
  .blog-details-area .blog-details-desc .article-footer .article-share {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .blog-details-area .blog-details-desc .article-footer .article-tags {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}