.about-banner-image {
  background-image: url("../../../Assets/img/about/about-us-bg.png");
  padding-top: 350px !important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.counter-content span {
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 600;
  padding: 5px 25px;
  background-color: #9966ff;
}
.counter-content h3 {
  font-size: 50px;
  margin: 14px 0 10px 0;
  color: #037695;
}
.counter-content a {
  display: inline-block;
  padding: 15px 30px;
  background-color: #037695;
  border: 1px solid #037695;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 20px;
  border-radius: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.counter-content a:hover {
  background-color: #85b4bc;
  color: #ffffff;
  text-decoration: none;
}
.counter-content p span {
  background: transparent;
  color: #666666;
}
.about.single-fun-fact {
  border: none;
  background: transparent;
  text-align: center;
  margin-bottom: 30px;
  padding: 0;
}

.about.single-fun-fact h3 {
  position: relative;
  font-size: 50px;
  font-weight: 700;
  margin: 0 0 5px 0;
  line-height: initial;
}

.about.single-fun-fact p {
  line-height: initial;
  margin: 0 0 0 0;
  font-size: 16px;
  /* color: #9966ff; */
  text-transform: uppercase;
  font-weight: bold;
}

.about.single-fun-fact:hover h3 {
  color: #000000;
}
.about.single-fun-fact:before {
  background: transparent !important;
  border: 0px;
}
.about.single-fun-fact:hover p {
  color: #000000;
}
.video-section {
  background-image: url("https://templates.envytheme.com/gouba/default/charity-volunteer-campaign/assets/img/video-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.video-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
}
.video-image {
  background-image: url("../../../Assets/img/Video\ Thumbnail.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  position: relative;
  z-index: 1;
}
.video-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.5;
}
.video-image .video {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
}
.video-image .video .video-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #ed272a;
  position: relative;
  z-index: 1;
}
.video-image .video .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.video-image .video .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}
.main-nav nav .navbar-nav .nav-item a {
  color: #1a1b1e;
}
.video-section .section-title span,
.video-section .section-title h2 {
  color: #ffffff;
}
.video-section .section-title span::before {
  background-color: #ffffff;
}
.video-image .video .video-btn:hover,
.video-image .video .video-btn .video-content .video-btn:focus {
  background-color: #ed272a;
  color: #ffffff;
  text-decoration: none;
}
section.video-section {
  margin-bottom: 10px;
}
.counter-background span {
  font-size: 15px;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  font-weight: 600;
  padding: 8px 25px;
  background-color: #9966ff;
  margin-left: 0px;
}
.counter-background span::before {
  display: none;
}
.team-section h2 {
  margin: 0px;
}
.pt-150 {
  padding-top: 150px;
}
.counter-section .remove-before span::before {
  display: none;
}
.counter-section .remove-before span {
  margin-left: 0px;
}
.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.remove-before {
  margin-top: 0px !important;
}
.remove-custm-space p span {
  display: inline;
  padding: 0px;
}
.counter-section .remove-before span {
  padding: 0px;
  display: initial;
  line-height: 1.6;
}
.bg-grey {
  background: #f5f5f5;
}
.privacy-area-content h4 {
  margin: 14px 0 10px 0;
  color: #037695;
}
@media only screen and (max-width: 1024px) {
  .pb-100 {
    padding-bottom: 70px;
  }
  .pt-150 {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .about-image.pt50.mb-mt-20 {
    padding-top: 0px !important;
  }
  .mb-mt-20 {
    margin-top: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .about-banner-image {
    margin-top: 60px;
    padding-top: 180px !important;
  }
  .counter-content span {
    padding: 5px 10px;
  }
  .pb-100,
  .pb-80 {
    padding-bottom: 50px !important;
  }
  .pt-150,
  .pt-80 {
    padding-top: 50px;
  }
  .mb-mt-20 {
    margin-top: 20px !important;
  }
  .about-section .about-image img {
    width: 100%;
  }
  .about-image.mb-mt-20.mt-4,
  .about-image.mt-4.mb-mt-20 {
    margin-top: 15px !important;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.pt50 {
  padding-top: 50px;
}
#team .team-item .image {
  height: 350px;
}

#team .team-item .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
