/*
Responsive CSS
============================*/
@media only screen and (max-width: 767px) {
    .default-btn {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .optional-btn {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .section-title {
        margin-bottom: 30px;
        span {
            font-size: 12px;
        } 
        h2 {
            font-size: 28px;
        }  
    } 
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    } 
    .contactModal {
        .modal-dialog {
            .modal-content {
                padding: 50px 20px;
                form {
                    #msgSubmit {
                        font-size: 14px;
                    }
                    .help-block {
                        ul {
                            li {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-modal {
        .sidebar-modal-inner {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .home-slider {
        .home-item {
            height: 100vh;
            padding-top: 150px;
            padding-bottom: 100px;
            .slider-content {
                margin-top: 0;
                text-align: center;
                h1 {
                    font-size: 32px;
                }
                .slider-btn {
                    margin-top: 20px;
                }
            }
            .shape {
                display: none;
            }
        }
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: 100%;
                    transform: translateY(-100%);
                }
            }
        }
    }
    .single-fun-fact {
        .icon {
            i {
                font-size: 45px;
            }
        }
        h3 {
            font-size: 35px;
            margin: 5px 0 5px 0;

            .sign-icon {
                font-size: 30px;
                margin-left: -5px;
            }            
        }
    }
    .about-area-content {
        span {
            font-size: 12px;
        }
        h3 {
            font-size: 25px;
        }
    }
    .about-list-tab {
        .tabs {
            li {
                a {
                    padding: 8px 15px;
                    font-size: 12px;
                }
            }
        }
        .tab_content {
            .tabs_item {
                .list {
                    margin-top: 20px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .about-image {
        .image-content {
            position: relative;
            bottom: 0;
            left: 0;
            max-width: 100%;
            padding: 20px;
            h3 {
                font-size: 25px;
            }
        }
    }
    .overview-image-slider {
        height: 240px;

        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    &.owl-next {
                        left: 15px;
                    }
                }
            }
        }

        .owl-stage-outer {
            height: 240px;
            .owl-stage {
                height: 240px;
                .owl-item {
                    height: 240px;
                    .cloned {
                        height: 240px;
                    }
                }
            }
        }
    
        .image {
            height: 220px;
        }
        .image-2 {
            height: 220px;
        }
        .image-3 {
            height: 220px;
        }
        .image-4 {
            height: 220px;
        }
    }
    .pl-0, .px-0 {
        padding-left: 15px !important;
    }
    .pr-0, .px-0 {
        padding-right: 15px !important;
    }
    .overview-content {
        padding: 20px;
        margin-bottom: 30px;
        text-align: center;
        h3 {
            font-size: 20px;
        }
    }
    .overview-dollar {
        padding: 20px;
        margin-top: 30px;
        text-align: center;
        h3 {
            font-size: 30px;
        }
    }
    .causes-shape {
        .shape-two {
            bottom: 0 !important;
            top: unset;
        }
    }
    .single-causes {
        padding: 20px;

        .causes-list {
            padding: 16px 20px;

            li {
                margin-right: 20px;
                &::before {
                    right: -12px;
                }
            }
        }
    }
    .donors-section {
        .section-title {
            margin-bottom: 30px;
            span {
                font-size: 14px;
            }
            h2 {
                font-size: 28px;
            }
        }
    }
    .events-image-slider {
        .item {
            .content {
                position: relative;
                max-width: 100%;
                padding: 20px;
                span {
                    font-size: 15px;
                }
            }
        }
        &.owl-theme {
            .owl-nav {
    
                [class*=owl-] {
                    left: 0;
                    bottom: 50%;
                    transform: translateY(-50%);
    
                    &.owl-next {
                        left: auto;
                        right: 0;
                        bottom: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .events-item {
        padding-left: 0;
        .events-text {
            margin-top: 20px;
            h3 {
                font-size: 23px;
            }
        }
        .events-content {
            margin-top: 25px;
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
            .icon {
                i {
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                }
            }
        }
        .events-btn {
            margin-top: 20px;
            .events-btn-one {
                font-size: 14px;
                margin-right: 20px;
            }
            .events-btn-two {
                font-size: 14px;
            }
        }
    }
    .donate-list-tab {
        .tabs {
            li {
                margin-bottom: 10px;
                font-size: 20px;
                a {
                    font-size: 20px;
                }
            }        
        }

        .tab_content {
            margin-top: 30px;
            padding: 20px;
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }

            .dot {
                &::before {
                    display: none;
                }
            }

            .tabs_item {
                .donate-content {
                    h3 {
                        font-size: 25px;
                    }
                }

                .donate-form {
                    .send-btn {
                        .default-btn {
                            padding: 10px 40px;
                        }
                    }
                }
            }
        }
    }
    .faq-section {
        .row {
            padding: 30px 10px;
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                padding: 20px 50px 20px 18px;
                font-size: 18px;

                i {
                    right: 18px;
                    bottom: 40px;
                }
            }
        }
    }
    .faq-image {
        margin-top: 30px;
    }
    .testimonials-slider {
        .testimonials-item {
            text-align: center;
            padding: 30px 30px 50px;
            .content {
                padding-left: 0;
                .image {
                    position: relative;
                    display: inline-block;
                }
                .text {
                    &::before {
                        display: none;
                    }
                    h3 {
                        margin-bottom: 5px;
                        margin-top: 15px;
                    }
                    p {
                        margin-top: 8px;
                    }
                }
            }
            .dot {
                &::before {
                    display: none;
                }
            }
        }
        
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0px;
                    top: 100%;
                    transform: translateY(-100%);
                    color: #000000;
                    
                    &.owl-next {
                        right: 0px;
                    }
                }
            }
        }
    }
    .blog-slider {
        .blog-item {
            padding: 16px;

            .post-meta {
                li {
                    font-size: 14px;
                }
            }
        }    
    }
    .others-one-content {
        margin-bottom: 30px;
        text-align: center;
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .others-one-btn {
            position: relative;
        }
    }
    .others-two-content {
        text-align: center;
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .others-two-btn {
            position: relative;
        }
    }
    .single-footer-widget {
        h3 {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
    .footer-bottom-text {
        text-align: center;
    }
    .footer-bottom-social {
        text-align: center;
        margin-top: 20px;
    }
    .demo-modal-panel {
        display: none;
    }
    .example-demo-modal {
        display: none;
    }
    
    .single-fun-fact {
        padding: 20px;
    }
    .donate-list-tab {
        .tab_content {
            .tabs_item {
                .donate-progress {
                    .donate-progress-content {
                        margin-bottom: 30px;
                        .text-right {
                            transform: translateY(-90%);
                        }
                    }
                }
            }
        }
    }
    .faq-section {
        background-color: #f9f9f9;
    }
    .go-top.active {
        top: 97%;
    }
    .testimonials-slider {
        .testimonials-item {
            .content {
                .image {
                    top: 0;
                }
            }
        }
    }
    .events-image-slider {
        .item {
            .content {
                span {
                    font-size: 13px;
                }
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    .user-area-all-style {
        .contact-form-action {
            padding: 20px;
            form {
                .default-btn {
                    width: 100%;
                }
                .form-condition {
                    margin-bottom: 0px;
                }
            }
            .forget {
                margin-bottom: 30px;
                float: unset;
            }
        }
        .contact-form-action {
            .now-register {
                float: unset;
            }
        }
    }
    .user-area-all-style .contact-form-action .forget {
        margin-bottom: 30px !important;
        flex-basis: block;
    }
    .coming-soon-area  {
        .coming-soon-content {
            height: 100%;
            padding: 30px;
            box-shadow: none;
            h1 {
                font-size: 25px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 13px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 20px;
                div {
                    font-size: 30px;
                    margin-left: 3px;
                    margin-right: 3px;
                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 35px;
                .input-newsletter {
                    height: 55px;
                    padding-left: 13px;
                    font-size: 15px;
                }
                button {
                    position: relative;
                    height: auto;
                    padding: 14px 30px;
                    font-size: 14px;
                    margin-top: 15px;
                }
            }
        } 
    }
    .error-area {
        padding: 70px 0;
        .error-content-wrap {
            p {
                margin: 15px 0 20px;
                font-size: 15px;
            }
            h1 {
                font-size: 100px;
            }
        }
    }
    .cart-area {
        .cart-table {
            text-align: center;
            table {
                thead {
                    tr {
                        th {
                            padding: 0 40px 10px;
                            white-space: nowrap;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .cart-buttons {
            text-align: center;
            .text-right {
                text-align: center !important;
                margin-top: 0;
            }
        }
        .cart-totals {
            padding: 20px;
            margin-top: 35px;
            h3 {
                margin-bottom: 18px;
                font-size: 17px;
            }
            ul {
                li {
                    font-size: 13px;
                }
            }
            .default-btn {
                display: block;
                text-align: center;
            }
        }
        .checkout-area {
            .title {
                margin-bottom: 20px;
                font-size: 17px;
            }
        }
        .billing-details {
            padding: 15px;
            .form-group {
                margin-bottom: 15px;
                label {
                    margin-bottom: 7px;
                }
                .form-control {
                    height: 40px;
                    line-height: 40px;
                }
                textarea {
                    height: 100% !important;
                }
            }
            .form-check {
                label {
                    top: 2px;
                }
            }
        }
        .order-details {
            margin-top: 30px;
            .order-table {
                padding: 15px;
                table {
                    thead {
                        tr {
                            th {
                                white-space: nowrap;
                                padding-left: 15px;
                                padding-top: 10px;
                                padding-right: 15px;
                                padding-bottom: 10px;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                white-space: nowrap;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
            .payment-box {
                padding: 15px;
            }
        }
        .cart-area {
            .default-btn {
                display: block;
                text-align: center;
            }
        }
        .coupon-wrap {
            padding: 20px !important;
            margin-top: 30px;
        }
        .cart-wraps {
            padding: 20px;
            .continue-shopping-box {
                margin-bottom: 20px;
            }
            .default-btn {
                width: 100%;
            }
        }
        .coupon-wrap {
            .default-btn {
                width: 100%;
            }
        }
    }
    .checkout-area {
        .title {
            margin-bottom: 20px;
            font-size: 17px;
        }
        .billing-details {
            padding: 15px;
            .form-group {
                margin-bottom: 15px;
                label {
                    margin-bottom: 7px;
                }
                .form-control {
                    height: 40px;
                    line-height: 40px;
                }
                textarea {
                    height: 100% !important;
                }
            }
            .form-check {
                label {
                    top: 2px;
                }
            }
        }
        .order-details {
            margin-top: 30px;
            .order-table {
                padding: 15px;
                table {
                    thead {
                        tr {
                            th {
                                white-space: nowrap;
                                padding-left: 15px;
                                padding-top: 10px;
                                padding-right: 15px;
                                padding-bottom: 10px;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                white-space: nowrap;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
            .payment-box {
                padding: 15px;
            }
        }
    }
    .product-details-area {
        .product-details-desc {
            margin-top: 25px;
            h3 {
                font-size: 16px;
            }
            .price {
                margin-bottom: 13px;
            }
            .product-review {
                margin-bottom: 13px;
                .rating {
                    font-size: 13px;
                }
            }
        }
        .products-details-tab {
            margin-top: 30px;
            .tabs {
                margin-bottom: -11px;
                li {
                    margin-bottom: 10px;
                    a {
                        font-size: 12px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        padding-right: 20px;
                        padding-left: 40px;
                        .dot {
                            left: 20px;
                        }
                    }
                }
            }
            .tab_content {
                padding: 25px 20px;
                .tabs_item {
                    .products-details-tab-content {
                        .additional-information {
                            li {
                                span {
                                    display: block;
                                    width: 100%;
                                    margin-bottom: 2px;
                                }
                            }
                        }
                        h3 {
                            font-size: 16px;
                        }
                        .product-review-form {
                            .review-title {
                                .rating {
                                    i {
                                        font-size: 13px;
                                    }
                                }
                                .btn {
                                    position: relative;
                                    right: 0;
                                    top: 0;
                                    transform: unset;
                                    margin-top: 15px;
                                    display: block;
                                }
                            }
                            .review-comments {
                                .review-item {
                                    padding-right: 0;
                                    .rating {
                                        i {
                                            font-size: 13px;
                                        }
                                    }
                                    h3 {
                                        font-size: 15px;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                    .review-report-link {
                                        position: relative;
                                        top: 0;
                                        margin-top: 15px;
                                    }
                                }
                            }
                            .review-form {
                                form {
                                    .review-rating {
                                        display: none;
                                    }
                                    .form-group {
                                        label {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .custom-payment-options {
                .payment-methods a img {
                    width: 39px;
                }
            }
        }
    }
    .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .btn {
        width: 100%;
    }
    .product-details-area .product-details-image {
        margin-top: 30px;
    }
    .page-navigation-area {
        margin: 0px auto 0;
    }
    .blog-details-area {
        .blog-details-desc {
            .article-content {
                .entry-meta {
                    ul {
                        li {
                            display: block;
                            font-size: 15px;
                            margin-right: 0;
                            margin-bottom: 5px;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
                h3 {
                    line-height: 1.5;
                    margin-bottom: 10px;
                    margin-top: 20px;
                    font-size: 22px;
                }
                p {
                    font-size: 15px;
                }
                .features-list {
                    li {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget-area .widget {
            padding: 0;
        }
        blockquote {
            padding: 30px !important;
            p {
                font-size: 16px !important;
            }
        }
        .blockquote {
            padding: 30px !important;
            p {
                font-size: 16px !important;
            }
        }
        .comments-area {
            .comment-author {
                .avatar {
                    left: 0;
                    position: relative;
                    display: block;
                    margin-bottom: 10px;
                }
                font-size: 15px;
                margin-bottom: 0.3em;
            }
            .comment-body {
                padding-left: 0;
            }
            .comments-title {
                font-size: 19px;
                &::before {
                    left: -20px;
                }
            }
            .comment-metadata {
                font-size: 12px;
            }
            .comment-respond {
                padding: 20px;
                .comment-reply-title {
                    font-size: 19px;
                    &::before {
                        left: -20px;
                    }
                }
                .comment-form-author {
                    width: 100%;
                    padding-right: 0;
                }
                .comment-form-email {
                    width: 100%;
                    padding-left: 0;
                }
                .form-submit {
                    input {
                        padding: 12px 25px 10px;
                        width: 100%;
                    }
                }
            }
        }
    }
    .blog-details-area {
        .b-d-s-item {
            margin-bottom: 30px;
            img {
                margin-bottom: 0;
            }
            h3 {
                margin-top: 10px !important;
            }
            &.mb-0 {
                margin-bottom: 10px !important;
            }
        }
    }
    .blog-details-area .blog-details-desc .article-content .article-image {
        margin-bottom: 20px;
    }
    .blog-details-area .comments-area .children {
        margin-left: 0;
    }
    .blog-details-area {
        .widget-area {
            .widget:first-child {
                margin-top: 35px;
            }
        }
    }
    .page-title-area {
        padding-top: 130px;
        padding-bottom: 40px;
        .page-title-content {
            h2 {
                font-size: 30px;
            }
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
    .testimonials-sections {
        .testimonials-item {
            padding: 20px;
            .content {
                padding-left: 0;
                text-align: center;
                .image {
                    position: unset;
                    margin-bottom: 10px;
                    i {
                        display: none;
                    }
                }
                .text {
                    &::before {
                        display: none;
                    }
                }
            }
            .dot::before {
                display: none;
            }
        }
    }
    .quick-contact {
        margin-top: 50px;
        padding: 20px;
        h3 {
            font-size: 24px !important;
        }
    }
    .contact-area {
        .section-title h2 {
            font-size: 24px;
        }
        .contact-wrap {
            padding: 20px;
        }
    }
    .terms-conditions {
        h3 {
            font-size: 22px;
        }
    }
    .faq-contact-area #contactForm {
        padding: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .faq-accordion {
        .accordion {
            .accordion-title {
                i {
                    bottom: 24px;
                }   
            }
        }
    } 
    .overview-dollar {
        margin-top: 0;
    }
    .overview-section {
        .container-fluid {
            max-width: 540px;
        }
        .overview-content {
            h3 {
                font-size: 18px;
            }
            span {
                font-size: 14px;
            }
        }
        .overview-dollar p {
            font-size: 13px;
        }
    }
    .overview-image-slider {
        .image {
            height: 215px;
        }
        .image-1 {
            height: 215px;
        }
        .image-2 {
            height: 215px;
        }
        .image-3 {
            height: 215px;
        }
        .image-4 {
            height: 215px;
        }
    }
    .user-area-all-style {
        .contact-form-action {
            .now-register {
                float: right;
            }
        }
    }
    .user-area-all-style .contact-form-action form .form-condition .agree-label {
        margin-bottom: 30px;
    }
    .cart-area .cart-wraps .continue-shopping-box {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .default-btn {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .optional-btn {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .section-title {
        margin-bottom: 30px;
        span {
            font-size: 12px;
        } 
        h2 {
            font-size: 28px;
        }  
    } 
    .pb-70 {
        padding-bottom: 40px;
    }
    .pt-100 {
        padding-top: 70px;
    }
    .pb-100 {
        padding-bottom: 70px;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .navbar {
        padding: 10px;
        .white-logo {
            display: none;
        }
        .black-logo {
            display: block;
            position: relative;
            top: 2px;
        }        
    }
    .navbar-light {
        background-color: #fff!important;
        .navbar-nav {
            .nav-item {
                padding-top: 5px;
                padding-bottom: 5px;
                a {
                    margin-left: 0;
                    margin-right: 0;
                    color: #000000;
                    &::before {
                        display: none;
                    }
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .others-option {
            .option-item {
                .dropdown-toggle {
                    color: #000000;
                    i {
                        color: #000000;
                    }
                }
                .burger-menu {
                    i {
                        color: #000000;
                    }
                }
                .default-btn {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 12px;
                }
            }
        }
    }
    .contactModal {
        .modal-dialog {
            .modal-content {
                padding: 50px 20px;
                form {
                    #msgSubmit {
                        font-size: 14px;
                    }
                    .help-block {
                        ul {
                            li {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-modal {
        .sidebar-modal-inner {
            width: 80%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .home-slider {
        .home-item {
            height: 100vh;
            padding-top: 250px;
            padding-bottom: 200px;
            .slider-content {
                margin-top: 0;
                text-align: center;
                h1 {
                    font-size: 32px;
                }
                .slider-btn {
                    margin-top: 20px;
                }
            }
            .shape {
                display: none;
            }
        }
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: 100%;
                    transform: translateY(-100%);
                }
            }
        }
    }
    .single-fun-fact {
        .icon {
            i {
                font-size: 45px;
            }
        }
        h3 {
            font-size: 35px;
            margin: 5px 0 5px 0;

            .sign-icon {
                font-size: 30px;
                margin-left: -5px;
            }            
        }
    }
    .about-area-content {
        span {
            font-size: 12px;
        }
        h3 {
            font-size: 25px;
        }
    }
    .about-list-tab {
        .tabs {
            li {
                a {
                    padding: 8px 15px;
                    font-size: 12px;
                }
            }
        }
        .tab_content {
            .tabs_item {
                .list {
                    margin-top: 20px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .about-image {
        img {
            width: 100%;
        }
        .image-content {
            position: relative;
            bottom: 0;
            left: 0;
            max-width: 100%;

            h3 {
                font-size: 25px;
            }
        }
    }
    .overview-image-slider {
        height: 230px;

        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    &.owl-next {
                        left: 15px;
                    }
                }
            }
        }

        .owl-stage-outer {
            height: 230px;
            .owl-stage {
                height: 230px;
                .owl-item {
                    height: 230px;
                    .cloned {
                        height: 230px;
                    }
                }
            }
        }
    
        .image {
            height: 230px;
        }
        .image-2 {
            height: 230px;
        }
        .image-3 {
            height: 230px;
        }
        .image-4 {
            height: 230px;
        }
    }
    .pl-0, .px-0 {
        padding-left: 15px !important;
    }
    .pr-0, .px-0 {
        padding-right: 15px !important;
    }
    .overview-content {
        padding: 25px;
        margin-bottom: 30px;
        h3 {
            font-size: 20px;
        }
    }
    .overview-dollar {
        padding: 20px;
        text-align: center;
        h3 {
            font-size: 30px;
        }
    }
    .overview-dollar-two {
        padding: 30px 35px;
        text-align: center;
        h3 {
            font-size: 30px;
        }
    }
    .causes-shape {
        .shape-two {
            bottom: 0 !important;
            top: unset;
        }
    }
    .single-causes {
        padding: 15px;

        .causes-list {
            padding: 16px 20px;

            li {
                margin-right: 20px;
                &::before {
                    right: -12px;
                }
            }
        }
        .content {
            h3 {
                font-size: 18px;
            } 
        }
    }
    .donors-section {
        .section-title {
            margin-bottom: 30px;
            span {
                font-size: 14px;
            }
            h2 {
                font-size: 28px;
            }
        }
    }
    .events-image-slider {
        .item {
            .content {
                position: relative;
                max-width: 100%;
                padding: 30px;
                span {
                    font-size: 15px;
                }
            }
        }
        &.owl-theme {
            .owl-nav {
    
                [class*=owl-] {
                    left: 0;
                    bottom: 50%;
                    transform: translateY(-50%);
    
                    &.owl-next {
                        left: auto;
                        right: 0;
                        bottom: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .events-item {
        padding-left: 0;
        .events-text {
            margin-top: 20px;
            h3 {
                font-size: 25px;
            }
        }
        .events-content {
            margin-top: 25px;
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
            .icon {
                i {
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                }
            }
        }
        .events-btn {
            margin-top: 20px;
            .events-btn-one {
                font-size: 14px;
                margin-right: 20px;
            }
            .events-btn-two {
                font-size: 14px;
            }
        }
    }
    .donate-list-tab {
        .tabs {
            li {
                margin-bottom: 10px;
                font-size: 20px;
                a {
                    font-size: 20px;
                }
            }        
        }

        .tab_content {
            margin-top: 30px;
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }

            .dot {
                &::before {
                    display: none;
                }
            }

            .tabs_item {
                .donate-content {
                    h3 {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                padding: 20px 50px 20px 18px;
                font-size: 18px;

                i {
                    right: 18px;
                    bottom: 24px;
                }
            }
        }
    }
    .faq-image {
        margin-top: 30px;
        text-align: center;
    }
    .testimonials-slider {
        .testimonials-item {
            text-align: center;
            padding: 30px 30px 50px;
            .content {
                padding-left: 0;
                .image {
                    position: relative;
                    display: inline-block;
                }
                .text {
                    &::before {
                        display: none;
                    }
                    h3 {
                        margin-bottom: 5px;
                        margin-top: 15px;
                    }
                    p {
                        margin-top: 8px;
                    }
                }
            }
            .dot {
                &::before {
                    display: none;
                }
            }
        }
        
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0px;
                    top: 100%;
                    transform: translateY(-100%);
                    color: #000000;
                    
                    &.owl-next {
                        right: 0px;
                    }
                }
            }
        }
    }
    .blog-slider {
        .blog-item {
            padding: 16px;

            .post-meta {
                li {
                    font-size: 14px;
                }
            }
        }    
    }
    .others-one-content {
        text-align: center;
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .others-one-btn {
            position: relative;
        }
    }
    .others-two-content {
        text-align: center;
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .others-two-btn {
            position: relative;
        }
    }
    .single-footer-widget {
        h3 {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
    .footer-bottom-text {
        text-align: center;
    }
    .footer-bottom-social {
        text-align: center;
        margin-top: 20px;
    }
    .example-demo-modal {
        .inner {
            width: 355px;
        }
    }

    .donate-list-tab {
        .tab_content {
            .tabs_item {
                .donate-progress {
                    .donate-progress-content {
                        margin-bottom: 30px;
                        .text-right {
                            transform: translateY(-90%);
                        }
                    }
                }
            }
        }
    }
    .faq-section {
        background-color: #f9f9f9;
    }
    .go-top.active {
        top: 97%;
    }
    .testimonials-slider {
        .testimonials-item {
            .content {
                .image {
                    top: 0;
                }
            }
        }
    }
    .single-fun-fact {
        padding: 20px;
    }
    .events-image-slider {
        .item {
            .content {
                span {
                    font-size: 13px;
                }
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    .product-details-area {
        .product-details-image {
            margin-bottom: 30px;
        }
    }
    .page-navigation-area {
        margin: 0px auto 0;
    }
    #secondary {
        margin-top: 35px;
    }
    .page-title-area {
        padding-top: 130px;
        padding-bottom: 40px;
        .page-title-content {
            h2 {
                font-size: 30px;
            }
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
    .blog-details-area .blog-details-desc .article-content h3 {
        font-size: 25px;
    }
    .testimonials-sections {
        .testimonials-item {
            padding: 20px;
            .content {
                padding-left: 0;
                text-align: center;
                .image {
                    position: unset;
                    margin-bottom: 10px;
                    i {
                        display: none;
                    }
                }
                .text {
                    &::before {
                        display: none;
                    }
                }
            }
            .dot::before {
                display: none;
            }
        }
    }
    .quick-contact {
        margin-top: 50px;
        padding: 30px;
    }
    .contact-area {
        .contact-wrap {
            padding: 30px;
        }
    }
    .terms-conditions {
        h3 {
            font-size: 22px;
        }
    }
    .faq-contact-area #contactForm {
        padding: 30px;
    }
}

@media only screen and (min-width : 992px) and (max-width : 1199px) {
    .navbar-light {
        .others-option {
            .option-item {
                .default-btn {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 20px;
                    padding-right: 20px;
                    font-size: 12px;
                }
            }
        }
    }
    .home-slider {
        .home-item {
            .slider-content {
                h1 {
                    font-size: 55px;
                }
            }
        }

        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: 90%;
                    transform: translateY(-100%);
                }
            }
        }
    }  
    .single-fun-fact {
        padding: 20px 30px;
        .icon {
            i {
                font-size: 45px;
            }
        }
        h3 {
            font-size: 35px;
            margin: 5px 0 5px 0;

            .sign-icon {
                font-size: 30px;
                margin-left: -5px;
            }            
        }
    }
    .about-area-content {
        h3 {
            font-size: 40px;
        }   
        p {
            font-size: 14px;
        } 
    }
    .text {
        p {
            font-size: 14px;
        }
    }
    .events-item {
        .events-text{
            h3 {
                font-size: 25px;
            }
        }
        .events-content {
            margin-top: 20px;
            h3 {
                font-size: 20px;
            }
        }        
    }
    .testimonials-slider {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0px;
                    top: 100%;
                    transform: translateY(-100%);
                    color: #000000;
                    
                    &.owl-next {
                        right: 0px;
                    }
                }
            }
        }
    }
    .sidebar-modal {
        .sidebar-modal-inner {
            width: 50%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                padding: 20px 20px 20px 16px;
                font-size: 18px;
            }
        }
    }
    .single-causes {
        padding: 10px;

        .content {
            h3 {
                font-size: 18px;
            }
        }
        .causes-list {
            li {
                margin-right: 20px;

                &::before {
                    right: -10px;
                }                
            }   
        }     
    }
    .overview-content {
        padding: 20px;
        h3 {
            font-size: 18px;
        }
    }
    .overview-dollar {
        padding: 44px 14px;

        h3 {
            font-size: 20px;
        }
        p {
            font-size: 13px;
        }
    }
    .donate-list-tab {
        .tabs {
            li {
                a {
                    font-size: 20px;
                }
            }
        }
        .tab_content {
            padding: 25px;
            .tabs_item {
                .donate-form {
                    .form-group {
                        .form-control {
                            font-size: 14px;
                            padding-right: 0;
                        } 
                    }
                } 
            }          
        }
    }
    .others-one-content {
        h3 {
            font-size: 18px;
        }
        .others-one-btn {
            a {
                font-size: 14px;
            }
        }
    }
    .others-two-content {
        h3 {
            font-size: 18px;
        }
        .others-two-btn {
            a {
                font-size: 14px;
            }
        }
    }
    .footer-bottom-text {
        p {
            font-size: 15px;
        }
    }
    .testimonials-slider {
        .testimonials-item {
            padding: 20px;
        }
    }
    .go-top.active {
        top: 92%;
    }
    .faq-section { 
        background-color: #f9f9f9;
    }
    .main-nav nav .navbar-nav .nav-item a {
        margin-left: 12px;
        margin-right: 12px;
    }
    .home-slider .home-item {
        height: calc(100vh - 10vh);
        padding-top: 200px;
        padding-bottom: 200px;
    }
}

@media only screen and (min-width: 1824px) {
    .home-slider {
        .home-item {
            .slider-content {
                margin-top: 45px;
            }
        }
    }
}

/* Only Mobil Menu Max width 991px */
@media only screen and (max-width: 991px) {
    .mean-container {
        .mean-bar {
            background-color: #037695;
            box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.05);
            height: 70px;
        }
        a {
            &.meanmenu-reveal {
                padding: 24px 15px 0 0;
                color: #ffffff;
                span {
                    background-color: #ffffff;
                    position: relative;
                    top: 8px;
                    margin-top: -5px;
                }
            }
        }
    }
    .mobile-nav .logo {
        top: 22px;
    }
    .mobile-nav {
        &.mean-container {
            .mean-nav {
                margin-top: 70px;
                ul {
                    li {
                        a {
                            &:hover {
                                color: #d80650;
                            }
                            &.active {
                                color: #d80650;
                            }
                        }
                    }
                }
            }
        }
    }
    .nav-area .others-option {
        display: none;
    }
    .mobile-nav nav .navbar-nav {
        height: 325px;
    }
} 