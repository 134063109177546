.content {
  white-space: pre-line;
}
.footer-section {
  padding-top: 50px;
}
.response {
  color: #fff;

  a {
    color: #ccc;
    text-decoration: underline;
  }
}

