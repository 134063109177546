.stories-content .section-title .field.field-group {
  width: 440px;
  text-align: left;
  margin-bottom: 0;
}
.stories-content
  .section-title
  .field.field-group
  .css-2b097c-container
  .css-15e6m3o-control,
.stories-content
  .section-title
  .field.field-group
  .css-2b097c-container
  .css-17vnx9a-control {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  outline: none !important;
}
.stories-content .section-title {
  margin-bottom: 0;
}
.stories-content .section-title span.css-1okebmr-indicatorSeparator::before {
  opacity: 0;
}
.stories-content .stories-list {
  padding: 0;
}
// .stories-content .section-title .css-g1d714-ValueContainer {
//     padding: 2px 10px !important;
// }

.stories-content .section-title .field.field-group + .field.field-group {
  margin-left: 10px;
}

.stories-content .section-title form {
  display: flex;
  align-items: center;
}
.stories-content .section-title .field.field-group .ml-5 {
  margin: 0 !important;
}

.stories-content .section-title .field.field-group .ml-5 span {
  display: none;
}
.stories-content .section-title form .accent-button {
  margin-left: 10px;
}

.stories-content .section-title form label {
  margin-bottom: 0;
  margin-right: 10px;
}
// .stories-content .section-title .css-g1d714-ValueContainer{overflow-y: auto; max-height: 66px;padding-left: 36px !important;}
.search-icon {
  padding: 0px 4px 0px 11px;
}

.search-icon i {
  color: #cccccc;
}
.stories-content .css-1wa3eu0-placeholder {
  font-size: 0;
}
.stories-content .css-1wa3eu0-placeholder::after {
  content: "Select or Search...";
  font-size: 16px;
  color: #ccc;
}

.stories-content .section-title .field.field-group .search-icon {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.stories-content .section-title .field.field-group .search-icon .fa {
  color: #ccc;
}
.stories-feed.display-none {
  display: none !important;
}
.section-title.filter-tags {
  margin-top: 0;
}
.filter-tags-wrapper {
  width: 100%;
  .weather-widget-placeload {
    padding: 0;
    margin: 0;

    .body {
      height: auto;

      .rect {
        height: 38px;
        margin: 0;
      }
    }
  }
}
.stories-feed .columns.is-multiline .story-feed-item .featured-image {
  display: block;
  border-radius: 16px;
  min-height: 190px;
  object-fit: cover;
  width: 100%;
  /* height: 100%;*/
  height: 370px;
  border: 1px solid rgba(192, 192, 187, 0.25);
}
.stories-wrapper .inner-wrapper .stories-content .stories-feed h3 {
  margin-bottom: 30px;
}

.user-info .art-title {
  width: calc(100% - 32px);
}
.load-more {
  background-color: #047695;
  color: #fff;
  &:hover{
    color: #fff;
  }
}
@media screen and (max-width: 1199px) {
  .stories-feed .columns.is-multiline .story-feed-item .featured-image {
    height: 270px;
  }
  .stories-content .section-title .field.field-group {
    width: 360px;
  }
}

@media screen and (max-width: 991px) {
  .stories-wrapper
    .inner-wrapper
    .stories-content
    .stories-feed
    .is-half-tablet {
    width: 33.3%;
  }
}
@media screen and (max-width: 767px) {
  .stories-wrapper
    .inner-wrapper
    .stories-content
    .stories-feed
    .is-half-tablet {
    width: 50%;
    display: inline-block;
  }
  .stories-wrapper .inner-wrapper .stories-content .section-title {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .stories-content .section-title form {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .stories-content .section-title form label {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }

  .stories-content .section-title .field.field-group {
    width: 80%;
    max-width: 350px;
  }
}
@media screen and (max-width: 539px) {
  .stories-wrapper
    .inner-wrapper
    .stories-content
    .stories-feed
    .is-half-tablet {
    width: 100%;
  }
  .stories-content .section-title form .accent-button {
    margin-left: 5px;
    width: 30px;
  }
}
