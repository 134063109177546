.news-banner-image {
  background-image: url("../../../Assets/img/now_showing_bg.png");
  padding-top: 350px !important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.image {
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}

.default-btn:disabled {
  cursor: not-allowed;
  background: #bbb;
  border-color: #bbb !important;

  &:hover {
    background: #bbb;
  }
}

.blog-item {
  min-height: 530px;
  padding: 25px 25px 60px 25px !important;

  .content .read-more {
    position: absolute;
    bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .news-banner-image {
    margin-top: 60px;
    padding-top: 180px !important;
  }
}
