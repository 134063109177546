.settings-wrapper {
  width: 100%;
  margin-left: 0;
}
.errors-msg {
  color: #df1b1b;
  position: absolute;
  font-size: 15px;
  padding: 0 10px;
  transition: 0.5s;
  transform: perspective(300px) rotateX(90deg);
  transform-origin: 0 0;

  &.show-error {
    transform: perspective(300px) rotateX(0);
  }
}

.settings-form {
  .field-group {
    textarea {
      height: auto !important;
    }

    input {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    &.is-invalid {
      border-color: #df1b1b57 !important;
    }
  }
}

.settings-wrapper {
  background-color: #f4f4f4;
  min-height: calc(100vh - 60px);
}

#hidden-img-input,
#hidden-main-img-input {
  position: fixed;
  top: -1000000px;
  left: -1000000px;
}

.user-img-wrapper {
  width: 200px;
  margin: 0 auto;
  position: relative;

  .upload-img-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #3d70b2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
    box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    z-index: 1;

    svg {
      stroke: #fff;
      width: 24px;
      height: 24px;
    }
  }
  .user-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18);
    object-fit: cover;
  }
}
