/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../fonts/FlatIcon2/Flaticon.eot");
	src: url("../fonts/FlatIcon2/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../fonts/FlatIcon2/Flaticon.woff2") format("woff2"),
	url("../fonts/FlatIcon2/Flaticon.woff") format("woff"),
	url("../fonts/FlatIcon2/Flaticon.ttf") format("truetype"),
	url("../fonts/FlatIcon2/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-search:before { content: "\f100"; }
.flaticon-cancel:before { content: "\f101"; }
.flaticon-dots-menu:before { content: "\f102"; }
.flaticon-toolbox:before { content: "\f103"; }
.flaticon-box:before { content: "\f104"; }
.flaticon-medicine:before { content: "\f105"; }
.flaticon-brain:before { content: "\f106"; }
.flaticon-goal:before { content: "\f107"; }
.flaticon-goal-1:before { content: "\f108"; }
.flaticon-layers:before { content: "\f109"; }
.flaticon-left-arrow:before { content: "\f10a"; }
.flaticon-right-arrow:before { content: "\f10b"; }
.flaticon-right:before { content: "\f10c"; }
.flaticon-play:before { content: "\f10d"; }
.flaticon-facebook:before { content: "\f10e"; }
.flaticon-twitter:before { content: "\f10f"; }
.flaticon-instagram:before { content: "\f110"; }
.flaticon-linkedin:before { content: "\f111"; }