.state-city-information {
  span {
    margin-right: 5px;

    &:after {
     content: ",";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
.user-name{
  cursor: pointer;
}
.modal-user-avatar.avatar-wrap{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  min-width: 94px;
  border: 1.4px solid #d8d8d8;
  border-radius: 50%;
  margin: 15px auto 6px auto;
  img{
    display: block;
    width: 78px;
    height: 78px;
    min-width: 78px;
    border-radius: 50%;
  }
}
.avatar-wrap img{
  cursor: pointer;
}
.user-block {
  .stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;

    span {
      font-family: "Roboto", sans-serif;
      font-size: .9rem;
      color: #ABB0BE;
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        color: #777;
      }
    }
  }
}
.follow-modal {
  .tabs-name {
    margin: 0;
    margin-right: 15px;
    font-size: 17px;
    font-weight: 500 !important;
    padding: 7px 20px;
    border-radius: 5px;
    cursor: pointer;
    color: #444;
    transition: 0.5s;

    &.active {
      background: #dfdfdf;
    }
  }

  .follow-lists {
    display: flex;
    flex-direction: column;

    .not-found-div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: 46px;
    }

    .follow-list-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .story-avatar {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 46px;
        width: 46px;
        background: #fff;
        border-radius: 50%;
        border: 1.6px solid #cecece;
        transition: all 0.3s;

        img {
          display: block;
          height: 36px;
          width: 36px;
          border-radius: 50%;
        }
      }

      .meta {
        margin-left: 12px;

        a span {
          font-family: "Montserrat", sans-serif;
          font-size: 0.9rem;
          font-weight: 700;
          color: #393a4f;
        }
      }
    }
  }
}
