.message-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: #0000007a;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  &.show-modal {
    visibility: visible;
    opacity: 1;
  }

  .message-modal-wrapper {
    width: 600px;
    height: auto;
    background: #fff;
    border-radius: 8px;
    padding: 25px;

    .message-modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 21px;
        font-weight: 500 !important;
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }
}
