.registeration-wrapper.columns.is-gapless {
    display: none;
}
.theme-color {
    color: #037695;
}
.team-item{
    h2 {
        font-size: 3.3rem; 
    }
    span {
        font-size: 1.7rem;
    }
}

.registeration-content-heading h2 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 3.2rem;
    color: #fcfcfc;
    line-height: 1.3;
    margin-bottom: 0px;
    margin-bottom: 0px;
    text-transform: inherit;
}
.registeration-heading-content {
    max-width: 400px;
    margin: 0 auto;
}
.registeration-content-heading{
    min-height: 100vh;
    align-items: center;
    display: flex;
    padding: 3rem 1.5rem;
}
.registeration-right {
    width: 50%;
}
.registeration-left {
    width: 100%;
    /* max-width: 860px; */
    width: calc(100% - 50%);
    min-height: 100vh;  
    position: relative;
    background: #1f7693;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#1f7693),
      to(#1f7693)
    );
    background: linear-gradient(180deg, #1f7693, #1f7693);
    background-size: 400% 400%;
    -webkit-animation: gradientShift 12s ease infinite;
    animation: gradientShift 12s ease infinite;
}
.registeration-right-content-heading {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    min-width: 380px;
}
.registeration-form .field {
    margin-bottom: 0.75rem;
}
.registeration-right .form-wrapper  .avatar img {
    display: block;
    height: 94px;
    width: 94px;
    border-radius: 50%;
}
.registeration-form .control input::placeholder{color: #cecece; font-size: 13px; font-weight:300!important; letter-spacing: 0.5px;}
.registeration-right .form-wrapper  .badge{
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 3px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #1CE589;
}

.registeration-right .form-wrapper .avatar {
    position: relative;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    margin: 0 auto 20px auto;
    border: 1.4px solid #cecece;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.registeration-wrapper .form-wrapper .registeration-form .field .control .input-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.registeration-wrapper .form-wrapper .registeration-form .field .control {
    position: relative;
}
.registeration-wrapper .form-wrapper .registeration-form .field .control button, .registeration-wrapper .form-wrapper .registeration-form .field .control .button {
    height: 46px;
    font-size: .95rem;
    width: 100%;
    background: #1f7693;
    border: 0px;
    color: #fff;
    padding-left: 1.6em;
    padding-right: 1.6em;
    outline: none;
    box-shadow: none;
}
.registeration-wrapper .form-wrapper .registeration-form .field .control .input-icon i {
    color: #cecece;
}
.registeration-form .control input {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    color: #363636;
    box-shadow: none;
    width: 100%;
    height: 40px;
    padding-left: 40px;
    line-height: 1.5;
    font-size: .9rem;
}
.registeration-wrapper .form-wrapper .registeration-form .field .control .input-icon svg {
    height: 18px;
    width: 18px;
    stroke: #cecece;
    transition: stroke .3s;
}
.registeration-wrapper .registeration-right  .logo-wrap .wrap-inner {
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background-size: 400% 400%;
    -webkit-animation: gradientShift 45s ease infinite;
    animation: gradientShift 45s ease infinite;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.registeration-wrapper .registeration-right .logo-wrap {
    position: absolute;
    top: calc(50% - 50px);
    left: -50px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.registeration-right .form-inner{position: relative;}
.registeration-wrapper .registeration-right  .form-inner .logo-wrap .wrap-inner img {
    display: block;
    max-width: 66px;
}
.registeration-wrapper .registeration-right  .form-inner .logo-wrap .input:focus {
    border-color: #5596e6;
}
.registeration-wrapper .registeration-right  .form-inner .control input:focus {
    border-color: #5596e6;
};
.registeration-wrapper .registeration-right  .form-inner .control input:focus+.input-icon svg,  .registeration-wrapper .registeration-right  .form-inner .control input:focus+.input-icon i{
    stroke: #5596e6;
    color:#5596e6!important;
}
.registeration-right {
    min-height: 100vh;
}
.registeration{display:flex;}
.registeration-wrapper .form-wrapper .forgot-password a {
    color: #999;
    font-size: .9rem;
}
.registeration-wrapper .form-wrapper .registeration-form .field .control select {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    color: #363636;
    box-shadow: none;
    width: 100%;
    height: 40px;
    padding-left: 8px;
    line-height: 1.5;
    font-size: .9rem;
    color: #cecece;
}

@-webkit-keyframes gradientShift {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @keyframes gradientShift {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
@media only screen and (max-width: 1024px) {
    .registeration-wrapper .registeration-right .logo-wrap{ display: none;} 
}
@media only screen and (max-width: 991px) {
    .registeration-left, .registeration-right, .registeration-wrapper .registeration-right .logo-wrap{width:100%; display: none;}
    .registeration{display:block;}
    .registeration-right-content-heading {
        min-width: inherit;
        padding: 0px;
    } 
    
}
@media only screen and (max-width: 767px) {
    .registeration-right-content-heading {
        min-width: inherit;
        padding: 3rem 1.5rem;
    } 
    .team-item{
        h2 {
            font-size: 2.8rem; 
        }
        span {
            font-size: 1.4rem;
        }
    }
}

@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.Toastify__progress-bar {
    animation: Toastify__trackProgress linear 1;
}

.loading {
    min-height: 30px;
    img {
        width: 13%
    }
}
