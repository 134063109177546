.cstmStar {
  font-size: 24px !important;
}
.fa-star.cstmStar {
  color: #ff0000;
}

.post-stats button {
  border-color: transparent;
  padding: 18px;
}
.post-image {
  height: 375px;
  width: 100%;
  border: 1px solid #e8e8e8;
}
.slick-slider .slick-thumb {
  bottom: -120px;
}
.slick-slider .slick-thumb li {
  width: 168px;
  height: 114px;
  img {
    height: 100%;
    width: 100%;
  }
}

.artwork-details {
  .slick-slider {
    margin-bottom: 20px;
    height: 100% !important;
    .slick-list {
      .slick-track {
        height: 100% !important;
      }
      height: 100% !important;

      .slick-slide>div {
        height: 100% !important;
      }
    }

    .slick-prev {
      left: 0;
      z-index: 1;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 30px !important;
    }
  }
}
.page-image{
  cursor: pointer;
}
.icon-edit {
  cursor: pointer;
}

.artWorks-link {
  padding: 20px 0;

  a {
    background: #0f7c991a;
    padding: 1px 7px;
    border-radius: 7px;

    i {
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}
.artWorks-dropdown {
  .custom-dropdown.navbar-item {
    padding-right: 0;
  }
  .nav-drop {
    position: absolute;
    top: 57%;
    right: 0;
    width: 150px;
    background: #fff;
    border: 1px solid;
    border-radius: 4px;
    margin-top: 16px;
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none;
    transition: all 0.2s;
    z-index: 100;
    -webkit-box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
    border-color: #e8e8e8;
    padding-top: 0;

    .media-content {
      padding: 10px 15px;
      cursor: pointer;
      font-weight: 500;
      font-size: .85rem;
      color: #393a4f;
      -webkit-transition: all .3s;
      transition: all .3s;

      &:hover {
        background: #fafafa;

        span {
          color: #5596e6 !important;

          svg {
            stroke: #5596e6 !important;
          }
        }
      }

      span {
        svg {
          width: 24px;
          height: 24px;
          stroke: #757a91;
          transform: scale(0.8);
          margin-right: 5px;
        }
      }
    }

    .inner {
      position: relative;

      .media {
        display: flex;
        flex-direction: column;
        padding-bottom: .5rem;
        padding-top: .5rem;

        .media-content {
          width: 100%;
        }
      }

      .nav-drop-body {
        max-height: 420px;
        padding: 0 10px;
        overflow-y: auto;
      }
    }

    &.is-active {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }
}

.post-image-wrap {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  flex-wrap: wrap;

  .post-image-wrapper {
    position: relative;

    .post-image-item {
      border-radius: 24px;
      overflow: hidden;
      margin: 8px;
    }

    img {
      object-fit: cover;
      transition: .4s;
    }

    .zoom-image {
      position: absolute;
      top: 8px;
      left: 8px;
      bottom: 8px;
      right: 8px;
      cursor: pointer;
      backdrop-filter: blur(1.5px);
      background: rgba(0, 0, 0, 0.4);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.4s;
      opacity: 0;
      visibility: hidden;
      z-index: 1;

      svg {
        stroke: #fff;
        width: 24px;
        height: 24px;
      }
    }

    &:hover .zoom-image {
      visibility: visible;
      opacity: 1;
    }

    &:hover img {
      transform: scale(1.2);
    }
  }

  &.one-item {
    .post-image-wrapper {
      width: 100%;
    }
  }

  &.two-items {
    .post-image-wrapper {
      width: 50%;

      img {
        height: 230px;
      }
    }
  }

  &.many-items {
    .post-image-wrapper {
      width: 33.3333%;

      img {
        height: 180px;
      }
    }
  }

  .zoom-image-modal {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000bf;
    backdrop-filter: blur(1.5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;

    &.open {
      visibility: visible;
      opacity: 1;
    }

    .zoom-image {
      padding: 20px;
      width: 100%;
      height: 100%;
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 35px;
        height: 35px;
        fill: #fff;
        z-index: 1111;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: 0.2s;
        transform: scale(0);

        &.open {
          transform: scale(1);
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .slick-slider .slick-thumb li {
    width: 98px;
    height: 54px;
  }
}

@media only screen and (max-width: 767px) {
  .slick-slider .slick-thumb li {
    width: 148px;
    height: 94px;
  }
}

.slides {
  position: relative;
  .slick-prev, .slick-next {
    position: absolute;
    top: 50%;
  }
  .slick-prev {
    left: 3%;
    z-index: 1;
  }
  .slick-next {
    right: 3%;
  }
}

.report-art-wrapper {
  position: relative;
  margin-left: 10px;

  .report-artwork-btn {
    background: transparent;
    padding: 5px !important;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #252525;
    transition: .3s;
    transform: scale(1);

    &:hover {
      border-color: #037694;
      color: #037694;
    }

    &.hide {
      transform: scale(0);
    }
  }

  .report-done {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #5eb52f;
    transition: .3s;
    transform: scale(0);

    &.show {
      transform: scale(1);
    }
  }
}
