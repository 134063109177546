@import url("../../../Assets/main/bulma.css");
@import url("../../../Assets/main/app.css");

/* line 67, src/assets/scss/layout/_layout-layout.scss */
.container.is-fluid {
  margin: 0 !important;
}

.followBtn {
  padding: 0px 16px !important;
}

.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .avatar-wrap {
    .disabled-follow-btn {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .badge {
    cursor: pointer;
    position: relative;
    .loading-follow-btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(255, 255, 255, 0.49);
      border-radius: 50%;
    }
    &.notFollowing {
      background: #a885fb !important;
    }
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.collections-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.collections-header > a.is-active {
  color: #393a4f;
  border-color: #5596e6;
}

.collections-header > a {
  margin-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  color: #cecece;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.collections-wrap {
  display: none;
  animation: fadeInLeft 0.5s;
  padding: 30px 0;

  &.is-active {
    display: block;
  }
}

@media (max-width: 767px) {
  .collections-wrap .collection .video-collection .episode {
    width: calc(100% - 20px);
  }

  .collections-header.is-home-v2 {
    padding-left: 58px;
  }
}

// @media (min-width: 1301px) {
//   .stories-wrapper .inner-wrapper {
//     width: calc(100% - 280px) !important;
//     margin-left: 280px !important;
//   }
// }

.collection {
  .header {
    display: flex;
    align-items: center;

    h4 {
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      font-weight: 700;
    }

    a {
      padding: 0 24px;
      font-size: 0.8rem;
    }
  }

  .video-collection {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 20px 0;
    margin-left: -10px;
    margin-right: -10px;

    //margin-bottom: 20px;

    .episode {
      margin: 10px;
      width: calc(25% - 20px);

      .episode-thumbnail {
        position: relative;

        &:hover {
          .episode-overlay {
          }

          .play-button {
            opacity: 1;
            transform: scale(1) rotate(0);
          }
        }

        .episode-overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 6px;

          transition: all 0.3s;
          z-index: 1;
        }

        .episode-duration {
          position: absolute;
          bottom: 10px;
          left: 10px;
          padding: 3px 6px;
          background: black;
          border-radius: 4px;

          font-size: 0.7rem;
          z-index: 2;
        }

        .play-button {
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 20px);
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          opacity: 0;
          transform: scale(0.7) rotate(-180deg);
          transition: all 0.3s;

          svg {
            height: 38px;
            width: 38px;
            stroke-width: 1px;
          }
        }

        img {
          display: block;
          border-radius: 6px;
        }
      }

      .episode-meta {
        display: flex;

        //align-items: center;
        margin-top: 8px;

        img {
          display: block;
          height: 32px;
          width: 32px;
          min-width: 32px;
          border-radius: 50%;
        }

        .info {
          margin-left: 8px;

          span {
            display: block;
            line-height: 1.2;
            max-width: 200px;
            &:first-child {
              font-family: "Montserrat", sans-serif;
              font-weight: 600;
              font-size: 0.85rem;
            }

            &:last-child {
              font-family: "Roboto", sans-serif;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

/* line 76, src/assets/scss/layout/_layout-layout.scss */
.view-wrapper {
  padding: 60px 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 80, src/assets/scss/layout/_layout-layout.scss */
.view-wrapper.is-side {
  width: calc(100% - 70px);
  margin-left: 70px;
}

/* line 85, src/assets/scss/layout/_layout-layout.scss */
.view-wrapper.is-full {
  padding: 60px 0 0 0;
}

/* line 89, src/assets/scss/layout/_layout-layout.scss */
.view-wrapper .view-wrap {
  padding: 20px 0;
}

/* line 92, src/assets/scss/layout/_layout-layout.scss */
.view-wrapper .view-wrap.is-headless {
  padding-top: 10px;
}

/* line 98, src/assets/scss/layout/_layout-layout.scss */
.inbox-wrapper {
  position: absolute;
  top: 58px;
  left: 0;
  width: 100%;
  height: calc(100% - 58px);
}

/* line 106, src/assets/scss/layout/_layout-layout.scss */
#profile {
  padding: 80px 0;
}

/* line 110, src/assets/scss/layout/_layout-layout.scss */
.main-container {
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 114, src/assets/scss/layout/_layout-layout.scss */
.main-container.has-sidebar {
  width: calc(100% - 300px);
  margin-left: 300px;
}

/* line 125, src/assets/scss/layout/_layout-layout.scss */
.columns.no-mb {
  margin-bottom: 0 !important;
}

/* line 131, src/assets/scss/layout/_layout-layout.scss */
.column.is-paddingless {
  padding: 0;
}

/* line 135, src/assets/scss/layout/_layout-layout.scss */
.column.no-padding-left {
  padding-left: 0;
}

/* line 139, src/assets/scss/layout/_layout-layout.scss */
.column.no-padding-right {
  padding-right: 0;
}

/* line 144, src/assets/scss/layout/_layout-layout.scss */
.card-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 148, src/assets/scss/layout/_layout-layout.scss */
.card-row .card-flex {
  width: calc(25% - 16px);
  margin: 8px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 6px;
}

/* line 163, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium {
  padding-bottom: 0 !important;
  padding-top: 4rem !important;
  background: #f5f5f5;
}

/* line 168, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-head {
  padding-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}

/* line 176, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-head .head-text h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  color: #393a4f;
}

/* line 182, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-head .head-text p {
  font-size: 1rem;
  color: #999;
}

/* line 191, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-head .head-action .buttons .button.action-button {
  height: 36px;
  min-width: 140px;
}

/* line 196, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-head .head-action .buttons .button.chat-button {
  background: transparent;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #3d70b2;
  font-weight: 500;
}

/* line 209, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body {
  padding-top: 3rem;
}

/* line 212, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .footer-column {
  padding-top: 20px;
}

/* line 215, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .footer-column .column-header {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #393a4f;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 10px 0;
}

/* line 224, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .footer-column .column-item {
  padding-bottom: 10px;
}

/* line 227, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .footer-column .column-item a {
  font-size: 0.9rem;
  color: #999;
}

/* line 231, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .footer-column .column-item a:hover {
  color: #3d70b2;
}

/* line 238, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

/* line 244, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .social-links a {
  color: #999;
  margin: 0 5px;
}

/* line 248, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .social-links a:hover {
  color: #3d70b2;
}

/* line 252, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .social-links a svg {
  height: 18px;
  width: 18px;
  stroke-width: 1.4px;
}

/* line 260, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .footer-description {
  color: #999;
  padding: 10px 0;
}

/* line 265, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .moto {
  color: #999;
  font-size: 0.85rem;
}

/* line 269, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .moto svg {
  height: 14px;
  width: 14px;
  fill: #f71416;
  stroke: #f71416;
  margin: 0 2px;
}

/* line 278, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-body .small-footer-logo {
  height: 30px;
}

/* line 283, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-copyright {
  color: #999;
  padding: 4rem 0 2rem 0;
  font-size: 0.9rem;
}

/* line 288, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-copyright a {
  color: #999;
}

/* line 291, src/assets/scss/layout/_layout-layout.scss */
.footer-light-medium .footer-copyright a:hover {
  color: #3d70b2;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 304, src/assets/scss/layout/_layout-layout.scss */
  .footer {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  /* line 310, src/assets/scss/layout/_layout-layout.scss */
  .footer .columns .column.is-4 {
    text-align: center;
  }

  /* line 313, src/assets/scss/layout/_layout-layout.scss */
  .footer .columns .column.is-4 > div {
    max-width: 430px;
    margin: 20px auto;
  }

  /* line 318, src/assets/scss/layout/_layout-layout.scss */
  .footer .columns .column.is-4 .moto {
    margin: 0;
  }

  /* line 324, src/assets/scss/layout/_layout-layout.scss */
  .footer .columns .column.is-offset-2 .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
  }

  /* line 328, src/assets/scss/layout/_layout-layout.scss */
  .footer .columns .column.is-offset-2 .columns .footer-column {
    padding: 0;
  }

  /* line 338, src/assets/scss/layout/_layout-layout.scss */
  .copyright .inner {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/*! _navbar.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 18, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 19;
  height: 58px;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0 !important;
}

/* line 31, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* line 35, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.no-background {
  background: transparent !important;
  border-bottom-color: transparent !important;
}

/* line 42, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing .navbar-item a:not(.button) {
  color: #999;
}

/* line 48, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing .navbar-end .button {
  font-size: 0.95rem;
  height: 42px;
  min-width: 120px;
}

/* line 57, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.no-background .navbar-brand .navbar-burger span {
  background: #fff;
}

/* line 64, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.no-background .navbar-end .navbar-item .button {
  background: transparent;
  border-color: #fcfcfc;
  color: #fcfcfc !important;
}

/* line 69, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.no-background .navbar-end .navbar-item .button:hover {
  border-color: #fff;
  background: #fff;
  color: #393a4f !important;
  opacity: 1 !important;
}

/* line 77, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.no-background .navbar-end .navbar-item a {
  color: #fcfcfc;
}

/* line 87, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.mobile-navbar.no-background
  .navbar-brand
  .dark-mobile-logo {
  display: none;
}

/* line 91, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.mobile-navbar.no-background
  .navbar-brand
  .light-mobile-logo {
  display: block;
}

/* line 98, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.mobile-navbar .navbar-brand .dark-mobile-logo {
  display: block;
}

/* line 102, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-landing.mobile-navbar .navbar-brand .light-mobile-logo {
  display: none;
}

/* line 110, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main.is-side {
  width: calc(100% - 70px);
  margin-left: 70px;
}

/* line 116, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .container.is-fluid {
  padding: 0;
}

/* line 122, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand {
  height: 58px;
  margin-left: 0 !important;
  margin-right: 20px;
}

/* line 127, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand.is-fluid {
  margin-right: 0;
  margin-left: 10px !important;
}

/* line 132, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand.is-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 136, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand.is-title h2 {
  margin-left: 20px;
  color: #393a4f;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}

/* line 146, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand .navbar-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 58px;
  min-width: 58px;
}

.navbar-main .navbar-brand .navbar-item {
  padding: 0 10px;
  height: auto;
}

/* line 153, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand .navbar-item .logo {
  max-height: 60px;
  max-width: 108px;
}

/* line 158, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand .navbar-item .user-image img {
  max-height: 42px;
  max-width: 42px;
}

/* line 163, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-brand .navbar-item:hover {
  background: #3d70b2 !important;
}

/* line 170, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-menu {
  margin-right: 0 !important;
}

/* line 175, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item {
  position: relative;
}

/* line 179, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart {
  margin-left: 16px;
}

/* line 182, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .cart-button {
  position: relative;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 191, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .cart-button svg {
  height: 20px;
  width: 20px;
  stroke: #6f7592;
  stroke-width: 1.4px;
}

/* line 198, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .cart-button .cart-count {
  position: absolute;
  top: -3px;
  right: -3px;
  height: 20px;
  min-width: 20px;
  background: #3d70b2;
  border: 2px solid #fff;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 211, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .cart-button .cart-count span {
  display: block;
  font-size: 0.55rem;
  color: #fafafa;
  padding: 0 5px;
  font-weight: 500;
}

/* line 221, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart {
  position: absolute;
  top: 60px;
  right: -10px;
  background: #fff;
  width: 320px;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
  opacity: 0;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  pointer-events: none;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
}

/* line 236, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 242, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart:after {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px;
  z-index: 1;
}

/* line 257, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart:before {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #e8e8e8;
  border-width: 10px;
  margin-left: -10px;
  z-index: 0;
}

/* line 272, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .cart-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

/* line 277, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .cart-inner
  .navbar-cart-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 292, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .cart-inner
  .navbar-cart-loader.is-active {
  opacity: 1;
}

/* line 296, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .cart-inner
  .navbar-cart-loader
  .loader {
  height: 3.5rem;
  width: 3.5rem;
}

/* line 303, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .button {
  background: #5596e6;
  border-color: #5596e6;
  color: #fff;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  display: block;
  border-radius: 3px;
  font-size: 0.9rem;
  margin: 20px 0 0 0;
  line-height: 0;
}

/* line 316, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .button:hover {
  background: #629ee8;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 322, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .shopping-cart-header {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}

/* line 326, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-header
  .cart-link {
  font-size: 0.75rem;
  color: #999;
  padding: 7px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 100px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 335, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-header
  .cart-link:hover {
  border-color: #5596e6;
  color: #5596e6;
}

/* line 341, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-header
  .shopping-cart-total {
  float: right;
}

/* line 345, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-header
  .shopping-cart-total
  span:first-child {
  color: #abb0be;
  font-size: 0.9rem;
}

/* line 350, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-header
  .shopping-cart-total
  span:nth-child(2) {
  color: #393a4f;
  font-weight: 500;
}

/* line 358, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .shopping-cart-items {
  padding-top: 20px;
}

/* line 361, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-items
  .cart-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 366, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .shopping-cart-items li {
  margin-bottom: 6px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 372, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .shopping-cart .shopping-cart-items img {
  display: block;
  height: 54px;
  width: 54px;
  min-width: 54px;
  min-height: 54px;
  margin-right: 12px;
}

/* line 381, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-items
  .meta-info {
  position: relative;
  top: -4px;
}

/* line 386, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-items
  .item-name {
  display: block;
  padding-top: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
  font-family: "Open Sans", sans-serif;
}

/* line 395, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-items
  .item-price {
  color: #6f7592;
  margin-right: 8px;
  font-size: 0.8rem;
  line-height: 1;
}

/* line 402, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-cart
  .shopping-cart
  .shopping-cart-items
  .item-quantity {
  color: #abb0be;
  font-size: 0.8rem;
  line-height: 1;
}

/* line 410, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-cart .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/* line 418, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu {
  position: relative;
}

/* line 422, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu.is-active .button {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #3d70b2;
  border-color: #3d70b2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 429, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu.is-active .plus-drop {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}

/* line 436, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop {
  position: absolute;
  top: 62px;
  right: 16px;
  width: 280px;
  background: #fff;
  min-height: 160px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 451, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop .drop-content {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px 0 20px 0;
}

/* line 457, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop .drop-content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
}

/* line 462, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop .drop-content a:hover {
  background: #f7f7f7;
}

/* line 466, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop .drop-content a svg {
  height: 22px;
  width: 22px;
  stroke: #757a91;
  stroke-width: 1px;
}

/* line 473, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop .drop-content a .meta {
  margin-left: 12px;
}

/* line 476, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-plus-menu .plus-drop .drop-content a .meta span {
  display: block;
  line-height: 1.4;
}

/* line 480, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-plus-menu
  .plus-drop
  .drop-content
  a
  .meta
  span:first-child {
  font-weight: 500;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 486, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item.is-plus-menu
  .plus-drop
  .drop-content
  a
  .meta
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 498, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

/* line 502, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 513, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-bold:hover,
.navbar-main .navbar-item.is-icon .icon-link.is-bold.is-active {
  background: #3a86e2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 517, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-bold:hover svg,
.navbar-main .navbar-item.is-icon .icon-link.is-bold.is-active svg {
  stroke: #fafafa;
}

/* line 521, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-bold:hover .mdi,
.navbar-main .navbar-item.is-icon .icon-link.is-bold.is-active .mdi {
  color: #fafafa;
}

/* line 527, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link:hover,
.navbar-main .navbar-item.is-icon .icon-link.is-active {
  background: #a985fc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 531, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link:hover svg,
.navbar-main .navbar-item.is-icon .icon-link.is-active svg {
  stroke: #fafafa;
}

.navbar-main .navbar-item.is-icon .icon-link:hover i,
.navbar-main .navbar-item.is-icon .icon-link.is-active i {
  color: #fafafa;
}

/* line 535, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link:hover .mdi,
.navbar-main .navbar-item.is-icon .icon-link.is-active .mdi {
  color: #fafafa;
}

/* line 542, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-primary:hover,
.navbar-main .navbar-item.is-icon .icon-link.is-primary.is-active {
  background: #3d70b2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 546, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-primary:hover svg,
.navbar-main .navbar-item.is-icon .icon-link.is-primary.is-active svg {
  stroke: #fff;
}

/* line 550, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-primary:hover .mdi,
.navbar-main .navbar-item.is-icon .icon-link.is-primary.is-active .mdi {
  color: #fafafa;
}

/* line 557, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-primary.is-friends:hover svg,
.navbar-main
  .navbar-item.is-icon
  .icon-link.is-primary.is-friends.is-active
  svg {
  fill: #fff;
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

/* line 567, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-secondary:hover,
.navbar-main .navbar-item.is-icon .icon-link.is-secondary.is-active {
  background: #41d6c3;
  -webkit-box-shadow: 0 14px 26px -12px rgba(65, 214, 195, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(65, 214, 195, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(65, 214, 195, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(65, 214, 195, 0.2) !important;
}

/* line 571, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-secondary:hover svg,
.navbar-main .navbar-item.is-icon .icon-link.is-secondary.is-active svg {
  stroke: #fff;
}

/* line 575, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-secondary:hover .mdi,
.navbar-main .navbar-item.is-icon .icon-link.is-secondary.is-active .mdi {
  color: #fafafa;
}

/* line 582, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-secondary.is-friends:hover svg,
.navbar-main
  .navbar-item.is-icon
  .icon-link.is-secondary.is-friends.is-active
  svg {
  fill: #fff;
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

/* line 594, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link.is-active .indicator {
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #41d6c3;
}

/* line 610, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link svg {
  width: 18px;
  height: 18px;
  stroke: #999;
  stroke-width: 1.4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar-main .navbar-item.is-icon .icon-link i {
  color: #999;
}

/* line 618, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-icon .icon-link .mdi {
  font-size: 20px;
  color: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 627, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-account {
  display: -webkit-box;
  display: -ms-flexbox;
  position: relative;
  z-index: 99;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 634, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-account .user-image {
  position: relative;
  max-height: 38px;
}

/* line 638, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-account .user-image .indicator {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1.4px solid #fff;
  background: #41d6c3;
}

/* line 652, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-account img {
  height: 38px;
  width: 38px;
  max-height: 38px;
  border-radius: 50%;
}

/* line 661, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-account.is-busy .indicator {
  background: #ff533d;
}

/* line 667, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item.is-account.is-opened .account-caret svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 673, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .action-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 36px;
  max-width: 36px;
  height: 36px;
  min-height: 36px;
  border-radius: 50%;
  padding: 0;
  display: none;
}

/* line 685, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .action-button svg {
  height: 16px;
  width: 16px;
}

/* line 692, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop {
  position: absolute;
  top: 90%;
  left: 0;
  width: 340px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-top: 16px;
  -webkit-box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18) !important;
  box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18) !important;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  pointer-events: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
}

/* line 709, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 716, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop:after,
.navbar-main .navbar-item .nav-drop:before {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/* line 727, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}

/* line 734, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 7px;
  margin-left: -7px;
}

/* line 742, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-status {
  left: calc(50% - 100px);
  width: 200px;
}

/* line 749, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-status .inner .drop-image img {
  display: block;
  width: 170px;
  height: 170px;
  min-height: 170px;
  margin: 0 auto;
}

/* line 758, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-status .inner .drop-text {
  padding: 0 10px 20px 10px;
  text-align: center;
}

/* line 762, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-status .inner .drop-text h4 {
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 767, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-status .inner .drop-text p {
  font-size: 0.8rem;
  color: #999;
}

/* line 776, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-account-dropdown {
  width: 300px;
  right: 0 !important;
  left: unset !important;
  max-height: unset !important;
}

/* line 782, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-account-dropdown:before {
  right: 23px !important;
  left: unset !important;
}

/* line 787, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop.is-account-dropdown:after {
  display: none !important;
}

/* line 793, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner {
  position: relative;
}

/* line 797, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  background: #fff;
}

/* line 806, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header span,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header a {
  display: block;
  text-transform: uppercase;
  font-size: 0.68rem;
  font-weight: 500;
  color: #abb0be;
}

/* line 813, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header span.username,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header a.username {
  font-weight: 600 !important;
}

/* line 818, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header a {
  height: unset;
  width: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 826, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header a:hover svg {
  stroke: #5596e6;
}

/* line 831, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-header a svg {
  height: 16px;
  width: 16px;
  stroke: #abb0be;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 841, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body {
  max-height: 420px;
  padding: 0 10px;
  overflow-y: auto;
}

/* line 847, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body::-webkit-scrollbar {
  width: 5px;
}

/* line 851, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

/* line 857, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.is-friend-requests,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.is-notifications,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.is-messages {
  padding: 8px 0;
}

/* line 861, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.is-messages .media {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0;
  padding: 0.75rem 16px !important;
  transition: .4s;

  &.not-read {
    background-color: #a985fc26;
  }
}

/* line 866, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  img,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  img,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  img {
  height: 36px;
  width: 36px;
  max-height: 36px;
  border-radius: 50%;
}

/* line 875, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-content
  span,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-content
  a,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-content
  span,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-content
  a,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-content
  span,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-content
  a {
  display: block;
  width: unset;
  height: unset;
}

/* line 881, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-content
  a,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-content
  a,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-content
  a {
  font-size: 0.75rem;
  font-weight: 500;
  color: #344258;
}

/* line 886, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-content
  a:hover,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-content
  a:hover,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-content
  a:hover {
  color: #5596e6;
}

/* line 891, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-content
  span,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-content
  span,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-content
  span {
  font-size: 0.75rem;
  color: #999;
}

/* line 895, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-content
  span
  a,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-content
  span
  a,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-content
  span
  a {
  color: #344258;
  display: inline-block;
}

/* line 903, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .added-icon,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .added-icon,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .added-icon {
  width: 20px;
  height: 20px;
  margin: 0 15px;
}

/* line 908, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .added-icon
  svg,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .added-icon
  svg,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .added-icon
  svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  stroke-width: 1.6px;
}

/* line 917, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-right.is-centered,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-right.is-centered,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-right.is-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 926, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-right
  .icon-button:hover
  svg,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-right
  .icon-button:hover
  svg,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-right
  .icon-button:hover
  svg {
  stroke: #5596e6 !important;
}

/* line 931, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .media
  .media-right
  .icon-button
  svg,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .media
  .media-right
  .icon-button
  svg,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-messages
  .media
  .media-right
  .icon-button
  svg {
  stroke: #abb0be !important;
  stroke-width: 1.6px;
}

/* line 940, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-friend-requests
  .time,
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.is-notifications
  .time,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.is-messages .time {
  font-size: 0.65rem !important;
  color: #999;
}

/* line 947, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.account-items {
  padding: 10px 0;
}

/* line 950, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-body.account-items hr {
  margin: 0.4rem 0 !important;
}

/* line 955, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item {
  padding: 8px 16px;
  display: block;
  line-height: 1.2;
}

/* line 961, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 965, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media
  .media-left
  img {
  height: 35px;
  width: 35px;
}

/* line 971, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media
  h3 {
  color: #393a4f;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 0;
}

/* line 978, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media
  .icon-wrap {
  height: 35px;
  width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 1rem;
}

/* line 986, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media
  .icon-wrap
  svg {
  width: 18px;
  height: 18px;
  stroke-width: 2px;
  stroke: #757a91;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 995, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media
  small {
  color: #757a91;
  font-size: 0.75rem;
}

/* line 1001, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item
  .media
  .media-right
  svg {
  width: 18px;
  height: 18px;
  margin: 0 16px;
}

/* line 1010, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item.is-active {
  background: #039be5;
}

/* line 1013, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item.is-active
  svg {
  stroke: #fff;
}

/* line 1017, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item.is-active
  small {
  color: #fff;
}

/* line 1022, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item:hover {
  background: #fafafa;
}

/* line 1025, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .navbar-item
  .nav-drop
  .inner
  .nav-drop-body.account-items
  .account-item:hover
  svg {
  stroke: #5596e6;
}

/* line 1034, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
  background: #fafafa;
}

/* line 1043, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-footer span,
.navbar-main .navbar-item .nav-drop .inner .nav-drop-footer a {
  display: block;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 500;
}

/* line 1050, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-footer a {
  color: #999;
  height: unset;
  width: unset;
}

/* line 1055, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .navbar-item .nav-drop .inner .nav-drop-footer a:hover {
  color: #3d70b2;
}

/* line 1065, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control {
  position: relative;
}

/* line 1069, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .input {
  position: relative;
  height: 38px;
  width: 320px;
  padding-right: 85px;
  padding-left: 38px;
  padding-bottom: 8px;
  background: #f7f7f7;
  border-color: #f7f7f7;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
}

/* line 1081, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .input:focus {
  background: #fff;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  border-color: #e3e3e3;
}

/* line 1088, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .input:not(:valid) ~ .reset-search {
  opacity: 0;
  pointer-events: none;
}

/* line 1093, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .input:focus ~ .search-icon svg {
  stroke: #999 !important;
}

/* line 1098, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .reset-search {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38px;
  width: 38px;
  opacity: 1;
  pointer-events: all;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
  cursor: pointer;
}

/* line 1113, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .reset-search svg {
  height: 18px;
  width: 18px;
  stroke: #999;
}

/* line 1121, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38px;
  width: 38px;
  z-index: 1;
}

/* line 1132, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-icon svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1140, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .drop-icon {
  position: absolute;
  top: 0;
  right: -40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38px;
  width: 38px;
  z-index: 1;
  cursor: default;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1154, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .drop-icon:hover svg {
  stroke: #999 !important;
}

/* line 1159, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .drop-icon svg {
  height: 20px;
  width: 20px;
  stroke: #cecece !important;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1169, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  margin-top: 12px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.04);
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1186, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options:after,
.navbar-main .control .search-options:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/* line 1197, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}

/* line 1204, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

/* line 1214, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .options-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 1222, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .options-list li svg {
  width: 18px;
  height: 18px;
  stroke: #cecece;
  stroke-width: 1px;
}

/* line 1231, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .options-list li.is-selected svg {
  stroke: #5596e6;
}

/* line 1236, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .control
  .search-options
  .options-list
  li.is-selected
  .option-description
  span:first-child {
  color: #5596e6;
}

/* line 1243, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .options-list li .option-description {
  display: block;
  margin: 0 15px;
}

/* line 1247, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .control
  .search-options
  .options-list
  li
  .option-description
  span {
  display: block;
}

/* line 1250, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .control
  .search-options
  .options-list
  li
  .option-description
  span:first-child {
  font-size: 0.9rem;
  font-weight: 500;
  color: #344258;
}

/* line 1256, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main
  .control
  .search-options
  .options-list
  li
  .option-description
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 1263, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .options-list li:hover {
  background: #fafafa;
}

/* line 1270, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options.is-active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 1277, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .is-close {
  margin: 16px 20px 6px 20px;
}

/* line 1280, src/assets/scss/layout/_layout-navbar.scss */
.navbar-main .control .search-options .is-close button {
  line-height: 0;
  width: 100%;
}

/* line 1294, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1304, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar.is-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* line 1309, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-brand {
  margin-right: 0;
}

/* line 1312, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-brand .navbar-item:hover {
  background: transparent !important;
}

/* line 1316, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-brand img {
  height: 34px;
  max-height: 34px;
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

/* line 1325, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-link {
  padding-right: 12px !important;
}

/* line 1330, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-item {
  min-width: unset !important;
}

/* line 1333, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-item:hover {
  background: transparent !important;
}

/* line 1339, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-item.has-dropdown .navbar-link:after {
  border-color: #ddd;
}

/* line 1346, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-item.is-mobile-icon span:not(.menu-badge) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1350, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-item.is-mobile-icon span:not(.menu-badge) svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  margin-right: 8px;
}

/* line 1361, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-burger {
  height: 58px;
  width: 58px;
}

/* line 1366, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-burger:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

/* line 1370, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-burger span {
  background-color: #999;
}

/* line 1375, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu {
  background: #fafafa;
}

/* line 1378, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .button {
  padding: 0;
  height: 36px;
  width: 100px;
}

/* line 1384, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-link {
  color: #393a4f;
  font-weight: 600;
}

/* line 1389, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-item {
  color: #999;
}

/* line 1394, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

/* line 1400, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-link img {
  height: 32px;
  width: 32px;
  max-height: 32px !important;
  border-radius: 50%;
}

/* line 1407, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-link svg {
  height: 20px;
  width: 20px;
  stroke-width: 1.2px;
  stroke: #5596e6;
}

/* line 1414, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-link span {
  margin: 0 10px;
}

/* line 1417, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-link span.is-heading {
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 400;
  text-transform: uppercase;
}

/* line 1427, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-dropdown .menu-badge {
  margin-left: auto;
  height: 22px;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 3px 6px;
  line-height: 1.3;
  min-width: 22px;
  text-align: center;
  border-radius: 100px;
  background: #f0f0f0;
  color: #393a4f;
}

/* line 1441, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-dropdown .button {
  height: 40px;
  display: block;
  width: calc(100% - 32px);
  font-size: 0.95rem;
  line-height: 2.3;
  margin: 8px 16px;
}

/* line 1450, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-dropdown .navbar-item {
  font-size: 0.9rem;
  padding: 0.75rem 1.5rem !important;
}

/* line 1454, src/assets/scss/layout/_layout-navbar.scss */
.mobile-navbar .navbar-menu .navbar-dropdown .navbar-item.is-atcive,
.mobile-navbar .navbar-menu .navbar-dropdown .navbar-item:hover {
  background: #475a78;
}

/* line 1465, src/assets/scss/layout/_layout-navbar.scss */
.options-nav {
  position: fixed;
  top: 58px;
  left: 0;
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1477, src/assets/scss/layout/_layout-navbar.scss */
.options-nav.no-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 1481, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 1485, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .option-tabs,
.options-nav .nav-inner.is-events .option-tabs {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 210px;
}

/* line 1490, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .option-tabs .option-tab,
.options-nav .nav-inner.is-events .option-tabs .option-tab {
  height: 50px;
  width: 33.3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 1498, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .option-tabs .option-tab.is-active span,
.options-nav .nav-inner.is-events .option-tabs .option-tab.is-active span {
  font-weight: 500;
  color: #393a4f;
}

/* line 1504, src/assets/scss/layout/_layout-navbar.scss */
.options-nav
  .nav-inner.is-friends
  .option-tabs
  .option-tab.is-active:first-child
  ~ .option-naver,
.options-nav
  .nav-inner.is-events
  .option-tabs
  .option-tab.is-active:first-child
  ~ .option-naver {
  margin-left: 0;
}

/* line 1510, src/assets/scss/layout/_layout-navbar.scss */
.options-nav
  .nav-inner.is-friends
  .option-tabs
  .option-tab.is-active:nth-child(2)
  ~ .option-naver,
.options-nav
  .nav-inner.is-events
  .option-tabs
  .option-tab.is-active:nth-child(2)
  ~ .option-naver {
  margin-left: 33.3%;
}

/* line 1516, src/assets/scss/layout/_layout-navbar.scss */
.options-nav
  .nav-inner.is-friends
  .option-tabs
  .option-tab.is-active:nth-child(3)
  ~ .option-naver,
.options-nav
  .nav-inner.is-events
  .option-tabs
  .option-tab.is-active:nth-child(3)
  ~ .option-naver {
  margin-left: 66.6%;
}

/* line 1522, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .option-tabs .option-tab span,
.options-nav .nav-inner.is-events .option-tabs .option-tab span {
  display: block;
  color: #cecece;
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
}

/* line 1530, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .option-tabs .option-naver,
.options-nav .nav-inner.is-events .option-tabs .option-naver {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2.6px;
  width: 33.3%;
  border-radius: 4px;
  background: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1542, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .end-group,
.options-nav .nav-inner.is-events .end-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: auto;
}

/* line 1548, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .end-group .is-icon,
.options-nav .nav-inner.is-events .end-group .is-icon {
  border-left: 1px solid #e0e0e0;
}

/* line 1551, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .end-group .is-icon svg,
.options-nav .nav-inner.is-events .end-group .is-icon svg {
  stroke: #999;
}

/* line 1556, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner.is-friends .end-group .is-friend-count,
.options-nav .nav-inner.is-events .end-group .is-friend-count {
  padding: 0 20px;
  color: #999;
  font-family: Roboto, sans-serif;
  font-size: 0.95rem;
}

/* line 1565, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1570, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-page-title {
  padding: 0 20px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

/* line 1575, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-page-title h2 {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #393a4f;
}

/* line 1583, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
}

/* line 1589, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-icon svg {
  height: 18px;
  width: 18px;
  stroke: #999;
}

/* line 1596, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-search {
  border-left: 1px solid #e0e0e0;
  width: 280px;
}

/* line 1600, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-search .control {
  padding: 0 16px;
  width: 100%;
}

/* line 1604, src/assets/scss/layout/_layout-navbar.scss */
.options-nav .nav-inner .nav-item.is-search .control input {
  border: none;
}

/* line 1617, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu {
  position: fixed;
  top: 58px;
  left: 0;
  width: 100%;
  height: calc(100% - 58px);
  background: #fff;
  opacity: 0;
  z-index: -1;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  transition: opacity 0.4s, transform 0.4s, -webkit-transform 0.4s;
}

/* line 1629, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu.is-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  z-index: 11;
}

/* line 1635, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 1640, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-container {
  max-width: 840px;
  margin: 0 auto;
}

/* line 1645, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
}

/* line 1651, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-header h3 {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  color: #393a4f;
}

/* line 1658, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-header .control {
  position: relative;
}

/* line 1661, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-header .control input {
  padding-right: 40px;
}

/* line 1665, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu
  .explorer-inner
  .explorer-header
  .control
  input:focus
  + .form-icon
  svg {
  stroke: #5596e6;
}

/* line 1671, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-header .control .form-icon {
  position: absolute;
  top: 0;
  right: 4px;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1681, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explorer-header .control .form-icon svg {
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 1691, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 1696, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item {
  text-align: center;
  width: calc(20% - 16px);
  margin: 8px;
  padding: 20px 0;
  border: 1px solid transparent;
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 1706, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item:hover {
  border-color: #e8e8e8;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1710, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item:hover img {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1716, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item.is-coming-soon img {
  -webkit-filter: grayscale(0.9);
  filter: grayscale(0.9);
}

/* line 1721, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item img {
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1730, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item h4 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #393a4f;
  margin-top: 6px;
}

/* line 1738, src/assets/scss/layout/_layout-navbar.scss */
.explorer-menu .explorer-inner .explore-list .explore-item .coming-soon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f5f5f5;
  color: #393a4f;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  max-width: 96px;
  padding: 4px 8px;
  border-radius: 100px;
  margin: 0 auto;
  position: relative;
  top: 3px;
}

/*! _layout-sidebar.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 15, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel {
  position: fixed;
  top: 107px;
  left: 0;
  height: calc(100% - 107px);
  width: 300px;
  background: #fff;
  border-right: 1px solid #e0e0e0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 2;
}

/* line 27, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel.is-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* line 31, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner {
  position: relative;
  width: 100%;
}

/* line 35, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .panel-title {
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #999;
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 44, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .filter-block {
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 48, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .filter-block label {
  display: block;
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #393a4f;
}

/* line 57, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .filter-block .age-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 61, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .filter-block .age-wrap .separator {
  text-align: center;
  padding: 0 8px;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: #999;
}

/* line 69, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .filter-block .age-wrap .field {
  margin-bottom: 0;
  max-width: 60px;
}

/* line 74, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel .panel-inner .filter-block .age-wrap .field .control input {
  height: 30px;
  text-align: center;
}

/* line 78, src/assets/scss/layout/_layout-sidebar.scss */
.filters-panel
  .panel-inner
  .filter-block
  .age-wrap
  .field
  .control
  input:focus {
  border-color: #757a91;
}

/*! _placeholders.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 30, src/assets/scss/layout/_layout-placeholders.scss */
.placeload {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: #fff;
}

/* line 36, src/assets/scss/layout/_layout-placeholders.scss */
.placeload.is-bold {
  border: none;
  background: none;
}

/* line 41, src/assets/scss/layout/_layout-placeholders.scss */
.placeload .inner-wrap {
  width: 100%;
}

/* line 45, src/assets/scss/layout/_layout-placeholders.scss */
.placeload .content-shape {
  height: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
}

/* line 50, src/assets/scss/layout/_layout-placeholders.scss */
.placeload .content-shape.is-lg {
  height: 16px;
}

/* line 55, src/assets/scss/layout/_layout-placeholders.scss */
.placeload .button-shape {
  width: 130px;
  height: 38px;
  border-radius: 4px;
}

/* line 63, src/assets/scss/layout/_layout-placeholders.scss */
.loads {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeload;
  animation-name: placeload;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eee;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eee),
    color-stop(18%, #ddd),
    color-stop(33%, #eee)
  );
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1200px 104px;
  position: relative;
}

@-webkit-keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

/* line 109, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload {
  height: 226px;
  margin-bottom: 20px;
  padding: 20px;
}

/* line 114, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 45px;
  margin-bottom: 1rem;
}

/* line 120, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .header .content-shape {
  width: 20%;
  margin-right: 8%;
}

/* line 126, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 100px;
  padding: 16px 0;
}

/* line 132, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .body .img {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
}

/* line 139, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .body .content-shape {
  margin-top: 10px;
  margin-left: 20px;
}

/* line 143, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .body .content-shape:first-child {
  width: 45%;
}

/* line 147, src/assets/scss/layout/_layout-placeholders.scss */
.compose-placeload .body .content-shape:nth-child(2) {
  width: 25%;
}

/* line 158, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload {
  background: #fff;
  width: 100%;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  position: relative;
  margin-bottom: 20px;
}

/* line 167, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 172, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .header .img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* line 179, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .header .header-content {
  margin-left: 20px;
  width: 100%;
}

/* line 184, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .header .header-content .content-shape:first-child {
  width: 60%;
}

/* line 188, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .header .header-content .content-shape:nth-child(2) {
  width: 40%;
}

/* line 195, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .image-placeholder {
  width: 100%;
  margin-top: 20px;
  height: 350px;
}

/* line 201, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .placeholder-footer {
  position: relative;
  margin-top: 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 209, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .placeholder-footer .footer-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* line 216, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .placeholder-footer .footer-block .img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* line 223, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload .placeholder-footer .footer-block .inner-wrap {
  margin-left: 10px;
}

/* line 227, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload
  .placeholder-footer
  .footer-block
  .inner-wrap
  .content-shape:first-child {
  width: 32%;
}

/* line 231, src/assets/scss/layout/_layout-placeholders.scss */
.post-placeload
  .placeholder-footer
  .footer-block
  .inner-wrap
  .content-shape:nth-child(2) {
  width: 24%;
}

/* line 244, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload,
.list-placeload {
  height: 382px;
  padding: 20px;
  margin-bottom: 20px;
}

/* line 249, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .header,
.list-placeload .header {
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 255, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .header .content-shape,
.list-placeload .header .content-shape {
  width: 55%;
}

/* line 261, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .body .flex-block,
.list-placeload .body .flex-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 76px;
}

/* line 267, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .body .flex-block .img,
.list-placeload .body .flex-block .img {
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
}

/* line 274, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .body .flex-block .inner-wrap,
.list-placeload .body .flex-block .inner-wrap {
  padding: 0 10px;
}

/* line 278, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .body .flex-block .inner-wrap .content-shape:first-child,
.list-placeload .body .flex-block .inner-wrap .content-shape:first-child {
  width: 78%;
}

/* line 282, src/assets/scss/layout/_layout-placeholders.scss */
.stories-placeload .body .flex-block .inner-wrap .content-shape:nth-child(2),
.list-placeload .body .flex-block .inner-wrap .content-shape:nth-child(2) {
  width: 54%;
}

/* line 295, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload {
  height: 323px;
  padding: 20px;
  margin-bottom: 20px;
}

/* line 300, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 283px;
}

/* line 306, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body .img {
  margin-top: -20px;
  height: 50px;
  width: 50px;
  min-width: 45px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
}

/* line 315, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body .content-shape {
  margin: 0 auto 12px auto;
}

/* line 318, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body .content-shape:nth-child(2) {
  width: 38%;
}

/* line 322, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body .content-shape:nth-child(3) {
  width: 88% !important;
}

/* line 326, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body .content-shape:nth-child(4) {
  width: 64% !important;
}

/* line 331, src/assets/scss/layout/_layout-placeholders.scss */
.mini-widget-placeload .body .button-shape {
  margin: 24px auto 0 auto;
}

/* line 341, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload {
  height: 450px;
  padding: 20px;
  margin-bottom: 20px;
}

/* line 346, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .header {
  height: 220px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 353, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .header .inner-wrap .img {
  height: 60px;
  width: 60px;
  min-width: 45px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
}

/* line 361, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .header .inner-wrap .content-shape {
  margin: 0 auto 12px auto;
}

/* line 364, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .header .inner-wrap .content-shape:nth-child(2) {
  width: 44%;
}

/* line 368, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .header .inner-wrap .content-shape:nth-child(3) {
  width: 88% !important;
}

/* line 375, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .body {
  height: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 382, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .body .inner-wrap .rect {
  height: 85px;
  width: 100%;
  min-width: 100%;
  border-radius: 4px;
  margin: 0 auto 30px auto;
}

/* line 390, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .body .inner-wrap .content-shape {
  margin: 12px auto;
}

/* line 393, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .body .inner-wrap .content-shape:nth-child(2) {
  width: 88%;
}

/* line 397, src/assets/scss/layout/_layout-placeholders.scss */
.weather-widget-placeload .body .inner-wrap .content-shape:nth-child(3) {
  width: 44%;
}

/* line 409, src/assets/scss/layout/_layout-placeholders.scss */
.questions-menu-placeload {
  width: 100%;
}

/* line 413, src/assets/scss/layout/_layout-placeholders.scss */
.questions-menu-placeload .inner-wrap .content-shape {
  height: 22px;
  margin-bottom: 16px;
}

/* line 417, src/assets/scss/layout/_layout-placeholders.scss */
.questions-menu-placeload .inner-wrap .content-shape:first-child {
  max-width: 90%;
}

/* line 421, src/assets/scss/layout/_layout-placeholders.scss */
.questions-menu-placeload .inner-wrap .content-shape:nth-child(2) {
  max-width: 50%;
}

/* line 425, src/assets/scss/layout/_layout-placeholders.scss */
.questions-menu-placeload .inner-wrap .content-shape:nth-child(3) {
  max-width: 65%;
}

/* line 429, src/assets/scss/layout/_layout-placeholders.scss */
.questions-menu-placeload .inner-wrap .content-shape:nth-child(4) {
  max-width: 20%;
}

/* line 440, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload {
  width: 100%;
}

/* line 443, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload.is-card {
  padding: 30px;
  margin-top: 30px;
}

/* line 448, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload.is-card .content-shape:nth-child(4) {
  margin-bottom: 0;
}

/* line 455, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload .inner-wrap .content-shape {
  height: 18px;
  margin-bottom: 16px;
}

/* line 459, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload .inner-wrap .content-shape:first-child {
  max-width: 30%;
}

/* line 463, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload .inner-wrap .content-shape:nth-child(2) {
  max-width: 60%;
}

/* line 467, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload .inner-wrap .content-shape:nth-child(3) {
  max-width: 75%;
}

/* line 471, src/assets/scss/layout/_layout-placeholders.scss */
.questions-settings-placeload .inner-wrap .content-shape:nth-child(4) {
  max-width: 30%;
}

/* line 482, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload {
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;
}

/* line 487, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload:last-child {
  margin-bottom: 0;
}

/* line 492, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload .inner-wrap .img {
  height: 60px;
  width: 60px;
  min-width: 45px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
}

/* line 500, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload .inner-wrap .content-shape {
  height: 14px;
  margin-bottom: 16px;
}

/* line 504, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload .inner-wrap .content-shape:nth-child(2) {
  max-width: 70%;
}

/* line 508, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload .inner-wrap .content-shape:nth-child(3) {
  max-width: 90%;
}

/* line 512, src/assets/scss/layout/_layout-placeholders.scss */
.questions-side-placeload .inner-wrap .content-shape:nth-child(4) {
  max-width: 40%;
  margin-bottom: 0;
}

/* line 524, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload {
  width: 100%;
}

/* line 528, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-header.is-spaced {
  margin-top: 30px;
}

/* line 533, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-header .content-shape:first-child {
  max-width: 70%;
  height: 18px;
}

/* line 540, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card {
  padding: 30px;
  margin-top: 30px;
}

/* line 544, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .card-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

/* line 549, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .card-head .img {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}

/* line 556, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .card-head > .content-shape {
  width: 100%;
  height: 16px;
  max-width: 40%;
  margin: 0 0 0 12px;
}

/* line 564, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .content-shape {
  margin-bottom: 16px;
}

/* line 567, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .content-shape.is-body {
  height: 15px;
}

/* line 570, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .content-shape.is-body:nth-child(2) {
  max-width: 80%;
}

/* line 574, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .content-shape.is-body:nth-child(3) {
  max-width: 95%;
}

/* line 578, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .content-shape.is-body:nth-child(4) {
  max-width: 60%;
}

/* line 582, src/assets/scss/layout/_layout-placeholders.scss */
.questions-single-placeload.is-card .content-shape.is-body:last-child {
  margin-bottom: 0;
}

/* line 594, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload {
  width: 100%;
}

/* line 597, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header {
  margin-bottom: 20px;
  padding: 30px;
}

/* line 601, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 605, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap .img {
  height: 80px;
  width: 80px;
  min-width: 80px;
  border-radius: 50%;
}

/* line 612, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap > div {
  width: 100%;
  margin-left: 20px;
}

/* line 617, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap .content-shape {
  width: 100%;
  height: 14px;
  max-width: 40%;
  margin-bottom: 12px;
}

/* line 623, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap .content-shape:first-child {
  max-width: 50%;
}

/* line 627, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap .content-shape:nth-child(2) {
  max-width: 30%;
}

/* line 631, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap .content-shape:nth-child(3) {
  max-width: 20%;
}

/* line 635, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-header .inner-wrap .content-shape:last-child {
  margin-bottom: 0;
}

/* line 642, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel {
  border: none;
  background: none;
}

/* line 646, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel .grid-title {
  padding: 14px 0;
}

/* line 649, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel .grid-title .content-shape {
  height: 16px;
  max-width: 20%;
}

/* line 655, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel .placeload-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 658, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel .placeload-grid .grid-item {
  margin: 6px;
  width: calc(33% - 12px);
  height: 200px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

/* line 666, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel .placeload-grid .grid-item .img {
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
  margin: 30px auto;
}

/* line 675, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel
  .placeload-grid
  .grid-item
  .shapes
  .content-shape {
  margin: 0 auto 10px;
  height: 12px;
}

/* line 679, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel
  .placeload-grid
  .grid-item
  .shapes
  .content-shape:first-child {
  max-width: 70%;
}

/* line 683, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-carousel
  .placeload-grid
  .grid-item
  .shapes
  .content-shape:nth-child(2) {
  max-width: 30%;
}

/* line 692, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity {
  border: none;
  background: none;
  margin-top: 20px;
}

/* line 697, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .activity-title {
  padding: 14px 0;
}

/* line 700, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .activity-title .content-shape {
  height: 16px;
  max-width: 20%;
}

/* line 706, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .unit-list {
  padding: 20px 0;
}

/* line 709, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .unit-list .unit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 12px;
  margin-bottom: 20px;
  margin-top: 10px;
}

/* line 715, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .unit-list .unit:last-child {
  margin-bottom: 0;
}

/* line 719, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .unit-list .unit .img {
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
}

/* line 726, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity .unit-list .unit .unit-meta {
  width: 100%;
  margin-left: 20px;
}

/* line 730, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity
  .unit-list
  .unit
  .unit-meta
  .content-shape {
  height: 14px;
}

/* line 733, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity
  .unit-list
  .unit
  .unit-meta
  .content-shape:first-child {
  max-width: 60%;
}

/* line 737, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity
  .unit-list
  .unit
  .unit-meta
  .content-shape:nth-child(2) {
  max-width: 30%;
  height: 12px;
}

/* line 742, src/assets/scss/layout/_layout-placeholders.scss */
.questions-stats-placeload.is-activity
  .unit-list
  .unit
  .unit-meta
  .content-shape:nth-child(3) {
  height: 10px;
  max-width: 20%;
}

/* line 757, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload {
  width: 100%;
}

/* line 760, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload .is-tile-placeload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 30px;
}

/* line 769, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload .is-tile-placeload.is-card {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 774, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload .is-tile-placeload.is-card .img {
  max-width: 160px;
}

/* line 778, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload .is-tile-placeload.is-card .placeload-content {
  margin-top: 0;
  margin-left: 30px;
  width: 100%;
}

/* line 784, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload.is-card
  .placeload-content
  .content-shape:first-child {
  max-width: 80%;
}

/* line 788, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload.is-card
  .placeload-content
  .content-shape:nth-child(2) {
  max-width: 60%;
}

/* line 792, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload.is-card
  .placeload-content
  .content-shape:nth-child(3) {
  max-width: 90%;
}

/* line 796, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload.is-card
  .placeload-content
  .content-shape:nth-child(4) {
  max-width: 40%;
}

/* line 803, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload .is-tile-placeload .img {
  height: 140px;
  width: 100%;
  border-radius: 2px;
}

/* line 809, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload .is-tile-placeload .placeload-content {
  margin-top: 30px;
}

/* line 812, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload
  .placeload-content
  .content-shape {
  height: 14px;
}

/* line 815, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload
  .placeload-content
  .content-shape:first-child {
  max-width: 80%;
}

/* line 819, src/assets/scss/layout/_layout-placeholders.scss */
.questions-categories-placeload
  .is-tile-placeload
  .placeload-content
  .content-shape:nth-child(2) {
  max-width: 40%;
}

/*! _cards.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 15, src/assets/scss/components/_components-cards.scss */
.card,
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card {
  position: relative;
  margin-bottom: 1.5rem;
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 24, src/assets/scss/components/_components-cards.scss */
.card .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}

/* line 30, src/assets/scss/components/_components-cards.scss */
.card .card-heading .dropdown,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .dropdown {
  margin-left: auto;
}

/* line 33, src/assets/scss/components/_components-cards.scss */
.card .card-heading .dropdown .button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .dropdown
  .button {
  padding: 0;
  background: none;
  border: none;
}

/* line 38, src/assets/scss/components/_components-cards.scss */
.card .card-heading .dropdown .button svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .dropdown
  .button
  svg {
  stroke: #888da8;
}

/* line 44, src/assets/scss/components/_components-cards.scss */
.card .card-heading .dropdown.is-light svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .dropdown.is-light
  svg {
  stroke: #fff;
}

/* line 51, src/assets/scss/components/_components-cards.scss */
.card .card-heading.is-bordered,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading.is-bordered {
  border-bottom: 1px solid #e8e8e8;
}

/* line 54, src/assets/scss/components/_components-cards.scss */
.card .card-heading.is-bordered h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading.is-bordered
  h4 {
  font-size: 0.9rem;
  color: #757a91;
  font-weight: 400;
}

/* line 63, src/assets/scss/components/_components-cards.scss */
.card .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body {
  padding: 10px 16px;
}

/* line 66, src/assets/scss/components/_components-cards.scss */
.card .card-body.no-padding,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body.no-padding {
  padding: 0;
}

/* line 72, src/assets/scss/components/_components-cards.scss */
.card .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-footer {
  margin: 8px 16px 16px 16px;
  border-top: 1px solid #e8e8e8;
}

/* line 78, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card {
  background: linear-gradient(to right, #c2e9fb, #a1c4fd);
}

/* line 85, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .card-heading .dropdown.is-spaced > div .button:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .card-heading
  .dropdown.is-spaced
  > div
  .button:hover {
  background: #2175dd !important;
}

/* line 91, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .card-body {
  padding-top: 0;
}

/* line 96, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .temperature,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .temperature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 101, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .temperature span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .temperature
  span {
  position: relative;
  display: block;
  font-size: 2.6rem;
  font-weight: 500;
  color: #fff;
}

/* line 108, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .temperature span:after,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .temperature
  span:after {
  content: "";
  position: absolute;
  top: 10px;
  right: -18px;
  height: 14px;
  width: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
}

/* line 122, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .weather-icon,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .weather-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 127, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .weather-icon div,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .weather-icon
  div {
  text-align: center;
}

/* line 131, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .weather-icon h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .weather-icon
  h4 {
  font-size: 1.4rem;
  color: #fff;
}

/* line 136, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .weather-icon svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .weather-icon
  svg {
  stroke: #fff;
  height: 2.8rem;
  width: 2.8rem;
}

/* line 142, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .weather-icon .details,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .weather-icon
  .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.85rem;
  color: #fff;
}

/* line 149, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .weather-icon .details span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .weather-icon
  .details
  span {
  display: block;
  margin: 0 10px;
}

/* line 157, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .previsions,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .previsions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  margin: 20px 0;
}

/* line 165, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .previsions .day,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .previsions
  .day {
  text-align: center;
  font-size: 0.7rem;
  color: #fff;
}

/* line 170, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .previsions .day *,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .previsions
  .day
  * {
  display: block;
}

/* line 174, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .previsions .day span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .previsions
  .day
  span:first-child {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 500;
}

/* line 180, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .previsions .day svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .previsions
  .day
  svg {
  margin: 3px auto;
  height: 18px;
  width: 18px;
}

/* line 188, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .current-date-location,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .current-date-location {
  margin-bottom: 20px;
}

/* line 191, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .current-date-location span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .current-date-location
  span {
  display: block;
  color: #fff;
}

/* line 196, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .current-date-location .date,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .current-date-location
  .date {
  font-size: 1.1rem;
  font-weight: 500;
}

/* line 201, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .current-date-location .location,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .current-date-location
  .location {
  font-size: 0.8rem;
}

/* line 204, src/assets/scss/components/_components-cards.scss */
.card.is-weather-card .current-date-location .location svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-weather-card.summary-card
  .current-date-location
  .location
  svg {
  height: 16px;
  width: 16px;
}

/* line 213, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card {
  background-color: #45d7c4;
}

/* line 216, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-heading {
  padding: 16px 16px 0 16px;
}

/* line 220, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-heading .dropdown.is-spaced > div .button:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-heading
  .dropdown.is-spaced
  > div
  .button:hover {
  background: #1c8174 !important;
}

/* line 225, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-heading > svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-heading
  > svg {
  stroke: #fff;
}

/* line 231, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body {
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 290px;
}

/* line 239, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-avatar {
  position: relative;
  width: 45px;
  margin: 0 auto 16px auto;
}

/* line 244, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-avatar img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-avatar
  img {
  display: block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

/* line 252, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-avatar .birthday-indicator,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-avatar
  .birthday-indicator {
  position: absolute;
  top: -6px;
  right: -14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 2px solid #45d7c4;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 500;
  background: #344258;
}

/* line 271, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-content {
  text-align: center;
  color: #fff;
}

/* line 275, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-content h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-content
  h4 {
  font-weight: 500;
}

/* line 279, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-content p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-content
  p {
  font-size: 0.9rem;
}

/* line 283, src/assets/scss/components/_components-cards.scss */
.card.is-birthday-card .card-body .birthday-content button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-birthday-card.summary-card
  .card-body
  .birthday-content
  button {
  line-height: 0;
  margin: 16px 0;
}

/* line 292, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card {
  background-color: #3e72b6;
}

/* line 295, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-heading {
  padding: 16px 16px 0 16px;
}

/* line 299, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-heading .dropdown.is-spaced > div .button:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-heading
  .dropdown.is-spaced
  > div
  .button:hover {
  background: #2d5384 !important;
}

/* line 304, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-heading > svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-heading
  > svg {
  stroke: #fff;
}

/* line 310, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body {
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 290px;
}

/* line 318, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body .job-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body
  .job-avatar {
  position: relative;
  width: 45px;
  margin: 0 auto 16px auto;
}

/* line 323, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body .job-avatar img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body
  .job-avatar
  img {
  display: block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

/* line 332, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body .job-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body
  .job-content {
  text-align: center;
  color: #fff;
}

/* line 336, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body .job-content h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body
  .job-content
  h4 {
  font-weight: 500;
}

/* line 340, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body .job-content p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body
  .job-content
  p {
  font-size: 0.9rem;
}

/* line 344, src/assets/scss/components/_components-cards.scss */
.card.is-new-job-card .card-body .job-content button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-job-card.summary-card
  .card-body
  .job-content
  button {
  line-height: 0;
  margin: 16px 0;
}

/* line 354, src/assets/scss/components/_components-cards.scss */
.card.is-ad .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-ad.summary-card
  .card-body {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px;
}

/* line 360, src/assets/scss/components/_components-cards.scss */
.card.is-ad .card-body img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-ad.summary-card
  .card-body
  img {
  display: block;
  max-width: 50% !important;
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

/* line 366, src/assets/scss/components/_components-cards.scss */
.card.is-ad .card-body .ad-text,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-ad.summary-card
  .card-body
  .ad-text {
  padding-left: 10px;
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: -20px;
}

/* line 373, src/assets/scss/components/_components-cards.scss */
.card.is-ad .card-body .ad-brand,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-ad.summary-card
  .card-body
  .ad-brand {
  position: absolute;
  bottom: 12px;
  right: 14px;
  text-transform: uppercase;
  font-size: 0.65rem;
  color: #999;
}

/*! _components-widgets.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 16, src/assets/scss/components/_components-widgets.scss */
.schedule {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  margin: 10px 0 20px 0;
  overflow: visible;
  background: #fff;
}

/* line 26, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 16px 16px 0 16px;
}

/* line 33, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-header .month {
  text-align: center;
  padding: 4px 0;
  color: #393a4f;
  width: 40%;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 42, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-header .month:hover {
  background: #f5f5f5;
}

/* line 47, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-header .nav-icon {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 57, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-header .nav-icon:hover {
  background: #f5f5f5;
}

/* line 61, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-header .nav-icon svg {
  height: 20px;
  width: 20px;
  stroke: #999;
}

/* line 69, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar {
  position: relative;
  padding: 20px 20px 40px 20px;
}

/* line 73, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .next-fab {
  position: absolute;
  bottom: -25px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

/* line 92, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .next-fab:hover {
  background: #fcfcfc;
}

/* line 95, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .next-fab:hover svg {
  stroke: #5596e6;
}

/* line 100, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .next-fab.is-toggled {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 105, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .next-fab svg {
  height: 20px;
  width: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 112, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 117, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row:not(:first-of-type) {
  margin-top: 4px;
}

/* line 121, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  padding: 14px;
  color: #596367;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 137, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day:hover {
  background: #f5f5f5;
}

/* line 141, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.day-name {
  color: #869ba1;
}

/* line 145, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event {
  position: relative;
  color: #fff;
}

/* line 149, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  z-index: -1;
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

/* line 164, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.primary {
  background: #3d70b2;
}

/* line 167, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.primary:before {
  background: #3d70b2;
}

/* line 172, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.green {
  background: #1ce589;
}

/* line 175, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.green:before {
  background: #1ce589;
}

/* line 180, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.purple {
  background: #5596e6;
}

/* line 183, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.purple:before {
  background: #5596e6;
}

/* line 188, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.pink {
  background: #fa3275;
}

/* line 191, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.pink:before {
  background: #fa3275;
}

/* line 196, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.animate {
  position: static;
}

/* line 199, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-calendar .calendar-row .day.event.animate:before {
  top: 160px;
  left: 55px;
  z-index: 1;
  will-change: transform;
}

/* line 211, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-divider {
  height: 1px;
  background: #ededed;
}

/* line 216, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events {
  padding: 16px;
  display: none;
  background: #fafafa;
}

/* line 221, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-events-title {
  margin-top: 16px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #999;
}

/* line 229, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 16px;
}

/* line 235, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-date {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 50%;
  color: #fff;
}

/* line 246, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-date.primary {
  background: #3d70b2;
}

/* line 250, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-date.green {
  background: #1ce589;
}

/* line 254, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-date.purple {
  background: #5596e6;
}

/* line 258, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-date.pink {
  background: #fa3275;
}

/* line 263, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-title {
  color: #596367;
  padding-left: 16px;
}

/* line 267, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-title span {
  display: block;
}

/* line 270, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-title span:first-child {
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 275, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .schedule-event .event-title span:nth-child(2) {
  color: #999;
  font-size: 0.85rem;
}

/* line 283, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-events .button-wrap {
  margin-top: 16px;
}

/* line 288, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  z-index: 1;
  overflow: hidden;
}

/* line 301, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container.animate-out {
  -webkit-animation: day-container-out 0.15s ease-out forwards;
  animation: day-container-out 0.15s ease-out forwards;
  will-change: transform;
}

/* line 307, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container.hidden {
  z-index: -100;
}

/* line 311, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header {
  position: relative;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 46px;
  padding: 16px;
  overflow: hidden;
  z-index: 2;
  -webkit-transition: height 0.075s linear;
  transition: height 0.075s linear;
}

/* line 323, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header.day-header--large {
  height: 200px;
}

/* line 326, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header.day-header--large
  .day-header-content {
  font-weight: lighter;
  padding: 16px;
  height: 200px;
  padding-top: 46px;
}

/* line 332, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header.day-header--large
  .day-header-content
  .day-header-title
  .day-header-title-day {
  font-size: 2.4rem;
  font-weight: 600;
}

/* line 337, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header.day-header--large
  .day-header-content
  .day-header-title
  .day-header-title-month {
  font-size: 1rem;
  text-transform: uppercase;
}

/* line 342, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header.day-header--large
  .day-header-content
  .day-header-event {
  font-size: 1.2rem;
  font-weight: 600;
  padding-top: 16px;
}

/* line 350, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-bg {
  position: absolute;
  top: 166px;
  left: 55px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  will-change: transform;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 361, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-bg.primary {
  background: #3d70b2;
}

/* line 365, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-bg.pink {
  background: #fa3275;
}

/* line 369, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-bg.purple {
  background: #5596e6;
}

/* line 373, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-bg.green {
  background: #1ce589;
}

/* line 377, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-bg.animate {
  -webkit-animation: day-header-grow 0.25s 0.05s ease-in-out forwards;
  animation: day-header-grow 0.25s 0.05s ease-in-out forwards;
}

/* line 383, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-close {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 18px;
  height: 18px;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
  z-index: 4;
}

/* line 394, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-close svg {
  height: 20px;
  width: 20px;
  stroke: #fcfcfc;
}

/* line 400, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-close.animate {
  opacity: 1;
}

/* line 405, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-content {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 46px;
  color: #fff;
  z-index: 3;
  opacity: 0;
  -webkit-transform: translateY(18px);
  transform: translateY(18px);
}

/* line 419, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-header .day-header-content.animate-in {
  -webkit-animation: day-header-content-in 0.15s ease-out forwards;
  animation: day-header-content-in 0.15s ease-out forwards;
}

/* line 424, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header
  .day-header-content
  .day-header-title {
  font-weight: normal;
}

/* line 427, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header
  .day-header-content
  .day-header-title
  .day-header-title-day,
.schedule
  .schedule-day-container
  .day-header
  .day-header-content
  .day-header-title
  .day-header-title-month {
  display: inline;
  vertical-align: baseline;
}

/* line 432, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header
  .day-header-content
  .day-header-title
  .day-header-title-day {
  font-size: 18px;
}

/* line 436, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header
  .day-header-content
  .day-header-title
  .day-header-title-month {
  font-size: 18px;
}

/* line 441, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-header
  .day-header-content
  .day-header-event {
  padding-top: 2px;
  font-size: 12px;
  font-weight: normal;
}

/* line 449, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 0;
  opacity: 0;
  padding-top: 216px;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  background: #fff;
}

/* line 465, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-content.animate-in {
  -webkit-animation: day-content-animate-in 0.2s ease-out forwards;
  animation: day-content-animate-in 0.2s ease-out forwards;
}

/* line 470, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-content .event-details-wrap {
  display: none;
  padding: 20px 0;
}

/* line 474, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-content .event-details-wrap.is-active {
  display: block;
}

/* line 478, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-content .event-details-wrap .meta-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

/* line 484, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .meta-block
  i {
  font-size: 24px;
  color: #999;
}

/* line 489, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .meta-block
  .meta {
  margin-left: 20px;
}

/* line 492, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .meta-block
  .meta
  span {
  display: block;
}

/* line 495, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .meta-block
  .meta
  span:first-child {
  font-weight: 500;
  font-size: 0.9rem;
}

/* line 500, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .meta-block
  .meta
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 508, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap {
  padding: 16px 0;
}

/* line 511, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 6px;
}

/* line 518, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 523, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img {
  display: block;
  height: 53px;
  width: 53px;
  font-size: 0.8rem;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #e8e8e8;
}

/* line 536, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img:not(:first-child):nth-child(1) {
  margin-left: -14px;
}

/* line 536, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img:not(:first-child):nth-child(2) {
  margin-left: -14px;
}

/* line 536, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img:not(:first-child):nth-child(3) {
  margin-left: -14px;
}

/* line 536, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img:not(:first-child):nth-child(4) {
  margin-left: -14px;
}

/* line 536, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img:not(:first-child):nth-child(5) {
  margin-left: -14px;
}

/* line 536, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  img:not(:first-child):nth-child(6) {
  margin-left: -14px;
}

/* line 543, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .participants-wrap
  .participants
  .is-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 53px;
  width: 53px;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #e8e8e8;
  margin-left: -14px;
}

/* line 560, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .event-description
  label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 6px;
}

/* line 567, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .event-description
  p {
  font-size: 0.9rem;
  color: #999;
}

/* line 573, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .button-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 578, src/assets/scss/components/_components-widgets.scss */
.schedule
  .schedule-day-container
  .day-content
  .event-details-wrap
  .button-wrap
  .button {
  width: 49%;
}

/* line 583, src/assets/scss/components/_components-widgets.scss */
.schedule .schedule-day-container .day-content .event-details-wrap .img {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  height: 150px;
  background: #2c3144;
}

@-webkit-keyframes day-header-grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(25);
    transform: scale(25);
  }
}

@keyframes day-header-grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(25);
    transform: scale(25);
  }
}

@-webkit-keyframes day-container-out {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    opacity: 0;
  }
}

@keyframes day-container-out {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    opacity: 0;
  }
}

@-webkit-keyframes day-header-content-in {
  0% {
    -webkit-transform: translateY(18px);
    transform: translateY(18px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes day-header-content-in {
  0% {
    -webkit-transform: translateY(18px);
    transform: translateY(18px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes day-content-animate-in {
  0% {
    -webkit-transform: translateY(35px);
    transform: translateY(35px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes day-content-animate-in {
  0% {
    -webkit-transform: translateY(35px);
    transform: translateY(35px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/*! _buttons.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 19, src/assets/scss/components/_components-buttons.scss */
button.button {
  line-height: 0;
}

/* line 23, src/assets/scss/components/_components-buttons.scss */
.button {
  font-size: 0.8rem;
  font-weight: 500;
  padding: 18px 22px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 29, src/assets/scss/components/_components-buttons.scss */
.button.is-rounded {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

/* line 35, src/assets/scss/components/_components-buttons.scss */
.button.icon-button {
  width: 32px;
  height: 32px;
  padding: 0;
}

/* line 40, src/assets/scss/components/_components-buttons.scss */
.button.icon-button svg {
  height: 16px !important;
  width: 16px !important;
  stroke: #344258;
}

/* line 47, src/assets/scss/components/_components-buttons.scss */
.button.icon-button.is-solid svg {
  stroke: #fff !important;
}

/* line 54, src/assets/scss/components/_components-buttons.scss */
.button.has-icon svg {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-right: 4px;
}

/* line 64, src/assets/scss/components/_components-buttons.scss */
.button.default-raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

/* line 70, src/assets/scss/components/_components-buttons.scss */
.button.is-phantom {
  background: transparent;
  border: 2px solid transparent;
}

/* line 74, src/assets/scss/components/_components-buttons.scss */
.button.is-phantom.primary-button {
  color: #3d70b2;
}

/* line 77, src/assets/scss/components/_components-buttons.scss */
.button.is-phantom.primary-button:hover {
  border-color: #3d70b2;
}

/* line 82, src/assets/scss/components/_components-buttons.scss */
.button.is-phantom.accent-button {
  color: #5596e6;
}

/* line 85, src/assets/scss/components/_components-buttons.scss */
.button.is-phantom.accent-button:hover {
  border-color: #5596e6;
}

/* line 92, src/assets/scss/components/_components-buttons.scss */
.button.is-reversed-phantom {
  background: transparent;
  border: 1px solid transparent;
}

/* line 96, src/assets/scss/components/_components-buttons.scss */
.button.is-reversed-phantom.primary-button {
  color: #3d70b2;
}

/* line 99, src/assets/scss/components/_components-buttons.scss */
.button.is-reversed-phantom.primary-button:hover {
  border-color: transparent !important;
  background: #3d70b2;
  color: #fafafa;
}

/* line 106, src/assets/scss/components/_components-buttons.scss */
.button.is-reversed-phantom.accent-button {
  color: #5596e6;
}

/* line 109, src/assets/scss/components/_components-buttons.scss */
.button.is-reversed-phantom.accent-button:hover {
  border-color: transparent !important;
  background: #5596e6;
  color: #fafafa;
}

/* line 120, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.primary-button {
  background: #3d70b2;
  border-color: #3d70b2;
  color: #fff;
}

/* line 125, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.primary-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  opacity: 0.8;
}

/* line 132, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.secondary-button {
  background: #41d6c3;
  border-color: #41d6c3;
  color: #fff;
}

/* line 137, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.secondary-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(65, 214, 195, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(65, 214, 195, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(65, 214, 195, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(65, 214, 195, 0.2) !important;
  opacity: 0.8;
}

/* line 144, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.accent-button {
  background: #5596e6;
  border-color: #5596e6;
  color: #fff;
}

/* line 149, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.accent-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  opacity: 0.8;
}

/* line 156, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.red-button {
  background: #f71416;
  border-color: #f71416;
  color: #fff;
}

/* line 161, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.red-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(247, 20, 22, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(247, 20, 22, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(247, 20, 22, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(247, 20, 22, 0.2) !important;
  opacity: 0.8;
}

/* line 168, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.green-button {
  background: #1ce589;
  border-color: #1ce589;
  color: #fff;
}

/* line 173, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.green-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(28, 229, 137, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(28, 229, 137, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(28, 229, 137, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(28, 229, 137, 0.2) !important;
  opacity: 0.8;
}

/* line 180, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.blue-button {
  background: #039be5;
  border-color: #039be5;
  color: #fff;
}

/* line 185, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.blue-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(3, 155, 229, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(3, 155, 229, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(3, 155, 229, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(3, 155, 229, 0.2) !important;
  opacity: 0.8;
}

/* line 192, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.grey-button {
  background: #f7f7f7;
  border-color: #f7f7f7;
}

/* line 196, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.grey-button svg {
  stroke: #344258 !important;
}

/* line 200, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.grey-button:hover {
  background: #f2f2f2;
  border-color: #f2f2f2;
}

/* line 207, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.dark-grey-button {
  background: #ededed;
  border-color: #ededed;
}

/* line 211, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.dark-grey-button svg {
  stroke: #344258 !important;
}

/* line 215, src/assets/scss/components/_components-buttons.scss */
.button.is-solid.dark-grey-button:hover {
  background: #dedede;
  border-color: #dedede;
}

/* line 223, src/assets/scss/components/_components-buttons.scss */
.button.light-button {
  background: transparent;
  border-color: #fff;
  color: #fff;
  font-weight: 500;
}

/* line 229, src/assets/scss/components/_components-buttons.scss */
.button.light-button:hover {
  background: #fff;
  color: #393a4f;
}

/* line 234, src/assets/scss/components/_components-buttons.scss */
.button.light-button.raised:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  opacity: 0.8;
}

/* line 248, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  text-decoration: none;
  opacity: 1 !important;
  overflow: hidden;
}

/* line 268, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button .like-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #d10709;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 0;
}

/* line 283, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button i.not-liked {
  display: block;
  color: #d10709;
  position: relative;
  z-index: 1;
}

/* line 290, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button i.is-liked {
  display: none;
  color: #fff;
  position: relative;
  z-index: 1;
}

/* line 299, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button.is-active {
  -webkit-box-shadow: 0px 5px 43px rgba(247, 20, 22, 0.18);
  box-shadow: 0px 5px 43px rgba(247, 20, 22, 0.18);
}

/* line 302, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button.is-active .like-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 307, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button.is-active i.not-liked {
  display: none;
}

/* line 311, src/assets/scss/components/_components-buttons.scss */
.like-wrapper .like-button.is-active i.is-liked {
  display: block;
}

/* line 326, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper.is-share a {
  background: #a985fc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 330, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper.is-share a:hover {
  background: #8ab7ee;
}

/* line 333, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper.is-share a:hover svg {
  stroke: #fff;
}

/* line 341, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper.is-comment a {
  background: #a985fc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 345, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper.is-comment a:hover {
  background: #8ab7ee;
}

/* line 348, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper.is-comment a:hover svg {
  stroke: #fff;
}

/* line 356, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper .small-fab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  color: #fcfcfc;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 367, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper .small-fab svg {
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 374, src/assets/scss/components/_components-buttons.scss */
.fab-wrapper .small-fab.is-active {
  background: #5596e6;
}

/* line 385, src/assets/scss/components/_components-buttons.scss */
.load-more-wrap {
  padding: 40px 0;
}

/* line 390, src/assets/scss/components/_components-buttons.scss */
.load-more-wrap.narrow-top {
  padding-top: 20px;
}

/* line 396, src/assets/scss/components/_components-buttons.scss */
.load-more-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  width: 8em;
  height: 2.25em;
  padding: 0.5em 1em;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  color: #757a91 !important;
  white-space: nowrap;
  line-height: 1;
  font-size: 1em;
  text-decoration: none;
  -webkit-transition: width 0.1s ease, height 0.4s ease, border-width 0.4s ease,
    border-radius 0.4s ease, border-color 0.4s ease, padding 0.4s ease;
  transition: width 0.1s ease, height 0.4s ease, border-width 0.4s ease,
    border-radius 0.4s ease, border-color 0.4s ease, padding 0.4s ease;
}

/* line 414, src/assets/scss/components/_components-buttons.scss */
.load-more-button:focus {
  outline: none;
  border-color: #ddd;
}

/* line 420, src/assets/scss/components/_components-buttons.scss */
.load-more-button:hover {
  background: #fafafa;
}

/* line 425, src/assets/scss/components/_components-buttons.scss */
.load-more-button.loading {
  height: 24px;
  width: 24px;
  padding: 0;
  border-width: 2px;
  border-radius: 17px;
  border-color: #eee #eee #eee #999;
  background: #fff;
  font-size: 0;
  color: transparent;
  pointer-events: none;
  -webkit-animation: spin 1.2s infinite linear;
  animation: spin 1.2s infinite linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* line 456, src/assets/scss/components/_components-buttons.scss */
.spinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 38px;
  min-width: 110px;
  border: 1px solid #e8e8e8;
  border-radius: 100px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  padding: 0 4px;
}

/* line 467, src/assets/scss/components/_components-buttons.scss */
.spinner .add,
.spinner .remove {
  height: 38px;
  width: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  outline: none !important;
}

/* line 479, src/assets/scss/components/_components-buttons.scss */
.spinner .add:hover svg,
.spinner .remove:hover svg {
  stroke: #393a4f;
}

/* line 484, src/assets/scss/components/_components-buttons.scss */
.spinner .add svg,
.spinner .remove svg {
  height: 14px;
  width: 14px;
  stroke: #6f7592;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 492, src/assets/scss/components/_components-buttons.scss */
.spinner .value {
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*! _dropdowns.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/components/_components-dropdowns.scss */
.dropdown-divider {
  background-color: #e8e8e8;
}

/* line 26, src/assets/scss/components/_components-dropdowns.scss */
.dropdown .avatar-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 31, src/assets/scss/components/_components-dropdowns.scss */
.dropdown .avatar-button img {
  height: 22px;
  width: 22px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

/* line 39, src/assets/scss/components/_components-dropdowns.scss */
.dropdown .avatar-button svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  fill: #393a4f;
  height: 8px;
  width: 8px;
  margin: 0 4px;
}

/* line 55, src/assets/scss/components/_components-dropdowns.scss */
.card-heading .dropdown.is-spaced > div .button,
.about-card .header .actions .dropdown.is-spaced > div .button {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 66, src/assets/scss/components/_components-dropdowns.scss */
.card-heading .dropdown.is-spaced > div .button:hover,
.about-card .header .actions .dropdown.is-spaced > div .button:hover {
  background: #f5f5f5;
}

/* line 70, src/assets/scss/components/_components-dropdowns.scss */
.card-heading .dropdown.is-spaced > div .button svg,
.about-card .header .actions .dropdown.is-spaced > div .button svg {
  height: 20px;
  width: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 83, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-modern.is-active .caret {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 87, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-modern.is-active .main-icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

/* line 92, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-modern > div .button {
  min-width: 110px;
  border-radius: 100px;
}

/* line 96, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-modern > div .button .caret,
.dropdown.is-spaced.is-modern > div .button .main-icon {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 101, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-modern .dropdown-menu {
  margin-top: 6px;
}

/* line 107, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-menu {
  -webkit-box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  border-color: #e8e8e8;
  padding-top: 0;
  min-width: 280px;
}

/* line 113, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-menu.has-margin {
  margin-top: 10px;
}

/* line 117, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-menu .dropdown-content {
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 124, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item {
  padding-right: 1rem !important;
  padding: 0.5rem 1rem;
}

/* line 129, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item.is-selected .checkmark {
  display: block !important;
}

/* line 135, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item.is-header h5 {
  font-weight: 500;
  margin-bottom: 10px;
  color: #757a91;
}

/* line 143, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 146, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media .media-content {
  line-height: 1.2;
}

/* line 150, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media h3 {
  font-weight: 500;
  font-size: 0.85rem;
  color: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 1.2;
}

/* line 158, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 20px;
}

/* line 166, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #757a91;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 175, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media small {
  color: #abb0be;
}

/* line 179, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media .checkmark {
  display: none;
}

/* line 182, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item .media .checkmark svg {
  margin-right: 0;
  margin-left: auto;
}

/* line 190, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item.is-title {
  background-color: transparent;
  margin-bottom: 10px;
}

/* line 196, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item.is-active {
  background: #039be5;
}

/* line 199, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item.is-active svg {
  stroke: #fff;
}

/* line 203, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item.is-active small {
  color: #fff;
}

/* line 208, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item:hover {
  background: #fafafa;
}

/* line 211, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item:hover h3 {
  color: #3d70b2;
}

/* line 215, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced .dropdown-item:hover svg {
  stroke: #3d70b2;
}

/* line 224, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-accent .dropdown-item:hover {
  background: #fafafa;
}

/* line 227, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-accent .dropdown-item:hover h3 {
  color: #5596e6 !important;
}

/* line 231, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-accent .dropdown-item:hover svg {
  stroke: #5596e6 !important;
}

/* line 241, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-neutral .dropdown-item:hover {
  background: #fafafa;
}

/* line 244, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-neutral .dropdown-item:hover h3 {
  color: #393a4f !important;
}

/* line 248, src/assets/scss/components/_components-dropdowns.scss */
.dropdown.is-spaced.is-neutral .dropdown-item:hover svg {
  stroke: #757a91 !important;
}

/* line 1, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker {
  border-color: #e5e5e5 !important;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  margin: 0 !important;
  top: -6px !important;
  height: 24.5rem !important;
}

/* line 8, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker .emoji-picker__search-container {
  display: none !important;
}

/* line 11, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker .emoji-picker__search-container input {
  outline: none !important;
  padding-left: 12px !important;
  -webkit-transition: all 0.3s !important;
  transition: all 0.3s !important;
  border-radius: 100px !important;
}

/* line 17, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker .emoji-picker__search-container input:focus {
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 21, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker .emoji-picker__search-container input:focus + span {
  color: #3d70b2;
}

/* line 26, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker .emoji-picker__search-container input + span svg {
  vertical-align: top;
}

/* line 35, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  .emoji-picker__tabs
  .emoji-picker__tab {
  color: #999;
}

/* line 38, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  .emoji-picker__tabs
  .emoji-picker__tab.active {
  color: #3d70b2;
  border-bottom-color: #3d70b2;
}

/* line 47, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  div
  > .emoji-picker__tab-body {
  border-bottom: 1px solid #e8e8e8;
}

/* line 50, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  div
  > .emoji-picker__tab-body
  h2 {
  padding: 4px 0;
}

/* line 54, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  div
  > .emoji-picker__tab-body
  .emoji-picker__emojis {
  height: 16.5rem;
  width: calc(1.3rem * 1.4 * 10);
  margin: 0 auto;
  overflow-x: hidden !important;
}

/* line 60, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  div
  > .emoji-picker__tab-body
  .emoji-picker__emojis::-webkit-scrollbar {
  width: 6px !important;
}

/* line 64, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker
  .emoji-picker__content
  .emoji-picker__tabs-container
  div
  > .emoji-picker__tab-body
  .emoji-picker__emojis::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/* line 74, src/assets/scss/components/_components-emoji-picker.scss */
.emoji-picker .emoji-picker__preview {
  border-top: none;
  height: 3.3em;
}

/*! _components-tabs.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 18, src/assets/scss/components/_components-tabs.scss */
.nav-tabs-wrapper .tabs ul.is-faded {
  border-bottom-color: transparent !important;
}

/* line 24, src/assets/scss/components/_components-tabs.scss */
.nav-tabs-wrapper .tabs ul li a {
  color: #cecece;
  border-image-width: 2px;
}

/* line 29, src/assets/scss/components/_components-tabs.scss */
.nav-tabs-wrapper .tabs ul li:hover a {
  border-bottom-color: inherit;
}

/* line 33, src/assets/scss/components/_components-tabs.scss */
.nav-tabs-wrapper .tabs ul li.is-active a {
  color: #5596e6;
  border-bottom-color: #5596e6;
}

/* line 40, src/assets/scss/components/_components-tabs.scss */
.nav-tabs-wrapper .tab-content {
  display: none;
  overflow: hidden;
  padding: 0 10px 10px 10px;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

/* line 46, src/assets/scss/components/_components-tabs.scss */
.nav-tabs-wrapper .tab-content.is-active {
  display: block;
}

/*! _modals.scss | Friendkit | © Css Ninja. 2019-2020 */ /*! _special-mixins.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 28, src/assets/scss/components/_components-modals.scss */
.modal {
  z-index: 1050;
}

/* line 31, src/assets/scss/components/_components-modals.scss */
.modal .modal-background {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 38, src/assets/scss/components/_components-modals.scss */
.modal.is-xsmall .modal-content {
  width: 480px;
}

/* line 44, src/assets/scss/components/_components-modals.scss */
.modal.is-small .modal-content {
  width: 540px;
}

/* line 50, src/assets/scss/components/_components-modals.scss */
.modal.is-medium .modal-content {
  width: 720px;
}

/* line 56, src/assets/scss/components/_components-modals.scss */
.modal.is-large .modal-content {
  width: 840px;
}

/* line 62, src/assets/scss/components/_components-modals.scss */
.modal.is-xlarge .modal-content {
  width: 1200px;
}

/* line 68, src/assets/scss/components/_components-modals.scss */
.modal.is-xxl .modal-content {
  width: 1340px;
}

/* line 74, src/assets/scss/components/_components-modals.scss */
.modal.is-light-bg .modal-background {
  background: rgba(0, 0, 0, 0.4);
}

/* line 80, src/assets/scss/components/_components-modals.scss */
.modal .card,
.modal .shop-wrapper .cart-container .cart-content .cart-summary .summary-card,
.shop-wrapper .cart-container .cart-content .cart-summary .modal .summary-card {
  -webkit-animation: modalScale 0.3s;
  animation: modalScale 0.3s;
  margin-bottom: 0;
}

/* line 85, src/assets/scss/components/_components-modals.scss */
.modal .card .card-heading,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
}

/* line 91, src/assets/scss/components/_components-modals.scss */
.modal .card .card-heading h3,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-heading
  h3 {
  font-size: 0.95rem;
  font-weight: 500;
}

/* line 97, src/assets/scss/components/_components-modals.scss */
.modal .card .card-heading .close-wrap,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .close-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-heading
  .close-wrap {
  height: 30px;
  width: 30px;
  margin-left: auto;
}

/* line 102, src/assets/scss/components/_components-modals.scss */
.modal .card .card-heading .close-wrap .close-modal,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .close-wrap
  .close-modal,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-heading
  .close-wrap
  .close-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

/* line 113, src/assets/scss/components/_components-modals.scss */
.modal .card .card-heading .close-wrap .close-modal:hover,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .close-wrap
  .close-modal:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-heading
  .close-wrap
  .close-modal:hover {
  background: #e8e8e8;
}

/* line 117, src/assets/scss/components/_components-modals.scss */
.modal .card .card-heading .close-wrap .close-modal svg,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .close-wrap
  .close-modal
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-heading
  .close-wrap
  .close-modal
  svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
}

/* line 127, src/assets/scss/components/_components-modals.scss */
.modal .card .card-body,
.modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .modal
  .summary-card
  .card-body {
  padding: 8px 12px;
}

/* line 139, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-heading {
  border-bottom: 1px solid #e8e8e8;
}

/* line 144, src/assets/scss/components/_components-modals.scss */
.create-group-modal .subheading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 151, src/assets/scss/components/_components-modals.scss */
.create-group-modal .subheading .group-avatar {
  position: relative;
  cursor: pointer;
}

/* line 155, src/assets/scss/components/_components-modals.scss */
.create-group-modal .subheading .group-avatar input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

/* line 164, src/assets/scss/components/_components-modals.scss */
.create-group-modal .subheading .group-avatar .add-photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  width: 42px;
  background: #ededed;
  border-radius: 4px;
}

/* line 173, src/assets/scss/components/_components-modals.scss */
.create-group-modal .subheading .group-avatar .add-photo svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
}

/* line 182, src/assets/scss/components/_components-modals.scss */
.create-group-modal .subheading .control input {
  margin: 0 10px;
  border: none !important;
}

/* line 190, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body {
  padding: 0 !important;
}

/* line 193, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

/* line 198, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .left-section {
  width: 67%;
}

/* line 202, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .left-section .search-subheader {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 12px;
  height: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 210, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .left-section .search-subheader .control {
  width: 100%;
  position: relative;
}

/* line 214, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .search-subheader
  .control
  input {
  height: 32px;
  border-radius: 0;
  padding-left: 36px;
  font-size: 0.9rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 221, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .search-subheader
  .control
  input:focus {
  border-color: #cecece;
}

/* line 224, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .search-subheader
  .control
  input:focus
  + .icon
  svg {
  stroke: #5596e6;
}

/* line 230, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .search-subheader
  .control
  .icon {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
}

/* line 240, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .search-subheader
  .control
  .icon
  svg {
  width: 18px;
  height: 18px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 251, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .left-section .user-list {
  height: 403px;
  padding: 12px 0;
  overflow-y: auto;
}

/* line 257, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .left-section .user-list .friend-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 6px;
  padding: 6px 12px;
  border-radius: 0;
  cursor: pointer;
}

/* line 265, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .user-list
  .friend-block:hover {
  background: #f2f2f2;
}

/* line 269, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .user-list
  .friend-block
  .friend-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* line 275, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .user-list
  .friend-block
  .friend-name {
  font-size: 0.85rem;
  font-weight: 500;
  color: #393a4f;
  margin: 0 10px;
}

/* line 282, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .left-section
  .user-list
  .friend-block
  .round-checkbox {
  margin-left: auto;
  margin-right: 12px;
}

/* line 291, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .right-section {
  width: 33%;
  height: 460px;
  background: #f5f6f7;
  border-left: 1px solid #e8e8e8;
  overflow-y: auto;
}

/* line 299, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .right-section .selected-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 12px;
  min-height: 57px;
}

/* line 306, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .right-section .selected-count span {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #999;
}

/* line 312, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-count
  span:first-child {
  text-transform: uppercase;
  font-size: 0.7rem;
}

/* line 320, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-body .inner .right-section .selected-list {
  padding: 12px;
}

/* line 324, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-list
  .selected-friend-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 0;
  border-radius: 0;
}

/* line 330, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-list
  .selected-friend-block
  .image-wrapper {
  position: relative;
}

/* line 333, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-list
  .selected-friend-block
  .image-wrapper
  .checked-badge {
  position: absolute;
  bottom: 3px;
  right: -3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border: 2px solid #f5f6f7;
  border-radius: 50%;
  background: #757a91;
}

/* line 346, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-list
  .selected-friend-block
  .image-wrapper
  .checked-badge
  svg {
  height: 8px;
  width: 8px;
  stroke: #fafafa;
  stroke-width: 3px;
}

/* line 356, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-list
  .selected-friend-block
  .friend-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* line 362, src/assets/scss/components/_components-modals.scss */
.create-group-modal
  .card-body
  .inner
  .right-section
  .selected-list
  .selected-friend-block
  .friend-name {
  font-size: 0.85rem;
  font-weight: 500;
  color: #393a4f;
  margin: 0 14px;
}

/* line 375, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 !important;
  padding: 8px;
}

/* line 382, src/assets/scss/components/_components-modals.scss */
.create-group-modal .card-footer button {
  margin-left: 12px;
  line-height: 0;
}

/* line 395, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card,
.albums-help-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .albums-help-modal
  .summary-card,
.videos-help-modal .card,
.videos-help-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .videos-help-modal
  .summary-card {
  overflow: hidden;
}

/* line 400, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-heading,
.videos-help-modal .card-heading {
  border-bottom: 1px solid #e8e8e8;
}

/* line 407, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block,
.videos-help-modal .card-body .content-block {
  position: absolute;
  height: 248px;
  width: 454px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 414, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block:first-child,
.videos-help-modal .card-body .content-block:first-child {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* line 418, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block:nth-child(2),
.videos-help-modal .card-body .content-block:nth-child(2) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* line 422, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block img,
.videos-help-modal .card-body .content-block img {
  display: block;
  height: 140px;
  margin: 20px auto;
}

/* line 428, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block .help-text,
.videos-help-modal .card-body .content-block .help-text {
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
}

/* line 433, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block .help-text h3,
.videos-help-modal .card-body .content-block .help-text h3 {
  font-weight: 500;
}

/* line 437, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block .help-text p,
.videos-help-modal .card-body .content-block .help-text p {
  color: #999;
  font-size: 0.9rem;
}

/* line 443, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .content-block.is-active,
.videos-help-modal .card-body .content-block.is-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

/* line 450, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .slide-dots,
.videos-help-modal .card-body .slide-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 258px 0 16px 0;
}

/* line 456, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .slide-dots .dot,
.videos-help-modal .card-body .slide-dots .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e8e8e8;
  margin: 0 3px;
}

/* line 463, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .slide-dots .dot.is-active,
.videos-help-modal .card-body .slide-dots .dot.is-active {
  background: #5596e6;
}

/* line 469, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .action,
.videos-help-modal .card-body .action {
  text-align: center;
  margin-bottom: 16px;
}

/* line 473, src/assets/scss/components/_components-modals.scss */
.albums-help-modal .card-body .action button,
.videos-help-modal .card-body .action button {
  line-height: 0;
  width: 160px;
}

/* line 486, src/assets/scss/components/_components-modals.scss */
.albums-modal .modal-content {
  max-width: calc(100% - 40px);
  max-height: calc(100vh - 20px) !important;
}

/* line 490, src/assets/scss/components/_components-modals.scss */
.albums-modal .modal-content .modal-card {
  max-height: calc(100vh - 20px) !important;
}

/* line 496, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-heading {
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

/* line 500, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-heading .button {
  line-height: 0 !important;
  padding: 14px 18px !important;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

/* line 507, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-heading .button i {
  font-size: 18px;
}

/* line 512, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-heading .close-wrap {
  margin-left: 0 !important;
}

/* line 518, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body {
  padding: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 523, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section,
.albums-modal .card-body .right-section {
  padding: 12px;
  height: 580px;
}

/* line 529, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section {
  width: 25%;
  background: #f5f6f7;
  border-right: 1px solid #e8e8e8;
}

/* line 535, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .control {
  margin-bottom: 10px;
}

/* line 538, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .control input {
  padding-left: 34px;
}

/* line 542, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .control .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #cecece;
}

/* line 553, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .control .icon svg {
  width: 16px;
  height: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 561, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .album-form {
  margin-bottom: 20px;
}

/* line 566, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .tagged-in-album {
  margin: 0 0 10px 0;
}

/* line 569, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .tagged-in-album .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3px;
}

/* line 575, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .tagged-in-album .head h4 {
  font-weight: 500;
  font-size: 0.85rem;
  color: #393a4f;
}

/* line 581, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .tagged-in-album .head .button {
  height: 22px;
  width: 22px;
  outline: none;
}

/* line 586, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .tagged-in-album .head .button svg {
  height: 12px !important;
  width: 12px !important;
  stroke-width: 2.6px;
}

/* line 594, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .tagged-in-album p {
  font-size: 0.75rem;
  max-width: 250px;
  color: #999;
  margin-bottom: 8px;
}

/* line 603, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .album-tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 610, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .album-tag-list .tagged-user {
  position: relative;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(1) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(2) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(3) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(4) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(5) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(6) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(7) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(8) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(9) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(10) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(11) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(12) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(13) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(14) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(15) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(16) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(17) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(18) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(19) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(20) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(21) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(22) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(23) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(24) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(25) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(26) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(27) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(28) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(29) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(30) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(31) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(32) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(33) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(34) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(35) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(36) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(37) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(38) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(39) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(40) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(41) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(42) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(43) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(44) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(45) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(46) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(47) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(48) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(49) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(50) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(51) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(52) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(53) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(54) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(55) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(56) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(57) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(58) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(59) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(60) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(61) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(62) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(63) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(64) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(65) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(66) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(67) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(68) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(69) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(70) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(71) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(72) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(73) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(74) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(75) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(76) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(77) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(78) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(79) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(80) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(81) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(82) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(83) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(84) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(85) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(86) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(87) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(88) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(89) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(90) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(91) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(92) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(93) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(94) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(95) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(96) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(97) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(98) {
  margin-left: -12px;
}

/* line 617, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:not(:first-child):nth-child(99) {
  margin-left: -12px;
}

/* line 624, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user:hover
  .remove-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 629, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .album-tag-list .tagged-user img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 3px solid #f5f6f7;
}

/* line 637, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user
  .remove-tag {
  position: absolute;
  top: -5px;
  left: -7px;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #fafafa;
  background: #999;
  cursor: pointer;
}

/* line 651, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user
  .remove-tag:hover {
  background: #f71416;
}

/* line 655, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .album-tag-list
  .tagged-user
  .remove-tag
  svg {
  height: 12px;
  width: 12px;
  stroke: #fafafa;
}

/* line 665, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .shared-album,
.albums-modal .card-body .left-section .album-date {
  margin-bottom: 10px;
  margin-top: 0;
}

/* line 669, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .shared-album .head,
.albums-modal .card-body .left-section .album-date .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3px;
}

/* line 675, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .shared-album .head h4,
.albums-modal .card-body .left-section .album-date .head h4 {
  font-weight: 500;
  font-size: 0.85rem;
  color: #393a4f;
}

/* line 683, src/assets/scss/components/_components-modals.scss */
.albums-modal
  .card-body
  .left-section
  .shared-album
  .head
  .basic-checkbox
  label:before,
.albums-modal
  .card-body
  .left-section
  .album-date
  .head
  .basic-checkbox
  label:before {
  margin-right: 0;
}

/* line 689, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .shared-album .head .button,
.albums-modal .card-body .left-section .album-date .head .button {
  height: 22px;
  width: 22px;
  outline: none;
}

/* line 694, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .shared-album .head .button svg,
.albums-modal .card-body .left-section .album-date .head .button svg {
  height: 12px !important;
  width: 12px !important;
  stroke-width: 2.6px;
}

/* line 702, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .shared-album p,
.albums-modal .card-body .left-section .album-date p {
  font-size: 0.75rem;
  max-width: 250px;
  color: #999;
  margin-bottom: 8px;
}

/* line 710, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .left-section .album-date {
  margin-top: 20px;
}

/* line 716, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-body .right-section {
  width: 75%;
  overflow-y: auto;
}

/* line 723, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 !important;
  padding: 8px;
}

/* line 730, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-footer button {
  margin-left: 6px;
  line-height: 0;
}

/* line 737, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-footer .dropdown button {
  padding: 0 16px;
  height: 38px;
}

/* line 741, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-footer .dropdown button svg {
  height: 16px;
  width: 16px;
}

/* line 745, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-footer .dropdown button svg.main-icon {
  margin-right: 4px;
}

/* line 750, src/assets/scss/components/_components-modals.scss */
.albums-modal .card-footer .dropdown button i {
  font-size: 18px;
  margin-right: 4px;
}

/* line 764, src/assets/scss/components/_components-modals.scss */
.videos-modal .modal-content {
  max-width: calc(100% - 40px);
  max-height: calc(100vh - 20px) !important;
}

/* line 770, src/assets/scss/components/_components-modals.scss */
.videos-modal .modal-card {
  max-height: calc(100vh - 20px) !important;
}

/* line 775, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-heading {
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

/* line 779, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-heading .button {
  line-height: 0 !important;
  padding: 14px 18px !important;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

/* line 786, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-heading .button i {
  font-size: 18px;
  margin-right: 4px;
}

/* line 792, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-heading .close-wrap {
  margin-left: 0 !important;
}

/* line 798, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body {
  padding: 0 !important;
}

/* line 801, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 806, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .left-section {
  width: 65%;
}

/* line 811, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section {
  position: relative;
  width: 35%;
  border-left: 1px solid #e8e8e8;
}

/* line 817, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .comment-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #eff1f2;
  height: 50px;
  width: 100%;
  border-radius: 0 0 6px 0;
  border-top: 1px solid #dee2e5;
}

/* line 827, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

/* line 835, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

/* line 842, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .control {
  position: relative;
  width: 100%;
}

/* line 846, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .control
  .textarea,
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .control
  .emojionearea-editor {
  resize: none;
  height: 36px;
  max-height: 36px;
  min-height: 36px;
  border-radius: 100px;
  overflow: hidden;
  line-height: 1.6;
  font-size: 0.8rem;
  padding-left: 16px;
  margin: 0 6px;
}

/* line 859, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .control
  .emoji-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 875, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .control
  .emoji-button:hover
  svg {
  stroke: #3d70b2;
}

/* line 880, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .control
  .emoji-button
  svg {
  height: 16px;
  width: 16px;
  stroke: #abb0be;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 890, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea-editor {
  padding-left: 0 !important;
}

/* line 893, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea-editor
  img {
  height: 18px;
  width: 18px;
  min-height: 18px;
  max-height: 18px;
}

/* line 901, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea {
  overflow: visible !important;
}

/* line 905, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea-picker {
  top: -230px;
  position: absolute;
  left: -50px;
  width: 310px;
}

/* line 911, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea-picker
  .emojionearea-wrapper {
  width: 310px;
}

/* line 914, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea-picker
  .emojionearea-wrapper
  img {
  height: 22px;
  width: 22px;
  min-height: 22px;
  max-height: 22px;
}

/* line 923, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea-scroll-area {
  width: 310px;
}

/* line 927, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea
  .emojionearea-button
  > div.emojionearea-button-open {
  background-position: 0 -22px;
}

/* line 931, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .comment-controls
  .controls-inner
  .emojionearea
  .emojionearea-picker
  .emojionearea-scroll-area
  .emojibtn {
  width: 24px !important;
  height: 24px !important;
}

/* line 939, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  width: 100%;
}

/* line 947, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

/* line 953, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header .user-meta {
  margin: 0 10px;
}

/* line 956, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header .user-meta span {
  display: block;
}

/* line 959, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .header
  .user-meta
  span:first-child {
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 963, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .header
  .user-meta
  span:first-child
  small {
  color: #999;
}

/* line 968, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .header
  .user-meta
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 975, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header .button {
  line-height: 0;
  margin-left: auto;
  padding: 14px 18px;
}

/* line 983, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header .dropdown .button {
  padding: 18px 6px;
  border: none;
  background: transparent;
}

/* line 988, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header .dropdown .button svg {
  height: 18px;
  width: 18px;
}

/* line 993, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .header .dropdown .button img {
  border-radius: 50%;
}

/* line 998, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .header
  .dropdown
  .dropdown-menu {
  margin-top: 10px;
}

/* line 1005, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .inner-content {
  padding: 12px;
}

/* line 1008, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .inner-content .control {
  width: 100%;
}

/* line 1011, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .inner-content .control input {
  padding-left: 34px;
}

/* line 1015, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .inner-content
  .control
  input:focus
  + .icon
  svg {
  stroke: #5596e6;
}

/* line 1021, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .inner-content .control .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1031, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .inner-content
  .control
  .icon
  svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1042, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .live-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

/* line 1049, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .live-stats .social-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 1053, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .shares-count,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .comments-count,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .likes-count,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .views-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

/* line 1059, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .shares-count
  span,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .comments-count
  span,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .likes-count
  span,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .views-count
  span {
  display: block;
  font-size: 0.8rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 1065, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .shares-count
  span.views,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .comments-count
  span.views,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .likes-count
  span.views,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .views-count
  span.views {
  margin: 0 2px;
}

/* line 1070, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .shares-count
  svg,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .comments-count
  svg,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .likes-count
  svg,
.videos-modal
  .card-body
  .inner
  .right-section
  .live-stats
  .social-count
  .views-count
  svg {
  height: 14px;
  width: 14px;
  stroke: #888da8;
}

/* line 1080, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 0;
}

/* line 1086, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .actions .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 1092, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .actions .action span {
  display: block;
  font-size: 0.8rem;
  margin: 0 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1099, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .actions .action svg {
  height: 16px;
  width: 16px;
  stroke: #888da8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1107, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .actions .action:hover span {
  color: #5596e6;
}

/* line 1111, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .actions .action:hover svg {
  stroke: #5596e6;
}

/* line 1120, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .tabs-wrapper .tabs {
  margin-bottom: 0 !important;
}

/* line 1124, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .tabs-wrapper .tabs li a {
  font-size: 0.85rem;
  color: #cecece;
  font-weight: 400;
}

/* line 1131, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .tabs-wrapper .tabs li:hover a {
  border-bottom-color: #999;
}

/* line 1137, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tabs
  li.is-active
  a {
  font-weight: 500;
  color: #5596e6;
  border-bottom-color: #5596e6;
}

/* line 1147, src/assets/scss/components/_components-modals.scss */
.videos-modal .card-body .inner .right-section .tabs-wrapper .tab-content {
  background: #f5f6f7;
  height: 320px;
  padding: 20px 14px;
  overflow-y: auto;
}

/* line 1154, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment {
  border: none !important;
  padding-top: 0 !important;
}

/* line 1158, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-left {
  margin-right: 10px;
}

/* line 1161, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-left
  img {
  border-radius: 50%;
}

/* line 1166, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content {
  background: #fff;
  padding: 8px;
  border-radius: 12px;
}

/* line 1171, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content
  .username {
  font-size: 0.8rem;
  font-weight: 500;
}

/* line 1176, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content
  p {
  font-size: 0.75rem;
  color: #999;
}

/* line 1181, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content
  .comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 8px;
}

/* line 1186, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content
  .comment-actions
  span,
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content
  .comment-actions
  a {
  display: block;
  font-size: 0.75rem;
}

/* line 1191, src/assets/scss/components/_components-modals.scss */
.videos-modal
  .card-body
  .inner
  .right-section
  .tabs-wrapper
  .tab-content
  .is-comment
  .media-content
  .comment-actions
  span {
  margin: 0 20px;
  color: #999;
}

/* line 1212, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading {
  padding: 8px !important;
}

/* line 1216, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading,
.share-modal .share-inputs {
  border-bottom: 1px solid #e8e8e8;
}

/* line 1219, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown,
.share-modal .share-inputs .dropdown {
  margin-left: 0 !important;
}

/* line 1222, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .button,
.share-modal .share-inputs .dropdown .button {
  padding: 10px 12px;
  background: #f5f6f7;
  color: #393a4f;
}

/* line 1227, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .button svg,
.share-modal .share-inputs .dropdown .button svg {
  height: 16px;
  width: 16px;
  margin: 0 4px;
  stroke: #393a4f;
}

/* line 1234, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .button i,
.share-modal .share-inputs .dropdown .button i {
  font-size: 20px;
  margin-right: 4px;
}

/* line 1239, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .button img,
.share-modal .share-inputs .dropdown .button img {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50% !important;
}

/* line 1247, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .dropdown-menu,
.share-modal .share-inputs .dropdown .dropdown-menu {
  width: 320px;
}

/* line 1251, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-heading
  .dropdown
  .dropdown-menu
  .dropdown-content
  .dropdown-item,
.share-modal
  .share-inputs
  .dropdown
  .dropdown-menu
  .dropdown-content
  .dropdown-item {
  cursor: pointer;
}

/* line 1254, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-heading
  .dropdown
  .dropdown-menu
  .dropdown-content
  .dropdown-item:hover,
.share-modal
  .share-inputs
  .dropdown
  .dropdown-menu
  .dropdown-content
  .dropdown-item:hover {
  background: #f5f5f5;
}

/* line 1259, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .dropdown-menu .dropdown-content img,
.share-modal .share-inputs .dropdown .dropdown-menu .dropdown-content img {
  height: 36px !important;
  width: 36px !important;
  border-radius: 50% !important;
  margin-right: 10px;
}

/* line 1266, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown .dropdown-menu .dropdown-content i,
.share-modal .share-inputs .dropdown .dropdown-menu .dropdown-content i {
  font-size: 22px;
  margin-right: 16px;
  color: #999;
}

/* line 1273, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-heading
  .dropdown
  .dropdown-menu
  .dropdown-content
  .media-content
  h3,
.share-modal
  .share-inputs
  .dropdown
  .dropdown-menu
  .dropdown-content
  .media-content
  h3 {
  font-size: 0.85rem;
}

/* line 1277, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-heading
  .dropdown
  .dropdown-menu
  .dropdown-content
  .media-content
  small,
.share-modal
  .share-inputs
  .dropdown
  .dropdown-menu
  .dropdown-content
  .media-content
  small {
  color: #999;
}

/* line 1285, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown.is-active .button,
.share-modal .share-inputs .dropdown.is-active .button {
  background: #5596e6;
  color: #fafafa;
}

/* line 1289, src/assets/scss/components/_components-modals.scss */
.share-modal .card-heading .dropdown.is-active .button svg,
.share-modal .share-inputs .dropdown.is-active .button svg {
  stroke: #fafafa;
}

/* line 1298, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs {
  border-bottom: none !important;
}

/* line 1301, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .field {
  margin-bottom: 0 !important;
}

/* line 1305, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .field .easy-autocomplete input {
  padding-left: 85px;
}

/* line 1311, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control {
  border-bottom: 1px solid #e8e8e8;
}

/* line 1314, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control.no-border {
  border-bottom-color: transparent;
}

/* line 1318, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control input {
  border: none;
  padding-left: 85px;
}

/* line 1323, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control .input-heading {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  width: 70px;
  padding: 0 6px;
  background: #999;
  color: #fafafa;
  font-size: 0.8rem;
}

/* line 1338, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control .page-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1343, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control .page-controls .page-selection {
  padding: 8px;
}

/* line 1347, src/assets/scss/components/_components-modals.scss */
.share-modal
  .share-inputs
  .control
  .page-controls
  .page-selection
  .page-selector
  img {
  display: block;
  position: relative;
  top: -1px;
  margin-right: 6px;
}

/* line 1356, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control .page-controls .alias {
  padding: 8px;
}

/* line 1359, src/assets/scss/components/_components-modals.scss */
.share-modal .share-inputs .control .page-controls .alias img {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

/* line 1373, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body > .control {
  position: relative;
}

/* line 1376, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body > .control .textarea {
  resize: none;
  border: none;
  padding-right: 42px;
}

/* line 1382, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body > .control .emoji-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 41px;
  width: 41px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 1398, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body > .control .emoji-button:hover svg {
  stroke: #3d70b2;
}

/* line 1403, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body > .control .emoji-button svg {
  height: 16px;
  width: 16px;
  stroke: #abb0be;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1413, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body .shared-publication {
  margin-top: 20px;
}

/* line 1418, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body .shared-publication .featured-image img {
  display: block;
}

/* line 1424, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body .shared-publication .publication-meta {
  border: 1px solid #e8e8e8;
  background: #f5f6f7;
  padding: 20px;
}

/* line 1429, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body .shared-publication .publication-meta .inner-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 20px;
}

/* line 1434, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body .shared-publication .publication-meta .inner-flex img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* line 1440, src/assets/scss/components/_components-modals.scss */
.share-modal .card-body .shared-publication .publication-meta .inner-flex p {
  font-size: 0.8rem;
  padding: 0 16px;
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 3.6em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}

/* line 26, src/assets/scss/abstracts/_mixins.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .inner-flex
  p:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}

/* line 33, src/assets/scss/abstracts/_mixins.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .inner-flex
  p:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: #f5f6f7;
}

/* line 1449, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .publication-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1454, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .publication-footer
  .stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

/* line 1461, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .publication-footer
  .stat-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

/* line 1467, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .publication-footer
  .stat-block
  i {
  margin-right: 2px;
  color: #999;
}

/* line 1472, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .publication-footer
  .stat-block
  small {
  font-size: 0.75rem;
}

/* line 1478, src/assets/scss/components/_components-modals.scss */
.share-modal
  .card-body
  .shared-publication
  .publication-meta
  .publication-footer
  .publication-origin
  small {
  text-transform: uppercase;
  color: #999;
  font-weight: 200;
  font-size: 0.65rem;
}

/* line 1491, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs {
  border-bottom: none !important;
}

/* line 1494, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .field {
  margin-bottom: 0 !important;
}

/* line 1498, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .field .easy-autocomplete input {
  padding-left: 85px;
}

/* line 1504, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control {
  border-top: 1px solid #e8e8e8;
}

/* line 1507, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control.no-border {
  border-bottom-color: transparent;
}

/* line 1511, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control input {
  border: none;
  padding-left: 85px;
}

/* line 1516, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control .input-heading {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  width: 70px;
  padding: 0 6px;
  background: #999;
  color: #fafafa;
  font-size: 0.8rem;
}

/* line 1531, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control .page-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1536, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control .page-controls .page-selection {
  padding: 8px;
}

/* line 1540, src/assets/scss/components/_components-modals.scss */
.share-modal
  .bottom-share-inputs
  .control
  .page-controls
  .page-selection
  .page-selector
  img {
  display: block;
  position: relative;
  top: -1px;
  margin-right: 6px;
}

/* line 1549, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control .page-controls .alias {
  padding: 8px;
}

/* line 1552, src/assets/scss/components/_components-modals.scss */
.share-modal .bottom-share-inputs .control .page-controls .alias img {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

/* line 1564, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 !important;
  padding: 8px;
  background: #f5f6f7;
  border-radius: 0 0 6px 6px;
}

/* line 1574, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer .action-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1579, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer .action-wrap .footer-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 34px;
  width: 34px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1589, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer .action-wrap .footer-action i {
  font-size: 18px;
  color: #999;
}

/* line 1594, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer .action-wrap .footer-action:hover {
  background: #e7e9ec;
}

/* line 1598, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer .action-wrap .footer-action.is-active {
  background: #e7e9ec;
}

/* line 1601, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer .action-wrap .footer-action.is-active i {
  color: #5596e6;
}

/* line 1608, src/assets/scss/components/_components-modals.scss */
.share-modal .card-footer button {
  line-height: 0;
  margin-left: 4px;
  height: 32px !important;
  padding: 8px 22px;
}

/* line 1623, src/assets/scss/components/_components-modals.scss */
.change-cover-modal .card .card-heading,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-heading,
.change-profile-pic-modal .card .card-heading,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-heading,
.new-story-modal .card .card-heading,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-heading {
  padding: 16px 28px;
}

/* line 1626, src/assets/scss/components/_components-modals.scss */
.change-cover-modal .card .card-heading h3,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-heading
  h3,
.change-profile-pic-modal .card .card-heading h3,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-heading
  h3,
.new-story-modal .card .card-heading h3,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-heading
  h3 {
  font-size: 1rem;
}

/* line 1630, src/assets/scss/components/_components-modals.scss */
.change-cover-modal .card .card-heading .small-avatar,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-heading
  .small-avatar,
.change-profile-pic-modal .card .card-heading .small-avatar,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-heading
  .small-avatar,
.new-story-modal .card .card-heading .small-avatar,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-heading
  .small-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1642, src/assets/scss/components/_components-modals.scss */
.change-cover-modal .card .card-heading .small-avatar img,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-heading
  .small-avatar
  img,
.change-profile-pic-modal .card .card-heading .small-avatar img,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-heading
  .small-avatar
  img,
.new-story-modal .card .card-heading .small-avatar img,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-heading
  .small-avatar
  img {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1653, src/assets/scss/components/_components-modals.scss */
.change-cover-modal .card .card-body .selection-placeholder,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder,
.change-profile-pic-modal .card .card-body .selection-placeholder,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder,
.new-story-modal .card .card-body .selection-placeholder,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder {
  padding: 0 20px 30px 20px;
}

/* line 1656, src/assets/scss/components/_components-modals.scss */
.change-cover-modal .card .card-body .selection-placeholder .selection-box,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box,
.new-story-modal .card .card-body .selection-placeholder .selection-box,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box {
  padding: 40px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1668, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.new-story-modal .card .card-body .selection-placeholder .selection-box:hover,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1672, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 1679, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box:hover
  .box-content
  .box-text
  span:first-child {
  color: #5596e6;
}

/* line 1686, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content {
  text-align: center;
}

/* line 1689, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  img {
  display: block;
  max-height: 120px;
  margin: 0 auto;
  opacity: 0.5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

/* line 1699, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text {
  margin: 10px 0;
}

/* line 1702, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span {
  display: block;
}

/* line 1705, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:first-child {
  font-weight: 500;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1711, src/assets/scss/components/_components-modals.scss */
.change-cover-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.change-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-cover-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.change-profile-pic-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.change-profile-pic-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .change-profile-pic-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.new-story-modal
  .card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.new-story-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .new-story-modal
  .summary-card
  .card-body
  .selection-placeholder
  .selection-box
  .box-content
  .box-text
  span:nth-child(2) {
  font-size: 0.9rem;
  color: #999;
}

/* line 1730, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-heading,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-heading {
  padding: 20px;
}

/* line 1733, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-heading h3,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-heading
  h3 {
  font-size: 1rem;
}

/* line 1738, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body {
  padding: 0 20px;
}

/* line 1741, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tabs,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tabs,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tabs {
  margin-bottom: 0;
}

/* line 1744, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tabs ul,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tabs
  ul,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tabs
  ul {
  border-color: transparent;
}

/* line 1748, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tabs ul li.is-active,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tabs
  ul
  li.is-active,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tabs
  ul
  li.is-active {
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
}

/* line 1753, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tabs ul li.is-active a,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tabs
  ul
  li.is-active
  a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tabs
  ul
  li.is-active
  a {
  color: #393a4f;
  font-weight: 500;
}

/* line 1759, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tabs ul li a,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tabs
  ul
  li
  a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tabs
  ul
  li
  a {
  font-size: 0.8rem;
  border-bottom-color: transparent !important;
}

/* line 1767, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tab-content,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tab-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tab-content {
  padding: 20px 0;
  max-height: 395px;
  overflow-y: auto;
}

/* line 1772, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tab-content .album-info,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tab-content
  .album-info,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tab-content
  .album-info {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1778, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tab-content .album-info h4,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tab-content
  .album-info
  h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tab-content
  .album-info
  h4 {
  font-weight: 600;
  color: #393a4f;
}

/* line 1782, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .tab-content .album-info h4 small,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tab-content
  .album-info
  h4
  small,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tab-content
  .album-info
  h4
  small {
  font-size: 0.9rem;
  color: #999;
  font-weight: 400;
}

/* line 1789, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .tab-content
  .album-info
  .close-nested-photos,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .tab-content
  .album-info
  .close-nested-photos,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .tab-content
  .album-info
  .close-nested-photos {
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 1796, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid,
.user-photos-modal .card .card-body .album-image-grid,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

/* line 1799, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .column,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .column,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .column,
.user-photos-modal .card .card-body .album-image-grid .column,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .column,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .column {
  padding: 0.5rem;
}

/* line 1803, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .grid-image,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image,
.user-photos-modal .card .card-body .album-image-grid .grid-image,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image {
  position: relative;
}

/* line 1807, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .grid-image:hover img,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image:hover
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image:hover
  img,
.user-photos-modal .card .card-body .album-image-grid .grid-image:hover img,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image:hover
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image:hover
  img {
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

/* line 1811, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .image-grid
  .grid-image:hover
  .inner-overlay,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image:hover
  .inner-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image:hover
  .inner-overlay,
.user-photos-modal
  .card
  .card-body
  .album-image-grid
  .grid-image:hover
  .inner-overlay,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image:hover
  .inner-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image:hover
  .inner-overlay {
  background: rgba(57, 58, 79, 0.2) !important;
}

/* line 1816, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .grid-image input,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  input,
.user-photos-modal .card .card-body .album-image-grid .grid-image input,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

/* line 1827, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .image-grid
  .grid-image
  input:checked
  + .inner
  .inner-overlay,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  input:checked
  + .inner
  .inner-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  input:checked
  + .inner
  .inner-overlay,
.user-photos-modal
  .card
  .card-body
  .album-image-grid
  .grid-image
  input:checked
  + .inner
  .inner-overlay,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  input:checked
  + .inner
  .inner-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  input:checked
  + .inner
  .inner-overlay {
  background: rgba(57, 58, 79, 0.5);
}

/* line 1831, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .image-grid
  .grid-image
  input:checked
  + .inner
  .indicator,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  input:checked
  + .inner
  .indicator,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  input:checked
  + .inner
  .indicator,
.user-photos-modal
  .card
  .card-body
  .album-image-grid
  .grid-image
  input:checked
  + .inner
  .indicator,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  input:checked
  + .inner
  .indicator,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  input:checked
  + .inner
  .indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 1837, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .grid-image .inner,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner,
.user-photos-modal .card .card-body .album-image-grid .grid-image .inner,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner {
  position: relative;
}

/* line 1840, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .grid-image .inner img,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  img,
.user-photos-modal .card .card-body .album-image-grid .grid-image .inner img,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  img {
  display: block;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1846, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .image-grid
  .grid-image
  .inner
  .inner-overlay,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  .inner-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  .inner-overlay,
.user-photos-modal
  .card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .inner-overlay,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .inner-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .inner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(57, 58, 79, 0.1);
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 0;
}

/* line 1858, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .image-grid .grid-image .inner .indicator,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  .indicator,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  .indicator,
.user-photos-modal
  .card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .indicator,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .indicator,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .indicator {
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #5596e6;
  border: 2px solid #fff;
  z-index: 0;
}

/* line 1872, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .image-grid
  .grid-image
  .inner
  .indicator
  svg,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  .indicator
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .image-grid
  .grid-image
  .inner
  .indicator
  svg,
.user-photos-modal
  .card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .indicator
  svg,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .indicator
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-image-grid
  .grid-image
  .inner
  .indicator
  svg {
  height: 12px;
  width: 12px;
  stroke: #fafafa;
  stroke-width: 3px;
}

/* line 1883, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 12px;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1892, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper:hover,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1897, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper:hover .image-count svg,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper:hover
  .image-count
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper:hover
  .image-count
  svg {
  stroke: #5596e6 !important;
}

/* line 1901, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper:hover .image-count span,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper:hover
  .image-count
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper:hover
  .image-count
  span {
  color: #5596e6 !important;
}

/* line 1908, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper .album-image img,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-image
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-image
  img {
  display: block;
  border: 4px;
}

/* line 1914, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper .album-meta,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1919, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper .album-meta .album-title,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title {
  margin-top: 6px;
}

/* line 1922, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span {
  display: block;
}

/* line 1925, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span:first-child,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span:first-child {
  min-width: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 1932, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span:nth-child(2),
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .album-title
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 1939, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper .album-meta .image-count,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .image-count,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .image-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 6px;
}

/* line 1944, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-body .album-wrapper .album-meta .image-count svg,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .image-count
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .image-count
  svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1951, src/assets/scss/components/_components-modals.scss */
.user-photos-modal
  .card
  .card-body
  .album-wrapper
  .album-meta
  .image-count
  span,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .image-count
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-body
  .album-wrapper
  .album-meta
  .image-count
  span {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #999;
  margin: 0 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* line 1964, src/assets/scss/components/_components-modals.scss */
.user-photos-modal .card .card-footer,
.user-photos-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .user-photos-modal
  .summary-card
  .card-footer {
  padding: 20px;
  margin: 0;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1981, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-heading,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-heading,
.upload-crop-cover-modal .card .card-heading,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-heading {
  padding: 20px;
}

/* line 1984, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-heading h3,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-heading
  h3,
.upload-crop-cover-modal .card .card-heading h3,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-heading
  h3 {
  font-size: 1rem;
}

/* line 1989, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body,
.upload-crop-cover-modal .card .card-body,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body {
  padding: 0 20px;
}

/* line 1992, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .profile-uploader-box,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box,
.upload-crop-profile-modal .card .card-body .cover-uploader-box,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box,
.upload-crop-cover-modal .card .card-body .profile-uploader-box,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box,
.upload-crop-cover-modal .card .card-body .cover-uploader-box,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box {
  display: block;
  width: 100%;
  height: 300px;
  padding: 40px;
  border: 3px dashed #e8e8e8;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 2004, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal
  .card
  .card-body
  .profile-uploader-box:hover
  .inner-content,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content,
.upload-crop-profile-modal
  .card
  .card-body
  .cover-uploader-box:hover
  .inner-content,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content,
.upload-crop-cover-modal
  .card
  .card-body
  .profile-uploader-box:hover
  .inner-content,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content,
.upload-crop-cover-modal
  .card
  .card-body
  .cover-uploader-box:hover
  .inner-content,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content {
  position: relative;
}

/* line 2007, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal
  .card
  .card-body
  .profile-uploader-box:hover
  .inner-content
  img,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content
  img,
.upload-crop-profile-modal
  .card
  .card-body
  .cover-uploader-box:hover
  .inner-content
  img,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content
  img,
.upload-crop-cover-modal
  .card
  .card-body
  .profile-uploader-box:hover
  .inner-content
  img,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box:hover
  .inner-content
  img,
.upload-crop-cover-modal
  .card
  .card-body
  .cover-uploader-box:hover
  .inner-content
  img,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box:hover
  .inner-content
  img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/* line 2015, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal
  .card
  .card-body
  .profile-uploader-box
  .inner-content,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content,
.upload-crop-profile-modal .card .card-body .cover-uploader-box .inner-content,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content,
.upload-crop-cover-modal .card .card-body .profile-uploader-box .inner-content,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content,
.upload-crop-cover-modal .card .card-body .cover-uploader-box .inner-content,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content {
  display: block;
}

/* line 2018, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal
  .card
  .card-body
  .profile-uploader-box
  .inner-content
  img,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  img,
.upload-crop-profile-modal
  .card
  .card-body
  .cover-uploader-box
  .inner-content
  img,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  img,
.upload-crop-cover-modal
  .card
  .card-body
  .profile-uploader-box
  .inner-content
  img,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  img,
.upload-crop-cover-modal
  .card
  .card-body
  .cover-uploader-box
  .inner-content
  img,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  img {
  display: block;
  max-height: 110px;
  margin: 0 auto;
  opacity: 0.5;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2028, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal
  .card
  .card-body
  .profile-uploader-box
  .inner-content
  span,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  span,
.upload-crop-profile-modal
  .card
  .card-body
  .cover-uploader-box
  .inner-content
  span,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  span,
.upload-crop-cover-modal
  .card
  .card-body
  .profile-uploader-box
  .inner-content
  span,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box
  .inner-content
  span,
.upload-crop-cover-modal
  .card
  .card-body
  .cover-uploader-box
  .inner-content
  span,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box
  .inner-content
  span {
  display: block;
  text-align: center;
  color: #cecece;
  padding: 10px 0;
}

/* line 2036, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .profile-uploader-box input,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .profile-uploader-box
  input,
.upload-crop-profile-modal .card .card-body .cover-uploader-box input,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .cover-uploader-box
  input,
.upload-crop-cover-modal .card .card-body .profile-uploader-box input,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .profile-uploader-box
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .profile-uploader-box
  input,
.upload-crop-cover-modal .card .card-body .cover-uploader-box input,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .cover-uploader-box
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .cover-uploader-box
  input {
  display: none;
}

/* line 2042, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .croppie-container .cr-boundary,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .croppie-container
  .cr-boundary,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .croppie-container
  .cr-boundary,
.upload-crop-cover-modal .card .card-body .croppie-container .cr-boundary,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .croppie-container
  .cr-boundary,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .croppie-container
  .cr-boundary {
  background-image: url(http://cdn.thedesigninspiration.com/wp-content/uploads/2014/07/Icon-Pattern-l.jpg);
  background-repeat: repeat;
  background-size: cover;
}

/* line 2048, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .croppie-container .cr-viewport,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .croppie-container
  .cr-viewport,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .croppie-container
  .cr-viewport,
.upload-crop-cover-modal .card .card-body .croppie-container .cr-viewport,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .croppie-container
  .cr-viewport,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .croppie-container
  .cr-viewport {
  -webkit-box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.1);
}

/* line 2052, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .croppie-container .cr-slider,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .croppie-container
  .cr-slider,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .croppie-container
  .cr-slider,
.upload-crop-cover-modal .card .card-body .croppie-container .cr-slider,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .croppie-container
  .cr-slider,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .croppie-container
  .cr-slider {
  cursor: pointer;
}

/* line 2057, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .upload-help,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .upload-help,
.upload-crop-cover-modal .card .card-body .upload-help,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .upload-help {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 2062, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .upload-help .profile-reset,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .profile-reset,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .upload-help
  .profile-reset,
.upload-crop-profile-modal .card .card-body .upload-help .cover-reset,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .cover-reset,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .upload-help
  .cover-reset,
.upload-crop-cover-modal .card .card-body .upload-help .profile-reset,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .profile-reset,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .upload-help
  .profile-reset,
.upload-crop-cover-modal .card .card-body .upload-help .cover-reset,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .cover-reset,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .upload-help
  .cover-reset {
  display: block;
  color: #999;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  background: #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2071, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-body .upload-help .profile-reset:hover,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .profile-reset:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .upload-help
  .profile-reset:hover,
.upload-crop-profile-modal .card .card-body .upload-help .cover-reset:hover,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .cover-reset:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-body
  .upload-help
  .cover-reset:hover,
.upload-crop-cover-modal .card .card-body .upload-help .profile-reset:hover,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .profile-reset:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .upload-help
  .profile-reset:hover,
.upload-crop-cover-modal .card .card-body .upload-help .cover-reset:hover,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .upload-help
  .cover-reset:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-body
  .upload-help
  .cover-reset:hover {
  background: #f0f0f0;
  color: #393a4f;
}

/* line 2079, src/assets/scss/components/_components-modals.scss */
.upload-crop-profile-modal .card .card-footer,
.upload-crop-profile-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-profile-modal
  .summary-card
  .card-footer,
.upload-crop-cover-modal .card .card-footer,
.upload-crop-cover-modal
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .upload-crop-cover-modal
  .summary-card
  .card-footer {
  border-top: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 20px;
}

/* line 2095, src/assets/scss/components/_components-modals.scss */
.end-tour-modal .modal-content .card .card-body .image-wrap,
.end-tour-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .end-tour-modal
  .modal-content
  .summary-card
  .card-body
  .image-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #f5f5f5;
  margin: 0 auto 20px auto;
}

/* line 2105, src/assets/scss/components/_components-modals.scss */
.end-tour-modal .modal-content .card .card-body .image-wrap img,
.end-tour-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-wrap
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .end-tour-modal
  .modal-content
  .summary-card
  .card-body
  .image-wrap
  img {
  display: block;
  height: 50px;
  width: 50px;
}

/* line 2112, src/assets/scss/components/_components-modals.scss */
.end-tour-modal .modal-content .card .card-body h3,
.end-tour-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .end-tour-modal
  .modal-content
  .summary-card
  .card-body
  h3 {
  font-weight: 500;
  font-size: 1.1rem;
  font-family: "montserrat", sans-serif;
}

/* line 2118, src/assets/scss/components/_components-modals.scss */
.end-tour-modal .modal-content .card .card-body p,
.end-tour-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .end-tour-modal
  .modal-content
  .summary-card
  .card-body
  p {
  font-size: 0.9rem;
  color: #999;
  max-width: 330px;
  margin: 0 auto;
}

/* line 2125, src/assets/scss/components/_components-modals.scss */
.end-tour-modal .modal-content .card .card-body .action,
.end-tour-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .action,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .end-tour-modal
  .modal-content
  .summary-card
  .card-body
  .action {
  margin: 20px 0;
}

/* line 2128, src/assets/scss/components/_components-modals.scss */
.end-tour-modal .modal-content .card .card-body .action .button,
.end-tour-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .action
  .button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .end-tour-modal
  .modal-content
  .summary-card
  .card-body
  .action
  .button {
  max-width: 180px;
  margin: 0 auto;
}

/* line 2146, src/assets/scss/components/_components-modals.scss */
.no-stream-modal .modal-content .card .card-body .image-wrap,
.no-stream-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .no-stream-modal
  .modal-content
  .summary-card
  .card-body
  .image-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto 20px auto;
}

/* line 2152, src/assets/scss/components/_components-modals.scss */
.no-stream-modal .modal-content .card .card-body .image-wrap img,
.no-stream-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .image-wrap
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .no-stream-modal
  .modal-content
  .summary-card
  .card-body
  .image-wrap
  img {
  display: block;
  width: 200px;
}

/* line 2158, src/assets/scss/components/_components-modals.scss */
.no-stream-modal .modal-content .card .card-body h3,
.no-stream-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .no-stream-modal
  .modal-content
  .summary-card
  .card-body
  h3 {
  font-weight: 500;
  font-size: 1.1rem;
  font-family: "montserrat", sans-serif;
}

/* line 2164, src/assets/scss/components/_components-modals.scss */
.no-stream-modal .modal-content .card .card-body p,
.no-stream-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .no-stream-modal
  .modal-content
  .summary-card
  .card-body
  p {
  font-size: 0.9rem;
  color: #999;
  max-width: 330px;
  margin: 0 auto;
}

/* line 2171, src/assets/scss/components/_components-modals.scss */
.no-stream-modal .modal-content .card .card-body .action,
.no-stream-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .action,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .no-stream-modal
  .modal-content
  .summary-card
  .card-body
  .action {
  margin: 20px 0;
}

/* line 2174, src/assets/scss/components/_components-modals.scss */
.no-stream-modal .modal-content .card .card-body .action .button,
.no-stream-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .action
  .button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .no-stream-modal
  .modal-content
  .summary-card
  .card-body
  .action
  .button {
  max-width: 180px;
  margin: 0 auto;
}

/* line 2188, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-heading,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-heading,
.image-story-modal .modal-content .card .card-heading,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-heading {
  padding: 16px 28px;
}

/* line 2191, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-heading h3,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-heading
  h3,
.image-story-modal .modal-content .card .card-heading h3,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-heading
  h3 {
  font-size: 1rem;
}

/* line 2195, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-heading .small-avatar,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-heading
  .small-avatar,
.image-story-modal .modal-content .card .card-heading .small-avatar,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-heading
  .small-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2207, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-heading .small-avatar img,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-heading
  .small-avatar
  img,
.image-story-modal .modal-content .card .card-heading .small-avatar img,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-heading
  .small-avatar
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-heading
  .small-avatar
  img {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 2217, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-body,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body,
.image-story-modal .modal-content .card .card-body,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body {
  padding: 10px 30px 30px 30px;
}

/* line 2221, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-body .story-meta p,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-meta
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-meta
  p,
.image-story-modal .modal-content .card .card-body .story-meta p,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-meta
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-meta
  p {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  color: #535473;
  margin-bottom: 1.5rem;
}

/* line 2228, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-body .story-meta select,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-meta
  select,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-meta
  select,
.image-story-modal .modal-content .card .card-body .story-meta select,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-meta
  select,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-meta
  select {
  width: 100%;
}

/* line 2235, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .upload-placeholder,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .upload-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .upload-placeholder,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .upload-placeholder,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .upload-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .upload-placeholder {
  display: block;
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
}

/* line 2242, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .image-upload-placeholder,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .image-upload-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .image-upload-placeholder,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .image-upload-placeholder,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .image-upload-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .image-upload-placeholder {
  display: block;
  width: 100%;
  max-width: 140px;
  margin: 0 auto;
}

/* line 2249, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container {
  position: relative;
  max-width: 260px;
  margin: 0 auto;
}

/* line 2254, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .delete,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .delete,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .delete,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .delete,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .delete,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .delete {
  position: absolute;
  top: 4px;
  right: 4px;
}

/* line 2260, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .preview-image,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .preview-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .preview-image,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .preview-image,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .preview-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .preview-block
  .preview-image-container
  .preview-image {
  display: block;
  max-width: 260px;
  min-height: 160px;
  margin: 0 auto;
  border-radius: 12px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 2272, src/assets/scss/components/_components-modals.scss */
.video-story-modal .modal-content .card .card-body .story-upload .input-block,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .input-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .input-block,
.image-story-modal .modal-content .card .card-body .story-upload .input-block,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .input-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .input-block {
  padding: 16px 0;
  max-width: 260px;
  margin: 0 auto;
}

/* line 2277, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .input-block
  input,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .input-block
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .input-block
  input,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  .input-block
  input,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  .input-block
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  .input-block
  input {
  width: 100%;
  background: #e8e8e8;
  outline: none !important;
  border-radius: 6px;
}

/* line 2285, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  input[type="file"],
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  input[type="file"],
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  input[type="file"],
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  input[type="file"],
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  input[type="file"],
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  input[type="file"] {
  cursor: pointer !important;
}

/* line 2289, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  input[type="file"]::-webkit-file-upload-button,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-webkit-file-upload-button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-webkit-file-upload-button,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  input[type="file"]::-webkit-file-upload-button,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-webkit-file-upload-button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-webkit-file-upload-button {
  background: #fff;
  border: 0;
  padding: 1em 2em;
  cursor: pointer;
  color: #393a4f;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  outline: none !important;
  margin-right: 12px;
}

/* line 2302, src/assets/scss/components/_components-modals.scss */
.video-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  input[type="file"]::-ms-browse,
.video-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-ms-browse,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .video-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-ms-browse,
.image-story-modal
  .modal-content
  .card
  .card-body
  .story-upload
  input[type="file"]::-ms-browse,
.image-story-modal
  .modal-content
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-ms-browse,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .image-story-modal
  .modal-content
  .summary-card
  .card-body
  .story-upload
  input[type="file"]::-ms-browse {
  background: #fff;
  border: 0;
  padding: 1em 2em;
  cursor: pointer;
  color: #393a4f;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  outline: none !important;
  margin-right: 12px;
}

/* line 2323, src/assets/scss/components/_components-modals.scss */
.tokenize .tokens-container .token span {
  font-size: 0.75rem;
  line-height: 2;
}

/* line 2328, src/assets/scss/components/_components-modals.scss */
.tokenize .tokens-container .token .dismiss {
  top: 1px;
}

/* line 2336, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu {
  background: #fff !important;
  border-radius: 6px !important;
  border: 1px solid #e0e0e0 !important;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  height: 144px;
  overflow-y: auto;
}

/* line 2344, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu::-webkit-scrollbar {
  width: 6px !important;
}

/* line 2348, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/* line 2353, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu li {
  margin: 0 8px;
  padding: 6px 10px;
  border-radius: 6px;
}

/* line 2358, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu li:hover {
  background: #f2f2f2;
}

/* line 2361, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu li:hover a {
  color: #5596e6;
}

/* line 2366, src/assets/scss/components/_components-modals.scss */
.tokenize-dropdown .dropdown-menu li a {
  color: #999;
  font-size: 0.85rem !important;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 2385, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-heading h3,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-heading
    h3,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-heading
    h3,
  .modal.videos-modal .card .card-heading h3,
  .modal.videos-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-heading
    h3,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.videos-modal
    .summary-card
    .card-heading
    h3 {
    display: none;
  }

  /* line 2389, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-heading .button,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-heading
    .button,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-heading
    .button,
  .modal.videos-modal .card .card-heading .button,
  .modal.videos-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-heading
    .button,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.videos-modal
    .summary-card
    .card-heading
    .button {
    margin-left: auto;
  }

  /* line 2398, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-body,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body,
  .modal.videos-modal .card .card-body,
  .modal.videos-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.videos-modal
    .summary-card
    .card-body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 2401, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-body .left-section,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .left-section,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .left-section,
  .modal.videos-modal .card .card-body .left-section,
  .modal.videos-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .left-section,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.videos-modal
    .summary-card
    .card-body
    .left-section {
    display: none;
    width: 100%;
  }

  /* line 2406, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-body .right-section,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .right-section,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .right-section,
  .modal.videos-modal .card .card-body .right-section,
  .modal.videos-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .right-section,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.videos-modal
    .summary-card
    .card-body
    .right-section {
    width: 100%;
  }

  /* line 2414, src/assets/scss/components/_components-modals.scss */
  .modal.albums-help-modal img,
  .modal.videos-help-modal img {
    height: 110px !important;
  }

  /* line 2420, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .modal-content,
  .modal.albums-modal .modal-card {
    max-height: calc(100vh - 20px);
  }

  /* line 2425, src/assets/scss/components/_components-modals.scss */
  .modal .modal-content {
    max-width: 340px;
  }

  /* line 2428, src/assets/scss/components/_components-modals.scss */
  .modal .modal-content .content-block {
    width: 314px;
  }

  /* line 2431, src/assets/scss/components/_components-modals.scss */
  .modal .modal-content .content-block img {
    height: 135px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 2447, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-body .album-date .head h4,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .album-date
    .head
    h4,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .album-date
    .head
    h4,
  .modal.albums-modal .card .card-body .tagged-in-album .head h4,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .tagged-in-album
    .head
    h4,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .tagged-in-album
    .head
    h4,
  .modal.albums-modal .card .card-body .shared-album .head h4,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .shared-album
    .head
    h4,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .shared-album
    .head
    h4 {
    font-size: 0.75rem;
  }

  /* line 2451, src/assets/scss/components/_components-modals.scss */
  .modal.albums-modal .card .card-body .album-date .head p,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .album-date
    .head
    p,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .album-date
    .head
    p,
  .modal.albums-modal .card .card-body .tagged-in-album .head p,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .tagged-in-album
    .head
    p,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .tagged-in-album
    .head
    p,
  .modal.albums-modal .card .card-body .shared-album .head p,
  .modal.albums-modal
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .card-body
    .shared-album
    .head
    p,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .modal.albums-modal
    .summary-card
    .card-body
    .shared-album
    .head
    p {
    font-size: 0.7rem;
  }
}

/*! _uploaders.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 16, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .button {
  line-height: 0;
}

/* line 20, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .button.has-icon svg {
  height: 18px;
  width: 18px;
}

/* line 27, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .file-count {
  font-size: 0.85rem;
  color: #999;
  height: 38px;
  vertical-align: middle;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 10px;
}

/* line 36, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .file-count span {
  margin: 0 4px;
}

/* line 42, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader #total-progress {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

/* line 48, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader #previews .dz-success .progress {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

/* line 54, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader #previews .delete {
  display: none;
}

/* line 59, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader #previews .is-template.dz-success .start,
.modal-uploader #previews .is-template.dz-success .cancel {
  display: none;
}

/* line 64, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader #previews .is-template.dz-success .delete {
  display: block;
}

/* line 69, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box {
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 3px;
}

/* line 76, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box:hover .remove-button {
  opacity: 1;
  background: #e8e8e8;
}

/* line 83, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .remove-button {
  position: absolute;
  top: -10px;
  right: -12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: transparent;
  cursor: pointer;
  opacity: 0;
}

/* line 99, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .remove-button:hover {
  background: #f71416;
}

/* line 102, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .remove-button:hover i {
  color: #fafafa;
}

/* line 107, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .remove-button i {
  font-size: 14px;
  color: #999;
}

/* line 114, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .preview img {
  display: block;
}

/* line 119, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .preview-body {
  padding: 8px;
  border: 1px solid #e8e8e8;
}

/* line 123, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .preview-body .name {
  display: block;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* line 131, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .preview-body .item-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
}

/* line 139, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .preview-box .preview-body .textarea {
  padding: 0;
  border: none;
  resize: none;
  color: #999;
}

/* line 149, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader progress {
  vertical-align: baseline;
}

/* line 153, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.2rem;
  margin: 0.4rem 0;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

/* line 165, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #5596e6;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

/* line 182, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

/* line 187, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

/* line 192, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .upload-item-actions {
  margin-left: 20px;
}

/* line 196, src/assets/scss/components/_components-uploaders.scss */
.modal-uploader .upload-item-progress {
  margin-left: auto;
  min-width: 120px;
}

/*! _video.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/components/_components-video.scss */
.video-wrapper .video-wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

/* line 22, src/assets/scss/components/_components-video.scss */
.video-wrapper .video-wrap video {
  display: block;
  width: 100%;
  height: auto;
  min-height: 630px;
  border-radius: 0 0 0 6px;
}

/* line 30, src/assets/scss/components/_components-video.scss */
.video-wrapper .video-wrap .live {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: opacity-shift 2s infinite;
  animation: opacity-shift 2s infinite;
  display: block;
  background: #ff2e1c;
  border-radius: 3px;
  color: #fff;
  padding: 8px 16px;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 1052;
}

/* line 57, src/assets/scss/components/_components-video.scss */
.video-block-wrapper {
  background-color: #344258;
  border-radius: 10px;
  overflow: hidden;
}

/* line 62, src/assets/scss/components/_components-video.scss */
.video-block-wrapper .video-block-inner {
  position: relative;
  height: 0;
  max-width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 0;
  overflow: hidden;
  cursor: pointer;
  display: block;
}

/* line 72, src/assets/scss/components/_components-video.scss */
.video-block-wrapper .video-block-inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

/* line 82, src/assets/scss/components/_components-video.scss */
.video-block-wrapper .video-block-inner .video-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: alpha(opacity=35);
  opacity: 0.2;
}

/* line 93, src/assets/scss/components/_components-video.scss */
.video-block-wrapper .video-block-inner .playbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* line 101, src/assets/scss/components/_components-video.scss */
.video-block-wrapper .video-block-inner .playbutton .icon-play svg {
  height: 50px;
  width: 50px;
  stroke: #fff;
  stroke-width: 1.6px;
}

/*! _datepicker.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/components/_components-datepicker.scss */
.datepicker-container {
  font-size: 12px;
  line-height: 30px;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 210px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  direction: ltr !important;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* line 39, src/assets/scss/components/_components-datepicker.scss */
.datepicker-container:before,
.datepicker-container:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: " ";
  border: 5px solid transparent;
}

/* line 53, src/assets/scss/components/_components-datepicker.scss */
.datepicker-dropdown {
  position: absolute;
  z-index: 999999 !important;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

/* line 64, src/assets/scss/components/_components-datepicker.scss */
.datepicker-inline {
  position: static;
}

/* line 68, src/assets/scss/components/_components-datepicker.scss */
.datepicker-top-left:before,
.datepicker-top-left:after,
.datepicker-top-right:before,
.datepicker-top-right:after {
  top: -5px;
  left: 10px;
  border-top: 0;
}

/* line 77, src/assets/scss/components/_components-datepicker.scss */
.datepicker-top-left:before,
.datepicker-top-right:before {
  border-bottom-color: #ccc;
}

/* line 82, src/assets/scss/components/_components-datepicker.scss */
.datepicker-top-left:after,
.datepicker-top-right:after {
  top: -4px;
  border-bottom-color: #fff;
}

/* line 88, src/assets/scss/components/_components-datepicker.scss */
.datepicker-bottom-left:before,
.datepicker-bottom-left:after,
.datepicker-bottom-right:before,
.datepicker-bottom-right:after {
  bottom: -5px;
  left: 10px;
  border-bottom: 0;
}

/* line 97, src/assets/scss/components/_components-datepicker.scss */
.datepicker-bottom-left:before,
.datepicker-bottom-right:before {
  border-top-color: #ccc;
}

/* line 102, src/assets/scss/components/_components-datepicker.scss */
.datepicker-bottom-left:after,
.datepicker-bottom-right:after {
  bottom: -4px;
  border-top-color: #fff;
}

/* line 108, src/assets/scss/components/_components-datepicker.scss */
.datepicker-top-right:before,
.datepicker-top-right:after,
.datepicker-bottom-right:before,
.datepicker-bottom-right:after {
  right: 10px;
  left: auto;
}

/* line 120, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul:before,
.datepicker-panel > ul:after {
  display: table;
  content: " ";
}

/* line 126, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul:after {
  clear: both;
}

/* line 130, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul {
  width: 102%;
  margin: 0;
  padding: 0;
}

/* line 136, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul:first-child li {
  line-height: 25px;
}

/* line 140, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul:first-child li:nth-child(2) {
  line-height: 28px !important;
}

/* line 144, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
}

/* line 156, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li:hover {
  background-color: #ededed;
  color: #444 !important;
  border-radius: 50px;
}

/* line 162, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.muted,
.datepicker-panel > ul > li.muted:hover {
  color: #999;
}

/* line 167, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.highlighted {
  color: #5d4394 !important;
  border-radius: 50px;
}

/* line 172, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.highlighted:hover {
  opacity: 0.8;
  color: #5d4394 !important;
}

/* line 177, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
  color: #fff !important;
  background-color: #5596e6;
  border-radius: 50px;
}

/* line 184, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.picked {
  -webkit-animation-name: gelatine;
  animation-name: gelatine;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

/* line 190, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.disabled,
.datepicker-panel > ul > li.disabled:hover {
  cursor: default;
  color: #ccc;
  background-color: #fff;
}

/* line 197, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li.disabled.highlighted,
.datepicker-panel > ul > li.disabled:hover.highlighted {
  background-color: #e6f2ff;
}

/* line 202, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li[data-view="years prev"],
.datepicker-panel > ul > li[data-view="year prev"],
.datepicker-panel > ul > li[data-view="month prev"],
.datepicker-panel > ul > li[data-view="years next"],
.datepicker-panel > ul > li[data-view="year next"],
.datepicker-panel > ul > li[data-view="month next"],
.datepicker-panel > ul > li[data-view="next"] {
  font-size: 18px;
}

/* line 212, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul > li[data-view="years current"],
.datepicker-panel > ul > li[data-view="year current"],
.datepicker-panel > ul > li[data-view="month current"] {
  width: 150px;
}

/* line 218, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul[data-view="years"] > li,
.datepicker-panel > ul[data-view="months"] > li {
  line-height: 52.5px;
  width: 52.5px;
  height: 52.5px;
}

/* line 225, src/assets/scss/components/_components-datepicker.scss */
.datepicker-panel > ul[data-view="week"] > li,
.datepicker-panel > ul[data-view="week"] > li:hover {
  cursor: default;
  background-color: #fff;
}

/* line 231, src/assets/scss/components/_components-datepicker.scss */
.datepicker-hide {
  display: none;
}

/*! _forms.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 20, src/assets/scss/components/_components-forms.scss */
.input,
.textarea {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* line 26, src/assets/scss/components/_components-forms.scss */
.input.is-light::-webkit-input-placeholder,
.textarea.is-light::-webkit-input-placeholder {
  color: #cecece;
}

/* line 30, src/assets/scss/components/_components-forms.scss */
.input.is-light::-moz-placeholder,
.textarea.is-light::-moz-placeholder {
  color: #cecece;
}

/* line 34, src/assets/scss/components/_components-forms.scss */
.input.is-light:-ms-input-placeholder,
.textarea.is-light:-ms-input-placeholder {
  color: #cecece;
}

/* line 38, src/assets/scss/components/_components-forms.scss */
.input.is-light:-moz-placeholder,
.textarea.is-light:-moz-placeholder {
  color: #cecece;
}

/* line 43, src/assets/scss/components/_components-forms.scss */
.input.is-dark,
.textarea.is-dark {
  color: #97a7c1 !important;
}

/* line 46, src/assets/scss/components/_components-forms.scss */
.input.is-dark::-webkit-input-placeholder,
.textarea.is-dark::-webkit-input-placeholder {
  color: #677fa5 !important;
}

/* line 50, src/assets/scss/components/_components-forms.scss */
.input.is-dark::-moz-placeholder,
.textarea.is-dark::-moz-placeholder {
  color: #677fa5 !important;
}

/* line 54, src/assets/scss/components/_components-forms.scss */
.input.is-dark:-ms-input-placeholder,
.textarea.is-dark:-ms-input-placeholder {
  color: #677fa5 !important;
}

/* line 58, src/assets/scss/components/_components-forms.scss */
.input.is-dark:-moz-placeholder,
.textarea.is-dark:-moz-placeholder {
  color: #677fa5 !important;
}

/* line 68, src/assets/scss/components/_components-forms.scss */
.input {
  font-size: 0.9rem;
  height: 36px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 73, src/assets/scss/components/_components-forms.scss */
.input:focus {
  border-color: #5596e6;
}

/* line 77, src/assets/scss/components/_components-forms.scss */
.input.is-sm {
  height: 32px;
  font-size: 0.9rem;
}

/* line 82, src/assets/scss/components/_components-forms.scss */
.input.no-radius {
  border-radius: 1px;
}

/* line 88, src/assets/scss/components/_components-forms.scss */
.input.is-fade:hover {
  border: 1px solid #e3e3e3;
}

/* line 92, src/assets/scss/components/_components-forms.scss */
.input.is-fade:focus {
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
}

/* line 99, src/assets/scss/components/_components-forms.scss */
.input.is-bordered:focus {
  border-color: #757a91;
}

/* line 109, src/assets/scss/components/_components-forms.scss */
.textarea {
  font-size: 0.9rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 113, src/assets/scss/components/_components-forms.scss */
.textarea.is-sm {
  font-size: 0.9rem;
  resize: none;
}

/* line 118, src/assets/scss/components/_components-forms.scss */
.textarea.no-radius {
  border-radius: 1px;
}

/* line 123, src/assets/scss/components/_components-forms.scss */
.textarea.is-fade:hover {
  border: 1px solid #e3e3e3;
}

/* line 127, src/assets/scss/components/_components-forms.scss */
.textarea.is-fade:focus {
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
}

/* line 139, src/assets/scss/components/_components-forms.scss */
.field label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 148, src/assets/scss/components/_components-forms.scss */
.control.has-icon {
  position: relative;
}

/* line 151, src/assets/scss/components/_components-forms.scss */
.control.has-icon .form-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 161, src/assets/scss/components/_components-forms.scss */
.control.has-icon .form-icon svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 169, src/assets/scss/components/_components-forms.scss */
.control.has-icon .input {
  height: 36px;
  padding-left: 40px;
}

/* line 174, src/assets/scss/components/_components-forms.scss */
.control.has-icon .input:focus + .form-icon svg {
  stroke: #5596e6;
}

/* line 182, src/assets/scss/components/_components-forms.scss */
.control.has-validation {
  position: relative;
}

/* line 185, src/assets/scss/components/_components-forms.scss */
.control.has-validation .error-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* line 197, src/assets/scss/components/_components-forms.scss */
.control.has-validation .error-icon svg {
  height: 16px;
  width: 16px;
  stroke: #f71416;
}

/* line 204, src/assets/scss/components/_components-forms.scss */
.control.has-validation .success-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* line 216, src/assets/scss/components/_components-forms.scss */
.control.has-validation .success-icon svg {
  height: 16px;
  width: 16px;
  stroke: #1ce589;
}

/* line 224, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-error input {
  border-color: #f71416;
}

/* line 227, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-error input + label {
  font-size: 0.75rem;
  color: #f71416;
}

/* line 233, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-error .error-icon {
  opacity: 1;
}

/* line 237, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-error .success-icon {
  opacity: 0;
}

/* line 243, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-success input {
  border-color: #1ce589;
}

/* line 247, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-success .error-icon {
  opacity: 0;
}

/* line 251, src/assets/scss/components/_components-forms.scss */
.control.has-validation.has-success .success-icon {
  opacity: 1;
}

/* line 265, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-label {
  font-weight: 500;
  font-size: 0.9rem;
  color: #999;
}

/* line 272, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box,
.is-combo .image-combo-box,
.is-combo .stacked-combo-box,
.is-combo .user-combo-box {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  margin-top: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 283, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box.has-rounded-images .combo-item img,
.is-combo .combo-box.has-rounded-images .item-icon img,
.is-combo .image-combo-box.has-rounded-images .combo-item img,
.is-combo .image-combo-box.has-rounded-images .item-icon img,
.is-combo .stacked-combo-box.has-rounded-images .combo-item img,
.is-combo .stacked-combo-box.has-rounded-images .item-icon img,
.is-combo .user-combo-box.has-rounded-images .combo-item img,
.is-combo .user-combo-box.has-rounded-images .item-icon img {
  border-radius: 50%;
}

/* line 289, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box.is-scrollable .box-dropdown .dropdown-inner,
.is-combo .image-combo-box.is-scrollable .box-dropdown .dropdown-inner,
.is-combo .stacked-combo-box.is-scrollable .box-dropdown .dropdown-inner,
.is-combo .user-combo-box.is-scrollable .box-dropdown .dropdown-inner {
  max-height: 190px;
  overflow-y: auto;
}

/* line 296, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner,
.is-combo .image-combo-box .box-inner,
.is-combo .stacked-combo-box .box-inner,
.is-combo .user-combo-box .box-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 302, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item,
.is-combo .image-combo-box .box-inner .combo-item,
.is-combo .stacked-combo-box .box-inner .combo-item,
.is-combo .user-combo-box .box-inner .combo-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  padding-left: 8px;
}

/* line 309, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item i,
.is-combo .image-combo-box .box-inner .combo-item i,
.is-combo .stacked-combo-box .box-inner .combo-item i,
.is-combo .user-combo-box .box-inner .combo-item i {
  color: #999;
  font-size: 18px;
}

/* line 314, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .fa,
.is-combo .image-combo-box .box-inner .combo-item .fa,
.is-combo .stacked-combo-box .box-inner .combo-item .fa,
.is-combo .user-combo-box .box-inner .combo-item .fa {
  font-size: 14px;
}

/* line 317, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .fa.is-green,
.is-combo .image-combo-box .box-inner .combo-item .fa.is-green,
.is-combo .stacked-combo-box .box-inner .combo-item .fa.is-green,
.is-combo .user-combo-box .box-inner .combo-item .fa.is-green {
  color: #1ce589;
}

/* line 321, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .fa.is-blue,
.is-combo .image-combo-box .box-inner .combo-item .fa.is-blue,
.is-combo .stacked-combo-box .box-inner .combo-item .fa.is-blue,
.is-combo .user-combo-box .box-inner .combo-item .fa.is-blue {
  color: #039be5;
}

/* line 325, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .fa.is-orange,
.is-combo .image-combo-box .box-inner .combo-item .fa.is-orange,
.is-combo .stacked-combo-box .box-inner .combo-item .fa.is-orange,
.is-combo .user-combo-box .box-inner .combo-item .fa.is-orange {
  color: #ff9700;
}

/* line 329, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .fa.is-red,
.is-combo .image-combo-box .box-inner .combo-item .fa.is-red,
.is-combo .stacked-combo-box .box-inner .combo-item .fa.is-red,
.is-combo .user-combo-box .box-inner .combo-item .fa.is-red {
  color: #f71416;
}

/* line 335, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item img,
.is-combo .image-combo-box .box-inner .combo-item img,
.is-combo .stacked-combo-box .box-inner .combo-item img,
.is-combo .user-combo-box .box-inner .combo-item img {
  display: block;
  height: 22px;
  width: 22px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 8px;
}

/* line 343, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item img.is-stacked,
.is-combo .image-combo-box .box-inner .combo-item img.is-stacked,
.is-combo .stacked-combo-box .box-inner .combo-item img.is-stacked,
.is-combo .user-combo-box .box-inner .combo-item img.is-stacked {
  height: 26px;
  width: 26px;
  border: 2px solid #fff;
  margin-right: 0 !important;
}

/* line 353, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(1),
.is-combo
  .image-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(1),
.is-combo
  .stacked-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(1),
.is-combo
  .user-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(1) {
  margin-left: -9px;
}

/* line 353, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(2),
.is-combo
  .image-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(2),
.is-combo
  .stacked-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(2),
.is-combo
  .user-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(2) {
  margin-left: -9px;
}

/* line 353, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(3),
.is-combo
  .image-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(3),
.is-combo
  .stacked-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(3),
.is-combo
  .user-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(3) {
  margin-left: -9px;
}

/* line 353, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(4),
.is-combo
  .image-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(4),
.is-combo
  .stacked-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(4),
.is-combo
  .user-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(4) {
  margin-left: -9px;
}

/* line 353, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(5),
.is-combo
  .image-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(5),
.is-combo
  .stacked-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(5),
.is-combo
  .user-combo-box
  .box-inner
  .combo-item
  img.is-stacked:not(:first-child):nth-child(5) {
  margin-left: -9px;
}

/* line 361, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .avatar-container,
.is-combo .image-combo-box .box-inner .combo-item .avatar-container,
.is-combo .stacked-combo-box .box-inner .combo-item .avatar-container,
.is-combo .user-combo-box .box-inner .combo-item .avatar-container {
  position: relative;
  display: block;
  height: 26px;
  width: 26px;
}

/* line 367, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .avatar-container .avatar,
.is-combo .image-combo-box .box-inner .combo-item .avatar-container .avatar,
.is-combo .stacked-combo-box .box-inner .combo-item .avatar-container .avatar,
.is-combo .user-combo-box .box-inner .combo-item .avatar-container .avatar {
  display: block;
  height: 26px;
  width: 26px;
  border-radius: 50%;
}

/* line 374, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item .avatar-container .badge,
.is-combo .image-combo-box .box-inner .combo-item .avatar-container .badge,
.is-combo .stacked-combo-box .box-inner .combo-item .avatar-container .badge,
.is-combo .user-combo-box .box-inner .combo-item .avatar-container .badge {
  position: absolute;
  bottom: 0;
  right: -6px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0;
}

/* line 387, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item span,
.is-combo .image-combo-box .box-inner .combo-item span,
.is-combo .stacked-combo-box .box-inner .combo-item span,
.is-combo .user-combo-box .box-inner .combo-item span {
  display: block;
  color: #393a4f;
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 8px;
}

/* line 394, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-inner .combo-item span.is-pushed,
.is-combo .image-combo-box .box-inner .combo-item span.is-pushed,
.is-combo .stacked-combo-box .box-inner .combo-item span.is-pushed,
.is-combo .user-combo-box .box-inner .combo-item span.is-pushed {
  margin-left: 18px;
}

/* line 402, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-chevron,
.is-combo .image-combo-box .box-chevron,
.is-combo .stacked-combo-box .box-chevron,
.is-combo .user-combo-box .box-chevron {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

/* line 412, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-chevron svg,
.is-combo .image-combo-box .box-chevron svg,
.is-combo .stacked-combo-box .box-chevron svg,
.is-combo .user-combo-box .box-chevron svg {
  height: 18px;
  width: 18px;
  stroke: #393a4f;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 422, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown,
.is-combo .image-combo-box .box-dropdown,
.is-combo .stacked-combo-box .box-dropdown,
.is-combo .user-combo-box .box-dropdown {
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  z-index: 50;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 434, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown.is-dropup,
.is-combo .image-combo-box .box-dropdown.is-dropup,
.is-combo .stacked-combo-box .box-dropdown.is-dropup,
.is-combo .user-combo-box .box-dropdown.is-dropup {
  top: -165px;
  height: 148px;
}

/* line 438, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown.is-dropup .dropdown-inner,
.is-combo .image-combo-box .box-dropdown.is-dropup .dropdown-inner,
.is-combo .stacked-combo-box .box-dropdown.is-dropup .dropdown-inner,
.is-combo .user-combo-box .box-dropdown.is-dropup .dropdown-inner {
  overflow-y: auto;
}

/* line 444, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner,
.is-combo .image-combo-box .box-dropdown .dropdown-inner,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner,
.is-combo .user-combo-box .box-dropdown .dropdown-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 190px;
  overflow-y: auto;
}

/* line 456, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul {
  padding: 10px 0;
}

/* line 460, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li {
  padding: 6px 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

/* line 468, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li .item-icon,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li .item-icon,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li .item-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

/* line 477, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon i,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li .item-icon i,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li .item-icon i,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li .item-icon i {
  color: #999;
  font-size: 20px;
}

/* line 482, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon .fa,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li .item-icon .fa,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li .item-icon .fa,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li .item-icon .fa {
  font-size: 16px;
  position: relative;
  top: 5px;
}

/* line 487, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-green,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-green,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-green,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-green {
  color: #1ce589;
}

/* line 491, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon .fa.is-blue,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-blue,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-blue,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-blue {
  color: #039be5;
}

/* line 495, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-orange,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-orange,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-orange,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-orange {
  color: #ff9700;
}

/* line 499, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon .fa.is-red,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-red,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-red,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .fa.is-red {
  color: #f71416;
}

/* line 505, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon img,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li .item-icon img,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li .item-icon img,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li .item-icon img {
  display: block;
  height: 22px;
  width: 22px;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* line 514, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  img.faded-img,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  img.faded-img,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  img.faded-img,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  img.faded-img {
  opacity: 0.4;
}

/* line 519, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon .avatar,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .avatar,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .avatar,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .avatar {
  display: block;
  height: 22px;
  width: 22px;
  border-radius: 50%;
}

/* line 526, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .item-icon .badge,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .badge,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .badge,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-icon
  .badge {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

/* line 538, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .checkmark,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li .checkmark,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li .checkmark,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li .checkmark {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  margin-left: auto;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 549, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li .checkmark svg,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li .checkmark svg,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li .checkmark svg,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li .checkmark svg {
  stroke: #5596e6;
  stroke-width: 3px;
  height: 16px;
  width: 16px;
}

/* line 557, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li span,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li span,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li span,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li span {
  display: block;
  color: #999;
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 564, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li:hover,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li:hover,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li:hover,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li:hover {
  background: #ededed;
}

/* line 571, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li.is-active .item-icon i,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  i,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  i,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  i {
  color: #393a4f;
}

/* line 575, src/assets/scss/components/_components-forms.scss */
.is-combo
  .combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  .faded-img,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  .faded-img,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  .faded-img,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  .faded-img {
  opacity: 1;
}

/* line 580, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li.is-active span,
.is-combo .image-combo-box .box-dropdown .dropdown-inner ul li.is-active span,
.is-combo .stacked-combo-box .box-dropdown .dropdown-inner ul li.is-active span,
.is-combo .user-combo-box .box-dropdown .dropdown-inner ul li.is-active span {
  color: #393a4f;
}

/* line 584, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box .box-dropdown .dropdown-inner ul li.is-active .checkmark,
.is-combo
  .image-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .checkmark,
.is-combo
  .stacked-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .checkmark,
.is-combo
  .user-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .checkmark {
  opacity: 1;
}

/* line 594, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box.is-active,
.is-combo .image-combo-box.is-active,
.is-combo .stacked-combo-box.is-active,
.is-combo .user-combo-box.is-active {
  border-color: #e8e8e8;
}

/* line 597, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box.is-active .box-chevron svg,
.is-combo .image-combo-box.is-active .box-chevron svg,
.is-combo .stacked-combo-box.is-active .box-chevron svg,
.is-combo .user-combo-box.is-active .box-chevron svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 601, src/assets/scss/components/_components-forms.scss */
.is-combo .combo-box.is-active .box-dropdown,
.is-combo .image-combo-box.is-active .box-dropdown,
.is-combo .stacked-combo-box.is-active .box-dropdown,
.is-combo .user-combo-box.is-active .box-dropdown {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 610, src/assets/scss/components/_components-forms.scss */
.is-combo .help-text {
  font-size: 0.75rem;
  color: #999;
}

/* line 616, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 58px;
  margin-top: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 627, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

/* line 634, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner .combo-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 58px;
  padding-left: 8px;
}

/* line 640, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner .combo-item i {
  color: #5596e6;
  font-size: 1.6rem;
}

/* line 646, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner .combo-item .combo-text {
  margin-left: 16px;
}

/* line 649, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner .combo-item .combo-text span {
  display: block;
}

/* line 652, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner .combo-item .combo-text span:first-child {
  color: #393a4f;
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 658, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-inner .combo-item .combo-text span:nth-child(2) {
  color: #999;
  font-size: 0.8rem;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* line 672, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-chevron {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 58px;
  width: 58px;
}

/* line 682, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-chevron svg {
  height: 18px;
  width: 18px;
  stroke: #393a4f;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 692, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown {
  position: absolute;
  top: 54px;
  left: 0;
  width: 100%;
  z-index: 50;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 704, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* line 717, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul {
  padding: 10px 0;
}

/* line 721, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul li {
  padding: 6px 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

/* line 729, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul li .item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  margin-right: 16px;
}

/* line 739, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul li .item-icon i {
  color: #999;
  font-size: 1.6rem;
}

/* line 747, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul li .item-name span {
  display: block;
}

/* line 750, src/assets/scss/components/_components-forms.scss */
.is-combo
  .big-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-name
  span:first-child {
  color: #393a4f;
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 756, src/assets/scss/components/_components-forms.scss */
.is-combo
  .big-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li
  .item-name
  span:nth-child(2) {
  color: #999;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* line 766, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul li:hover {
  background: #ededed;
}

/* line 772, src/assets/scss/components/_components-forms.scss */
.is-combo
  .big-combo-box
  .box-dropdown
  .dropdown-inner
  ul
  li.is-active
  .item-icon
  i {
  color: #5596e6;
}

/* line 776, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box .box-dropdown .dropdown-inner ul li.is-active span {
  color: #393a4f;
}

/* line 786, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box.is-active {
  border-color: #e8e8e8;
}

/* line 789, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box.is-active .box-chevron svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 793, src/assets/scss/components/_components-forms.scss */
.is-combo .big-combo-box.is-active .box-dropdown {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 806, src/assets/scss/components/_components-forms.scss */
.custom-select {
  width: 100%;
  z-index: 3;
}

/* line 811, src/assets/scss/components/_components-forms.scss */
.custom-select.is-dropup .select-drop {
  top: -100%;
}

/* line 817, src/assets/scss/components/_components-forms.scss */
.custom-select .select-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 40px 0 10px;
  height: 36px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #999;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 831, src/assets/scss/components/_components-forms.scss */
.custom-select .select-box:hover {
  border-color: #e8e8e8;
}

/* line 836, src/assets/scss/components/_components-forms.scss */
.custom-select .select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

/* line 848, src/assets/scss/components/_components-forms.scss */
.custom-select .select-icon svg {
  height: 20px;
  width: 20px;
  stroke: #bababa !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 857, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  min-height: 135px;
  max-height: 138px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  z-index: 5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  pointer-events: none;
}

/* line 875, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fff;
}

/* line 882, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
}

/* line 887, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 896, src/assets/scss/components/_components-forms.scss */
.custom-select
  .select-drop
  .drop-inner
  .option-row
  input:checked
  + .option-meta {
  background: #f2f2f2;
}

/* line 899, src/assets/scss/components/_components-forms.scss */
.custom-select
  .select-drop
  .drop-inner
  .option-row
  input:checked
  + .option-meta
  span {
  color: #393a4f;
}

/* line 904, src/assets/scss/components/_components-forms.scss */
.custom-select
  .select-drop
  .drop-inner
  .option-row
  input:checked
  + .option-meta
  svg.is-purple {
  stroke: #9c2bed;
}

/* line 912, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row .option-meta {
  padding: 12px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 918, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row .option-meta img {
  display: block;
  max-height: 20px;
}

/* line 923, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row .option-meta svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
}

/* line 928, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row .option-meta svg.is-small {
  height: 14px;
  width: 14px;
}

/* line 934, src/assets/scss/components/_components-forms.scss */
.custom-select .select-drop .drop-inner .option-row .option-meta span {
  display: block;
  font-weight: 500;
  color: #999;
  font-size: 0.9rem;
  margin-left: 8px;
}

/* line 948, src/assets/scss/components/_components-forms.scss */
.custom-select.is-active .select-drop {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 954, src/assets/scss/components/_components-forms.scss */
.custom-select.is-active .select-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 962, src/assets/scss/components/_components-forms.scss */
.custom-select.is-accounts .option-meta img {
  max-height: 36px !important;
  border-radius: 50%;
}

/* line 975, src/assets/scss/components/_components-forms.scss */
.tokenize {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 981, src/assets/scss/components/_components-forms.scss */
.tokenize.focus .tokens-container {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 986, src/assets/scss/components/_components-forms.scss */
.tokenize .tokens-container {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/*! _radios.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 45, src/assets/scss/components/_components-radios.scss */
.material-radio {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 52, src/assets/scss/components/_components-radios.scss */
.material-radio.is-accent .dot {
  background-color: #5596e6;
}

/* line 56, src/assets/scss/components/_components-radios.scss */
.material-radio.is-accent input:checked {
  background: transparent;
  border-color: #5596e6;
}

/* line 59, src/assets/scss/components/_components-radios.scss */
.material-radio.is-accent input:checked + .dot {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 64, src/assets/scss/components/_components-radios.scss */
.material-radio.is-accent input:focus:checked,
.material-radio.is-accent input.focus:checked {
  background-color: transparent;
  border-color: #5596e6;
  -webkit-box-shadow: 0 0 0 12px rgba(85, 150, 230, 0.1);
  box-shadow: 0 0 0 12px rgba(85, 150, 230, 0.1);
}

/* line 72, src/assets/scss/components/_components-radios.scss */
.material-radio.is-accent:active input:checked {
  background-color: transparent;
  border-color: #5596e6;
  -webkit-box-shadow: 0 0 0 12px rgba(85, 150, 230, 0.1);
  box-shadow: 0 0 0 12px rgba(85, 150, 230, 0.1);
}

/* line 81, src/assets/scss/components/_components-radios.scss */
.material-radio.is-green .dot {
  background-color: #1ce589;
}

/* line 85, src/assets/scss/components/_components-radios.scss */
.material-radio.is-green input:checked {
  background: transparent;
  border-color: #1ce589;
}

/* line 88, src/assets/scss/components/_components-radios.scss */
.material-radio.is-green input:checked + .dot {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 93, src/assets/scss/components/_components-radios.scss */
.material-radio.is-green input:focus:checked,
.material-radio.is-green input.focus:checked {
  background-color: transparent;
  border-color: #1ce589;
  -webkit-box-shadow: 0 0 0 12px rgba(28, 229, 137, 0.1);
  box-shadow: 0 0 0 12px rgba(28, 229, 137, 0.1);
}

/* line 101, src/assets/scss/components/_components-radios.scss */
.material-radio.is-green:active input:checked {
  background-color: transparent;
  border-color: #1ce589;
  -webkit-box-shadow: 0 0 0 12px rgba(28, 229, 137, 0.1);
  box-shadow: 0 0 0 12px rgba(28, 229, 137, 0.1);
}

/* line 110, src/assets/scss/components/_components-radios.scss */
.material-radio.is-blue .dot {
  background-color: #039be5;
}

/* line 114, src/assets/scss/components/_components-radios.scss */
.material-radio.is-blue input:checked {
  background: transparent;
  border-color: #039be5;
}

/* line 117, src/assets/scss/components/_components-radios.scss */
.material-radio.is-blue input:checked + .dot {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 122, src/assets/scss/components/_components-radios.scss */
.material-radio.is-blue input:focus:checked,
.material-radio.is-blue input.focus:checked {
  background-color: transparent;
  border-color: #039be5;
  -webkit-box-shadow: 0 0 0 12px rgba(3, 155, 229, 0.1);
  box-shadow: 0 0 0 12px rgba(3, 155, 229, 0.1);
}

/* line 130, src/assets/scss/components/_components-radios.scss */
.material-radio.is-blue:active input:checked {
  background-color: transparent;
  border-color: #039be5;
  -webkit-box-shadow: 0 0 0 12px rgba(3, 155, 229, 0.1);
  box-shadow: 0 0 0 12px rgba(3, 155, 229, 0.1);
}

/* line 139, src/assets/scss/components/_components-radios.scss */
.material-radio.is-orange .dot {
  background-color: #ff9700;
}

/* line 143, src/assets/scss/components/_components-radios.scss */
.material-radio.is-orange input:checked {
  background: transparent;
  border-color: #ff9700;
}

/* line 146, src/assets/scss/components/_components-radios.scss */
.material-radio.is-orange input:checked + .dot {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 151, src/assets/scss/components/_components-radios.scss */
.material-radio.is-orange input:focus:checked,
.material-radio.is-orange input.focus:checked {
  background-color: transparent;
  border-color: #ff9700;
  -webkit-box-shadow: 0 0 0 12px rgba(255, 151, 0, 0.1);
  box-shadow: 0 0 0 12px rgba(255, 151, 0, 0.1);
}

/* line 159, src/assets/scss/components/_components-radios.scss */
.material-radio.is-orange:active input:checked {
  background-color: transparent;
  border-color: #ff9700;
  -webkit-box-shadow: 0 0 0 12px rgba(255, 151, 0, 0.1);
  box-shadow: 0 0 0 12px rgba(255, 151, 0, 0.1);
}

/* line 168, src/assets/scss/components/_components-radios.scss */
.material-radio.is-red .dot {
  background-color: #f71416;
}

/* line 172, src/assets/scss/components/_components-radios.scss */
.material-radio.is-red input:checked {
  background: transparent;
  border-color: #f71416;
}

/* line 175, src/assets/scss/components/_components-radios.scss */
.material-radio.is-red input:checked + .dot {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 180, src/assets/scss/components/_components-radios.scss */
.material-radio.is-red input:focus:checked,
.material-radio.is-red input.focus:checked {
  background-color: transparent;
  border-color: #f71416;
  -webkit-box-shadow: 0 0 0 12px rgba(247, 20, 22, 0.1);
  box-shadow: 0 0 0 12px rgba(247, 20, 22, 0.1);
}

/* line 188, src/assets/scss/components/_components-radios.scss */
.material-radio.is-red:active input:checked {
  background-color: transparent;
  border-color: #f71416;
  -webkit-box-shadow: 0 0 0 12px rgba(247, 20, 22, 0.1);
  box-shadow: 0 0 0 12px rgba(247, 20, 22, 0.1);
}

/* line 196, src/assets/scss/components/_components-radios.scss */
.material-radio .dot {
  position: absolute;
  top: calc(50% - 3px);
  left: 6.5px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #3d70b2;
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: 5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 209, src/assets/scss/components/_components-radios.scss */
.material-radio input,
.material-radio input:hover,
.material-radio input.hover {
  -webkit-appearance: none;
  border: 1.4px solid #cecece;
  border-radius: 13px;
  height: 19px;
  margin: 0;
  vertical-align: top;
  width: 19px;
}

/* line 221, src/assets/scss/components/_components-radios.scss */
.material-radio input:focus,
.material-radio input.focus {
  background-color: transparent;
  border-color: #cecece;
  -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  outline: none;
}

/* line 229, src/assets/scss/components/_components-radios.scss */
.material-radio input:disabled {
  border-color: rgba(0, 0, 0, 0.26);
}

/* line 233, src/assets/scss/components/_components-radios.scss */
.material-radio input:disabled:focus,
.material-radio input:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
}

/* line 240, src/assets/scss/components/_components-radios.scss */
.material-radio input:checked {
  background: transparent;
  border-color: #3d70b2;
}

/* line 243, src/assets/scss/components/_components-radios.scss */
.material-radio input:checked + .dot {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 248, src/assets/scss/components/_components-radios.scss */
.material-radio input:focus:checked,
.material-radio input.focus:checked {
  background-color: transparent;
  border-color: #3d70b2;
  -webkit-box-shadow: 0 0 0 12px rgba(61, 112, 178, 0.1);
  box-shadow: 0 0 0 12px rgba(61, 112, 178, 0.1);
}

/* line 255, src/assets/scss/components/_components-radios.scss */
.material-radio input:disabled:checked {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
}

/* line 259, src/assets/scss/components/_components-radios.scss */
.material-radio input:disabled:focus:checked,
.material-radio input:disabled.focus:checked {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
}

/* line 266, src/assets/scss/components/_components-radios.scss */
.material-radio .radio-label {
  line-height: 30px;
  font-size: 0.9rem;
  margin: 0 10px;
}

/* line 271, src/assets/scss/components/_components-radios.scss */
.material-radio .radio-label small {
  font-weight: 600;
  color: #3d70b2;
}

/* line 281, src/assets/scss/components/_components-radios.scss */
.material-radio:active input,
.material-radio.active input {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: #cecece;
  -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  outline: none;
}

/* line 288, src/assets/scss/components/_components-radios.scss */
.material-radio:active input:disabled,
.material-radio.active input:disabled {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
}

/* line 294, src/assets/scss/components/_components-radios.scss */
.material-radio:active input:checked,
.material-radio.active input:checked {
  background-color: rgba(61, 112, 178, 0.1);
  border-color: #3d70b2;
  -webkit-box-shadow: 0 0 0 12px rgba(61, 112, 178, 0.1);
  box-shadow: 0 0 0 12px rgba(61, 112, 178, 0.1);
}

/* line 300, src/assets/scss/components/_components-radios.scss */
.material-radio:active input:disabled:checked,
.material-radio.active input:disabled:checked {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.07);
}

/*! _switch.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 16, src/assets/scss/components/_components-switch.scss */
.animated-toggle {
  width: 56px;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 28, src/assets/scss/components/_components-switch.scss */
.animated-toggle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* line 33, src/assets/scss/components/_components-switch.scss */
.animated-toggle input:checked ~ .toggler {
  border-color: #5596e6;
}

/* line 36, src/assets/scss/components/_components-switch.scss */
.animated-toggle input:checked ~ .toggler .dark,
.animated-toggle input:checked ~ .toggler .light {
  -webkit-transform: translateX(100%) rotate(360deg);
  transform: translateX(100%) rotate(360deg);
}

/* line 40, src/assets/scss/components/_components-switch.scss */
.animated-toggle input:checked ~ .toggler .dark {
  opacity: 1;
}

/* line 44, src/assets/scss/components/_components-switch.scss */
.animated-toggle input:checked ~ .toggler .light {
  opacity: 0;
}

/* line 51, src/assets/scss/components/_components-switch.scss */
.animated-toggle .toggler {
  position: relative;
  display: block;
  height: 31px;
  width: 53px;
  border: 2px solid #3d70b2;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 61, src/assets/scss/components/_components-switch.scss */
.animated-toggle .toggler .dark,
.animated-toggle .toggler .light {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateX(0) rotate(0);
  transform: translateX(0) rotate(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* line 75, src/assets/scss/components/_components-switch.scss */
.animated-toggle .toggler .dark svg,
.animated-toggle .toggler .light svg {
  stroke: #fff;
  height: 14px !important;
  width: 14px !important;
}

/* line 82, src/assets/scss/components/_components-switch.scss */
.animated-toggle .toggler .light {
  background: #3d70b2;
  border-color: #3d70b2;
  opacity: 1;
  z-index: 1;
}

/* line 89, src/assets/scss/components/_components-switch.scss */
.animated-toggle .toggler .dark {
  background: #5596e6;
  border-color: #5596e6;
  opacity: 0;
  z-index: 0;
}

/* line 102, src/assets/scss/components/_components-switch.scss */
.f-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

/* line 111, src/assets/scss/components/_components-switch.scss */
.f-switch.is-primary input:checked + i {
  background-color: #3d70b2;
}

/* line 119, src/assets/scss/components/_components-switch.scss */
.f-switch.is-accent input:checked + i {
  background-color: #5596e6;
}

/* line 127, src/assets/scss/components/_components-switch.scss */
.f-switch.is-red input:checked + i {
  background-color: #f71416;
}

/* line 135, src/assets/scss/components/_components-switch.scss */
.f-switch.is-blue input:checked + i {
  background-color: #039be5;
}

/* line 142, src/assets/scss/components/_components-switch.scss */
.f-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

/* line 154, src/assets/scss/components/_components-switch.scss */
.f-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  -webkit-transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

/* line 167, src/assets/scss/components/_components-switch.scss */
.f-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  -webkit-transform: translate3d(2px, 2px, 0);
  transform: translate3d(2px, 2px, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* line 183, src/assets/scss/components/_components-switch.scss */
.f-switch:active i::after {
  width: 28px;
  -webkit-transform: translate3d(2px, 2px, 0);
  transform: translate3d(2px, 2px, 0);
}

/* line 190, src/assets/scss/components/_components-switch.scss */
.f-switch:active input:checked + i::after {
  -webkit-transform: translate3d(16px, 2px, 0);
  transform: translate3d(16px, 2px, 0);
}

/* line 197, src/assets/scss/components/_components-switch.scss */
.f-switch input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* line 203, src/assets/scss/components/_components-switch.scss */
.f-switch input:checked + i {
  background-color: #1ce589;
}

/* line 206, src/assets/scss/components/_components-switch.scss */
.f-switch input:checked + i::before {
  -webkit-transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

/* line 210, src/assets/scss/components/_components-switch.scss */
.f-switch input:checked + i::after {
  -webkit-transform: translate3d(22px, 2px, 0);
  transform: translate3d(22px, 2px, 0);
}

/* line 217, src/assets/scss/components/_components-switch.scss */
.f-switch small {
  color: #999;
  position: relative;
  top: -4px;
}

/*! _popovers.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 16, src/assets/scss/components/_components-popovers.scss */
.webui-popover {
  -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 10px 4px !important;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 10px 4px !important;
  background: #fff !important;
  color: #393a4f !important;
  border: none !important;
  margin-top: -20px !important;
}

/* line 23, src/assets/scss/components/_components-popovers.scss */
.webui-popover.top > .webui-arrow {
  border-top-color: #fff !important;
}

/* line 27, src/assets/scss/components/_components-popovers.scss */
.webui-popover.bottom-right > .webui-arrow {
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

/* line 32, src/assets/scss/components/_components-popovers.scss */
.webui-popover.top-right > .webui-arrow {
  border-right-color: transparent !important;
}

/* line 35, src/assets/scss/components/_components-popovers.scss */
.webui-popover.top-right > .webui-arrow:after {
  border-top-color: #fff !important;
}

/* line 40, src/assets/scss/components/_components-popovers.scss */
.webui-popover.top-left > .webui-arrow {
  border-top-color: #fff !important;
}

/* line 44, src/assets/scss/components/_components-popovers.scss */
.webui-popover.left > .webui-arrow {
  border-left-color: transparent !important;
}

/* line 49, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-arrow {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
}

/* line 53, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-arrow:after {
  border-top-color: transparent !important;
}

/* line 59, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-title {
  background-color: #fff !important;
  border-bottom: none !important;
  font-weight: 500;
}

/* line 66, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content {
  font-size: 0.75rem !important;
}

/* line 69, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  font-size: 0.85rem;
}

/* line 76, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .staff-avatar {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

/* line 83, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .content-block {
  margin-left: 4px;
  color: #999;
}

/* line 88, src/assets/scss/components/_components-popovers.scss */
.webui-popover
  .webui-popover-content
  .popover-flex-block
  .content-block
  span:first-child {
  font-weight: 500;
  color: #393a4f;
}

/* line 94, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .content-block label,
.webui-popover .webui-popover-content .popover-flex-block .content-block span {
  display: block;
}

/* line 98, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .content-block label {
  color: #8d8fad;
  text-transform: uppercase;
  font-size: 70%;
}

/* line 106, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .fa.fa-star,
.webui-popover .webui-popover-content .popover-flex-block .fa.fa-star-half {
  color: #ffd700;
}

/* line 110, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .icon-block {
  height: 38px;
  width: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

/* line 118, src/assets/scss/components/_components-popovers.scss */
.webui-popover .webui-popover-content .popover-flex-block .icon-block svg {
  height: 24px;
  width: 24px;
  stroke: #999;
  stroke-width: 1.3px;
}

/* line 124, src/assets/scss/components/_components-popovers.scss */
.webui-popover
  .webui-popover-content
  .popover-flex-block
  .icon-block
  svg.is-medium {
  height: 26px;
  width: 26px;
}

/* line 138, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block {
  position: relative;
}

/* line 142, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper {
  padding: 8px 8px 0 8px;
}

/* line 146, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-cover {
  position: relative;
  margin-bottom: 10px;
  min-height: 104px;
}

/* line 151, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-cover img {
  display: block;
}

/* line 156, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-cover .popover-avatar {
  position: absolute;
  right: 14px;
  bottom: -25px;
  width: 50px;
  height: 50px;
  min-width: 50px;
  z-index: 0;
}

/* line 165, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block
  .profile-popover-wrapper
  .popover-cover
  .popover-avatar
  img {
  display: block;
}

/* line 169, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block
  .profile-popover-wrapper
  .popover-cover
  .popover-avatar
  .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #fff;
}

/* line 177, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block
  .profile-popover-wrapper
  .popover-cover
  .popover-avatar
  .badge {
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
}

/* line 191, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-meta span {
  display: block;
}

/* line 195, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-meta span.user-meta,
.profile-popover-block .profile-popover-wrapper .popover-meta span.page-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

/* line 201, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  span.user-meta
  .username,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  span.user-meta
  .pagename,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  span.page-meta
  .username,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  span.page-meta
  .pagename {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 209, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-meta span.job-title {
  font-size: 0.8rem;
  color: #3d70b2;
  font-weight: 500;
  margin-bottom: 8px;
}

/* line 218, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .profile-popover-wrapper .popover-meta .common-friends,
.profile-popover-block .profile-popover-wrapper .popover-meta .user-location,
.profile-popover-block .profile-popover-wrapper .popover-meta .page-activity,
.profile-popover-block .profile-popover-wrapper .popover-meta .page-followers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

/* line 224, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .common-friends
  .text,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .user-location
  .text,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .page-activity
  .text,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .page-followers
  .text {
  margin: 0 10px;
  color: #757a91;
}

/* line 229, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .common-friends
  svg,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .user-location
  svg,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .page-activity
  svg,
.profile-popover-block
  .profile-popover-wrapper
  .popover-meta
  .page-followers
  svg {
  stroke: #888da8;
  width: 16px;
  height: 16px;
}

/* line 239, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .popover-actions {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 10px;
  z-index: 1;
}

/* line 248, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .popover-actions .popover-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  max-width: 30px;
  margin: 0 2px;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 260, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .popover-actions .popover-icon:hover {
  background: #3d70b2;
}

/* line 263, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .popover-actions .popover-icon:hover svg {
  stroke: #fff;
}

/* line 268, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .popover-actions .popover-icon svg {
  width: 16px;
  height: 16px;
  stroke: #cecece;
}

/* line 277, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 0;
}

/* line 292, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .loader-overlay.is-active {
  opacity: 1;
  z-index: 1;
}

/* line 300, src/assets/scss/components/_components-popovers.scss */
.profile-popover-block .loader-overlay .loader.is-loading {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}

/*! _components-tooltips.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 15, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;
}

/* line 26, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.fade {
  opacity: 0;
  -webkit-transition: opacity 0.6s linear;
  transition: opacity 0.6s linear;
}

/* line 34, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.fade.in {
  opacity: 1;
}

/* line 38, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

/* line 43, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.top {
  margin-top: -3px;
}

/* line 47, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.right {
  margin-left: 3px;
}

/* line 51, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.bottom {
  margin-top: 3px;
}

/* line 55, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.left {
  margin-left: -3px;
}

/* line 59, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip .ggtooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  border-radius: 4px;
  border: solid 1px #000000;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

/* line 73, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip .ggtooltip-arrow,
.ggtooltip .ggtooltip-arrow-shadow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

/* line 81, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.top .ggtooltip-arrow,
.ggtooltip.top .ggtooltip-arrow-shadow {
  bottom: 1px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #000000;
  border-width: 5px 5px 0;
}

/* line 89, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.top .ggtooltip-arrow-shadow {
  bottom: 0;
}

/* line 93, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.right .ggtooltip-arrow,
.ggtooltip.right .ggtooltip-arrow-shadow {
  top: 50%;
  left: 1px;
  margin-top: -5px;
  border-right-color: #000000;
  border-width: 5px 5px 5px 0;
}

/* line 101, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.right .ggtooltip-arrow-shadow {
  left: 0;
}

/* line 105, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.left .ggtooltip-arrow,
.ggtooltip.left .ggtooltip-arrow-shadow {
  top: 50%;
  right: 1px;
  margin-top: -5px;
  border-left-color: #000000;
  border-width: 5px 0 5px 5px;
}

/* line 113, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.left .ggtooltip-arrow-shadow {
  right: 0;
}

/* line 117, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.bottom .ggtooltip-arrow,
.ggtooltip.bottom .ggtooltip-arrow-shadow {
  top: 1px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}

/* line 125, src/assets/scss/components/_components-tooltips.scss */
.ggtooltip.bottom .ggtooltip-arrow-shadow {
  top: 0;
}

/*! _checkboxes.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 16, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox {
  position: relative;
}

/* line 19, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 25, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 37, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 12px;
}

/* line 53, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

/* line 63, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox input[type="checkbox"]:checked + label {
  background-color: #5596e6;
  border-color: #5596e6;
}

/* line 68, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* line 74, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-primary input[type="checkbox"]:checked + label {
  background-color: #3d70b2;
  border-color: #3d70b2;
}

/* line 81, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-green input[type="checkbox"]:checked + label {
  background-color: #1ce589;
  border-color: #1ce589;
}

/* line 88, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-blue input[type="checkbox"]:checked + label {
  background-color: #039be5;
  border-color: #039be5;
}

/* line 95, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-orange input[type="checkbox"]:checked + label {
  background-color: #ff9700;
  border-color: #ff9700;
}

/* line 102, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-red input[type="checkbox"]:checked + label {
  background-color: #f71416;
  border-color: #f71416;
}

/* line 109, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-small {
  height: 22px;
  width: 22px;
}

/* line 113, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-small label {
  height: 22px;
  width: 22px;
}

/* line 117, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-small label:after {
  left: 4px;
  top: 4.5px;
  width: 13px;
  height: 8px;
  -webkit-transform: rotate(-45deg) scale(0.65);
  transform: rotate(-45deg) scale(0.65);
}

/* line 126, src/assets/scss/components/_components-checkboxes.scss */
.round-checkbox.is-small span {
  position: relative;
  top: -2px;
  padding: 0 10px;
  cursor: default;
}

/* line 143, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-accent .styled-checkbox:hover + label:before {
  background: #5596e6;
}

/* line 148, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-accent .styled-checkbox:checked + label:before {
  background: #5596e6;
  border-color: #5596e6;
}

/* line 154, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-accent .styled-checkbox:disabled + label {
  color: #5596e6;
}

/* line 163, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-red .styled-checkbox:hover + label:before {
  background: #f71416;
}

/* line 168, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-red .styled-checkbox:checked + label:before {
  background: #f71416;
  border-color: #f71416;
}

/* line 174, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-red .styled-checkbox:disabled + label {
  color: #f71416;
}

/* line 183, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-blue .styled-checkbox:hover + label:before {
  background: #039be5;
}

/* line 188, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-blue .styled-checkbox:checked + label:before {
  background: #039be5;
  border-color: #039be5;
}

/* line 194, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-blue .styled-checkbox:disabled + label {
  color: #039be5;
}

/* line 203, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-green .styled-checkbox:hover + label:before {
  background: #1ce589;
}

/* line 208, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-green .styled-checkbox:checked + label:before {
  background: #1ce589;
  border-color: #1ce589;
}

/* line 214, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-green .styled-checkbox:disabled + label {
  color: #1ce589;
}

/* line 223, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-orange .styled-checkbox:hover + label:before {
  background: #ff9700;
}

/* line 228, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-orange .styled-checkbox:checked + label:before {
  background: #ff9700;
  border-color: #ff9700;
}

/* line 234, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox.is-orange .styled-checkbox:disabled + label {
  color: #ff9700;
}

/* line 240, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox {
  position: absolute;
  opacity: 0;
}

/* line 244, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

/* line 251, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #cecece;
  background: white;
}

/* line 264, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox:hover + label:before {
  background: #3d70b2;
}

/* line 269, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox:focus + label:before {
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

/* line 274, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox:checked + label:before {
  background: #3d70b2;
  border-color: #3d70b2;
}

/* line 280, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox:disabled + label {
  color: #3d70b2;
  cursor: auto;
}

/* line 286, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox:disabled + label:before {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ddd;
}

/* line 292, src/assets/scss/components/_components-checkboxes.scss */
.basic-checkbox .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  -webkit-box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
    4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*! _tables.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 4px;
}

/* line 22, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header span {
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: #999;
  text-transform: uppercase;
  padding-bottom: 10px;
}

/* line 30, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header span.product {
  width: 40%;
}

/* line 34, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header span.quantity {
  width: 15%;
  text-align: center;
}

/* line 39, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header span.price {
  width: 15%;
  text-align: center;
}

/* line 44, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header span.discount {
  width: 15%;
  text-align: center;
}

/* line 49, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-header span.total {
  width: 15%;
  text-align: center;
}

/* line 56, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  margin-bottom: 6px;
}

/* line 66, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40%;
}

/* line 71, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .product img {
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
  width: 50px;
  height: 50px;
  min-width: 50px;
}

/* line 79, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .product .product-name {
  display: block;
  margin-left: 12px;
  max-width: 360px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: #393a4f;
}

/* line 91, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .quantity span,
.flex-table .flex-table-item .price span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #999;
}

/* line 101, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .discount span,
.flex-table .flex-table-item .total span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 110, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .quantity {
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #dedede;
}

/* line 118, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .price {
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #dedede;
}

/* line 126, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .price span:before {
  content: "$";
}

/* line 132, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .discount {
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #dedede;
}

/* line 140, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .total {
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #dedede;
}

/* line 148, src/assets/scss/components/_components-tables.scss */
.flex-table .flex-table-item .total span:before {
  content: "$";
}

@media (max-width: 767px) {
  /* line 160, src/assets/scss/components/_components-tables.scss */
  .flex-table .flex-table-header {
    display: none;
  }

  /* line 164, src/assets/scss/components/_components-tables.scss */
  .flex-table .flex-table-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100% !important;
    padding: 20px;
    margin-bottom: 16px;
  }

  /* line 171, src/assets/scss/components/_components-tables.scss */
  .flex-table .flex-table-item:last-child {
    margin-bottom: 0;
  }

  /* line 175, src/assets/scss/components/_components-tables.scss */
  .flex-table .flex-table-item > div {
    border: none !important;
  }

  /* line 180, src/assets/scss/components/_components-tables.scss */
  .flex-table .product {
    width: 100% !important;
    padding-bottom: 10px;
  }

  /* line 185, src/assets/scss/components/_components-tables.scss */
  .flex-table .quantity,
  .flex-table .price,
  .flex-table .discount,
  .flex-table .total {
    position: relative;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    width: 100% !important;
    border: none;
  }

  /* line 191, src/assets/scss/components/_components-tables.scss */
  .flex-table .quantity:before,
  .flex-table .price:before,
  .flex-table .discount:before,
  .flex-table .total:before {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 500;
    color: #999;
  }

  /* line 200, src/assets/scss/components/_components-tables.scss */
  .flex-table .quantity:before {
    content: "Quantity";
    position: absolute;
    top: 0;
    left: 0;
  }

  /* line 207, src/assets/scss/components/_components-tables.scss */
  .flex-table .quantity.has-spinner {
    padding-bottom: 6px;
  }

  /* line 210, src/assets/scss/components/_components-tables.scss */
  .flex-table .quantity.has-spinner:before {
    content: "Quantity";
    position: absolute;
    top: 8px;
    left: 0;
  }

  /* line 220, src/assets/scss/components/_components-tables.scss */
  .flex-table .price:before {
    content: "Price";
    position: absolute;
    top: 2px;
    left: 0;
  }

  /* line 229, src/assets/scss/components/_components-tables.scss */
  .flex-table .discount:before {
    content: "Discount";
    position: absolute;
    top: 2px;
    left: 0;
  }

  /* line 238, src/assets/scss/components/_components-tables.scss */
  .flex-table .total:before {
    content: "Total";
    position: absolute;
    top: 2px;
    left: 0;
  }
}

/*! _animations.scss | Friendkit | © Css Ninja. 2019-2020 */
@-webkit-keyframes bouncy {
  from,
  to {
    -webkit-transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
  }
}

@keyframes bouncy {
  from,
  to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}

/* line 59, src/assets/scss/components/_components-animations.scss */
.bouncy {
  -webkit-animation: bouncy 0.6s;
  animation: bouncy 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@-webkit-keyframes opacity-shift {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-shift {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* line 138, src/assets/scss/components/_components-animations.scss */
.fadeIn {
  -webkit-animation: fadeIn 500ms ease-in-out;
  animation: fadeIn 500ms ease-in-out;
}

@-webkit-keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* line 175, src/assets/scss/components/_components-animations.scss */
.preFadeInLeft {
  opacity: 0;
}

/* line 179, src/assets/scss/components/_components-animations.scss */
.fadeInLeft {
  opacity: 0;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* line 217, src/assets/scss/components/_components-animations.scss */
.preFadeInUp {
  opacity: 0;
}

/* line 221, src/assets/scss/components/_components-animations.scss */
.fadeInUp {
  opacity: 0;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(4.5);
    transform: scale(4.5);
    opacity: 0;
  }
}

@keyframes wave {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(4.5);
    transform: scale(4.5);
    opacity: 0;
  }
}

@-webkit-keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
  }
}

@keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}

/* line 290, src/assets/scss/components/_components-animations.scss */
.gelatine {
  -webkit-animation: gelatine 0.6s;
  animation: gelatine 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes modalScale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes modalScale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes gradientShift {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

/*! _utils.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 23, src/assets/scss/components/_components-utils.scss */
a {
  color: #3f88e3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 27, src/assets/scss/components/_components-utils.scss */
a:hover {
  color: #3f88e3;
}

/* line 31, src/assets/scss/components/_components-utils.scss */
a.is-inverted {
  color: #5596e6;
}

/* line 35, src/assets/scss/components/_components-utils.scss */
a.standard-link {
  color: #039be5;
  text-decoration: underline;
}

/* line 45, src/assets/scss/components/_components-utils.scss */
var {
  font-style: normal;
}

/* line 49, src/assets/scss/components/_components-utils.scss */
code {
  word-break: break-all;
}

/* line 53, src/assets/scss/components/_components-utils.scss */
.is-disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: default !important;
}

/* line 59, src/assets/scss/components/_components-utils.scss */
.no-click {
  pointer-events: none;
}

/* line 63, src/assets/scss/components/_components-utils.scss */
.is-vhidden {
  visibility: hidden !important;
}

/* line 67, src/assets/scss/components/_components-utils.scss */
.is-hidden {
  display: none !important;
}

/* line 71, src/assets/scss/components/_components-utils.scss */
.no-padding {
  padding: 0 !important;
}

/* line 75, src/assets/scss/components/_components-utils.scss */
.no-margin {
  margin: 0 !important;
}

/* line 79, src/assets/scss/components/_components-utils.scss */
.ml-auto {
  margin-left: auto !important;
}

/* line 83, src/assets/scss/components/_components-utils.scss */
.mt-10 {
  margin-top: 10px !important;
}

/* line 87, src/assets/scss/components/_components-utils.scss */
.mb-10 {
  margin-bottom: 10px !important;
}

/* line 95, src/assets/scss/components/_components-utils.scss */
.has-background-image {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
}

/* line 100, src/assets/scss/components/_components-utils.scss */
.has-background-image.is-top {
  background-position: top !important;
}

/* line 104, src/assets/scss/components/_components-utils.scss */
.has-background-image.is-bottom {
  background-position: bottom !important;
}

/* line 114, src/assets/scss/components/_components-utils.scss */
.has-slimscroll {
  overflow-x: hidden !important;
}

/* line 117, src/assets/scss/components/_components-utils.scss */
.has-slimscroll::-webkit-scrollbar {
  width: 6px !important;
}

/* line 121, src/assets/scss/components/_components-utils.scss */
.has-slimscroll::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/* line 128, src/assets/scss/components/_components-utils.scss */
.has-slimscroll-md {
  overflow-x: hidden !important;
}

/* line 131, src/assets/scss/components/_components-utils.scss */
.has-slimscroll-md::-webkit-scrollbar {
  width: 4px !important;
}

/* line 135, src/assets/scss/components/_components-utils.scss */
.has-slimscroll-md::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/* line 142, src/assets/scss/components/_components-utils.scss */
.has-slimscroll-xs {
  overflow-x: hidden !important;
}

/* line 145, src/assets/scss/components/_components-utils.scss */
.has-slimscroll-xs::-webkit-scrollbar {
  width: 2px !important;
}

/* line 149, src/assets/scss/components/_components-utils.scss */
.has-slimscroll-xs::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/* line 166, src/assets/scss/components/_components-utils.scss */
.checkmark-wrapper .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #5596e6;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

/* line 178, src/assets/scss/components/_components-utils.scss */
.checkmark-wrapper .checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  -webkit-box-shadow: inset 0px 0px 0px #5596e6;
  box-shadow: inset 0px 0px 0px #5596e6;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

/* line 192, src/assets/scss/components/_components-utils.scss */
.checkmark-wrapper .checkmark.is-small {
  height: 36px;
  width: 36px;
}

/* line 197, src/assets/scss/components/_components-utils.scss */
.checkmark-wrapper .checkmark.is-xs {
  height: 24px;
  width: 24px;
}

/* line 204, src/assets/scss/components/_components-utils.scss */
.checkmark-wrapper .checkmark__check {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #5596e6;
    box-shadow: inset 0px 0px 0px 30px #5596e6;
  }
}

@keyframes fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #5596e6;
    box-shadow: inset 0px 0px 0px 30px #5596e6;
  }
}

/* line 242, src/assets/scss/components/_components-utils.scss */
.fancybox-content {
  display: inline-block;
}

/* line 245, src/assets/scss/components/_components-utils.scss */
.fancybox-content.hidden {
  display: none;
}

/* line 251, src/assets/scss/components/_components-utils.scss */
.morphing-btn-wrap,
#morphing {
  width: 111.425px;
}

@media all and (max-width: 599px) {
  /* line 257, src/assets/scss/components/_components-utils.scss */
  .fancybox-custom-layout .fancybox-stage {
    position: relative;
    height: 240px;
    right: 0;
    left: 0;
    top: 0;
    z-index: 99;
  }

  /* line 266, src/assets/scss/components/_components-utils.scss */
  .fancybox-custom-layout .fancybox-slide {
    padding: 0 !important;
    width: calc(100% - 44px) !important;
  }

  /* line 270, src/assets/scss/components/_components-utils.scss */
  .fancybox-custom-layout .fancybox-slide .fancybox-content {
    width: 100% !important;
  }

  /* line 273, src/assets/scss/components/_components-utils.scss */
  .fancybox-custom-layout .fancybox-slide .fancybox-content img {
    margin: 0 20px !important;
    max-width: calc(100% - 40px) !important;
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  /* line 281, src/assets/scss/components/_components-utils.scss */
  .fancybox-custom-layout .fancybox-caption {
    width: calc(100% - 44px) !important;
    height: calc(100% - 240px) !important;
    top: 240px !important;
  }
}

/* line 292, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-bg {
  background: #5596e6;
}

/* line 296, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-slide {
  background: #181e28;
  padding: 0 40px;
}

/* line 300, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-slide img {
  border-radius: 6px;
}

/* line 305, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

/* line 310, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption {
  background: #fff;
  bottom: 0;
  color: #6c6f73;
  left: auto;
  padding: 10px 0;
  right: 44px;
  top: 0;
  width: 350px;
  text-align: left;
}

/* line 321, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption:before {
  display: none;
}

/* line 325, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .fancybox-caption__body {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-bottom: 50px;
}

/* line 333, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption a {
  text-decoration: none;
}

/* line 336, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption a:hover {
  color: #5596e6;
}

/* line 342, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .comment-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fbfbfc;
  height: 50px;
  width: 100%;
  border-radius: 0 0 6px 0;
  border-top: 1px solid #dee2e5;
}

/* line 353, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .comment-controls .controls-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

/* line 361, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

/* line 367, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .control {
  position: relative;
  width: 100%;
}

/* line 371, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .control
  .textarea,
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .control
  .emojionearea-editor {
  resize: none;
  height: 36px;
  max-height: 36px;
  min-height: 36px;
  border-radius: 100px;
  overflow: hidden;
  line-height: 1.6;
  font-size: 0.8rem;
  padding-left: 16px;
  margin: 0 6px;
  text-align: left;
}

/* line 385, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .control
  .emoji-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 401, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .control
  .emoji-button:hover
  svg {
  stroke: #3d70b2;
}

/* line 406, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .control
  .emoji-button
  svg {
  height: 16px;
  width: 16px;
  stroke: #abb0be;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 416, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea-editor {
  padding-left: 0 !important;
}

/* line 419, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea-editor
  img {
  height: 18px;
  width: 18px;
  min-height: 18px;
  max-height: 18px;
}

/* line 427, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea {
  overflow: visible !important;
}

/* line 431, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea-picker {
  top: -230px;
  position: absolute;
  left: -50px;
  width: 310px;
}

/* line 437, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea-picker
  .emojionearea-wrapper {
  width: 310px;
}

/* line 440, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea-picker
  .emojionearea-wrapper
  img {
  height: 22px;
  width: 22px;
  min-height: 22px;
  max-height: 22px;
}

/* line 449, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea-scroll-area {
  width: 310px;
}

/* line 453, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea
  .emojionearea-button
  > div.emojionearea-button-open {
  background-position: 0 -22px;
}

/* line 457, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comment-controls
  .controls-inner
  .emojionearea
  .emojionearea-picker
  .emojionearea-scroll-area
  .emojibtn {
  width: 24px !important;
  height: 24px !important;
}

/* line 465, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  width: 100%;
}

/* line 473, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

/* line 479, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .user-meta {
  margin: 0 10px;
}

/* line 482, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .user-meta span {
  display: block;
}

/* line 485, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .user-meta span:first-child {
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 489, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .header
  .user-meta
  span:first-child
  small {
  color: #999;
}

/* line 494, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .user-meta span:nth-child(2) {
  text-align: left;
  font-size: 0.8rem;
  color: #999;
}

/* line 502, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .button {
  line-height: 0;
  margin-left: auto;
  padding: 14px 18px;
}

/* line 510, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .dropdown .button {
  padding: 18px 6px;
  border: none;
  background: transparent;
}

/* line 515, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .dropdown .button svg {
  height: 18px;
  width: 18px;
}

/* line 521, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .header .dropdown .dropdown-menu {
  margin-top: 10px;
}

/* line 528, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .inner-content {
  padding: 12px;
}

/* line 531, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .inner-content .control {
  width: 100%;
}

/* line 534, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .inner-content .control input {
  padding-left: 34px;
}

/* line 538, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .inner-content
  .control
  input:focus
  + .icon
  svg {
  stroke: #5596e6;
}

/* line 544, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .inner-content .control .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 554, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .inner-content .control .icon svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 565, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .live-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

/* line 572, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .live-stats .social-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 576, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .shares-count,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .comments-count,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .likes-count,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .views-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

/* line 582, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .shares-count
  span,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .comments-count
  span,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .likes-count
  span,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .views-count
  span {
  display: block;
  font-size: 0.8rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 588, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .shares-count
  span.views,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .comments-count
  span.views,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .likes-count
  span.views,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .views-count
  span.views {
  margin: 0 2px;
}

/* line 593, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .shares-count
  svg,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .comments-count
  svg,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .likes-count
  svg,
.fancybox-custom-layout
  .fancybox-caption
  .live-stats
  .social-count
  .views-count
  svg {
  height: 14px;
  width: 14px;
  stroke: #888da8;
}

/* line 603, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 0 0 0;
}

/* line 609, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .actions .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 615, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .actions .action span {
  display: block;
  font-size: 0.8rem;
  margin: 0 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 622, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .actions .action svg {
  height: 16px;
  width: 16px;
  stroke: #888da8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 630, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .actions .action:hover span {
  color: #5596e6;
}

/* line 634, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .actions .action:hover svg {
  stroke: #5596e6;
}

/* line 642, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .comments-list {
  background: #f5f6f7;
  height: calc(100% - 162px);
  padding: 20px 14px;
  overflow-y: auto;
}

/* line 649, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .comments-list .is-comment {
  border: none !important;
  padding-top: 0 !important;
}

/* line 653, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-caption .comments-list .is-comment.is-nested {
  margin-left: 40px;
}

/* line 657, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-left {
  margin-right: 10px;
}

/* line 660, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-left
  img {
  border-radius: 50%;
}

/* line 665, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
}

/* line 671, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .username {
  font-size: 0.8rem;
  font-weight: 500;
}

/* line 676, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  p {
  font-size: 0.75rem;
  color: #999;
}

/* line 682, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 8px;
}

/* line 687, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  span,
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  a {
  display: block;
  font-size: 0.75rem;
}

/* line 692, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  span {
  margin: 0 10px;
  color: #999;
}

/* line 697, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

/* line 703, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count
  span {
  display: block;
  font-size: 0.75rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 709, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count
  span.views {
  margin: 0 2px;
}

/* line 714, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout
  .fancybox-caption
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count
  svg {
  height: 12px;
  width: 12px;
  stroke: #888da8;
}

/* line 726, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-stage {
  right: 394px;
}

/* line 731, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-toolbar {
  background: #5596e6;
  bottom: 0;
  left: auto;
  right: 0;
  top: 0;
  width: 44px;
}

/* line 741, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-button {
  background: transparent;
}

/* line 744, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-button div {
  padding: 2px;
}

/* line 749, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-button[disabled] {
  color: #fff;
}

/* line 753, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-button:not([disabled]) {
  color: #fff;
}

/* line 757, src/assets/scss/components/_components-utils.scss */
.fancybox-custom-layout .fancybox-button--arrow_right {
  right: 308px;
}

/* line 765, src/assets/scss/components/_components-utils.scss */
.tabs.is-boxed li.is-active a {
  border-color: #e8e8e8;
}

/* line 773, src/assets/scss/components/_components-utils.scss */
.subloader {
  position: fixed;
  top: 107px;
  left: 0;
  height: calc(100% - 107px);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 786, src/assets/scss/components/_components-utils.scss */
.subloader.is-active {
  opacity: 1;
  z-index: 8;
}

/* line 791, src/assets/scss/components/_components-utils.scss */
.subloader.is-grey {
  background: #f4f4f4;
}

/* line 795, src/assets/scss/components/_components-utils.scss */
.subloader.is-white {
  background: #fff;
}

/* line 799, src/assets/scss/components/_components-utils.scss */
.subloader .loader {
  height: 5rem;
  width: 5rem;
}

/* line 809, src/assets/scss/components/_components-utils.scss */
pre {
  background-color: transparent !important;
  color: unset;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 0 !important;
  white-space: pre;
  word-wrap: normal;
}

/* line 819, src/assets/scss/components/_components-utils.scss */
.hljs {
  display: block;
  overflow-x: auto;
  font-size: 0.875em;
  padding: 1.5em;
  background: #344258;
  overflow-x: auto;
  white-space: pre;
  border-radius: 8px;
  color: white;
}

/* line 831, src/assets/scss/components/_components-utils.scss */
.hljs-name,
.hljs-strong {
  font-weight: bold;
  color: #4fc1ea;
}

/* line 837, src/assets/scss/components/_components-utils.scss */
.hljs-code,
.hljs-emphasis {
  font-style: italic;
}

/* line 842, src/assets/scss/components/_components-utils.scss */
.hljs-tag {
  color: #909090;
}

/* line 846, src/assets/scss/components/_components-utils.scss */
.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-selector-class {
  color: #ade5fc;
}

/* line 853, src/assets/scss/components/_components-utils.scss */
.hljs-string,
.hljs-bullet {
  color: #f39200 !important;
}

/* line 858, src/assets/scss/components/_components-utils.scss */
.hljs-type,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-quote,
.hljs-built_in,
.hljs-builtin-name {
  color: #ffa;
}

/* line 868, src/assets/scss/components/_components-utils.scss */
.hljs-attr {
  color: #68bb88;
}

/* line 872, src/assets/scss/components/_components-utils.scss */
.hljs-number,
.hljs-symbol,
.hljs-bullet {
  color: #d36363;
}

/* line 878, src/assets/scss/components/_components-utils.scss */
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
  color: #fcc28c;
}

/* line 884, src/assets/scss/components/_components-utils.scss */
.hljs-comment,
.hljs-deletion,
.hljs-code {
  color: #888;
}

/* line 890, src/assets/scss/components/_components-utils.scss */
.hljs-regexp,
.hljs-link {
  color: #c6b4f0;
}

/* line 895, src/assets/scss/components/_components-utils.scss */
.hljs-meta {
  color: #fc9b9b;
}

/* line 899, src/assets/scss/components/_components-utils.scss */
.hljs-deletion {
  background-color: #fc9b9b;
  color: #333;
}

/* line 904, src/assets/scss/components/_components-utils.scss */
.hljs-addition {
  background-color: #a2fca2;
  color: #333;
}

/* line 909, src/assets/scss/components/_components-utils.scss */
.hljs a {
  color: inherit;
}

/* line 913, src/assets/scss/components/_components-utils.scss */
.hljs a:focus,
.hljs a:hover {
  color: inherit;
  text-decoration: underline;
}

/* line 928, src/assets/scss/components/_components-utils.scss */
#message {
  top: -200px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* line 935, src/assets/scss/components/_components-utils.scss */
.fancybox-slide--current #message {
  top: 0;
}

/* line 941, src/assets/scss/components/_components-utils.scss */
.fancybox-is-closing #message {
  top: 0;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

@media all and (min-width: 600px) {
  /* line 955, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-bg {
    background: #fcfaf9;
  }

  /* line 959, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
    opacity: 1;
  }

  /* line 964, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-caption {
    background: #f1ecec;
    bottom: 0;
    color: #6c6f73;
    left: auto;
    padding: 30px 20px;
    right: 44px;
    top: 0;
    width: 256px;
  }

  /* line 975, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-caption h3 {
    color: #444;
    font-size: 21px;
    line-height: 1.3;
    margin-bottom: 24px;
  }

  /* line 982, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-caption a {
    color: #444;
  }

  /* line 987, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-caption::before {
    display: none;
  }

  /* line 992, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-stage {
    right: 300px;
  }

  /* line 997, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-toolbar {
    background: #3b3b45;
    bottom: 0;
    left: auto;
    right: 0;
    top: 0;
    width: 44px;
  }

  /* line 1007, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-button {
    background: transparent;
  }

  /* line 1012, src/assets/scss/components/_components-utils.scss */
  .element-block
    .fancybox-custom-layout
    .fancybox-navigation
    .fancybox-button
    div {
    padding: 6px;
    background: #fcfaf9;
    border-radius: 50%;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    opacity: 0.7;
  }

  /* line 1021, src/assets/scss/components/_components-utils.scss */
  .element-block
    .fancybox-custom-layout
    .fancybox-navigation
    .fancybox-button:not([disabled]):hover
    div {
    opacity: 1;
  }

  /* line 1025, src/assets/scss/components/_components-utils.scss */
  .element-block
    .fancybox-custom-layout
    .fancybox-navigation
    .fancybox-button[disabled] {
    color: #999;
  }

  /* line 1029, src/assets/scss/components/_components-utils.scss */
  .element-block
    .fancybox-custom-layout
    .fancybox-navigation
    .fancybox-button:not([disabled]) {
    color: #333;
  }

  /* line 1034, src/assets/scss/components/_components-utils.scss */
  .element-block .fancybox-custom-layout .fancybox-button--arrow_right {
    right: 308px;
  }
}

/* line 1048, src/assets/scss/components/_components-utils.scss */
.morphing-btn-wrap {
  display: inline-block;
  position: relative;
  text-align: center;
}

/* line 1054, src/assets/scss/components/_components-utils.scss */
.morphing-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.3s, color 0.2s 0.2s, width 0.2s 0s;
  -webkit-transition: color 0.3s 0.2s, width 0.2s 0s;
  transition: color 0.3s 0.2s, width 0.2s 0s;
  white-space: nowrap;
}

/* line 1063, src/assets/scss/components/_components-utils.scss */
.morphing-btn_circle {
  color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  -webkit-transition: color 0.2s 0s, width 0.3s 0.2s;
  transition: color 0.2s 0s, width 0.3s 0.2s;
  width: 35.6px !important;
}

/* line 1076, src/assets/scss/components/_components-utils.scss */
.morphing-btn-clone {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #ff5268;
  border-radius: 50%;
  position: fixed;
  -webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 3;
}

/* line 1089, src/assets/scss/components/_components-utils.scss */
.morphing-btn-clone_visible {
  display: block;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}

/* line 1098, src/assets/scss/components/_components-utils.scss */
.fancybox-morphing .fancybox-bg {
  background: #ff5268;
  opacity: 1;
}

/* line 1103, src/assets/scss/components/_components-utils.scss */
.fancybox-morphing .fancybox-toolbar {
  right: 40px;
  top: 20px;
}

/* line 1108, src/assets/scss/components/_components-utils.scss */
.fancybox-morphing .fancybox-button--close {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
}

/* line 1114, src/assets/scss/components/_components-utils.scss */
.fancybox-morphing .fancybox-button--close:hover {
  background: rgba(0, 0, 0, 0.25);
}

/* line 1120, src/assets/scss/components/_components-utils.scss */
#morphing-content {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  line-height: 2;
  margin: 0;
  padding: 6em 10vw;
  position: relative;
  z-index: 3;
}

/* line 1131, src/assets/scss/components/_components-utils.scss */
#morphing-content * {
  color: #fff;
}

/* line 1143, src/assets/scss/components/_components-utils.scss */
.fc-container .fancybox-bg {
  background: #eee;
}

/* line 1147, src/assets/scss/components/_components-utils.scss */
.fancybox-is-open.fc-container .fancybox-bg {
  opacity: 0.95;
}

/* line 1151, src/assets/scss/components/_components-utils.scss */
.fc-content {
  -webkit-box-shadow: 10px 10px 60px -25px;
  box-shadow: 10px 10px 60px -25px;
  max-width: 550px;
}

/* line 1158, src/assets/scss/components/_components-utils.scss */
.fancybox-fx-material.fancybox-slide--previous,
.fancybox-fx-material.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translateY(-60px) scale(1.1);
  transform: translateY(-60px) scale(1.1);
}

/* line 1164, src/assets/scss/components/_components-utils.scss */
.fancybox-fx-material.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}

/* line 1175, src/assets/scss/components/_components-utils.scss */
.quick-view-container {
  background: rgba(10, 10, 10, 0.85);
}

/* line 1179, src/assets/scss/components/_components-utils.scss */
.quick-view-content {
  bottom: 0;
  height: calc(100% - 40px);
  left: 0;
  margin: auto;
  max-height: 650px;
  max-width: 980px;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 40px);
}

/* line 1192, src/assets/scss/components/_components-utils.scss */
.quick-view-carousel {
  background: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 57%;
}

/* line 1202, src/assets/scss/components/_components-utils.scss */
.quick-view-carousel .fancybox-stage {
  bottom: 30px;
}

/* line 1206, src/assets/scss/components/_components-utils.scss */
.quick-view-aside {
  background: #fff;
  bottom: 30px;
  color: #777;
  left: auto;
  padding: 50px 0 30px 0;
  position: absolute;
  right: 0;
  top: 30px;
  width: 43%;
}

/* line 1218, src/assets/scss/components/_components-utils.scss */
.quick-view-aside::before,
.quick-view-aside::after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}

/* line 1227, src/assets/scss/components/_components-utils.scss */
.quick-view-aside::before {
  background: #f4f4f4;
  width: 8px;
}

/* line 1232, src/assets/scss/components/_components-utils.scss */
.quick-view-aside::after {
  background: #e9e9e9;
  width: 1px;
}

/* line 1237, src/assets/scss/components/_components-utils.scss */
.quick-view-aside > div {
  height: 100%;
  overflow: auto;
  padding: 5vh 30px 0 30px;
  text-align: center;
}

/* line 1244, src/assets/scss/components/_components-utils.scss */
.quick-view-aside > div > p {
  font-size: 90%;
}

/* line 1248, src/assets/scss/components/_components-utils.scss */
.quick-view-close {
  background: #f0f0f0;
  border: 0;
  color: #222;
  cursor: pointer;
  font-family: Arial;
  font-size: 14px;
  height: 44px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-indent: -99999px;
  top: 30px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: 44px;
}

/* line 1266, src/assets/scss/components/_components-utils.scss */
.quick-view-close:hover {
  background: #e4e4e4;
}

/* line 1270, src/assets/scss/components/_components-utils.scss */
.quick-view-close::before,
.quick-view-close::after {
  background-color: #222;
  content: "";
  height: 18px;
  left: 22px;
  position: absolute;
  top: 12px;
  width: 1px;
}

/* line 1281, src/assets/scss/components/_components-utils.scss */
.quick-view-close:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* line 1289, src/assets/scss/components/_components-utils.scss */
.quick-view-close:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* line 1297, src/assets/scss/components/_components-utils.scss */
.quick-view-bullets {
  bottom: 0;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  width: 100%;
  z-index: 99999;
}

/* line 1310, src/assets/scss/components/_components-utils.scss */
.quick-view-bullets li {
  display: inline-block;
  vertical-align: top;
}

/* line 1315, src/assets/scss/components/_components-utils.scss */
.quick-view-bullets li a {
  display: block;
  height: 30px;
  position: relative;
  width: 20px;
}

/* line 1322, src/assets/scss/components/_components-utils.scss */
.quick-view-bullets li a span {
  background: #d4d2d2;
  border-radius: 99px;
  height: 10px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-indent: -99999px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
}

/* line 1335, src/assets/scss/components/_components-utils.scss */
.quick-view-bullets li.active a span {
  background: #ff4453;
}

/* line 1347, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble {
  border: 1px solid #e8e8e8 !important;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  border-radius: 2px !important;
}

/* line 1352, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble .hopscotch-bubble-container {
  background: #fff !important;
  position: relative !important;
  z-index: 2 !important;
}

/* line 1358, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble
  .hopscotch-bubble-container
  .hopscotch-actions
  .hopscotch-next {
  height: 32px;
  min-width: 80px;
  background: #fff;
  color: #393a4f;
  border-radius: 100px;
  border-color: #cecece;
  text-shadow: none !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1368, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble
  .hopscotch-bubble-container
  .hopscotch-actions
  .hopscotch-next:hover {
  background: #5596e6;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  border-color: #5596e6;
  color: #fff;
}

/* line 1380, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble .hopscotch-arrow.down .hopscotch-bubble-arrow-border {
  position: relative !important;
  top: -6px !important;
  border-top: 17px solid #dbdbdb !important;
}

/* line 1388, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble .hopscotch-arrow.up .hopscotch-bubble-arrow-border {
  position: relative !important;
  top: 6px !important;
  border-bottom: 17px solid #dbdbdb !important;
}

/* line 1396, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble .hopscotch-arrow.right .hopscotch-bubble-arrow-border {
  position: relative !important;
  left: -6px !important;
  border-left: 17px solid #dbdbdb !important;
}

/* line 1404, src/assets/scss/components/_components-utils.scss */
.hopscotch-bubble .hopscotch-arrow.left .hopscotch-bubble-arrow-border {
  position: relative !important;
  right: -6px !important;
  border-right: 17px solid #dbdbdb !important;
}

/* line 1413, src/assets/scss/components/_components-utils.scss */
.hopscotch-highlight {
  position: relative !important;
  z-index: 1001 !important;
}

@-webkit-keyframes scaleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* line 1477, src/assets/scss/components/_components-utils.scss */
#successAnimationCircle {
  stroke-dasharray: 151px;
  stroke: #41d6c3;
}

/* line 1482, src/assets/scss/components/_components-utils.scss */
#successAnimationCheck {
  stroke-dasharray: 36px;
  stroke: #41d6c3;
}

/* line 1487, src/assets/scss/components/_components-utils.scss */
#successAnimationResult {
  fill: #41d6c3;
  opacity: 0;
}

/* line 1492, src/assets/scss/components/_components-utils.scss */
#successAnimation.animated {
  -webkit-animation: 1s ease-out 0s 1 both scaleAnimation;
  animation: 1s ease-out 0s 1 both scaleAnimation;
}

/* line 1495, src/assets/scss/components/_components-utils.scss */
#successAnimation.animated #successAnimationCircle {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
    0.3s linear 0.9s 1 both fadeOut;
  animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
    0.3s linear 0.9s 1 both fadeOut;
}

/* line 1499, src/assets/scss/components/_components-utils.scss */
#successAnimation.animated #successAnimationCheck {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
    0.3s linear 0.9s 1 both fadeOut;
  animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
    0.3s linear 0.9s 1 both fadeOut;
}

/* line 1503, src/assets/scss/components/_components-utils.scss */
#successAnimation.animated #successAnimationResult {
  -webkit-animation: 0.3s linear 0.9s both fadeIn;
  animation: 0.3s linear 0.9s both fadeIn;
}

/*! _chat.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  pointer-events: none;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 31, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper.is-active,
.chat-wrapper.is-standalone {
  pointer-events: all !important;
  z-index: 1000 !important;
  opacity: 1 !important;
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

/* line 41, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper.is-standalone .chat-inner .chat-nav .close-chat {
  display: none !important;
}

/* line 49, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 55, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav {
  position: absolute;
  top: 0;
  left: 60px;
  width: calc(100% - 60px);
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  z-index: 99;
}

/* line 72, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 78, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-start .recipient-block {
  display: inherit;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
}

/* line 83, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-start
  .recipient-block
  .avatar-container {
  position: relative;
  height: 46px;
  width: 46px;
  border: 2px solid transparent;
}

/* line 89, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-start
  .recipient-block
  .avatar-container
  .user-avatar {
  border-radius: 50%;
}

/* line 94, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-start .recipient-block .username {
  margin-left: 8px;
}

/* line 97, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-start .recipient-block .username span {
  display: block;
}

/* line 100, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-start
  .recipient-block
  .username
  span:first-child {
  font-weight: 500;
  color: #757a91;
}

/* line 105, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-start
  .recipient-block
  .username
  span:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 109, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-start
  .recipient-block
  .username
  span:nth-child(2)
  svg {
  height: 14px;
  width: 14px;
  stroke: #888da8;
  margin-right: 4px;
}

/* line 116, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-start
  .recipient-block
  .username
  span:nth-child(2)
  span {
  font-size: 0.9rem;
  color: #888da8;
}

/* line 127, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 133, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
}

/* line 144, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-nav-item.is-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
}

/* line 151, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-nav-item.is-icon:last-child {
  margin-right: 4px;
}

/* line 155, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-nav-item.is-icon svg {
  height: 20px;
  width: 20px;
  stroke: #abb0be;
}

/* line 163, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-nav-item.close-chat {
  border-radius: 50%;
}

/* line 166, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-nav-item.close-chat:hover {
  background: #f0f0f0;
}

/* line 169, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-end
  .chat-nav-item.close-chat:hover
  svg {
  stroke: #757a91;
}

/* line 177, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-search {
  margin: 0 8px;
}

/* line 181, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-nav .nav-end .chat-search .input:focus {
  border-color: #5596e6;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
}

/* line 185, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-nav
  .nav-end
  .chat-search
  .input:focus
  + .form-icon
  svg {
  stroke: #5596e6;
}

/* line 195, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  background: #fff;
  border-right: 1px solid #e0e0e0;
}

/* line 205, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .header-item,
.chat-wrapper .chat-inner .users-sidebar .user-item,
.chat-wrapper .chat-inner .users-sidebar .footer-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  width: 60px;
}

/* line 215, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .header-item img {
  height: 38px !important;
  width: 38px !important;
}

/* line 222, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .conversations-list {
  height: calc(100% - 120px);
  overflow-y: auto;
}

/* line 228, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .user-item {
  cursor: pointer;
}

/* line 232, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .user-item.is-active {
  background: #f5f5f5;
}

/* line 235, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .user-item.is-active .user-status {
  border-color: #f5f5f5 !important;
}

/* line 241, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .user-item:hover {
  background: #ededed;
}

/* line 246, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .user-item .avatar-container {
  position: relative;
  height: 38px;
  width: 38px;
  border: 2px solid transparent;
}

/* line 253, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .users-sidebar
  .user-item
  .avatar-container
  .user-avatar {
  border-radius: 50%;
}

/* line 258, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .users-sidebar
  .user-item
  .avatar-container
  .user-status {
  position: absolute;
  top: -3px;
  right: -1px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #999;
  border: 2px solid #fff;
}

/* line 268, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .users-sidebar
  .user-item
  .avatar-container
  .user-status.is-online {
  background: #41d6c3;
}

/* line 272, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .users-sidebar
  .user-item
  .avatar-container
  .user-status.is-busy {
  background: #3d70b2;
}

/* line 276, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .users-sidebar
  .user-item
  .avatar-container
  .user-status.is-away {
  background: #abb0be;
}

/* line 284, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .footer-item {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* line 290, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .footer-item .add-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  min-height: 42px;
  width: 42px;
  max-width: 42px;
  margin: 0 auto;
  border: 1.8px dashed #999;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 305, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .footer-item .add-button:hover {
  border: 1.8px solid #3d70b2;
}

/* line 308, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .footer-item .add-button:hover svg {
  stroke: #3d70b2;
}

/* line 313, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .users-sidebar .footer-item .add-button svg {
  height: 18px;
  width: 18px;
  stroke: #999;
  -webkit-transition: inherit;
  transition: inherit;
}

/* line 324, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body {
  position: absolute;
  bottom: 0;
  left: 60px;
  height: 100%;
  width: calc(100% - 60px);
  background: #f0f0f0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 334, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body.is-opened {
  width: calc(100% - 460px);
}

/* line 337, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body.is-opened .chat-action {
  width: calc(100% - 460px) !important;
}

/* line 343, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body .chat-body-inner {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 60px;
  padding: 20px 80px 60px 80px;
  overflow-y: auto;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

/* line 354, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body .chat-body-inner .date-divider {
  position: relative;
  margin-bottom: 40px;
  margin-top: 20px;
}

/* line 359, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body .chat-body-inner .date-divider hr {
  margin: 10px 0;
  background: #ccc;
  height: 1px;
}

/* line 365, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .date-divider
  .date-divider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5px 10px;
  background-color: #f0f0f0;
  color: #ccc;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* line 380, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body .chat-body-inner .chat-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
}

/* line 386, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body .chat-body-inner .chat-message img {
  display: block;
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

/* line 395, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message
  .message-block
  span {
  display: block;
  width: 100%;
  font-size: 0.8rem;
  color: #999;
}

/* line 402, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message
  .message-block
  .message-text {
  position: relative;
  display: block;
  width: auto;
  max-width: 320px;
  padding: 10px;
  border-radius: 3px;
  font-size: 0.9rem;
}

/* line 412, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message
  .message-block
  .message-text:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

/* line 426, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-body .chat-body-inner .chat-message.is-sent {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* line 430, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-sent
  img {
  margin-left: 20px;
}

/* line 436, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-sent
  .message-block
  .message-text {
  color: #757a91;
  background-color: #fff;
}

/* line 440, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-sent
  .message-block
  .message-text:before {
  right: -5px;
  border-top: 5px solid #fff;
}

/* line 448, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-sent
  + .is-sent {
  margin-top: -16px !important;
}

/* line 451, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-sent
  + .is-sent
  img {
  visibility: hidden;
}

/* line 460, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-received
  img {
  margin-right: 20px;
}

/* line 466, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-received
  .message-block
  span {
  text-align: right;
}

/* line 470, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-received
  .message-block
  .message-text {
  color: #fafafa;
  background-color: #5082c3;
}

/* line 474, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-received
  .message-block
  .message-text:before {
  left: -5px;
  border-top: 5px solid #5082c3;
}

/* line 482, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-received
  + .is-received {
  margin-top: -16px !important;
}

/* line 485, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-body
  .chat-body-inner
  .chat-message.is-received
  + .is-received
  img {
  visibility: hidden;
}

/* line 495, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-action {
  position: fixed;
  bottom: 0;
  left: 60px;
  width: calc(100% - 60px);
  height: 60px;
  padding: 0 40px;
  background: #f0f0f0;
}

/* line 505, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-action .chat-action-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* line 513, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-action .chat-action-inner .control {
  position: relative;
  width: 100%;
}

/* line 518, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-action .chat-action-inner .control .textarea {
  resize: none;
  height: 44px;
  max-height: 44px;
  min-height: 44px;
  line-height: 2.1;
  padding: 5px 9px;
  padding-left: 54px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 528, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-action
  .chat-action-inner
  .control
  .textarea:focus {
  border-color: #e8e8e8 !important;
}

/* line 534, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-action
  .chat-action-inner
  .control
  .compose-dropdown {
  position: absolute;
  top: 0;
  left: 0;
}

/* line 541, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-action .chat-action-inner .control .add-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 44px;
  width: 44px;
}

/* line 548, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-action
  .chat-action-inner
  .control
  .add-button
  .button-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 34px;
  min-height: 34px;
  width: 34px;
  max-width: 34px;
  border: 1px solid #3d70b2;
  border-radius: 4px;
  background: #3d70b2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 564, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-action
  .chat-action-inner
  .control
  .add-button:hover
  .button-inner {
  border-color: #41d6c3;
  background-color: #41d6c3;
}

/* line 568, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-action
  .chat-action-inner
  .control
  .add-button:hover
  .button-inner
  svg {
  stroke: #fafafa;
}

/* line 573, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-action
  .chat-action-inner
  .control
  .add-button
  svg {
  height: 18px;
  width: 18px;
  stroke: #fafafa;
  -webkit-transition: inherit;
  transition: inherit;
}

/* line 585, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel {
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(100% - 60px);
  width: 400px;
  background: #fff;
  border-left: 1px solid #e8e8e8;
  -webkit-transform: translateX(400px);
  transform: translateX(400px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 597, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel.is-opened {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* line 602, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel .panel-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

/* line 608, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel .panel-inner .panel-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  width: 100%;
  background: #fff;
  padding: 16px;
}

/* line 617, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel .panel-inner .panel-header h3 {
  font-size: 1.2rem;
  font-weight: 500;
}

/* line 623, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel .panel-inner .panel-header .panel-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 633, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-header
  .panel-close:hover {
  background: #f0f0f0;
}

/* line 636, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-header
  .panel-close:hover
  svg {
  stroke: #757a91;
}

/* line 641, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-header
  .panel-close
  svg {
  height: 20px;
  width: 20px;
  stroke: #abb0be;
  -webkit-transition: inherit;
  transition: inherit;
}

/* line 651, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper .chat-inner .chat-panel .panel-inner .panel-body {
  position: absolute;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 60px);
}

/* line 661, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner {
  padding: 16px;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

/* line 667, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .subheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

/* line 673, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .subheader
  .action-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 683, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .subheader
  .action-icon
  i {
  font-size: 22px;
  color: #abb0be;
}

/* line 688, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .subheader
  .action-icon:hover {
  background: #f5f5f5;
}

/* line 695, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .details-avatar {
  position: relative;
  height: 110px;
  width: 110px;
  margin: 10px auto;
}

/* line 701, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .details-avatar
  img {
  display: block;
  border-radius: 50%;
}

/* line 706, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .details-avatar
  .call-me {
  position: absolute;
  bottom: 0;
  right: -12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #5596e6;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 721, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .details-avatar
  .call-me:hover {
  background: #8ab7ee;
}

/* line 725, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .details-avatar
  .call-me
  i {
  font-size: 20px;
  color: #fafafa;
}

/* line 734, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-meta
  h3 {
  text-align: center;
  color: #393a4f;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.2;
}

/* line 742, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-meta
  h4 {
  font-size: 0.95rem;
  color: #abb0be;
}

/* line 749, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
  -webkit-transform: scale(0.84);
  transform: scale(0.84);
}

/* line 757, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon {
  width: 36px;
  height: 20px;
  background: #5f6062;
  position: relative;
  margin: 0 3px;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* line 771, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon:before {
  content: "";
  position: absolute;
  top: -12.2px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 12.2px solid #5f6062;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* line 788, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon:after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 12.5px solid #5f6062;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* line 805, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon:hover {
  background: #757a91 !important;
}

/* line 808, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon:hover:after {
  border-top: 12.5px solid #757a91 !important;
}

/* line 812, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon:hover:before {
  border-bottom: 12.5px solid #757a91 !important;
}

/* line 818, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-red {
  background: #f71416;
}

/* line 821, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-red:after {
  border-top: 12.5px solid #f71416;
}

/* line 825, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-red:before {
  border-bottom: 12.5px solid #f71416;
}

/* line 830, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-green {
  background: #1ce589;
}

/* line 833, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-green:after {
  border-top: 12.5px solid #1ce589;
}

/* line 837, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-green:before {
  border-bottom: 12.5px solid #1ce589;
}

/* line 842, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-accent {
  background: #5596e6;
}

/* line 845, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-accent:after {
  border-top: 12.5px solid #5596e6;
}

/* line 849, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-accent:before {
  border-bottom: 12.5px solid #5596e6;
}

/* line 854, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-blue {
  background: #039be5;
}

/* line 857, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-blue:after {
  border-top: 12.5px solid #039be5;
}

/* line 861, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-blue:before {
  border-bottom: 12.5px solid #039be5;
}

/* line 866, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-orange {
  background: #ff9700;
}

/* line 869, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-orange:after {
  border-top: 12.5px solid #ff9700;
}

/* line 873, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-orange:before {
  border-bottom: 12.5px solid #ff9700;
}

/* line 878, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-purple {
  background: #9c2bed;
}

/* line 881, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-purple:after {
  border-top: 12.5px solid #9c2bed;
}

/* line 885, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon.is-purple:before {
  border-bottom: 12.5px solid #9c2bed;
}

/* line 890, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-badges
  .hexagon
  .mdi {
  position: relative;
  top: 1px;
  color: white;
  font-size: 18px;
  line-height: 16px;
}

/* line 901, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about {
  margin: 20px 0;
}

/* line 905, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  label {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
  color: #888da8;
  margin-bottom: 16px;
}

/* line 915, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 10px;
}

/* line 923, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block:last-child {
  margin-bottom: 0;
}

/* line 927, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  i {
  font-size: 22px;
  color: #abb0be;
}

/* line 933, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  .about-text {
  margin-left: 12px;
}

/* line 936, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  .about-text
  span {
  display: block;
}

/* line 939, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  .about-text
  span:first-child {
  font-size: 0.8rem;
  font-weight: 500;
}

/* line 944, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  .about-text
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 950, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  .about-text
  a {
  color: #999;
}

/* line 953, src/assets/scss/pages/_app-chat.scss */
.chat-wrapper
  .chat-inner
  .chat-panel
  .panel-inner
  .panel-body.is-user-details
  .panel-body-inner
  .user-about
  .about-block
  .about-text
  a:hover {
  color: #5596e6;
}

/* line 975, src/assets/scss/pages/_app-chat.scss */
.add-conversation-modal .modal-content {
  overflow: visible;
}

/* line 978, src/assets/scss/pages/_app-chat.scss */
.add-conversation-modal .modal-content img {
  height: 70px;
  width: 70px;
  display: block;
  margin: 0 auto;
}

/* line 985, src/assets/scss/pages/_app-chat.scss */
.add-conversation-modal .modal-content .help-text {
  text-align: center;
  font-size: 0.9rem;
  padding: 10px 50px;
  color: #999;
}

/* line 992, src/assets/scss/pages/_app-chat.scss */
.add-conversation-modal .modal-content .field {
  padding: 0 50px;
  margin-bottom: 0;
  margin-top: 16px;
}

/* line 998, src/assets/scss/pages/_app-chat.scss */
.add-conversation-modal .modal-content .action {
  padding: 10px 50px 20px 50px;
}

/* line 1001, src/assets/scss/pages/_app-chat.scss */
.add-conversation-modal .modal-content .action button {
  line-height: 0;
}

/*! _feed.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 24, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs-wrapper {
  border-radius: 6px;
}

/* line 28, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs {
  border-radius: 6px 6px 0 0;
}

/* line 31, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs ul,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  ul {
  background: #fcfcfc;
}

/* line 35, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li {
  background: #fcfcfc;
}

/* line 39, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li.is-active a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li.is-active
  a {
  color: #757a91;
  font-weight: 500;
}

/* line 43, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li.is-active a svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li.is-active
  a
  svg {
  stroke: #757a91;
}

/* line 50, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li.close-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li.close-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 55, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li.close-wrap .close-publish,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li.close-wrap
  .close-publish {
  margin: 0 16px;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

/* line 67, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li.close-wrap .close-publish:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li.close-wrap
  .close-publish:hover {
  background: #e8e8e8;
}

/* line 71, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs li.close-wrap .close-publish svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  li.close-wrap
  .close-publish
  svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
}

/* line 81, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  a {
  font-size: 0.9rem;
  color: #888da8;
  padding: 0.75em !important;
}

/* line 86, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .tabs a svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .tabs
  a
  svg {
  stroke: #888da8;
}

/* line 93, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose {
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 98, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose .compose-form,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose
  .compose-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 105, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose
  img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

/* line 112, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose .control,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose
  .control {
  width: 100%;
  margin-left: 20px;
}

/* line 116, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose .control .textarea,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose
  .control
  .textarea {
  padding: 0;
  border-color: transparent;
  resize: none;
}

/* line 125, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  border-radius: 0 0 6px 6px;
  background: #fff;
  cursor: pointer;
}

/* line 135, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .is-full,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .is-full {
  width: 100% !important;
  margin: 0 auto !important;
}

/* line 140, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .is-narrower,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .is-narrower {
  padding: 0.3rem 0.5rem !important;
}

/* line 145, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 16px;
  margin-right: 10px;
  background: #f7f7f7;
  border-radius: 500px;
  font-size: 0.85rem;
  color: #888da8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 159, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option.is-centered,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option.is-centered {
  margin: 0 auto;
}

/* line 163, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option:hover {
  background: #f2f2f2;
}

/* line 166, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option:hover svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option:hover
  svg {
  stroke: #393a4f;
}

/* line 171, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option
  span {
  display: block;
  padding: 0 8px;
}

/* line 176, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option
  svg {
  height: 20px;
  width: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 182, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option
  img {
  display: block;
  height: 20px;
  width: 20px;
}

/* line 188, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .compose-options .compose-option input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .compose-options
  .compose-option
  input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 201, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .more-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .more-wrap {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
  background: #fafafa;
}

/* line 209, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .more-wrap .is-more,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .more-wrap
  .is-more {
  line-height: 0;
  height: 38px;
  padding: 6px;
  margin-right: 6px;
}

/* line 215, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .more-wrap .is-more span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .more-wrap
  .is-more
  span {
  margin: 0 8px 0 4px;
}

/* line 219, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .more-wrap .is-more svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .more-wrap
  .is-more
  svg {
  width: 16px;
  height: 16px;
}

/* line 225, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .more-wrap .is-solid,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .more-wrap
  .is-solid {
  line-height: 0;
}

/* line 231, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options {
  display: none;
  background: #f5f6f7;
  padding: 8px;
  border-top: 1px solid #e8e8e8;
  height: auto;
}

/* line 239, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options.is-opened,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options.is-opened {
  height: 350px !important;
  overflow: auto !important;
}

/* line 243, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options.is-opened::-webkit-scrollbar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options.is-opened::-webkit-scrollbar {
  width: 4px;
}

/* line 247, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options.is-opened::-webkit-scrollbar-thumb,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options.is-opened::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

/* line 256, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
}

/* line 264, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel:hover {
  background: #edeef0;
}

/* line 269, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel .channel-icon,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .channel-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #e8e8e8;
}

/* line 280, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .target-channels
  .channel
  .channel-icon
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .channel-icon
  svg {
  height: 18px;
  width: 18px;
  stroke: #999;
  stroke-width: 1px;
}

/* line 289, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel .story-icon,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .story-icon {
  position: relative;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  border-radius: 50%;
  background: #e8e8e8;
  border: 1px solid #cecece;
}

/* line 299, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .target-channels
  .channel
  .story-icon
  .plus-icon,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .story-icon
  .plus-icon {
  position: absolute;
  bottom: -1px;
  right: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  background: #3d70b2;
}

/* line 312, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .target-channels
  .channel
  .story-icon
  .plus-icon
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .story-icon
  .plus-icon
  svg {
  height: 10px;
  width: 10px;
  stroke-width: 3px;
  stroke: #fafafa;
}

/* line 322, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel .channel-name,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .channel-name {
  font-size: 0.85rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 329, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel .dropdown,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .dropdown {
  margin-left: auto;
}

/* line 332, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .target-channels .channel .dropdown button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button {
  line-height: 0;
  height: 36px;
  padding: 6px;
}

/* line 337, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button
  span {
  margin: 0 4px;
}

/* line 341, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button
  svg {
  width: 16px;
  height: 16px;
}

/* line 346, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button
  i,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .target-channels
  .channel
  .dropdown
  button
  i {
  font-size: 1.1rem;
}

/* line 355, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list {
  margin-top: 10px;
  border-top: 1px solid #dedede;
}

/* line 360, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 10px;
}

/* line 366, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  span {
  color: #757a91;
  font-size: 0.8rem;
  font-weight: 500;
}

/* line 372, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header .actions,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 376, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header .actions a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .actions
  a {
  display: block;
  margin-left: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #757a91;
}

/* line 383, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-header
  .actions
  a:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .actions
  a:first-child {
  margin-left: auto;
}

/* line 387, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-header
  .actions
  a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .actions
  a:hover {
  color: #5596e6;
}

/* line 391, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-header
  .actions
  a.search-trigger,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .actions
  a.search-trigger {
  height: 24px;
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 399, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header .actions a svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .actions
  a
  svg {
  height: 16px;
  width: 16px;
  stroke: #757a91;
}

/* line 407, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header .control,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .control {
  position: relative;
}

/* line 410, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header .control .input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .control
  .input {
  padding-left: 36px;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 0.9rem;
}

/* line 416, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-header
  .control
  .input:focus,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .control
  .input:focus {
  border-color: #e8e8e8;
}

/* line 421, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-header .control span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .control
  span {
  position: absolute;
  top: 0;
  left: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
}

/* line 431, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-header
  .control
  span
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-header
  .control
  span
  svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
}

/* line 441, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-body {
  padding: 0 6px;
}

/* line 445, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content .hidden-options .friends-list .list-body .friend-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-body
  .friend-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 6px;
  padding: 6px 0;
  border-radius: 4px;
  cursor: pointer;
}

/* line 454, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-body
  .friend-block:hover
  .round-checkbox
  label,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-body
  .friend-block:hover
  .round-checkbox
  label {
  border-color: #5596e6;
}

/* line 459, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-body
  .friend-block
  .friend-avatar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-body
  .friend-block
  .friend-avatar {
  height: 38px;
  width: 38px;
  margin: 0 10px;
  border-radius: 50%;
}

/* line 466, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content
  .hidden-options
  .friends-list
  .list-body
  .friend-block
  .friend-name,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.summary-card
  .hidden-options
  .friends-list
  .list-body
  .friend-block
  .friend-name {
  font-size: 0.85rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 477, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content.is-highlighted,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.is-highlighted.summary-card {
  position: relative !important;
  z-index: 1001 !important;
}

/* line 481, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content.is-highlighted .close-publish,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.is-highlighted.summary-card
  .close-publish {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/* line 485, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content.is-highlighted .more-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.is-highlighted.summary-card
  .more-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 489, src/assets/scss/pages/_app-feed.scss */
.card.is-new-content.is-highlighted .hidden-options,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-new-content.is-highlighted.summary-card
  .hidden-options {
  display: block;
}

/* line 497, src/assets/scss/pages/_app-feed.scss */
.card.is-post.is-nested,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.is-nested.summary-card {
  position: relative;
  margin-top: 20px !important;
  border-bottom-color: transparent;
  border-radius: 6px 6px 0 0;
}

/* line 505, src/assets/scss/pages/_app-feed.scss */
.card.is-post.is-simple .post-actions,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.is-simple.summary-card
  .post-actions {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

/* line 509, src/assets/scss/pages/_app-feed.scss */
.card.is-post.is-simple .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.is-simple.summary-card
  .card-footer {
  padding-top: 10px;
}

/* line 516, src/assets/scss/pages/_app-feed.scss */
.card.is-post.has-nested .content-wrap .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.has-nested.summary-card
  .content-wrap
  .card-footer {
  margin-top: 16px;
}

/* line 522, src/assets/scss/pages/_app-feed.scss */
.card.is-post .card-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .card-heading,
.card.is-post .card-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .card-body {
  padding: 16px 16px 0 16px;
}

/* line 526, src/assets/scss/pages/_app-feed.scss */
.card.is-post .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .card-footer {
  border: none;
  padding: 16px;
  margin: 0;
}

/* line 533, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 539, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

/* line 545, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  a,
.card.is-post .user-block .time,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  .time {
  display: block;
}

/* line 549, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  a {
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 555, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  a:hover {
  color: #4a4b67;
}

/* line 561, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block .time,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  .time {
  font-size: 0.8rem;
  color: #999;
}

/* line 566, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block .user-info,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  .user-info {
  padding: 0 10px;
}

/* line 569, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block .user-info a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  .user-info
  a {
  line-height: 1.1;
}

/* line 572, src/assets/scss/pages/_app-feed.scss */
.card.is-post .user-block .user-info a span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .user-block
  .user-info
  a
  span {
  color: #5596e6;
  font-weight: 500;
}

/* line 584, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-text p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-text
  p {
  color: #757a91;
  font-size: 0.9rem;
}

/* line 588, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-text p a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-text
  p
  a {
  color: #393a4f;
  font-weight: 500;
}

/* line 592, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-text p a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-text
  p
  a:hover {
  color: #5596e6;
}

/* line 599, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .action-wrap,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .action-wrap {
  position: relative;
  margin-top: -22px !important;
}

/* line 604, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .action-wrap .like-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .action-wrap
  .like-wrapper {
  position: absolute;
  bottom: -27px;
  right: 26px;
  max-height: 54px;
}

/* line 612, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .action-wrap .fab-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .action-wrap
  .fab-wrapper {
  position: absolute;
  bottom: -21px;
  max-height: 43px;
}

/* line 617, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .action-wrap .fab-wrapper.is-share,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .action-wrap
  .fab-wrapper.is-share {
  right: 84px;
}

/* line 621, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .action-wrap .fab-wrapper.is-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .action-wrap
  .fab-wrapper.is-comment {
  right: 131px;
}

/* line 628, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image {
  position: relative;
  margin: 10px 0;
}

/* line 632, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  img {
  display: block;
  border-radius: 3px;
}

/* line 638, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .like-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .like-wrapper {
  position: absolute;
  bottom: -27px;
  right: 0;
  max-height: 54px;
}

/* line 646, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .fab-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .fab-wrapper {
  position: absolute;
  bottom: -21px;
  max-height: 43px;
}

/* line 651, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .fab-wrapper.is-share,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .fab-wrapper.is-share {
  right: 58px;
}

/* line 655, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .fab-wrapper.is-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .fab-wrapper.is-comment {
  right: 105px;
}

/* line 661, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 669, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid a.is-half,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid
  a.is-half {
  display: block;
  width: calc(50% - 0.25rem) !important;
  height: 100%;
}

/* line 676, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid
  img {
  display: block;
  margin-bottom: 0.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 50%;
}

/* line 685, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid .like-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid
  .like-wrapper {
  position: absolute;
  bottom: -18px;
  right: 18px;
  max-height: 54px;
}

/* line 692, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid .fab-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid
  .fab-wrapper {
  position: absolute;
  bottom: -13px;
  max-height: 43px;
}

/* line 697, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid .fab-wrapper.is-share,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid
  .fab-wrapper.is-share {
  right: 76px;
}

/* line 701, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .triple-grid .fab-wrapper.is-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .triple-grid
  .fab-wrapper.is-comment {
  right: 123px;
}

/* line 707, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 712, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  img {
  display: block;
  margin-bottom: 0.5rem;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 718, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid .masonry-column-left,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  .masonry-column-left {
  height: 100%;
  width: calc(61% - 0.5rem);
}

/* line 723, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid .masonry-column-right,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  .masonry-column-right {
  width: 39%;
  height: 100%;
  margin-left: 0.5rem;
}

/* line 730, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid .like-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  .like-wrapper {
  position: absolute;
  bottom: -18px;
  right: 0;
  max-height: 54px;
}

/* line 737, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid .fab-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  .fab-wrapper {
  position: absolute;
  bottom: -13px;
  max-height: 43px;
}

/* line 742, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid .fab-wrapper.is-share,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  .fab-wrapper.is-share {
  right: 58px;
}

/* line 746, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-image .masonry-grid .fab-wrapper.is-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-image
  .masonry-grid
  .fab-wrapper.is-comment {
  right: 105px;
}

/* line 754, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 12px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background: #f5f5f5;
  margin: 16px 0 10px 0;
}

/* line 766, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link.is-video .link-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link.is-video
  .link-image {
  position: relative;
}

/* line 769, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link.is-video .link-image .video-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link.is-video
  .link-image
  .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: rgba(52, 66, 88, 0.6);
}

/* line 779, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link.is-video .link-image .video-button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link.is-video
  .link-image
  .video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 46px;
  width: 46px;
  background: #3d70b2;
  border-radius: 50%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

/* line 796, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link.is-video .link-image .video-button img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link.is-video
  .link-image
  .video-button
  img {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  left: 2px;
  z-index: 3;
}

/* line 806, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link.is-video .link-image .video-button:after,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link.is-video
  .link-image
  .video-button:after {
  content: "";
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #3d70b2;
  -webkit-animation: wave 1.6s infinite;
  animation: wave 1.6s infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  z-index: 0;
}

/* line 826, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-image > img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-image
  > img {
  display: block;
  height: 130px;
  width: 180px;
  min-width: 180px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 837, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-content,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-content {
  margin-left: 12px;
}

/* line 841, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-content h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-content
  h4 {
  margin-bottom: 4px;
}

/* line 844, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-content h4 a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-content
  h4
  a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #757a91;
}

/* line 849, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-content h4 a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-content
  h4
  a:hover {
  color: #5596e6;
}

/* line 856, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-content p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-content
  p {
  font-size: 0.9rem;
  color: #999;
}

/* line 862, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .link-content small,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .link-content
  small {
  text-transform: uppercase;
  font-size: 0.65rem;
  color: #757a91;
  margin-top: 8px;
}

/* line 871, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .like-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .like-wrapper {
  position: absolute;
  bottom: -25px;
  right: 0;
  max-height: 54px;
}

/* line 879, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .fab-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .fab-wrapper {
  position: absolute;
  bottom: -20px;
  max-height: 43px;
}

/* line 884, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .fab-wrapper.is-share,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .fab-wrapper.is-share {
  right: 58px;
}

/* line 888, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-link .fab-wrapper.is-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-link
  .fab-wrapper.is-comment {
  right: 105px;
}

/* line 895, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}

/* line 902, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .like-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .like-wrapper {
  margin: 0 2px;
}

/* line 905, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .like-wrapper .like-button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .like-wrapper
  .like-button {
  width: 36px;
  height: 36px;
  border: none;
}

/* line 912, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .like-wrapper .like-overlay,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .like-wrapper
  .like-overlay {
  top: 0 !important;
  left: 0 !important;
  width: 36px;
  height: 36px;
}

/* line 919, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .like-wrapper i,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .like-wrapper
  i {
  font-size: 16px;
}

/* line 925, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .fab-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .fab-wrapper {
  margin: 0 2px;
}

/* line 928, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .fab-wrapper .small-fab,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .fab-wrapper
  .small-fab {
  width: 36px;
  height: 36px;
  border: none;
}

/* line 933, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .post-actions .fab-wrapper .small-fab svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .post-actions
  .fab-wrapper
  .small-fab
  svg {
  height: 16px;
  width: 16px;
}

/* line 942, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 948, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-group,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 955, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-group img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-group
  img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  border: 3px solid #fff;
}

/* line 961, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-group img:not(:first-child),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-group
  img:not(:first-child) {
  margin-left: -12px;
}

/* line 966, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-group .no-like,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-group
  .no-like {
  width: 38px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 973, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-group .no-like svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-group
  .no-like
  svg {
  width: 24px;
  height: 24px;
}

/* line 981, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-text,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-text {
  margin-left: 10px;
  line-height: 1.4;
}

/* line 985, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-text p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-text
  p {
  font-size: 0.8rem;
  color: #888da8;
}

/* line 989, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-text p a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-text
  p
  a {
  color: #393a4f;
  font-weight: 500;
}

/* line 993, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .likers-text p a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .likers-text
  p
  a:hover {
  color: #5596e6;
}

/* line 1001, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .social-count,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 1006, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .social-count .shares-count,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .shares-count,
.card.is-post .content-wrap .card-footer .social-count .comments-count,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .comments-count,
.card.is-post .content-wrap .card-footer .social-count .likes-count,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .likes-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

/* line 1012, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .social-count .shares-count span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .shares-count
  span,
.card.is-post .content-wrap .card-footer .social-count .comments-count span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .comments-count
  span,
.card.is-post .content-wrap .card-footer .social-count .likes-count span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .likes-count
  span {
  display: block;
  font-size: 0.9rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 1019, src/assets/scss/pages/_app-feed.scss */
.card.is-post .content-wrap .card-footer .social-count .shares-count svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .shares-count
  svg,
.card.is-post .content-wrap .card-footer .social-count .comments-count svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .comments-count
  svg,
.card.is-post .content-wrap .card-footer .social-count .likes-count svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .content-wrap
  .card-footer
  .social-count
  .likes-count
  svg {
  height: 18px;
  width: 18px;
  stroke: #888da8;
}

/* line 1032, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-heading,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}

/* line 1038, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-heading h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-heading
  h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #757a91;
}

/* line 1045, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-heading .close-comments,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-heading
  .close-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

/* line 1056, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-heading .close-comments:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-heading
  .close-comments:hover {
  background: #e8e8e8;
}

/* line 1060, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-heading .close-comments svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-heading
  .close-comments
  svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
}

/* line 1069, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body {
  padding: 16px;
  max-height: 450px;
  overflow-y: auto;
}

/* line 1075, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .comments-placeholder,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .comments-placeholder {
  text-align: center;
}

/* line 1078, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .comments-placeholder img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .comments-placeholder
  img {
  display: block;
  max-width: 80px;
  margin: 0 auto;
  opacity: 0.7;
}

/* line 1085, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .comments-placeholder h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .comments-placeholder
  h3 {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.1rem;
  color: #393a4f;
}

/* line 1092, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .comments-placeholder p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .comments-placeholder
  p {
  font-size: 0.9rem;
  color: #999;
}

/* line 1099, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 1104, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .media-left img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-left
  img {
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
}

/* line 1114, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .media-content a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  a {
  font-size: 0.85rem;
  font-weight: 500 !important;
  display: block;
  color: #393a4f;
  line-height: 1.2;
}

/* line 1121, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .media-content a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  a:hover {
  color: #3d70b2;
}

/* line 1126, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .media-content .time,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .time {
  display: block;
  font-size: 0.7rem;
  color: #888da8;
  margin-bottom: 10px;
}

/* line 1133, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .media-content p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  p {
  font-size: 0.9rem !important;
  color: #757a91;
}

/* line 1139, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls {
  padding: 8px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1146, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 1152, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count
  span {
  display: block;
  font-size: 0.8rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 1159, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count
  svg {
  position: relative;
  top: -1px;
  height: 12px;
  width: 12px;
  stroke: #888da8;
}

/* line 1168, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count:hover
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count:hover
  span {
  color: #3d70b2;
}

/* line 1172, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count:hover
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .like-count:hover
  svg {
  stroke: #3d70b2;
}

/* line 1179, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .reply,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .reply,
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .edit,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .edit {
  margin: 0 5px;
}

/* line 1182, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .reply
  a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .reply
  a,
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .edit
  a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .edit
  a {
  font-size: 0.75rem;
  color: #888da8;
}

/* line 1186, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .reply
  a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .reply
  a:hover,
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .edit
  a:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-content
  .controls
  .edit
  a:hover {
  color: #3d70b2;
}

/* line 1196, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .media-right svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-right
  svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
}

/* line 1203, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-right
  .dropdown
  .button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-right
  .dropdown
  .button {
  padding: 4px !important;
  border-color: transparent !important;
}

/* line 1208, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .media-right
  .dropdown
  .media,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .media-right
  .dropdown
  .media {
  border: none !important;
  padding-top: 0 !important;
}

/* line 1216, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .is-comment .is-comment .media-left,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .is-comment
  .media-left {
  position: relative;
}

/* line 1219, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .is-comment
  .media-left:after,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .is-comment
  .media-left:after {
  content: "";
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1.4px;
  height: calc(100% - 46px);
  background: #e8e8e8;
}

/* line 1231, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .comments-body
  .is-comment
  .is-comment
  .media-left:before,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .is-comment
  .is-comment
  .media-left:before {
  content: "";
  position: absolute;
  top: calc(50% + 20px);
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 13px;
  width: 13px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  z-index: 1;
}

/* line 1252, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .load-more .load-more-button,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .load-more
  .load-more-button {
  background: #e8e8e8;
  width: 80px;
  padding: 5px 16px;
  border-radius: 500px;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

/* line 1261, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .load-more .load-more-button:active,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .load-more
  .load-more-button:active,
.card.is-post .comments-wrap .comments-body .load-more .load-more-button:focus,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .load-more
  .load-more-button:focus {
  outline: none;
}

/* line 1265, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .load-more .load-more-button:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .load-more
  .load-more-button:hover {
  background: #f2f2f2;
}

/* line 1269, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .comments-body .load-more .load-more-button svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .comments-body
  .load-more
  .load-more-button
  svg {
  width: 22px;
  height: 22px;
  stroke: #888da8;
}

/* line 1279, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer {
  margin: 0 !important;
  padding: 16px;
}

/* line 1283, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment {
  width: 100%;
}

/* line 1287, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment .media-left img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .media-left
  img {
  border-radius: 50%;
}

/* line 1294, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment .media-content .field,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .media-content
  .field {
  margin-bottom: 0;
}

/* line 1298, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .media-content
  .textarea,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .media-content
  .textarea {
  border-color: #e0e0e0;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  resize: none;
}

/* line 1307, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment .actions,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

/* line 1317, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment .actions .toolbar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1323, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment .actions .action,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action {
  margin-right: 15px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* line 1329, src/assets/scss/pages/_app-feed.scss */
.card.is-post .comments-wrap .card-footer .post-comment .actions .action svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action
  svg {
  width: 18px;
  height: 18px;
  stroke: #888da8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1337, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action:hover
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action:hover
  svg {
  stroke: #3d70b2;
}

/* line 1342, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action.is-auto,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action.is-auto {
  margin-left: auto;
}

/* line 1346, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action.is-upload,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action.is-upload {
  position: relative;
}

/* line 1349, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action.is-upload
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .actions
  .action.is-upload
  input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 1364, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .emojionearea-scroll-area::-webkit-scrollbar,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .emojionearea-scroll-area::-webkit-scrollbar {
  width: 4px;
}

/* line 1368, src/assets/scss/pages/_app-feed.scss */
.card.is-post
  .comments-wrap
  .card-footer
  .post-comment
  .emojionearea-scroll-area::-webkit-scrollbar-thumb,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-post.summary-card
  .comments-wrap
  .card-footer
  .post-comment
  .emojionearea-scroll-area::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

/* line 1381, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block,
.card .card-body .add-friend-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}

/* line 1387, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block:not(:first-child),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block:not(:first-child),
.card .card-body .add-friend-block:not(:first-child),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block:not(:first-child) {
  border-top: 1px solid #e8e8e8;
}

/* line 1392, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  img,
.card .card-body .add-friend-block img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  img {
  height: 40px;
  width: 40px;
  max-height: 40px;
  border-radius: 50%;
}

/* line 1400, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .page-meta,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .page-meta,
.card .card-body .add-friend-block .page-meta,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .page-meta {
  padding: 0 10px;
  line-height: 1.3;
}

/* line 1404, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .page-meta span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .page-meta
  span,
.card .card-body .add-friend-block .page-meta span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .page-meta
  span {
  display: block;
}

/* line 1407, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .page-meta span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .page-meta
  span:first-child,
.card .card-body .add-friend-block .page-meta span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .page-meta
  span:first-child {
  font-size: 0.85rem;
  color: #393a4f;
  font-weight: 500;
}

/* line 1413, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .page-meta span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .page-meta
  span:nth-child(2),
.card .card-body .add-friend-block .page-meta span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .page-meta
  span:nth-child(2) {
  font-size: 0.7rem;
  color: #757a91;
}

/* line 1421, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .add-page,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-page,
.card .card-body .page-block .add-friend,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-friend,
.card .card-body .add-friend-block .add-page,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-page,
.card .card-body .add-friend-block .add-friend,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-friend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-left: auto;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 1432, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .add-page svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-page
  svg,
.card .card-body .page-block .add-friend svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-friend
  svg,
.card .card-body .add-friend-block .add-page svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-page
  svg,
.card .card-body .add-friend-block .add-friend svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-friend
  svg {
  width: 18px;
  height: 18px;
  stroke: #888da8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1439, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .add-page:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-page:hover,
.card .card-body .page-block .add-friend:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-friend:hover,
.card .card-body .add-friend-block .add-page:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-page:hover,
.card .card-body .add-friend-block .add-friend:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-friend:hover {
  background: #f5f5f5;
}

/* line 1442, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .add-page:hover svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-page:hover
  svg,
.card .card-body .page-block .add-friend:hover svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .add-friend:hover
  svg,
.card .card-body .add-friend-block .add-page:hover svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-page:hover
  svg,
.card .card-body .add-friend-block .add-friend:hover svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .add-friend:hover
  svg {
  stroke: #5596e6;
}

/* line 1449, src/assets/scss/pages/_app-feed.scss */
.card .card-body .page-block .checkmark-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .page-block
  .checkmark-wrapper,
.card .card-body .add-friend-block .checkmark-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .add-friend-block
  .checkmark-wrapper {
  margin-left: auto;
  margin-right: 8px;
}

/* line 1456, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 16px;
}

/* line 1462, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block:not(:first-child),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block:not(:first-child) {
  border-top: 1px solid #e8e8e8;
}

/* line 1466, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .img-wrapper,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  width: 48px;
  max-height: 48px;
  min-width: 48px;
  border-radius: 50%;
  border: 1px solid #888da8;
}

/* line 1477, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .img-wrapper img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .img-wrapper
  img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

/* line 1485, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .add-story,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .add-story {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 44px;
  width: 44px;
  max-height: 44px;
  min-width: 44px;
  border-radius: 50%;
  border: 2px dashed #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1497, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .add-story svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .add-story
  svg {
  width: 18px;
  height: 18px;
  stroke: #e8e8e8;
}

/* line 1503, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .add-story:hover,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .add-story:hover {
  border: 2px solid #3d70b2;
}

/* line 1506, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .add-story:hover svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .add-story:hover
  svg {
  stroke: #3d70b2;
}

/* line 1513, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .story-meta,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .story-meta {
  padding: 0 10px;
}

/* line 1516, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .story-meta span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .story-meta
  span {
  display: block;
}

/* line 1519, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .story-meta span:first-child,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .story-meta
  span:first-child {
  font-size: 0.85rem;
  color: #393a4f;
  font-weight: 500;
}

/* line 1525, src/assets/scss/pages/_app-feed.scss */
.card .card-body .story-block .story-meta span:nth-child(2),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .card-body
  .story-block
  .story-meta
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #757a91;
}

/* line 1539, src/assets/scss/pages/_app-feed.scss */
.options-summary {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  width: auto;
  color: #888da8;
  background: #f7f7f7;
  border-radius: 500px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1549, src/assets/scss/pages/_app-feed.scss */
.options-summary:hover {
  background: #f2f2f2;
}

/* line 1554, src/assets/scss/pages/_app-feed.scss */
.options-summary .tagged-friend + span small {
  display: none !important;
}

/* line 1558, src/assets/scss/pages/_app-feed.scss */
.options-summary span {
  padding: 2px;
}

/* line 1561, src/assets/scss/pages/_app-feed.scss */
.options-summary span a {
  font-size: 0.8rem;
}

/* line 1567, src/assets/scss/pages/_app-feed.scss */
.options-summary .mood-display {
  padding: 0 4px;
  cursor: pointer;
}

/* line 1571, src/assets/scss/pages/_app-feed.scss */
.options-summary .mood-display img {
  position: relative;
  top: 2px;
  height: 14px !important;
  width: 14px !important;
}

/* line 1578, src/assets/scss/pages/_app-feed.scss */
.options-summary .mood-display span {
  font-size: 0.8rem;
  color: #5596e6;
  margin: 0 4px;
}

/* line 1584, src/assets/scss/pages/_app-feed.scss */
.options-summary .mood-display .action-text {
  color: #393a4f;
  margin-right: 4px;
}

/* line 1596, src/assets/scss/pages/_app-feed.scss */
.tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
}

/* line 1604, src/assets/scss/pages/_app-feed.scss */
.tag-list.no-margin {
  margin: 0 !important;
}

/* line 1608, src/assets/scss/pages/_app-feed.scss */
.tag-list .tag-control {
  width: auto !important;
  padding-right: 6px;
  margin: 0 0 4px 0 !important;
}

/* line 1613, src/assets/scss/pages/_app-feed.scss */
.tag-list .tag-control.is-spaced {
  padding: 4px;
}

/* line 1617, src/assets/scss/pages/_app-feed.scss */
.tag-list .tag-control .is-link {
  background: #888da8 !important;
}

/* line 1627, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 1631, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete.is-active,
.is-autocomplete:hover {
  background: transparent !important;
}

/* line 1637, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete.is-dropup .easy-autocomplete-container {
  top: -260px !important;
  width: calc(100% - 40px);
  margin: 0 auto;
  right: 0;
}

/* line 1646, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control {
  margin: 0 !important;
  position: relative;
}

/* line 1651, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.has-margin {
  margin-top: 8px !important;
}

/* line 1657, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-location-wrapper input,
.is-autocomplete .control.is-gif-wrapper input {
  height: 36px;
  padding-left: 36px;
  font-size: 0.9rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1665, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-location-wrapper input:focus,
.is-autocomplete .control.is-gif-wrapper input:focus {
  border-color: #e8e8e8;
}

/* line 1668, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-location-wrapper input:focus + .icon,
.is-autocomplete .control.is-gif-wrapper input:focus + .icon {
  color: #3d70b2;
}

/* line 1676, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper {
  position: relative;
}

/* line 1681, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper input:focus ~ .gif-dropdown {
  opacity: 1;
  pointer-events: all;
}

/* line 1689, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper .gif-dropdown {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  height: 320px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  z-index: 40;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1706, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper .gif-dropdown::-webkit-scrollbar {
  width: 4px;
}

/* line 1710, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control.is-gif-wrapper
  .gif-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

/* line 1715, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper .gif-dropdown .inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 1721, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper .gif-dropdown .gif-block {
  display: block;
  margin: 0 auto;
  width: 33.3%;
  padding: 2px;
}

/* line 1727, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control.is-gif-wrapper .gif-dropdown .gif-block img {
  display: block;
  border-radius: 0 !important;
  width: 100%;
  height: auto;
  margin-bottom: 4px;
}

/* line 1739, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .icon,
.is-autocomplete .control .close-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #cecece;
}

/* line 1750, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .icon svg,
.is-autocomplete .control .close-icon svg {
  width: 16px;
  height: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1757, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .close-icon {
  left: unset !important;
  right: 0 !important;
  cursor: pointer;
}

/* line 1763, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .close-icon:hover svg {
  color: #393a4f;
}

/* line 1770, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .input-block {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  color: #fafafa;
  padding: 0 10px;
  height: 36px;
  width: auto;
  border-radius: 4px 0 0 4px;
  background: #757a91;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 1786, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .input-block:hover {
  background: #83889c;
}

/* line 1792, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete {
  width: 100% !important;
}

/* line 1796, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete input {
  height: 36px;
  padding-left: 36px;
  font-size: 0.9rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1805, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete input.is-subactivity {
  padding-left: 74px !important;
}

/* line 1809, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete input:focus {
  border-color: #e8e8e8;
}

/* line 1812, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete input:focus + .icon {
  color: #3d70b2;
}

/* line 1819, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1825, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .avatar-wrapper {
  position: relative;
  width: 36px;
  height: 36px;
}

/* line 1831, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .avatar-wrapper.is-smaller {
  width: 22px !important;
  height: 22px !important;
}

/* line 1835, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .avatar-wrapper.is-smaller
  .autocpl-avatar {
  width: 22px !important;
  height: 22px !important;
}

/* line 1841, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .avatar-wrapper
  .autocpl-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

/* line 1846, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .avatar-wrapper
  .autocpl-avatar.is-squared {
  border-radius: 8px !important;
}

/* line 1852, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .avatar-wrapper
  .avatar-badge {
  position: absolute;
  bottom: 0;
  right: -3px;
  width: 18px;
  height: 18px;
  border: 1.4px solid #fff;
  border-radius: 50%;
}

/* line 1864, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .icon-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

/* line 1869, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .icon-wrapper
  .autocpl-avatar {
  width: 22px;
  height: 22px;
  border-radius: 50% !important;
}

/* line 1877, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .entry-text {
  margin-left: 10px;
  line-height: 1.3;
}

/* line 1881, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete
  .control
  .easy-autocomplete
  .template-wrapper
  .entry-text
  span {
  font-size: 0.8rem;
  color: #757a91;
}

/* line 1888, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .next-icon {
  margin-left: auto !important;
}

/* line 1891, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .next-icon i {
  font-size: 20px;
  color: #888da8;
}

/* line 1897, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .is-description {
  font-size: 0.7rem;
}

/* line 1901, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .control .easy-autocomplete .template-wrapper .right-content {
  margin-left: auto;
  font-size: 0.75rem;
  color: #999;
}

/* line 1911, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete li {
  height: auto !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border-left: none !important;
  border-right: none !important;
}

/* line 1920, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .easy-autocomplete-container {
  top: 120% !important;
  border: none !important;
  z-index: 10 !important;
}

/* line 1925, src/assets/scss/pages/_app-feed.scss */
.is-autocomplete .easy-autocomplete-container ul {
  border-radius: 4px !important;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
}

/* line 1937, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu {
  padding-right: 30px;
}

/* line 1940, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1944, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li.is-active a {
  border-color: #5596e6;
}

/* line 1947, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li.is-active a span {
  color: #393a4f;
  font-weight: 500;
}

/* line 1952, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li.is-active a svg {
  stroke: #393a4f;
}

/* line 1956, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li.is-active a .close-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: #e8e8e8;
}

/* line 1960, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li.is-active a .close-icon svg {
  stroke: #393a4f;
}

/* line 1968, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li:hover .close-icon {
  background: #e8e8e8;
}

/* line 1971, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li:hover .close-icon svg {
  stroke: #393a4f;
}

/* line 1977, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
  border-left: 2px solid transparent;
}

/* line 1983, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li a span {
  display: block;
  color: #393a4f;
  font-size: 0.9rem;
  margin-left: 10px;
}

/* line 1990, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li a svg {
  position: relative;
  height: 18px;
  width: 18px;
  stroke: #757a91;
}

/* line 1999, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li a .close-icon {
  height: 28px;
  width: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2009, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .main-menu li a .close-icon svg {
  stroke: #cecece;
  height: 16px;
  width: 16px;
}

/* line 2019, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .submenu {
  padding-left: 50px;
  margin-bottom: 10px;
  display: none;
}

/* line 2026, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .submenu li.is-subactive a {
  color: #393a4f;
  font-weight: 500;
}

/* line 2032, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .submenu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px;
  font-size: 0.85rem;
  color: #757a91;
  border: none !important;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 2041, src/assets/scss/pages/_app-feed.scss */
.feed-menu-v1 .submenu li a .tag {
  margin-left: auto;
  display: inherit;
  max-height: 19px;
  font-size: 0.75rem;
  border-radius: 100px;
  margin-right: 10px;
  background: #5596e6;
  color: #fff !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 2061, src/assets/scss/pages/_app-feed.scss */
  .compose .compose-form img {
    height: 36px !important;
    width: 36px !important;
  }

  /* line 2068, src/assets/scss/pages/_app-feed.scss */
  .card .page-block img,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .page-block
    img,
  .card .add-friend-block img,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .add-friend-block
    img {
    height: 32px !important;
    width: 32px !important;
  }

  /* line 2075, src/assets/scss/pages/_app-feed.scss */
  .card .story-block .img-wrapper,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .story-block
    .img-wrapper {
    height: 40px !important;
    width: 40px !important;
    max-height: 40px !important;
    min-width: 40px !important;
  }

  /* line 2081, src/assets/scss/pages/_app-feed.scss */
  .card .story-block .img-wrapper img,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .story-block
    .img-wrapper
    img {
    height: 32px !important;
    width: 32px !important;
  }

  /* line 2087, src/assets/scss/pages/_app-feed.scss */
  .card .story-block .add-story,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .story-block
    .add-story {
    height: 38px !important;
    width: 38px !important;
    max-height: 38px !important;
    min-width: 38px !important;
  }

  /* line 2097, src/assets/scss/pages/_app-feed.scss */
  .list-placeload .img {
    height: 32px !important;
    width: 32px !important;
    min-width: 32px !important;
  }

  /* line 2105, src/assets/scss/pages/_app-feed.scss */
  .stories-placeload .img {
    height: 38px !important;
    width: 38px !important;
    max-height: 38px !important;
    min-width: 38px !important;
  }
}

/*! _posts-feed.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 18, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list {
  padding: 20px 0;
  max-width: 980px;
  margin: 0 auto;
}

/* line 23, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 26, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner .story {
  margin: 8px;
  width: calc(10% - 16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 32, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner .story .story-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  width: 60px;
  min-width: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1.6px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 45, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner .story .story-avatar:hover {
  border-color: #3d70b2;
}

/* line 49, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner .story .story-avatar .add-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba(85, 150, 230, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 62, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner .story .story-avatar .add-overlay svg {
  height: 15px;
  width: 15px;
  stroke-width: 3px;
  stroke: #fff;
}

/* line 70, src/assets/scss/pages/_app-posts-feed.scss */
.stories-list .container-inner .story .story-avatar img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

/* line 81, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper {
  padding: 0 0 20px 0;
}

/* line 84, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper.is-2x {
  max-width: 1040px;
  margin: 0 auto;
}

/* line 89, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item {
  border: none;
  margin-bottom: 0.75rem;
}

/* line 94, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-image img {
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 99, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content {
  padding: 0;
}

/* line 102, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media {
  padding: 10px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 106, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media .media-left {
  margin-right: 0.5rem;
}

/* line 109, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 44px;
  width: 44px;
  background: #fff;
  border-radius: 50%;
  border: 1.6px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 121, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar:hover {
  border-color: #3d70b2;
}

/* line 125, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar.is-smaller {
  height: 36px;
  width: 36px;
}

/* line 129, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar.is-smaller
  img {
  height: 28px;
  width: 28px;
  min-width: 28px;
}

/* line 136, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar
  .add-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 44px;
  width: 44px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba(85, 150, 230, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 149, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar
  .add-overlay
  svg {
  height: 15px;
  width: 15px;
  stroke-width: 3px;
  stroke: #fff;
}

/* line 157, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-left
  .post-avatar
  img {
  display: block;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
}

/* line 167, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media .media-content {
  line-height: 1.2;
}

/* line 172, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-content.is-smaller
  span:first-child {
  font-size: 0.9rem;
}

/* line 176, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-content.is-smaller
  span:nth-child(2) {
  font-size: 0.75rem;
}

/* line 182, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media .media-content span {
  display: block;
  font-family: "Roboto", sans-serif;
}

/* line 186, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-content
  span:first-child {
  font-weight: 500;
  color: #393a4f;
}

/* line 191, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-content
  span:nth-child(2) {
  font-size: 0.85rem;
  color: #abb0be;
}

/* line 198, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media .media-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 203, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media .media-right > span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
  color: #393a4f;
  margin-right: 12px;
}

/* line 211, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper .post-feed-item .card-content .media .media-right .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 0;
  margin-left: 2px;
  border-color: transparent;
}

/* line 223, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-right
  .button.is-liked
  svg {
  stroke: #f71416;
  fill: #f71416;
}

/* line 229, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-right
  .button:hover {
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 234, src/assets/scss/pages/_app-posts-feed.scss */
.posts-feed-wrapper
  .post-feed-item
  .card-content
  .media
  .media-right
  .button
  svg {
  height: 14px;
  width: 14px;
}

/* line 249, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 30px 0;
  max-width: 1280px;
  margin: 0 auto;
}

/* line 256, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper {
  width: 340px;
  padding: 0 40px;
}

/* line 260, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .profile-avatar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 265, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .profile-avatar-wrapper .button {
  width: 38px;
  height: 38px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border-color: transparent;
}

/* line 276, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .profile-avatar-wrapper
  .button:first-child
  svg {
  position: relative;
  left: -1px;
}

/* line 283, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .profile-avatar-wrapper
  .button:last-child
  svg {
  position: relative;
  right: -1px;
}

/* line 289, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .profile-avatar-wrapper .button:hover {
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 293, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .profile-avatar-wrapper
  .button:hover
  svg {
  stroke: #393a4f;
}

/* line 298, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .profile-avatar-wrapper .button svg {
  width: 18px;
  height: 18px;
  stroke: #abb0be;
  stroke-width: 3px;
}

/* line 306, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .profile-avatar-wrapper .avatar-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 74px;
  height: 74px;
  min-width: 74px;
  border: 1.4px solid #d8d8d8;
  border-radius: 50%;
  margin: 0 auto 6px auto;
}

/* line 318, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .profile-avatar-wrapper
  .avatar-wrap
  img {
  display: block;
  width: 58px;
  height: 58px;
  min-width: 58px;
  border-radius: 50%;
}

/* line 326, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .profile-avatar-wrapper
  .avatar-wrap
  .badge {
  position: absolute;
  bottom: 2px;
  right: -3px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 339, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .profile-avatar-wrapper
  .avatar-wrap
  .badge
  svg {
  height: 10px;
  width: 10px;
  stroke: #fcfcfc;
  stroke-width: 4px;
}

/* line 349, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
}

/* line 355, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-stats .stat-block {
  text-align: center;
}

/* line 358, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-stats .stat-block span {
  display: block;
}

/* line 361, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .user-stats
  .stat-block
  span:first-child {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  color: #393a4f;
}

/* line 368, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-stats .stat-block span:last-child {
  font-size: 0.7rem;
  color: #999;
}

/* line 376, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-bio {
  padding: 40px 0;
}

/* line 379, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-bio h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  color: #393a4f;
  margin-bottom: 8px;
}

/* line 387, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-bio p {
  font-family: "Roboto", sans-serif;
  font-size: 0.85rem;
  color: #abb0be;
  margin-bottom: 8px;
}

/* line 394, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .user-bio a {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
  color: #5596e6;
}

/* line 402, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .connections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 406, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .connections .connection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 8px;
  width: calc(33.3% - 16px);
}

/* line 413, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .connections
  .connection
  .connection-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  width: 60px;
  min-width: 40px;
  background: #fff;
  border-radius: 50%;
  border: 1.4px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 426, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .connections
  .connection
  .connection-avatar:hover {
  border-color: #3d70b2;
}

/* line 430, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper
  .profile-wrapper
  .connections
  .connection
  .connection-avatar
  img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

/* line 440, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .more-connections {
  text-align: center;
  padding: 20px 0;
}

/* line 444, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .profile-wrapper .more-connections a {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
  color: #5596e6;
}

/* line 453, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .posts-wrapper {
  width: calc(100% - 340px);
}

/* line 456, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .posts-wrapper .column {
  padding: 0.5rem;
}

/* line 459, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .posts-wrapper .column .profile-post {
  margin-bottom: 0;
}

/* line 462, src/assets/scss/pages/_app-posts-feed.scss */
.feed-profile-wrapper .posts-wrapper .column .profile-post img {
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 474, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper {
  position: relative;
  padding: 30px 0;
  max-width: 1280px;
  margin: 0 auto;
}

/* line 481, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-list {
  min-height: 500px;
  margin-top: 0;
}

/* line 486, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  color: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 999;
}

/* line 499, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom i {
  position: relative;
  font-size: 3.6rem;
  color: #abb0be;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 507, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom:hover i {
  color: #393a4f;
}

/* line 512, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom.is-prev {
  left: -30px;
}

/* line 515, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom.is-prev i {
  right: 2px;
}

/* line 520, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom.is-next {
  right: -30px;
}

/* line 523, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .slick-custom.is-next i {
  left: 2px;
}

/* line 529, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .feed-slider-item {
  border: none;
  margin-bottom: 0.75rem;
  margin: 60px 20px 20px 20px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.6;
  outline: none !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 538, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .feed-slider-item.slick-center {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  z-index: 9999;
}

/* line 545, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item.slick-center
  .card-content
  .media,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item.slick-center
  .card-content
  .liked-by,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item.slick-center
  .card-header
  .media,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item.slick-center
  .card-header
  .liked-by {
  opacity: 1;
  pointer-events: all;
}

/* line 553, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .feed-slider-item .card-image img {
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 558, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .feed-slider-item .card-content,
.feed-slider-wrapper .feed-slider-inner .feed-slider-item .card-header {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 562, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper .feed-slider-inner .feed-slider-item .card-content .media,
.feed-slider-wrapper .feed-slider-inner .feed-slider-item .card-header .media {
  width: 100%;
  padding: 10px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 571, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-left,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-left {
  margin-right: 0.5rem;
}

/* line 574, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-left
  .post-avatar,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-left
  .post-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 50%;
  border: 1.2px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 586, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-left
  .post-avatar:hover,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-left
  .post-avatar:hover {
  border-color: #3d70b2;
}

/* line 590, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-left
  .post-avatar
  img,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-left
  .post-avatar
  img {
  display: block;
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 50%;
}

/* line 600, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-content,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-content {
  line-height: 1.2;
}

/* line 603, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-content
  span,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-content
  span {
  display: block;
  font-family: "Roboto", sans-serif;
}

/* line 607, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-content
  span:first-child,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-content
  span:first-child {
  font-weight: 500;
  font-size: 0.75rem;
  color: #393a4f;
}

/* line 613, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-content
  span:nth-child(2),
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-content
  span:nth-child(2) {
  font-size: 0.55rem;
  color: #abb0be;
}

/* line 620, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-right,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 625, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-right
  > span,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-right
  > span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.65rem;
  color: #393a4f;
  margin-right: 12px;
}

/* line 633, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-right
  .button,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-right
  .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 0;
  margin-left: 2px;
  border-color: transparent;
}

/* line 645, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-right
  .button.is-liked
  svg,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-right
  .button.is-liked
  svg {
  stroke: #f71416;
  fill: #f71416;
}

/* line 651, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-right
  .button:hover,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-right
  .button:hover {
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 656, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .media
  .media-right
  .button
  svg,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .media
  .media-right
  .button
  svg {
  height: 10px;
  width: 10px;
}

/* line 664, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .liked-by,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .liked-by {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 672, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .liked-by
  img,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .liked-by
  img {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

/* line 679, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .liked-by
  > span,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .liked-by
  > span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 0.6rem;
  margin-left: 8px;
  color: #abb0be;
}

/* line 686, src/assets/scss/pages/_app-posts-feed.scss */
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-content
  .liked-by
  > span
  span,
.feed-slider-wrapper
  .feed-slider-inner
  .feed-slider-item
  .card-header
  .liked-by
  > span
  span {
  color: #393a4f;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  /* line 702, src/assets/scss/pages/_app-posts-feed.scss */
  .stories-list {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  /* line 707, src/assets/scss/pages/_app-posts-feed.scss */
  .stories-list .container-inner {
    overflow-x: auto;
  }

  /* line 710, src/assets/scss/pages/_app-posts-feed.scss */
  .stories-list .container-inner .story {
    min-width: 60px;
  }

  /* line 721, src/assets/scss/pages/_app-posts-feed.scss */
  .posts-feed-wrapper .post-feed-item .card-content .media .media-right > span {
    display: none;
  }

  /* line 730, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-profile-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 733, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-profile-wrapper .profile-wrapper,
  .feed-profile-wrapper .posts-wrapper {
    width: 100%;
  }

  /* line 742, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 745, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media
    .media-left {
    margin: 0 0 10px 0;
  }

  /* line 749, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media
    .media-content {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* line 764, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 767, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media
    .media-left {
    margin: 0 0 10px 0;
  }

  /* line 771, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media
    .media-content {
    text-align: center;
  }

  /* line 778, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* line 787, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-profile-wrapper .profile-wrapper {
    width: 280px;
    padding: 0 30px;
  }

  /* line 792, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-profile-wrapper .posts-wrapper {
    width: calc(100% - 280px);
  }

  /* line 804, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper
    .feed-slider-inner
    .feed-slider-item
    .card-header
    .media
    .media-right
    > span {
    display: none;
  }

  /* line 812, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom {
    top: 42%;
  }

  /* line 815, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom i {
    position: relative;
    font-size: 3rem;
    color: #abb0be;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }

  /* line 823, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom:hover i {
    color: #393a4f;
  }

  /* line 828, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom.is-prev {
    left: -10px;
  }

  /* line 831, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom.is-prev i {
    right: 2px;
  }

  /* line 836, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom.is-next {
    right: -10px;
  }

  /* line 839, src/assets/scss/pages/_app-posts-feed.scss */
  .feed-slider-wrapper .feed-slider-inner .slick-custom.is-next i {
    left: 2px;
  }
}

/*! _app-inbox.scss | Friendkit | © Css Ninja. 2019-2020 */ /*! _special-mixins.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 21, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper .inbox-wrapper-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

/* line 26, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper .inbox-wrapper-inner .inbox-left-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 16%;
  background: #f4f4f4 !important;
  border-right: 1px solid #dbdbdb;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 37, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner {
  width: 100%;
  height: 100%;
}

/* line 41, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .compose {
  padding: 0 20px;
  min-height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 47, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .compose
  .button {
  height: 40px;
}

/* line 52, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .left-menu {
  padding: 10px 0;
}

/* line 55, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .left-menu
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px;
  padding: 10px;
  color: #aaa;
  font-size: 0.95rem;
  border-radius: 4px;
}

/* line 65, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .left-menu
  a.is-active {
  background-color: #5596e6;
  color: #fafafa;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 71, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .left-menu
  a
  span {
  display: block;
  margin-left: 10px;
}

/* line 76, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .left-menu
  a
  svg {
  height: 18px;
  width: 18px;
}

/* line 83, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .close-menu {
  padding: 20px 50px;
  text-align: center;
}

/* line 87, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .close-menu
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
  cursor: pointer;
}

/* line 93, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-left-sidebar
  .inbox-left-sidebar-inner
  .close-menu
  a
  svg {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

/* line 103, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper .inbox-wrapper-inner .inbox-center-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 112, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper .inbox-wrapper-inner .inbox-center-container.is-left-opened {
  width: calc(100% - 280px);
  left: 280px;
}

/* line 117, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper .inbox-wrapper-inner .inbox-center-container.is-both-opened {
  width: 34%;
  left: 16%;
  right: 50%;
}

/* line 123, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

/* line 128, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .messages {
  display: block;
  background-color: #fff;
}

/* line 132, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .messages
  .action-buttons {
  min-height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 139, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .messages
  .action-buttons
  .button {
  max-height: 32px;
  padding: 4px 8px;
}

/* line 143, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .messages
  .action-buttons
  .button
  svg {
  height: 16px;
  width: 16px;
  stroke: #888da8;
}

/* line 152, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .message-count {
  font-size: 0.9rem;
  color: #999;
  margin-left: 12px;
}

/* line 158, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 60px;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  overflow-y: auto;
}

/* line 167, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner {
  padding: 20px;
}

/* line 170, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .message {
  display: block;
  background-color: #fff;
}

/* line 175, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card {
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 180, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card.is-active,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-active.summary-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .is-active.summary-card {
  background-color: #fcfcfc;
  -webkit-box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.11) !important;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.11) !important;
}

/* line 184, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card.is-active
  .msg-from
  a,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-active.summary-card
  .msg-from
  a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .is-active.summary-card
  .msg-from
  a {
  color: #5596e6;
}

/* line 188, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card.is-active
  .msg-attachment
  svg,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .is-active.summary-card
  .msg-attachment
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .is-active.summary-card
  .msg-attachment
  svg {
  stroke: #5596e6;
}

/* line 195, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card.has-attachment
  .msg-attachment
  svg,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .has-attachment.summary-card
  .msg-attachment
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .has-attachment.summary-card
  .msg-attachment
  svg {
  display: block;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 202, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-from,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-from,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-from {
  color: #999;
}

/* line 205, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-from
  a,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-from
  a,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-from
  a {
  font-weight: 500;
  color: #999;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 212, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-timestamp,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-timestamp,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-timestamp {
  float: right;
  padding: 0 20px;
  color: #999;
  font-size: 0.9rem;
}

/* line 219, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-attachment,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-attachment,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-attachment {
  float: right;
}

/* line 222, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-attachment
  svg,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-attachment
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-attachment
  svg {
  display: none;
  height: 16px;
  width: 16px;
  stroke: #999;
}

/* line 230, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-subject,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-subject,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-subject {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

/* line 235, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-subject
  span,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-subject
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-subject
  span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #393a4f;
  margin: 0 4px;
}

/* line 244, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-subject
  svg,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-subject
  svg,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-subject
  svg {
  position: relative;
  top: -1px;
  height: 16px;
  width: 16px;
  stroke: #999;
  fill: none;
  cursor: pointer;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 255, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-subject
  svg.is-active,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-subject
  svg.is-active,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-subject
  svg.is-active {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  stroke: #3d70b2;
  fill: #3d70b2;
}

/* line 263, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-snippet,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-snippet,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-snippet {
  color: #999;
  font-size: 0.9rem;
  padding-right: 20px;
}

/* line 268, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-snippet
  p,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-snippet
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-snippet
  p {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 2.4em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}

/* line 26, src/assets/scss/abstracts/_mixins.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-snippet
  p:before,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-snippet
  p:before,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-snippet
  p:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}

/* line 33, src/assets/scss/abstracts/_mixins.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .card
  .msg-snippet
  p:after,
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .msg-snippet
  p:after,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .summary-card
  .msg-snippet
  p:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: #fff;
}

/* line 274, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  strong {
  color: #5d5d5d;
}

/* line 278, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-center-container
  .inbox-center-container-inner
  .inbox-messages
  .inbox-messages-inner
  .msg-check {
  padding: 0 20px;
}

/* line 286, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper .inbox-wrapper-inner .inbox-message-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-left: 1px solid #dbdbdb;
}

/* line 296, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 302, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-head
  .action-buttons {
  min-height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 309, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-head
  .action-buttons
  .button {
  max-height: 32px;
  padding: 4px 8px;
}

/* line 313, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-head
  .action-buttons
  .button
  svg {
  height: 16px;
  width: 16px;
  stroke: #888da8;
}

/* line 322, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 60px;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  width: 100%;
  overflow-y: auto;
  border-left: none;
  padding: 1.25em !important;
}

/* line 334, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

/* line 343, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner.is-active {
  display: block;
}

/* line 348, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview-transition.is-first {
  margin-top: -36px !important;
}

/* line 352, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview-transition
  .mail {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 60px auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #ccc;
}

/* line 364, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview-transition
  .mail:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  height: 1px;
  width: 40px;
  background: #888da8;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 376, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview-transition
  .mail:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  height: 1px;
  width: 40px;
  background: #888da8;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 388, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview-transition
  .mail
  svg {
  width: 20px;
  height: 20px;
  stroke: #888da8;
}

/* line 396, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview {
  margin: 0 0 20px 0;
  padding: 30px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #e8e8e8;
}

/* line 402, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 407, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .avatar
  img {
  width: 48px;
  border-radius: 50px;
  border: 2px solid #999;
  padding: 2px;
}

/* line 416, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 420, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta {
  display: block;
  padding: 0 20px;
}

/* line 424, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta
  .name {
  font-weight: 500;
  color: #393a4f;
}

/* line 429, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta
  .date {
  font-size: 0.9rem;
  color: #999;
}

/* line 435, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta-right {
  text-align: right;
  margin-left: auto;
}

/* line 439, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta-right
  .tag {
  font-weight: 500;
  background: #f7f7f7;
  color: #393a4f;
}

/* line 445, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta-right
  svg {
  position: relative;
  top: 2px;
  height: 16px;
  width: 16px;
  stroke: #cecece;
}

/* line 453, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .message-preview
  .box-inner
  .header
  .meta-right
  small {
  color: #999;
  font-size: 0.95rem;
}

/* line 462, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments {
  padding-bottom: 40px;
}

/* line 466, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-header
  .paperclip {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 60px auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #ccc;
}

/* line 478, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-header
  .paperclip:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  height: 1px;
  width: 40px;
  background: #888da8;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 490, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-header
  .paperclip:after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  height: 1px;
  width: 40px;
  background: #888da8;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 502, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-header
  .paperclip
  svg {
  width: 20px;
  height: 20px;
  stroke: #888da8;
}

/* line 510, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 380px;
  margin: 0 auto;
}

/* line 516, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box {
  width: calc(50% - 20px);
  margin: 0 10px 16px 10px;
  padding: 0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

/* line 524, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 528, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .box-inner {
  text-align: center;
  padding: 22px 12px;
}

/* line 532, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .box-inner
  svg {
  stroke-width: 1.2px;
}

/* line 536, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .box-inner
  h5 {
  font-weight: 500;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* line 544, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .actions-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e8e8e8;
}

/* line 548, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .actions-group
  .action {
  width: 50%;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 556, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .actions-group
  .action:first-child {
  border-right: 1px solid #e8e8e8;
}

/* line 560, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .actions-group
  .action:hover {
  background: #fafafa;
}

/* line 563, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .actions-group
  .action:hover
  svg {
  stroke: #393a4f;
}

/* line 568, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box.has-file
  .actions-group
  .action
  svg {
  height: 18px;
  width: 18px;
  stroke: #888da8;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 578, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .attachments
  .attachments-inner
  .box
  img {
  display: block;
  border-radius: 2px;
}

/* line 586, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper {
  padding: 40px;
}

/* line 589, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-title {
  font-size: 1.1rem;
  color: #393a4f;
  font-weight: 500;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 597, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-title
  svg {
  height: 18px;
  width: 18px;
  stroke: #999;
  margin-left: 8px;
  cursor: pointer;
}

/* line 606, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 30px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 613, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .flex-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 617, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  img {
  margin-top: 2px;
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

/* line 624, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .control {
  position: relative;
  width: 100%;
  margin-left: 10px;
}

/* line 629, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .control
  .textarea {
  border: none !important;
  margin-bottom: 20px;
}

/* line 634, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .control
  .reply-textarea {
  margin-bottom: 20px;
  min-height: 120px;
}

/* line 638, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .control
  .reply-textarea
  p {
  font-style: normal;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
}

/* line 645, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .control
  .ql-editor::before {
  font-style: normal;
  font-size: 1rem;
  color: #cecece;
}

/* line 652, src/assets/scss/pages/_app-inbox.scss */
.inbox-wrapper
  .inbox-wrapper-inner
  .inbox-message-container
  .inbox-message-container-inner
  .message-body
  .message-body-inner
  .reply-wrapper
  .reply-wrapper-inner
  .send-message {
  line-height: 0;
}

/* line 670, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped {
  display: inline-block;
  margin-right: 16px;
}

/* line 674, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped:last-child {
  margin-right: 0;
}

/* line 678, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped .button {
  margin-right: -5px;
  border-radius: 0;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 684, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped .button:first-child {
  border-radius: 5px 0 0 5px;
}

/* line 688, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped .button:last-child {
  border-radius: 0 5px 5px 0;
}

/* line 692, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped .button:hover {
  background: #f5f5f5;
}

/* line 696, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped .button.is-solo {
  border-radius: 5px !important;
}

/* line 700, src/assets/scss/pages/_app-inbox.scss */
.action-buttons .control.is-grouped .button svg {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 1300px) {
  /* line 714, src/assets/scss/pages/_app-inbox.scss */
  .inbox-wrapper .message-count {
    display: none;
  }
}

@media (max-width: 767px) {
  /* line 724, src/assets/scss/pages/_app-inbox.scss */
  .inbox-wrapper .action-buttons .is-grouped {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  /* line 733, src/assets/scss/pages/_app-inbox.scss */
  .inbox-wrapper
    .inbox-message-container
    .action-buttons
    .is-grouped.is-hidden-landscape {
    margin-left: auto !important;
  }

  /* line 743, src/assets/scss/pages/_app-inbox.scss */
  .inbox-wrapper
    .inbox-center-container
    .action-buttons
    .is-grouped.is-hidden-landscape {
    margin-right: auto !important;
  }

  /* line 753, src/assets/scss/pages/_app-inbox.scss */
  .inbox-left-sidebar .inbox-left-sidebar-inner .close-menu {
    padding: 0 30px !important;
  }

  /* line 759, src/assets/scss/pages/_app-inbox.scss */
  .is-vhidden {
    display: none !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 765, src/assets/scss/pages/_app-inbox.scss */
  .inbox-left-sidebar {
    width: 21% !important;
  }

  /* line 769, src/assets/scss/pages/_app-inbox.scss */
  .inbox-message-container {
    width: 45% !important;
  }

  /* line 773, src/assets/scss/pages/_app-inbox.scss */
  .inbox-message-container .inbox-message-container-inner .message-body {
    padding: 1.25em !important;
  }

  /* line 776, src/assets/scss/pages/_app-inbox.scss */
  .inbox-message-container
    .inbox-message-container-inner
    .message-body
    .message-preview {
    padding: 20px !important;
  }

  /* line 783, src/assets/scss/pages/_app-inbox.scss */
  .inbox-center-container {
    left: 21% !important;
  }

  /* line 788, src/assets/scss/pages/_app-inbox.scss */
  .inbox-center-container .action-buttons .is-grouped.is-arrows {
    display: none !important;
  }
}

/*! _app-profile.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 26, src/assets/scss/pages/_app-profile.scss */
.cover-bg {
  position: relative;
}

/* line 30, src/assets/scss/pages/_app-profile.scss */
.cover-bg:hover .cover-overlay {
  background: rgba(57, 58, 79, 0.5);
}

/* line 34, src/assets/scss/pages/_app-profile.scss */
.cover-bg:hover .cover-edit {
  border-color: #fcfcfc;
  background: rgba(252, 252, 252, 0.1);
}

/* line 38, src/assets/scss/pages/_app-profile.scss */
.cover-bg:hover .cover-edit i {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 42, src/assets/scss/pages/_app-profile.scss */
.cover-bg:hover .cover-edit span {
  opacity: 1;
}

/* line 48, src/assets/scss/pages/_app-profile.scss */
.cover-bg .cover-image {
  display: block;
  border-radius: 3px;
  -o-object-position: top;
  object-position: top;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-height: 328px;
}

/* line 57, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  z-index: 1;
}

/* line 71, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .avatar-image {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
  z-index: 1;
}

/* line 81, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .avatar-button {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #3d70b2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  z-index: 1;
}

/* line 98, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .avatar-button:hover {
  background: #4c7fc2;
}

/* line 102, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .avatar-button.is-active {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* line 106, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .avatar-button svg {
  height: 18px;
  width: 18px;
  stroke: #fcfcfc;
}

/* line 113, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  z-index: 0;
  -webkit-transform: translate(0, 10px) rotate(260deg);
  transform: translate(0, 10px) rotate(260deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 129, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button .inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 139, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.pop-shift .inactive-icon {
  display: block;
}

/* line 143, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.pop-shift .active-icon {
  display: none;
}

/* line 148, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.pop-shift.is-shifted .inactive-icon {
  display: none;
}

/* line 152, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.pop-shift.is-shifted .active-icon {
  display: block;
}

/* line 158, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-center,
.cover-bg .avatar .pop-button.is-far-right,
.cover-bg .avatar .pop-button.is-left,
.cover-bg .avatar .pop-button.is-far-left {
  background: #fff;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 162, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-center:hover,
.cover-bg .avatar .pop-button.is-far-right:hover,
.cover-bg .avatar .pop-button.is-left:hover,
.cover-bg .avatar .pop-button.is-far-left:hover {
  background: #5596e6;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 166, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-center:hover svg,
.cover-bg .avatar .pop-button.is-far-right:hover svg,
.cover-bg .avatar .pop-button.is-left:hover svg,
.cover-bg .avatar .pop-button.is-far-left:hover svg {
  stroke: #fafafa;
}

/* line 171, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-center svg,
.cover-bg .avatar .pop-button.is-far-right svg,
.cover-bg .avatar .pop-button.is-left svg,
.cover-bg .avatar .pop-button.is-far-left svg {
  stroke: #757a91;
}

/* line 176, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-right {
  background: #3d70b2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 180, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-right svg {
  stroke: #fafafa;
}

/* line 186, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-active.is-far-left {
  -webkit-transform: translate(-240%, 30%) rotate(0);
  transform: translate(-240%, 30%) rotate(0);
}

/* line 190, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-active.is-left {
  -webkit-transform: translate(-160%, -110%) rotate(0);
  transform: translate(-160%, -110%) rotate(0);
}

/* line 194, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-active.is-center {
  -webkit-transform: translate(0, -170%) rotate(0);
  transform: translate(0, -170%) rotate(0);
}

/* line 198, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-active.is-right {
  -webkit-transform: translate(160%, -110%) rotate(0);
  transform: translate(160%, -110%) rotate(0);
}

/* line 202, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button.is-active.is-far-right {
  -webkit-transform: translate(240%, 30%) rotate(0);
  transform: translate(240%, 30%) rotate(0);
}

/* line 207, src/assets/scss/pages/_app-profile.scss */
.cover-bg .avatar .pop-button svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 216, src/assets/scss/pages/_app-profile.scss */
.cover-bg .cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  background: rgba(57, 58, 79, 0.18);
  z-index: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 228, src/assets/scss/pages/_app-profile.scss */
.cover-bg .cover-edit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px;
  padding: 2px 8px;
  border: 1.4px solid transparent;
  border-radius: 3px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 243, src/assets/scss/pages/_app-profile.scss */
.cover-bg .cover-edit i {
  font-size: 20px;
  color: #fcfcfc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/* line 250, src/assets/scss/pages/_app-profile.scss */
.cover-bg .cover-edit span {
  opacity: 0;
  display: block;
  color: #fcfcfc;
  font-weight: 400;
  font-size: 0.85rem;
  margin: 0 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 261, src/assets/scss/pages/_app-profile.scss */
.cover-bg .timeline-mobile-dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* line 266, src/assets/scss/pages/_app-profile.scss */
.cover-bg .timeline-mobile-dropdown div > .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: transparent;
  border-radius: 50%;
  border: 1.4px solid #fff;
  padding: 0;
}

/* line 277, src/assets/scss/pages/_app-profile.scss */
.cover-bg .timeline-mobile-dropdown div > .button svg {
  height: 20px;
  width: 20px;
  stroke: #fff;
}

/* line 290, src/assets/scss/pages/_app-profile.scss */
.profile-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0 0 0;
}

/* line 296, src/assets/scss/pages/_app-profile.scss */
.profile-menu .button {
  font-size: 0.9rem;
  padding: 20px 22px;
  background: #fcfcfc;
  color: #999;
}

/* line 302, src/assets/scss/pages/_app-profile.scss */
.profile-menu .button.is-active {
  background: #5596e6;
  border-color: #5596e6;
  color: #fcfcfc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 309, src/assets/scss/pages/_app-profile.scss */
.profile-menu .button.has-min-width {
  width: 140px;
}

/* line 316, src/assets/scss/pages/_app-profile.scss */
.profile-menu .dropdown > .button svg {
  height: 20px;
  width: 20px;
}

/* line 324, src/assets/scss/pages/_app-profile.scss */
.profile-subheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

/* line 330, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-start,
.profile-subheader .subheader-end {
  width: 25%;
}

/* line 335, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-start svg {
  height: 18px;
  width: 18px;
  stroke: #5596e6;
  display: none;
}

/* line 342, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-start span {
  display: block;
  font-weight: 500;
}

/* line 346, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-start span:first-child {
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  line-height: 1.2;
}

/* line 354, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-start span:nth-child(2) {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  color: #999;
}

/* line 363, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-end {
  text-align: right;
}

/* line 367, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-middle {
  text-align: center;
}

/* line 370, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-middle h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #393a4f;
  line-height: 1.2;
}

/* line 377, src/assets/scss/pages/_app-profile.scss */
.profile-subheader .subheader-middle span {
  display: block;
  font-size: 0.9rem;
  color: #999;
}

/* line 389, src/assets/scss/pages/_app-profile.scss */
.box-heading {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 399, src/assets/scss/pages/_app-profile.scss */
.box-heading h4 {
  font-weight: 500;
  padding: 0 6px;
}

/* line 404, src/assets/scss/pages/_app-profile.scss */
.box-heading .heading-search {
  position: relative;
  margin-left: 10px;
}

/* line 408, src/assets/scss/pages/_app-profile.scss */
.box-heading .heading-search input {
  padding-left: 38px;
}

/* line 413, src/assets/scss/pages/_app-profile.scss */
.box-heading .heading-search input:focus + .search-icon svg {
  stroke: #5596e6;
}

/* line 420, src/assets/scss/pages/_app-profile.scss */
.box-heading .heading-search .search-icon {
  position: absolute;
  top: 0;
  left: 2px;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 430, src/assets/scss/pages/_app-profile.scss */
.box-heading .heading-search .search-icon svg {
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 443, src/assets/scss/pages/_app-profile.scss */
.box-heading .dropdown.photos-dropdown.is-active div > .button svg,
.box-heading .dropdown.friends-dropdown.is-active div > .button svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 449, src/assets/scss/pages/_app-profile.scss */
.box-heading .dropdown.photos-dropdown div > .button,
.box-heading .dropdown.friends-dropdown div > .button {
  width: auto !important;
  padding: 0 8px 0 12px;
  height: 34px;
  min-width: 110px;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 100px;
}

/* line 460, src/assets/scss/pages/_app-profile.scss */
.box-heading .dropdown.photos-dropdown div > .button svg,
.box-heading .dropdown.friends-dropdown div > .button svg {
  height: 16px;
  width: 16px;
  stroke: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 468, src/assets/scss/pages/_app-profile.scss */
.box-heading .dropdown.photos-dropdown .dropdown-menu,
.box-heading .dropdown.friends-dropdown .dropdown-menu {
  margin-top: 6px;
}

/* line 473, src/assets/scss/pages/_app-profile.scss */
.box-heading .dropdown .button {
  width: 38px !important;
  height: 38px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: transparent;
}

/* line 482, src/assets/scss/pages/_app-profile.scss */
.box-heading .dropdown .button svg {
  height: 20px;
  width: 20px;
  stroke: #888da8;
}

/* line 491, src/assets/scss/pages/_app-profile.scss */
.box-heading .button-wrap .button {
  line-height: 0;
  width: 90px;
  font-weight: 500;
}

/* line 496, src/assets/scss/pages/_app-profile.scss */
.box-heading .button-wrap .button.is-active {
  color: #5596e6;
  border-color: #5596e6;
}

/* line 508, src/assets/scss/pages/_app-profile.scss */
.profile-timeline {
  padding: 10px 0;
}

/* line 511, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 30px;
}

/* line 518, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post.is-simple .post-actions {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

/* line 523, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post .time {
  padding-right: 30px;
  position: relative;
}

/* line 527, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post .time .img-container {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 536, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post .time .img-container:after {
  content: "";
  position: absolute;
  top: 60px;
  left: 24px;
  width: 1.4px;
  height: calc(100% - 30px);
  background: #e5e5e5;
}

/* line 546, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post .time .img-container img {
  display: block;
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
}

/* line 556, src/assets/scss/pages/_app-profile.scss */
.profile-timeline .profile-post .is-post {
  margin-bottom: 0;
  width: 100%;
}

/* line 563, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper {
  padding-top: 10px;
}

/* line 567, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-profile-info .info-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 16px;
}

/* line 573, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-profile-info .info-row:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

/* line 577, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-profile-info .info-row span,
.basic-infos-wrapper .is-profile-info .info-row a {
  display: block;
}

/* line 581, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-profile-info .info-row span {
  font-size: 0.85rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 587, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-profile-info .info-row a {
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
}

/* line 593, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-profile-info .info-row i {
  font-size: 20px;
  color: #cecece;
  margin: 0 4px;
}

/* line 601, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community,
.basic-infos-wrapper .is-about {
  padding: 16px;
}

/* line 604, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community h4,
.basic-infos-wrapper .is-about h4 {
  margin-bottom: 16px;
  color: #999;
}

/* line 609, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community .flex-block,
.basic-infos-wrapper .is-about .flex-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 16px 0;
}

/* line 614, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community .flex-block a,
.basic-infos-wrapper .is-about .flex-block a {
  color: #393a4f;
  font-weight: 500;
}

/* line 618, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community .flex-block a:hover,
.basic-infos-wrapper .is-about .flex-block a:hover {
  color: #5596e6;
}

/* line 623, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community .flex-block p,
.basic-infos-wrapper .is-about .flex-block p {
  font-size: 0.8rem;
  margin-left: 12px;
}

/* line 628, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-community .flex-block svg,
.basic-infos-wrapper .is-about .flex-block svg {
  height: 16px;
  width: 16px;
}

/* line 635, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit {
  padding: 16px;
}

/* line 638, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit .title-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

/* line 643, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit .title-wrap img {
  display: block;
  max-width: 30px;
}

/* line 648, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit .title-wrap h4 {
  color: #999;
}

/* line 653, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit p {
  font-size: 0.8rem;
}

/* line 657, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit .created {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}

/* line 662, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit .created svg {
  height: 16px;
  width: 16px;
  stroke: #999;
  margin-right: 8px;
}

/* line 669, src/assets/scss/pages/_app-profile.scss */
.basic-infos-wrapper .is-friendkit .created span {
  display: block;
  color: #999;
  font-size: 0.8rem;
}

/* line 678, src/assets/scss/pages/_app-profile.scss */
.is-photos-widget {
  padding: 10px 0 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 685, src/assets/scss/pages/_app-profile.scss */
.is-photos-widget img {
  display: block;
  width: calc(25% - 6px);
  margin-bottom: 12px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 693, src/assets/scss/pages/_app-profile.scss */
.is-photos-widget img:hover {
  -webkit-box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
}

/* line 699, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget {
  padding: 10px 0 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 706, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container {
  position: relative;
  display: block;
  width: calc(33% - 6px);
  min-height: 60px;
  margin-bottom: 12px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 716, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container:hover img {
  -webkit-box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.18) !important;
}

/* line 721, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 730, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: rgba(52, 66, 88, 0.6);
}

/* line 740, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container .video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 34px;
  width: 34px;
  background: #3d70b2;
  border-radius: 50%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

/* line 757, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container .video-button img {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  left: 1px;
  z-index: 3;
}

/* line 767, src/assets/scss/pages/_app-profile.scss */
.is-videos-widget .video-container .video-button:after {
  content: "";
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #3d70b2;
  -webkit-animation: wave 1.6s infinite;
  animation: wave 1.6s infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  z-index: 0;
}

/* line 785, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list {
  padding-top: 10px;
}

/* line 789, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item {
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 794, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

/* line 798, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item img {
  display: block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

/* line 805, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .text-content {
  margin: 0 10px;
}

/* line 808, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .text-content span,
.friend-cards-list .is-friend-card .friend-item .text-content a {
  display: block;
}

/* line 813, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .text-content a:first-child {
  font-weight: 500;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 819, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .text-content a:nth-child(3) {
  font-size: 0.8rem;
  font-weight: 500;
  color: #5596e6;
}

/* line 826, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .text-content span {
  font-size: 0.75rem;
  color: #999;
}

/* line 832, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .star-friend {
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

/* line 842, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .star-friend.is-active svg {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  stroke: #3d70b2;
  fill: #3d70b2;
}

/* line 849, src/assets/scss/pages/_app-profile.scss */
.friend-cards-list .is-friend-card .friend-item .star-friend svg {
  height: 20px;
  width: 20px;
  stroke: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

/* line 861, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list {
  padding: 10px 0 0 0;
}

/* line 865, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item {
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 870, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

/* line 874, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item img {
  display: block;
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

/* line 881, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item .text-content {
  margin: 0 10px;
}

/* line 884, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item .text-content span,
.trip-cards-list .is-trip-card .trip-item .text-content a {
  display: block;
}

/* line 889, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item .text-content a:first-child {
  font-weight: 500;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 895, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item .text-content a:nth-child(3) {
  font-size: 0.8rem;
  font-weight: 500;
  color: #5596e6;
}

/* line 902, src/assets/scss/pages/_app-profile.scss */
.trip-cards-list .is-trip-card .trip-item .text-content span {
  font-size: 0.85rem;
  color: #999;
}

/* line 915, src/assets/scss/pages/_app-profile.scss */
.profile-about {
  width: 100%;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 920, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu {
  min-width: 260px;
}

/* line 923, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .left-menu-inner {
  width: 100%;
  border-radius: 6px 0 0 6px;
}

/* line 928, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

/* line 936, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .menu-item.is-active .menu-icon {
  background: #5596e6;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 940, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .menu-item.is-active .menu-icon i,
.profile-about .left-menu .menu-item.is-active .menu-icon span {
  color: #fcfcfc;
}

/* line 946, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .menu-item .menu-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  width: 100%;
  padding: 0 12px;
  border-radius: 6px;
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 957, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .menu-item i {
  font-size: 1.4rem;
  color: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 963, src/assets/scss/pages/_app-profile.scss */
.profile-about .left-menu .menu-item span {
  margin: 0 8px;
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #cecece;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 974, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content {
  padding-left: 30px;
  width: calc(100% - 260px);
  min-height: 420px;
}

/* line 979, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section {
  display: none;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

/* line 983, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section.is-active {
  display: block;
}

/* line 987, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section .flex-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  background: #fff;
}

/* line 1002, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block:hover
  .flex-block-meta
  .action-link {
  opacity: 1;
  pointer-events: all;
}

/* line 1006, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block:hover
  .flex-block-meta
  .action-link:hover {
  color: #757a91;
}

/* line 1012, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section .flex-block:hover .go-button {
  -webkit-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  opacity: 1;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 1017, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block:hover
  .go-button:hover {
  opacity: 0.9;
}

/* line 1023, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section .flex-block img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 1030, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section .flex-block .flex-block-meta {
  margin-left: 10px;
}

/* line 1033, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block
  .flex-block-meta
  span,
.profile-about
  .right-content
  .content-section
  .flex-block
  .flex-block-meta
  .action-link {
  display: block;
}

/* line 1037, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block
  .flex-block-meta
  span {
  color: #999;
  font-size: 0.9rem;
}

/* line 1041, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block
  .flex-block-meta
  span
  a {
  color: #393a4f;
  font-size: 0.85rem;
  font-weight: 500;
}

/* line 1048, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .right-content
  .content-section
  .flex-block
  .flex-block-meta
  .action-link {
  font-size: 0.75rem;
  color: #cecece;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1056, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section .flex-block .go-button {
  margin-left: auto;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background: #5596e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0) rotate(0);
  transform: scale(0) rotate(0);
  opacity: 0;
}

/* line 1069, src/assets/scss/pages/_app-profile.scss */
.profile-about .right-content .content-section .flex-block .go-button svg {
  height: 18px;
  width: 18px;
  stroke: #fcfcfc;
}

/* line 1079, src/assets/scss/pages/_app-profile.scss */
.profile-about .about-summary {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 30px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  background: #fff;
}

/* line 1088, src/assets/scss/pages/_app-profile.scss */
.profile-about .about-summary h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
}

/* line 1093, src/assets/scss/pages/_app-profile.scss */
.profile-about .about-summary p {
  font-size: 0.95rem;
  color: #757a91;
}

/* line 1099, src/assets/scss/pages/_app-profile.scss */
.profile-about .glider-dots {
  padding: 20px 0;
}

/* line 1102, src/assets/scss/pages/_app-profile.scss */
.profile-about .glider-dots .glider-dot {
  width: 8px;
  height: 8px;
  margin: 4px;
}

/* line 1107, src/assets/scss/pages/_app-profile.scss */
.profile-about .glider-dots .glider-dot.active {
  background: #5596e6 !important;
}

/* line 1114, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline {
  position: relative;
}

/* line 1117, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #dedede;
  margin-right: 1rem;
}

/* line 1125, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item .image-container {
  position: relative;
}

/* line 1128, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item .image-container > img {
  display: block;
  min-height: 160px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 2px;
}

/* line 1135, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .slider-timeline
  .timeline-item
  .image-container
  .logo-container {
  position: absolute;
  bottom: -30px;
  right: 10px;
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
}

/* line 1147, src/assets/scss/pages/_app-profile.scss */
.profile-about
  .slider-timeline
  .timeline-item
  .image-container
  .logo-container
  img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* line 1156, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item h3 {
  margin-top: 12px;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

/* line 1163, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item > p {
  font-size: 0.8rem;
  color: #999;
}

/* line 1168, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item .more {
  margin-top: 6px;
}

/* line 1171, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item .more p {
  font-size: 0.9rem;
  color: #595b7b;
}

/* line 1177, src/assets/scss/pages/_app-profile.scss */
.profile-about .slider-timeline .timeline-item .date {
  margin-top: 6px;
  font-size: 0.8rem;
  color: #999;
}

/* line 1187, src/assets/scss/pages/_app-profile.scss */
.friends-columns .column {
  padding: 0.5rem;
}

/* line 1196, src/assets/scss/pages/_app-profile.scss */
.about-card {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #dedede;
  background: #fff;
}

/* line 1202, src/assets/scss/pages/_app-profile.scss */
.about-card:not(:last-child) {
  margin-bottom: 30px;
}

/* line 1206, src/assets/scss/pages/_app-profile.scss */
.about-card .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
}

/* line 1212, src/assets/scss/pages/_app-profile.scss */
.about-card .header .icon-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1216, src/assets/scss/pages/_app-profile.scss */
.about-card .header .icon-title i {
  font-size: 22px;
  color: #cecece;
}

/* line 1221, src/assets/scss/pages/_app-profile.scss */
.about-card .header .icon-title h3 {
  color: #393a4f;
  font-weight: 500;
  font-size: 1.1rem;
  margin-left: 8px;
}

/* line 1229, src/assets/scss/pages/_app-profile.scss */
.about-card .header .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1233, src/assets/scss/pages/_app-profile.scss */
.about-card .header .actions .button-wrapper {
  position: relative;
}

/* line 1236, src/assets/scss/pages/_app-profile.scss */
.about-card .header .actions .button-wrapper .indicator {
  position: absolute;
  top: -8px;
  left: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  min-width: 24px;
  padding: 0 4px;
  background: #5596e6;
  border: 2px solid #fff;
  border-radius: 50%;
}

/* line 1250, src/assets/scss/pages/_app-profile.scss */
.about-card .header .actions .button-wrapper .indicator span {
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fcfcfc;
}

/* line 1259, src/assets/scss/pages/_app-profile.scss */
.about-card .header .actions .button:first-child {
  margin-right: 8px;
}

/* line 1265, src/assets/scss/pages/_app-profile.scss */
.about-card .header .dropdown div > .button {
  margin-right: 0 !important;
}

/* line 1271, src/assets/scss/pages/_app-profile.scss */
.about-card .body {
  padding: 0 20px 20px 20px;
}

/* line 1274, src/assets/scss/pages/_app-profile.scss */
.about-card .body.has-flex-list {
  padding-left: calc(20px - 0.5rem);
  padding-right: calc(20px - 0.5rem);
  padding-bottom: calc(20px - 0.5rem);
}

/* line 1280, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #dedede;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1290, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1293, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card:hover .dropdown {
  opacity: 1;
  pointer-events: all;
}

/* line 1299, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* line 1305, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .meta {
  margin-left: 12px;
}

/* line 1308, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .meta span {
  display: block;
}

/* line 1311, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .meta span:first-child {
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 1317, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .meta span:nth-child(2) {
  font-size: 0.9rem;
  color: #999;
}

/* line 1324, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .dropdown {
  margin-left: auto;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* line 1330, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .dropdown > div .button {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1341, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .dropdown > div .button:hover {
  background: #f0f0f0;
}

/* line 1345, src/assets/scss/pages/_app-profile.scss */
.about-card .body .friend-small-card .dropdown > div .button svg {
  height: 20px;
  width: 20px;
}

/* line 1356, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper:hover .photo-overlay,
.about-card .body .photo-list .photo-wrapper:hover .video-overlay,
.about-card .body .photo-list .video-wrapper:hover .photo-overlay,
.about-card .body .photo-list .video-wrapper:hover .video-overlay,
.about-card .body .video-list .photo-wrapper:hover .photo-overlay,
.about-card .body .video-list .photo-wrapper:hover .video-overlay,
.about-card .body .video-list .video-wrapper:hover .photo-overlay,
.about-card .body .video-list .video-wrapper:hover .video-overlay {
  opacity: 0.5;
}

/* line 1360, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper:hover .small-like,
.about-card .body .photo-list .video-wrapper:hover .small-like,
.about-card .body .video-list .photo-wrapper:hover .small-like,
.about-card .body .video-list .video-wrapper:hover .small-like {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}

/* line 1367, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper .photo-overlay,
.about-card .body .photo-list .photo-wrapper .video-overlay,
.about-card .body .photo-list .video-wrapper .photo-overlay,
.about-card .body .photo-list .video-wrapper .video-overlay,
.about-card .body .video-list .photo-wrapper .photo-overlay,
.about-card .body .video-list .photo-wrapper .video-overlay,
.about-card .body .video-list .video-wrapper .photo-overlay,
.about-card .body .video-list .video-wrapper .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: #393a4f;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* line 1379, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper .small-like,
.about-card .body .photo-list .video-wrapper .small-like,
.about-card .body .video-list .photo-wrapper .small-like,
.about-card .body .video-list .video-wrapper .small-like {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}

/* line 1392, src/assets/scss/pages/_app-profile.scss */
.about-card
  .body
  .photo-list
  .photo-wrapper
  .small-like.is-active
  .inner
  .like-overlay,
.about-card
  .body
  .photo-list
  .video-wrapper
  .small-like.is-active
  .inner
  .like-overlay,
.about-card
  .body
  .video-list
  .photo-wrapper
  .small-like.is-active
  .inner
  .like-overlay,
.about-card
  .body
  .video-list
  .video-wrapper
  .small-like.is-active
  .inner
  .like-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 1396, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper .small-like.is-active .inner svg,
.about-card .body .photo-list .video-wrapper .small-like.is-active .inner svg,
.about-card .body .video-list .photo-wrapper .small-like.is-active .inner svg,
.about-card .body .video-list .video-wrapper .small-like.is-active .inner svg {
  stroke: #fff;
  fill: #fff;
}

/* line 1403, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper .small-like .inner,
.about-card .body .photo-list .video-wrapper .small-like .inner,
.about-card .body .video-list .photo-wrapper .small-like .inner,
.about-card .body .video-list .video-wrapper .small-like .inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 50%;
}

/* line 1413, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper .small-like .inner .like-overlay,
.about-card .body .photo-list .video-wrapper .small-like .inner .like-overlay,
.about-card .body .video-list .photo-wrapper .small-like .inner .like-overlay,
.about-card .body .video-list .video-wrapper .small-like .inner .like-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background: #f71416;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1430, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper .small-like .inner svg,
.about-card .body .photo-list .video-wrapper .small-like .inner svg,
.about-card .body .video-list .photo-wrapper .small-like .inner svg,
.about-card .body .video-list .video-wrapper .small-like .inner svg {
  position: relative;
  height: 14px;
  width: 14px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1442, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -0.5rem;
}

/* line 1449, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper {
  position: relative;
  width: calc(20% - 1rem);
  margin: 0.5rem;
  overflow: hidden;
}

/* line 1455, src/assets/scss/pages/_app-profile.scss */
.about-card .body .photo-list .photo-wrapper img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}

/* line 1464, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -0.5rem;
}

/* line 1471, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper {
  position: relative;
  width: calc(25% - 1rem);
  margin: 0.5rem;
  overflow: hidden;
}

/* line 1477, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}

/* line 1484, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper .video-overlay {
  opacity: 0.5 !important;
}

/* line 1488, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper .video-length {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fcfcfc;
  font-weight: 500;
  font-size: 0.8rem;
}

/* line 1497, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper .video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 34px;
  width: 34px;
  background: #5596e6;
  border-radius: 50%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

/* line 1514, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper .video-button img {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  left: 1px;
  z-index: 3;
}

/* line 1524, src/assets/scss/pages/_app-profile.scss */
.about-card .body .video-list .video-wrapper .video-button:after {
  content: "";
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #5596e6;
  -webkit-animation: wave 1.6s infinite;
  animation: wave 1.6s infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  z-index: 0;
}

/* line 1542, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -0.5rem;
}

/* line 1549, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper {
  position: relative;
  width: calc(33% - 1rem);
  margin: 0.5rem;
  overflow: hidden;
  padding: 12px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1560, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1564, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}

/* line 1572, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper .foot .place-name {
  display: block;
  margin-top: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1580, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper .foot .place-name:hover {
  color: #5596e6;
}

/* line 1585, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper .foot .rating {
  line-height: 1;
}

/* line 1588, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper .foot .rating svg {
  height: 14px;
  width: 14px;
  stroke: #cecece;
  fill: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1595, src/assets/scss/pages/_app-profile.scss */
.about-card .body .place-list .place-wrapper .foot .rating svg.is-checked {
  stroke: #3d70b2;
  fill: #3d70b2;
}

/* line 1611, src/assets/scss/pages/_app-profile.scss */
.page-about-card {
  padding: 20px;
}

/* line 1614, src/assets/scss/pages/_app-profile.scss */
.page-about-card .card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* line 1620, src/assets/scss/pages/_app-profile.scss */
.page-about-card .card-title h4 {
  font-family: "Montserrat", sans-serif;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 1629, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-header {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0;
}

/* line 1633, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-header h4 {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  color: #999;
}

/* line 1641, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content {
  padding: 10px 0;
}

/* line 1644, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .flex-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0;
}

/* line 1649, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .flex-inner.has-meta {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 16px 0;
}

/* line 1653, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .flex-inner.has-meta
  .meta {
  margin-left: 12px;
}

/* line 1656, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .flex-inner.has-meta
  .meta
  span {
  display: block;
  padding: 0;
}

/* line 1660, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .flex-inner.has-meta
  .meta
  span:first-child {
  font-weight: 500;
  color: #393a4f;
}

/* line 1665, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .flex-inner.has-meta
  .meta
  span:nth-child(2) {
  color: #999;
  font-size: 0.85rem;
}

/* line 1673, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .flex-inner .mdi {
  font-size: 24px;
  color: #999;
}

/* line 1678, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .flex-inner svg {
  height: 16px;
  width: 16px;
  stroke: #999;
}

/* line 1684, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .flex-inner span {
  display: block;
  font-size: 0.9rem;
  padding: 0 10px;
}

/* line 1689, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .flex-inner span a {
  color: #999;
}

/* line 1692, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .flex-inner
  span
  a:hover {
  color: #5596e6;
}

/* line 1699, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .history-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

/* line 1703, src/assets/scss/pages/_app-profile.scss */
.page-about-card .about-body .about-block .block-content .history-block .date {
  font-weight: 500;
  font-size: 0.9rem;
  color: #393a4f;
  margin-left: 8px;
}

/* line 1711, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .history-block
  .timeline
  ul {
  list-style-type: disc;
  padding-left: 30px;
}

/* line 1715, src/assets/scss/pages/_app-profile.scss */
.page-about-card
  .about-body
  .about-block
  .block-content
  .history-block
  .timeline
  ul
  li {
  font-size: 0.85rem;
  color: #999;
  margin-bottom: 12px;
}

/* line 1732, src/assets/scss/pages/_app-profile.scss */
.community-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 1736, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info {
  width: 59%;
}

/* line 1739, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
}

/* line 1745, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .header {
  margin-bottom: 20px;
}

/* line 1748, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .header h3 {
  font-family: "Montserrat", sans-serif;
  color: #393a4f;
  font-size: 0.9rem;
  font-weight: 600;
}

/* line 1756, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .followers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 1760, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .followers .follower {
  width: calc(20% - 16px);
  margin: 8px;
}

/* line 1765, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-info
  .page-followers
  .followers
  .follower:hover
  img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 1771, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .followers .follower img {
  display: block;
  border-radius: 50%;
  -webkit-filter: grayscale(4);
  filter: grayscale(4);
  opacity: 0.8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1781, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .more-followers {
  padding: 30px 0 10px 0;
  text-align: center;
}

/* line 1785, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .more-followers a {
  padding: 8px 24px;
  border-radius: 100px;
  background: #f7f7f7;
  color: #999;
  font-size: 0.9rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1793, src/assets/scss/pages/_app-profile.scss */
.community-content .community-info .page-followers .more-followers a:hover {
  background: #ededed;
  color: #393a4f;
}

/* line 1802, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets {
  width: 39%;
}

/* line 1805, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-counts {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

/* line 1813, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-counts .like-count,
.community-content .community-widgets .page-counts .follow-count {
  margin: 10px 0;
  width: 50%;
}

/* line 1817, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-counts .like-count:first-child,
.community-content .community-widgets .page-counts .follow-count:first-child {
  border-right: 1px solid #e8e8e8;
}

/* line 1821, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-counts .like-count span,
.community-content .community-widgets .page-counts .follow-count span {
  display: block;
  text-align: center;
}

/* line 1825, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-counts .like-count span:first-child,
.community-content
  .community-widgets
  .page-counts
  .follow-count
  span:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1.4rem;
}

/* line 1832, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-counts
  .like-count
  span:nth-child(2),
.community-content
  .community-widgets
  .page-counts
  .follow-count
  span:nth-child(2) {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #999;
}

/* line 1841, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-invites {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
}

/* line 1848, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-invites .header h3 {
  font-size: 0.9rem;
}

/* line 1852, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-invites .header .control {
  margin-top: 8px;
  position: relative;
}

/* line 1856, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-invites .header .control input {
  padding-left: 36px;
}

/* line 1859, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .header
  .control
  input:focus {
  border-color: #e8e8e8;
}

/* line 1863, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .header
  .control
  input:focus
  + .search-icon
  svg {
  stroke: #5596e6;
}

/* line 1868, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .header
  .control
  .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1878, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .header
  .control
  .search-icon
  svg {
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 1888, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-invites .suggestions {
  padding: 10px 0 0 0;
}

/* line 1891, src/assets/scss/pages/_app-profile.scss */
.community-content .community-widgets .page-invites .suggestions .suggestion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0;
}

/* line 1897, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .suggestions
  .suggestion:last-child {
  border-bottom: none;
}

/* line 1901, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .suggestions
  .suggestion
  img {
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

/* line 1908, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .suggestions
  .suggestion
  .meta {
  margin-left: 10px;
}

/* line 1911, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .suggestions
  .suggestion
  .meta
  span {
  display: block;
  font-size: 0.85rem;
  font-weight: 500;
}

/* line 1918, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .suggestions
  .suggestion
  .button {
  margin-left: auto;
}

/* line 1921, src/assets/scss/pages/_app-profile.scss */
.community-content
  .community-widgets
  .page-invites
  .suggestions
  .suggestion
  .button:hover {
  border-color: #5596e6;
  background: #5596e6;
  color: #fcfcfc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 1938, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap {
  padding: 6px 0;
}

/* line 1941, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* line 1945, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .flex-2 {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

/* line 1949, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .flex-3 {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

/* line 1953, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 1957, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div {
  position: relative;
  margin: 4px;
  height: 280px;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

/* line 1968, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div:hover .overlay {
  opacity: 0.6;
  pointer-events: all;
}

/* line 1974, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div:hover .image-owner .avatar {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 1978, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div:hover .image-owner .name {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* line 1984, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div:hover .photo-time {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1 !important;
}

/* line 1989, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div:hover .photo-like {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 1994, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .overlay {
  position: absolute;
  top: -4px;
  left: -4px;
  height: 100%;
  width: 100%;
  margin: 4px;
  background: #393a4f;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2008, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .image-owner {
  position: absolute;
  bottom: 14px;
  left: 14px;
  max-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
}

/* line 2017, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .image-owner .avatar {
  display: block;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  border: 1.4px solid #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2027, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .image-owner .name {
  color: #fff;
  font-size: 0.9rem;
  margin-left: 8px;
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2037, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-time {
  position: absolute;
  top: 20px;
  left: 14px;
  color: #fff;
  font-size: 0.85rem;
  margin-left: 8px;
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  z-index: 2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2050, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like {
  position: absolute;
  top: 14px;
  right: 14px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2064, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like:hover .inner svg {
  stroke: #f71416;
}

/* line 2070, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like.is-liked {
  -webkit-box-shadow: 0 14px 26px -12px rgba(247, 20, 22, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(247, 20, 22, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(247, 20, 22, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(247, 20, 22, 0.2) !important;
}

/* line 2074, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like.is-liked .inner .like-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 2078, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like.is-liked .inner svg {
  stroke: #fff;
  fill: #fff;
}

/* line 2085, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like .inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

/* line 2095, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like .inner .like-overlay {
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  border-radius: 50%;
  background: #db0709;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

/* line 2108, src/assets/scss/pages/_app-profile.scss */
.image-grid-wrap .image-row > div .photo-like .inner svg {
  position: relative;
  height: 16px;
  width: 16px;
  stroke: #393a4f;
  z-index: 2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2125, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox {
  position: fixed;
  top: 58px;
  left: 0;
  height: calc(100% - 58px);
  width: 100%;
  background: #fff;
  z-index: -1;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2138, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox.is-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  z-index: 3;
}

/* line 2145, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 2150, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .image-side {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 380px);
  background: #181e28;
}

/* line 2158, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .image-side .image-side-inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 2166, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap {
  position: relative;
  max-width: calc(100% - 160px);
}

/* line 2171, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap:hover
  .meta-overlay {
  pointer-events: all;
  opacity: 0.6;
  z-index: 1;
}

/* line 2178, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap:hover
  .meta-block
  h3,
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap:hover
  .meta-block
  p {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* line 2185, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap:hover
  .action-block
  .action-button {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

/* line 2193, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .image-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #090b0f;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2208, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .image-loader.is-active {
  pointer-events: all;
  opacity: 1;
}

/* line 2213, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .image-loader
  .loader {
  height: 3rem;
  width: 3rem;
}

/* line 2219, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .meta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #090b0f;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2235, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .meta-block {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
}

/* line 2241, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .meta-block
  h3 {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 0.8rem;
  max-width: 440px;
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2251, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .meta-block
  p {
  padding-top: 8px;
  color: #fff;
  font-size: 0.8rem;
  max-width: 540px;
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/* line 2263, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 2271, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block
  .action-button {
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 1.2px solid #fff;
  margin: 0 5px;
  opacity: 0;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2286, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block
  .action-button:hover
  svg {
  stroke: #3d70b2;
}

/* line 2291, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block
  .action-button:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

/* line 2295, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block
  .action-button:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/* line 2299, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block
  .action-button:nth-child(4) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

/* line 2303, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  .action-block
  .action-button
  svg {
  height: 18px;
  width: 18px;
  stroke: #fff;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 2312, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .image-side
  .image-side-inner
  .image-wrap
  img {
  display: block;
  border-radius: 4px;
}

/* line 2320, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 380px;
  background: #fff;
}

/* line 2328, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .comments-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2343, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-loader.is-active {
  pointer-events: all;
  opacity: 1;
}

/* line 2348, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-loader
  .loader {
  height: 3rem;
  width: 3rem;
}

/* line 2355, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  width: 100%;
}

/* line 2363, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .header img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

/* line 2369, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .header .user-meta {
  margin: 0 10px;
}

/* line 2372, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .header .user-meta span {
  display: block;
}

/* line 2375, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .user-meta
  span:first-child {
  font-size: 0.9rem;
  font-weight: 500;
}

/* line 2379, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .user-meta
  span:first-child
  small {
  color: #999;
}

/* line 2384, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .user-meta
  span:nth-child(2) {
  font-size: 0.8rem;
  color: #999;
}

/* line 2391, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .header .button {
  line-height: 0;
  margin-left: auto;
  padding: 14px 18px;
}

/* line 2399, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .dropdown
  .button {
  padding: 18px 6px;
  border: none;
  background: transparent;
}

/* line 2404, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .dropdown
  .button
  svg {
  height: 18px;
  width: 18px;
}

/* line 2409, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .dropdown
  .button
  img {
  border-radius: 50%;
}

/* line 2414, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .header
  .dropdown
  .dropdown-menu {
  margin-top: 10px;
}

/* line 2421, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .inner-content {
  padding: 12px 12px 0 12px;
}

/* line 2424, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .inner-content .control {
  width: 100%;
}

/* line 2427, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .inner-content
  .control
  input {
  padding-left: 34px;
}

/* line 2431, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .inner-content
  .control
  input:focus
  + .icon
  svg {
  stroke: #5596e6;
}

/* line 2437, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .inner-content
  .control
  .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 2447, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .inner-content
  .control
  .icon
  svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2458, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .live-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;
}

/* line 2465, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 2469, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .shares-count,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .comments-count,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .likes-count,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .views-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

/* line 2475, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .shares-count
  span,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .comments-count
  span,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .likes-count
  span,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .views-count
  span {
  display: block;
  font-size: 0.8rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 2481, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .shares-count
  span.views,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .comments-count
  span.views,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .likes-count
  span.views,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .views-count
  span.views {
  margin: 0 2px;
}

/* line 2486, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .shares-count
  svg,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .comments-count
  svg,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .likes-count
  svg,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .live-stats
  .social-count
  .views-count
  svg {
  height: 14px;
  width: 14px;
  stroke: #888da8;
}

/* line 2496, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 0;
}

/* line 2502, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .actions .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

/* line 2508, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .actions .action span {
  display: block;
  font-size: 0.8rem;
  margin: 0 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2515, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .actions .action svg {
  height: 16px;
  width: 16px;
  stroke: #888da8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2523, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .actions
  .action:hover
  span {
  color: #5596e6;
}

/* line 2527, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .actions
  .action:hover
  svg {
  stroke: #5596e6;
}

/* line 2535, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .comments-list {
  background: #f5f6f7;
  height: calc(100% - 232px);
  padding: 20px 14px;
  overflow-y: auto;
}

/* line 2542, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment {
  border: none !important;
  padding-top: 0 !important;
}

/* line 2546, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment.is-nested {
  margin-left: 40px;
}

/* line 2550, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-left {
  margin-right: 10px;
}

/* line 2553, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-left
  img {
  border-radius: 50%;
}

/* line 2558, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
}

/* line 2564, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .username {
  font-size: 0.8rem;
  font-weight: 500;
}

/* line 2569, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  p {
  font-size: 0.75rem;
  color: #999;
}

/* line 2575, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 8px;
}

/* line 2580, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  span,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  a {
  display: block;
  font-size: 0.75rem;
  color: #393a4f;
}

/* line 2586, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  span {
  margin: 0 10px;
  color: #999;
}

/* line 2591, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

/* line 2597, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count
  span {
  display: block;
  font-size: 0.75rem;
  color: #888da8;
  margin: 0 5px;
}

/* line 2603, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count
  span.views {
  margin: 0 2px;
}

/* line 2608, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comments-list
  .is-comment
  .media-content
  .comment-actions
  .likes-count
  svg {
  height: 12px;
  width: 12px;
  stroke: #888da8;
}

/* line 2620, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox .lightbox-inner .sidebar-side .comment-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fbfbfc;
  height: 50px;
  width: 100%;
  border-radius: 0 0 6px 0;
  border-top: 1px solid #dee2e5;
}

/* line 2631, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

/* line 2639, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

/* line 2645, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .control {
  width: 100%;
}

/* line 2648, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .control
  .textarea,
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .control
  .emojionearea-editor {
  resize: none;
  height: 36px;
  max-height: 36px;
  min-height: 36px;
  border-radius: 100px;
  overflow: hidden;
  line-height: 1.6;
  font-size: 0.8rem;
  padding-left: 16px;
  margin: 0 6px;
}

/* line 2663, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea-editor {
  padding-left: 0 !important;
}

/* line 2666, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea-editor
  img {
  height: 18px;
  width: 18px;
  min-height: 18px;
  max-height: 18px;
}

/* line 2674, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea {
  overflow: visible !important;
}

/* line 2678, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea-picker {
  top: -230px;
  position: absolute;
  left: -50px;
  width: 310px;
}

/* line 2684, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea-picker
  .emojionearea-wrapper {
  width: 310px;
}

/* line 2687, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea-picker
  .emojionearea-wrapper
  img {
  height: 22px;
  width: 22px;
  min-height: 22px;
  max-height: 22px;
}

/* line 2696, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea-scroll-area {
  width: 310px;
}

/* line 2700, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea
  .emojionearea-button
  > div.emojionearea-button-open {
  background-position: 0 -22px;
}

/* line 2704, src/assets/scss/pages/_app-profile.scss */
.custom-profile-lightbox
  .lightbox-inner
  .sidebar-side
  .comment-controls
  .controls-inner
  .emojionearea
  .emojionearea-picker
  .emojionearea-scroll-area
  .emojibtn {
  width: 24px !important;
  height: 24px !important;
}

/* line 2718, src/assets/scss/pages/_app-profile.scss */
.friends-grid {
  padding: 20px 0;
}

/* line 2722, src/assets/scss/pages/_app-profile.scss */
.friends-grid .columns .column {
  padding: 0.5rem;
}

/* line 2727, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item {
  display: block;
  width: 100%;
  padding: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 2736, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 2740, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item:hover .avatar-wrap .circle {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* line 2744, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item:hover .avatar-wrap .chat-button {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* line 2751, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item .avatar-wrap {
  position: relative;
  height: 96px;
  width: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

/* line 2760, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item .avatar-wrap .circle {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1.4px solid #cecece;
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

/* line 2773, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item .avatar-wrap .chat-button {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #5596e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 3;
}

/* line 2790, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item .avatar-wrap .chat-button svg {
  height: 14px;
  width: 14px;
  stroke: #fff;
  fill: #fff;
}

/* line 2798, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item .avatar-wrap img {
  position: relative;
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  z-index: 2;
}

/* line 2808, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item h3 {
  font-family: "Montserrat", sans-serif;
  color: #393a4f;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 6px;
}

/* line 2817, src/assets/scss/pages/_app-profile.scss */
.friends-grid .friend-item p {
  color: #999;
  font-size: 0.8rem;
}

@media (max-width: 767px) {
  /* line 2829, src/assets/scss/pages/_app-profile.scss */
  .profile-about {
    padding: 20px 0;
  }

  /* line 2832, src/assets/scss/pages/_app-profile.scss */
  .profile-about .left-menu {
    margin-bottom: 20px;
  }

  /* line 2836, src/assets/scss/pages/_app-profile.scss */
  .profile-about .right-content {
    width: 100% !important;
  }

  /* line 2841, src/assets/scss/pages/_app-profile.scss */
  .community-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  /* line 2844, src/assets/scss/pages/_app-profile.scss */
  .community-content .community-widgets {
    margin-bottom: 20px;
  }

  /* line 2848, src/assets/scss/pages/_app-profile.scss */
  .community-content .community-info,
  .community-content .community-widgets {
    width: 100% !important;
  }

  /* line 2855, src/assets/scss/pages/_app-profile.scss */
  .custom-profile-lightbox .lightbox-inner .image-side {
    width: 100%;
    height: 180px;
  }

  /* line 2860, src/assets/scss/pages/_app-profile.scss */
  .custom-profile-lightbox
    .lightbox-inner
    .image-side
    .image-side-inner
    .image-wrap {
    max-width: calc(100% - 80px);
  }

  /* line 2866, src/assets/scss/pages/_app-profile.scss */
  .custom-profile-lightbox .lightbox-inner .sidebar-side {
    width: 100%;
    top: 180px;
    height: calc(100% - 180px);
    z-index: 10;
    background: #fff;
  }

  /* line 2876, src/assets/scss/pages/_app-profile.scss */
  .image-grid-wrap .image-row > div {
    min-height: 280px;
  }
}

@media screen and (max-width: 400px) {
  /* line 2882, src/assets/scss/pages/_app-profile.scss */
  .image-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 2889, src/assets/scss/pages/_app-profile.scss */
  .profile-about .right-content {
    width: calc(100% - 220px) !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 2896, src/assets/scss/pages/_app-profile.scss */
  .profile-about {
    padding: 20px 0;
  }

  /* line 2899, src/assets/scss/pages/_app-profile.scss */
  .profile-about .left-menu {
    margin-bottom: 20px;
  }

  /* line 2903, src/assets/scss/pages/_app-profile.scss */
  .profile-about .right-content {
    width: 100% !important;
  }

  /* line 2910, src/assets/scss/pages/_app-profile.scss */
  .custom-profile-lightbox .lightbox-inner .image-side {
    width: 100%;
    height: 340px;
  }

  /* line 2915, src/assets/scss/pages/_app-profile.scss */
  .custom-profile-lightbox
    .lightbox-inner
    .image-side
    .image-side-inner
    .image-wrap {
    max-width: calc(100% - 80px);
  }

  /* line 2921, src/assets/scss/pages/_app-profile.scss */
  .custom-profile-lightbox .lightbox-inner .sidebar-side {
    width: 100%;
    top: 340px;
    height: calc(100% - 340px);
    z-index: 10;
    background: #fff;
  }

  /* line 2932, src/assets/scss/pages/_app-profile.scss */
  .friends-grid .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 2935, src/assets/scss/pages/_app-profile.scss */
  .friends-grid .columns .friend-item {
    min-height: 216px;
  }
}

/*! _app-profile-minimal.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 18, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-cover img {
  display: block;
  max-height: 300px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 26, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-width: 1040px;
  margin: 20px auto;
}

/* line 32, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-width: 30%;
  padding: 0 20px;
}

/* line 40, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left .profile-avatar {
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 130px;
  width: 130px;
}

/* line 49, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left .profile-avatar img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
}

/* line 57, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left .profile-avatar .button {
  position: absolute;
  bottom: 10px;
  right: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

/* line 69, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left .profile-avatar .button svg {
  height: 18px;
  width: 18px;
}

/* line 77, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left > .button span {
  display: inline-block;
  line-height: 1;
}

/* line 82, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .left > .button svg {
  height: 18px;
  width: 18px;
  margin-right: 6px;
  stroke-width: 1.4px;
}

/* line 91, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  padding: 0 0 0 20px;
}

/* line 95, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 100, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right .head h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #393a4f;
}

/* line 107, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right .head .button {
  min-width: 130px;
}

/* line 112, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right .stats {
  padding: 1rem 0;
}

/* line 115, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right .stats span {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #abb0be;
  padding-right: 30px;
}

/* line 124, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-info .right .bio p {
  max-width: 480px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 134, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-grid {
  max-width: 1040px;
  margin: 0 auto;
  padding: 40px 0 0 0;
}

/* line 139, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-grid .column {
  padding: 0.5rem;
}

/* line 142, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-grid .column .profile-post {
  margin-bottom: 0;
  border: none;
}

/* line 146, src/assets/scss/pages/_app-profile-minimal.scss */
.minimal-profile-wrapper .profile-grid .column .profile-post img {
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  /* line 160, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 0.5rem;
    margin-bottom: 0;
  }

  /* line 165, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .right {
    padding: 0;
  }

  /* line 168, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .right .head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 171, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .right .head .actions {
    margin: 10px 0;
  }

  /* line 174, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .right .head .actions .button {
    min-width: 220px;
  }

  /* line 180, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .right .stats,
  .minimal-profile-wrapper .profile-info .right .bio {
    text-align: center;
  }

  /* line 185, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .left {
    min-height: 45px;
    margin-bottom: 9px;
  }

  /* line 189, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info .left > .button {
    position: absolute;
    top: -221px;
    left: 0;
    right: 0;
    background: none;
    color: #fff;
    border-color: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* line 205, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info {
    padding: 0 0.5rem;
  }

  /* line 210, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-grid .columns {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* line 221, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-info {
    padding: 0 0.5rem;
  }

  /* line 226, src/assets/scss/pages/_app-profile-minimal.scss */
  .minimal-profile-wrapper .profile-grid .columns {
    margin-left: 0;
    margin-right: 0;
  }
}

/*! _app-friends.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/pages/_app-friends.scss */
.friends-wrapper {
  position: relative;
  margin: 0 auto;
  top: 40px;
  padding: 0 6%;
  min-height: calc(100vh - 280px);
}

/* line 26, src/assets/scss/pages/_app-friends.scss */
.friends-wrapper.has-sidebar .card-row .friend-card {
  width: calc(33.3% - 16px);
}

/* line 32, src/assets/scss/pages/_app-friends.scss */
.friends-wrapper .card-row-wrap {
  display: none;
}

/* line 35, src/assets/scss/pages/_app-friends.scss */
.friends-wrapper .card-row-wrap.is-active {
  display: block;
}

/* line 40, src/assets/scss/pages/_app-friends.scss */
.friends-wrapper .card-row-placeholder {
  min-height: calc(100vh - 280px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 47, src/assets/scss/pages/_app-friends.scss */
.friends-wrapper .card-row {
  margin-top: 20px;
  padding-bottom: 60px;
}

/* line 57, src/assets/scss/pages/_app-friends.scss */
.friend-card {
  position: relative;
  border-radius: 8px;
  padding: 20px;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

/* line 63, src/assets/scss/pages/_app-friends.scss */
.friend-card.has-shapes {
  overflow: hidden;
}

/* line 66, src/assets/scss/pages/_app-friends.scss */
.friend-card.has-shapes .shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 0;
}

/* line 76, src/assets/scss/pages/_app-friends.scss */
.friend-card:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 80, src/assets/scss/pages/_app-friends.scss */
.friend-card .shape {
  display: none;
}

/* line 84, src/assets/scss/pages/_app-friends.scss */
.friend-card .star-friend {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

/* line 97, src/assets/scss/pages/_app-friends.scss */
.friend-card .star-friend.is-active svg {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  stroke: #3d70b2;
  fill: #3d70b2;
}

/* line 104, src/assets/scss/pages/_app-friends.scss */
.friend-card .star-friend svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  fill: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

/* line 114, src/assets/scss/pages/_app-friends.scss */
.friend-card .img-container {
  position: relative;
  height: 90px;
  width: 90px;
  margin: 10px auto;
  z-index: 1;
}

/* line 121, src/assets/scss/pages/_app-friends.scss */
.friend-card .img-container .avatar {
  display: block;
  border-radius: 50%;
}

/* line 125, src/assets/scss/pages/_app-friends.scss */
.friend-card .img-container .avatar.is-placeholder {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.3;
}

/* line 131, src/assets/scss/pages/_app-friends.scss */
.friend-card .img-container .country {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 3px solid #fff;
}

/* line 143, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-info {
  text-align: center;
}

/* line 146, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-info h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  color: #393a4f;
  line-height: 1;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 155, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-info p {
  font-family: Roboto, sans-serif;
  font-size: 0.85rem;
  color: #999;
}

/* line 162, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

/* line 168, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-stats .stat-block {
  text-align: center;
  padding: 0 20px;
}

/* line 172, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-stats .stat-block:nth-child(2) {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

/* line 177, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-stats .stat-block label {
  display: block;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 0.6rem;
  color: #999;
}

/* line 186, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-stats .stat-block .stat-number {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #393a4f;
}

/* line 195, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 30px;
}

/* line 200, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-actions .button {
  margin: 0 4px;
}

/* line 204, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-actions .button:hover svg {
  stroke: #393a4f;
}

/* line 209, src/assets/scss/pages/_app-friends.scss */
.friend-card .friend-actions .button svg {
  height: 16px;
  width: 16px;
  stroke: #ddd;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

@media screen and (max-width: 1300px) {
  /* line 225, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper {
    padding: 0 !important;
  }
}

@media screen and (max-width: 1100px) {
  /* line 234, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper .card-row .friend-card {
    width: calc(33.3% - 16px);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 246, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper.has-sidebar .card-row .friend-card {
    width: calc(100% - 16px) !important;
  }

  /* line 253, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper .card-row .friend-card {
    width: calc(50% - 16px);
  }

  /* line 256, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper .card-row .friend-card .friend-actions {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  /* line 264, src/assets/scss/pages/_app-friends.scss */
  .options-nav .is-search {
    max-width: 180px !important;
  }
}

@media screen and (max-width: 767px) {
  /* line 274, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper .card-row .friend-card {
    width: calc(50% - 16px);
  }

  /* line 277, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper .card-row .friend-card .friend-actions {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  /* line 286, src/assets/scss/pages/_app-friends.scss */
  .options-nav .is-friends #show-filters {
    display: none !important;
  }

  /* line 290, src/assets/scss/pages/_app-friends.scss */
  .options-nav .is-friends .is-page-title h2 {
    font-size: 0.9rem;
  }

  /* line 294, src/assets/scss/pages/_app-friends.scss */
  .options-nav .is-friends .end-group {
    display: none !important;
  }
}

@media screen and (max-width: 560px) {
  /* line 305, src/assets/scss/pages/_app-friends.scss */
  .friends-wrapper .card-row .friend-card {
    width: calc(100% - 16px);
  }
}

/*! _app-events.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 18, src/assets/scss/pages/_app-events.scss */
.events-wrapper {
  position: absolute;
  top: 58px;
  left: 0;
  width: 100%;
  height: calc(100vh - 58px);
  background: #fff;
}

/* line 27, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel {
  position: fixed;
  top: 58px;
  left: 0;
  width: 260px;
  height: calc(100vh - 58px);
  background: #fff;
  border-right: 1px solid #e0e0e0;
}

/* line 36, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 60px;
}

/* line 42, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dedede;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 49, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a.is-active {
  background: #f2f2f2;
}

/* line 53, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a.is-active span.tag {
  background: #5596e6 !important;
  color: #fff !important;
}

/* line 58, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a.is-active span.date-block svg {
  stroke: #5596e6;
}

/* line 64, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span {
  display: block;
}

/* line 67, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.date-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 71, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.date-block svg {
  position: relative;
  top: -1px;
  height: 18px;
  width: 18px;
  margin-right: 6px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 81, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.date-block .month {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #393a4f;
  font-size: 0.85rem;
  line-height: 1;
  width: 75px;
}

/* line 91, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.meta-block {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 97, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.meta-block .time {
  font-size: 0.8rem;
  color: #999;
  line-height: 1;
}

/* line 103, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.meta-block .type {
  display: inline-block;
}

/* line 106, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner a span.meta-block .type .tag {
  display: inline-block;
  border-radius: 100px;
  line-height: 2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #f2f2f2;
  color: #393a4f;
}

/* line 119, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner .add-event {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  background: #fff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* line 131, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner .add-event .button {
  height: 42px;
  width: 100%;
  border: 2px dashed #cecece;
  color: #999;
}

/* line 137, src/assets/scss/pages/_app-events.scss */
.events-wrapper .left-panel .left-panel-inner .add-event .button:hover {
  border: 2px solid #3d70b2;
  background: #3d70b2;
  color: #fafafa;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 149, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel {
  position: fixed;
  top: 58px;
  right: 0;
  width: 380px;
  height: calc(100vh - 58px);
  background: #fff;
  border-left: 1px solid #e0e0e0;
}

/* line 158, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 30px;
}

/* line 165, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-header h3 {
  font-size: 1rem;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

/* line 172, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body {
  position: relative;
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 0 20px 20px 20px;
}

/* line 178, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body .activity-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 10px 0;
}

/* line 183, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body .activity-block img {
  display: block;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}

/* line 191, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body .activity-block .activity-meta {
  margin-left: 10px;
}

/* line 194, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body .activity-block .activity-meta p {
  font-size: 0.85rem;
  color: #393a4f;
}

/* line 198, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body .activity-block .activity-meta p a {
  font-weight: 500;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 203, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .right-panel
  .panel-body
  .activity-block
  .activity-meta
  p
  a:hover {
  color: #5596e6;
}

/* line 209, src/assets/scss/pages/_app-events.scss */
.events-wrapper .right-panel .panel-body .activity-block .activity-meta span {
  display: block;
  font-size: 0.85rem;
  color: #999;
}

/* line 220, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner {
  position: relative;
  width: calc(100% - 640px);
  height: 100%;
  margin-left: 260px;
  margin-right: 380px;
}

/* line 228, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 237, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item {
  position: relative;
  width: 100%;
  padding: 40px 60px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 247, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-inner-wrap {
  position: relative;
}

/* line 250, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-inner-wrap
  .event-options {
  position: absolute;
  top: 5px;
  right: 0;
}

/* line 256, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-inner-wrap
  .event-options:hover
  .button
  svg {
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
}

/* line 261, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-inner-wrap
  .event-options
  .button {
  background: transparent;
  border: none;
  padding: 0;
  height: 28px;
  width: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 271, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-inner-wrap
  .event-options
  .button
  svg {
  color: #757a91;
}

/* line 279, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-title {
  font-size: 2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  color: #393a4f;
  line-height: 1.1;
  max-width: 520px;
}

/* line 289, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 40px 0 10px 0;
}

/* line 293, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-subtitle svg {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

/* line 299, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-subtitle h3 {
  color: #999;
}

/* line 305, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

/* line 309, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-owner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 80px;
}

/* line 314, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-owner
  img {
  display: block;
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
  margin: 0 auto;
}

/* line 323, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-owner
  .owner-meta {
  margin-left: 12px;
}

/* line 326, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-owner
  .owner-meta
  span {
  display: block;
}

/* line 329, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-owner
  .owner-meta
  span:first-child {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 336, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-owner
  .owner-meta
  span:nth-child(2) {
  font-size: 0.9rem;
  color: #999;
}

/* line 345, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-description
  p {
  color: #757a91;
  margin-bottom: 10px;
}

/* line 349, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-content
  .event-description
  p:last-child {
  margin-bottom: 0;
}

/* line 357, src/assets/scss/pages/_app-events.scss */
.events-wrapper .wrapper-inner .event-list .event-item .event-participants {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 362, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 367, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-group
  img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  border: 3px solid #fff;
}

/* line 373, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-group
  img:not(:first-child) {
  margin-left: -12px;
}

/* line 379, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-text {
  margin-left: 10px;
}

/* line 382, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-text
  p {
  font-size: 0.8rem;
  color: #888da8;
}

/* line 386, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-text
  p
  a {
  color: #393a4f;
}

/* line 389, src/assets/scss/pages/_app-events.scss */
.events-wrapper
  .wrapper-inner
  .event-list
  .event-item
  .event-participants
  .participants-text
  p
  a:hover {
  color: #5596e6;
}

/* line 407, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-cover img {
  display: block;
  max-height: 380px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: bottom;
  object-position: bottom;
}

/* line 416, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content {
  position: relative;
  max-width: 940px;
  margin: -80px auto 20px auto;
  z-index: 1;
}

/* line 422, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 430, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left {
  position: relative;
  width: 60%;
  padding: 30px;
}

/* line 435, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left .button-separator {
  position: absolute;
  top: 50%;
  right: -25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 450, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left .button-separator svg {
  height: 18px;
  width: 18px;
  stroke-width: 3px;
  stroke: #abb0be;
}

/* line 458, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 465, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left h3 {
  font-family: "Roboto", sans-serif;
  color: #abb0be;
  margin-bottom: 1rem;
}

/* line 471, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left .info-block {
  margin-bottom: 1rem;
}

/* line 474, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left .info-block:last-child {
  margin-bottom: 0;
}

/* line 478, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left .info-block .info-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 482, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-head
  .event-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 50%;
}

/* line 493, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-head
  .event-icon
  svg {
  height: 16px;
  width: 16px;
  stroke-width: 1.6px;
  stroke: #5596e6;
}

/* line 501, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-head
  > span {
  display: inline-block;
  color: #abb0be;
  font-size: 0.9rem;
  margin-left: 6px;
}

/* line 510, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .left .info-block .info-body p,
.event-page-wrapper .event-content .event-head .left .info-block .info-body a {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 516, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-body
  .socials {
  margin-top: 8px;
}

/* line 519, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-body
  .socials
  a {
  margin-right: 6px;
  color: #abb0be;
}

/* line 523, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-body
  .socials
  a:hover {
  color: #5596e6;
}

/* line 527, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-content
  .event-head
  .left
  .info-block
  .info-body
  .socials
  a
  svg {
  stroke-width: 1.6px;
  height: 20px;
  width: 20px;
}

/* line 538, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .right {
  width: 40%;
  border-left: 2px dashed #dedede;
  padding: 30px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fafafa;
}

/* line 547, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .right h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #393a4f;
  margin-bottom: 1rem;
  margin-top: 6px;
}

/* line 556, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .right .subscribe-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 561, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .right .subscribe-block p {
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  color: #393a4f;
}

/* line 567, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .right .subscribe-block img {
  display: block;
  max-width: 110px;
}

/* line 573, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-content .event-head .right .condition {
  padding: 20px 0 0 0;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 585, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-participants {
  padding: 20px 0;
  max-width: 940px;
  margin: 0 auto;
}

/* line 590, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-participants .container-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 593, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-participants .container-inner .participant {
  margin: 8px;
  width: calc(10% - 16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 599, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-participants
  .container-inner
  .participant
  .participant-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  width: 60px;
  min-width: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1.6px solid #cecece;
  opacity: 0.6;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 614, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-participants
  .container-inner
  .participant
  .participant-avatar:hover {
  border-color: #3d70b2;
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/* line 620, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-participants
  .container-inner
  .participant
  .participant-avatar
  .more-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #5596e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 633, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-participants
  .container-inner
  .participant
  .participant-avatar
  .more-overlay
  span {
  font-family: "Roboto", sans-serif;
  color: #fff;
}

/* line 639, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-participants
  .container-inner
  .participant
  .participant-avatar
  img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

/* line 650, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 654, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left {
  width: 60%;
  padding: 0 30px 30px 30px;
  border-right: 1px solid #dedede;
}

/* line 659, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block {
  margin-bottom: 40px;
}

/* line 662, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block:last-child {
  margin-bottom: 0;
}

/* line 666, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #393a4f;
  margin-bottom: 1rem;
  margin-top: 10px;
}

/* line 675, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block p {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #393a4f;
  margin-bottom: 10px;
}

/* line 682, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block .video-block-wrapper {
  margin: 0 6px 6px 6px;
}

/* line 686, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block .photo-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 690, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block .photo-group a {
  display: block;
  margin: 6px;
  width: calc(33.3% - 12px);
}

/* line 695, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .left .details-block .photo-group a img {
  border-radius: 8px;
  height: 110px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 705, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right {
  width: 40%;
  padding: 0 30px;
}

/* line 709, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .event-owner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

/* line 714, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .event-owner img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

/* line 720, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .event-owner .meta {
  margin-left: 12px;
}

/* line 723, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .event-owner .meta span {
  display: block;
  font-family: "Roboto", sans-serif;
}

/* line 727, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .event-owner .meta span:first-child {
  font-size: 0.85rem;
  color: #abb0be;
}

/* line 732, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .event-owner .meta span:nth-child(2) {
  font-size: 1.1rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 741, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .side-block {
  margin-bottom: 1rem;
}

/* line 744, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .side-block:last-child {
  margin-bottom: 0;
}

/* line 748, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .side-block .side-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 752, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .side-block .side-head .event-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 50%;
  margin-right: 6px;
}

/* line 762, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper
  .event-details
  .right
  .side-block
  .side-head
  .event-icon
  svg {
  height: 16px;
  width: 16px;
  stroke-width: 1.6px;
  stroke: #5596e6;
}

/* line 770, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .side-block .side-head > span {
  display: inline-block;
  color: #abb0be;
  font-size: 0.9rem;
}

/* line 778, src/assets/scss/pages/_app-events.scss */
.event-page-wrapper .event-details .right .side-block .side-body a {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #3d70b2;
}

@media only screen and (max-width: 1300px) {
  /* line 796, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .right-panel {
    width: 310px !important;
  }

  /* line 800, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner {
    width: calc(100% - 570px) !important;
    margin-right: 310px !important;
  }

  /* line 805, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner .event-list .event-item {
    padding: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  /* line 816, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .right-panel {
    display: none !important;
  }

  /* line 820, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner {
    width: calc(100% - 260px) !important;
    margin-right: 0 !important;
  }

  /* line 825, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner .event-list .event-item {
    padding: 40px 60px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 836, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .right-panel,
  .events-wrapper .left-panel {
    display: none !important;
  }

  /* line 840, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner {
    width: calc(100%) !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  /* line 846, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner .event-list .event-item {
    padding: 40px 60px;
    height: auto;
  }

  /* line 850, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner .event-list .event-item .event-title {
    font-size: 2rem !important;
  }

  /* line 860, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head {
    margin: 0 16px;
  }

  /* line 863, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .left {
    width: 50%;
  }

  /* line 866, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .left h2 {
    line-height: 1.2;
    margin-bottom: 6px;
  }

  /* line 872, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .right {
    width: 50%;
  }

  /* line 877, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-participants {
    max-width: 740px;
    padding: 30px 0;
  }
}

@media (max-width: 767px) {
  /* line 888, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .right-panel,
  .events-wrapper .left-panel {
    display: none !important;
  }

  /* line 892, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner {
    width: calc(100%) !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  /* line 898, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner .event-list .event-item {
    padding: 40px 30px;
    height: auto;
  }

  /* line 902, src/assets/scss/pages/_app-events.scss */
  .events-wrapper .wrapper-inner .event-list .event-item .event-title {
    font-size: 1.6rem !important;
    max-width: 280px;
  }

  /* line 909, src/assets/scss/pages/_app-events.scss */
  .events-wrapper
    .wrapper-inner
    .event-list
    .event-item
    .event-inner-wrap
    .event-content
    .event-owner {
    display: none;
  }

  /* line 914, src/assets/scss/pages/_app-events.scss */
  .events-wrapper
    .wrapper-inner
    .event-list
    .event-item
    .event-inner-wrap
    .event-options {
    top: 0px;
    right: 0px;
  }

  /* line 919, src/assets/scss/pages/_app-events.scss */
  .events-wrapper
    .wrapper-inner
    .event-list
    .event-item
    .event-inner-wrap
    .event-participants {
    margin-left: 0 !important;
  }

  /* line 930, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content {
    padding: 0 10px;
  }

  /* line 933, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }

  /* line 937, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .left {
    width: 100%;
    padding-bottom: 50px;
  }

  /* line 941, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .left h2 {
    line-height: 1.2;
    margin-bottom: 6px;
  }

  /* line 946, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .left .button-separator {
    top: unset;
    bottom: -25px;
    -webkit-transform: translate(0, 0) rotate(90deg);
    transform: translate(0, 0) rotate(90deg);
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  /* line 956, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-head .right {
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    border-top: 2px dashed #dedede;
    border-left: none;
  }

  /* line 966, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-participants {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  /* line 971, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-participants .container-inner {
    overflow-x: auto;
  }

  /* line 974, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper
    .event-content
    .event-participants
    .container-inner
    .participant {
    min-width: 60px;
  }

  /* line 980, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-details {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  /* line 983, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-details .left {
    width: 100%;
    padding: 20px 10px 10px 10px;
    border: none;
  }

  /* line 989, src/assets/scss/pages/_app-events.scss */
  .event-page-wrapper .event-content .event-details .right {
    width: 100%;
    padding: 10px 10px 30px 10px;
    border-right: none;
    border-bottom: 1px solid #dedede;
  }
}

/*! _app-news.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/pages/_app-news.scss */
.news-header {
  position: relative;
  top: -2px;
  width: 100%;
  height: calc(100vh - 60px);
}

/* line 23, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper {
  position: absolute;
  height: 100%;
  width: calc(100% - 360px);
  margin-right: 360px;
}

/* line 30, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-primary .overlay-layer {
  background: rgba(61, 112, 178, 0.6);
}

/* line 33, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-primary .overlay-layer:hover {
  background: rgba(61, 112, 178, 0.85);
}

/* line 40, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-accent .overlay-layer {
  background: rgba(85, 150, 230, 0.6);
}

/* line 43, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-accent .overlay-layer:hover {
  background: rgba(85, 150, 230, 0.85);
}

/* line 50, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-blue .overlay-layer {
  background: rgba(3, 155, 229, 0.6);
}

/* line 53, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-blue .overlay-layer:hover {
  background: rgba(3, 155, 229, 0.85);
}

/* line 60, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-green .overlay-layer {
  background: rgba(28, 229, 137, 0.6);
}

/* line 63, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-green .overlay-layer:hover {
  background: rgba(28, 229, 137, 0.85);
}

/* line 70, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-red .overlay-layer {
  background: rgba(247, 20, 22, 0.6);
}

/* line 73, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper.is-red .overlay-layer:hover {
  background: rgba(247, 20, 22, 0.85);
}

/* line 79, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(57, 58, 79, 0.6);
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* line 87, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer:hover {
  background: rgba(57, 58, 79, 0.85);
}

/* line 91, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer .text-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 99, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text {
  padding: 0 60px;
}

/* line 102, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text .tag {
  margin-bottom: 20px;
  text-transform: uppercase;
  height: 26px;
  border-radius: 50px;
  padding-left: 16px;
  padding-right: 16px;
  background: transparent;
  border: 1.2px solid white;
  color: white;
  margin-right: 6px;
}

/* line 115, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text h2 {
  font-family: Montserrat, sans-serif;
  font-size: 3.5rem;
  font-weight: 800;
  max-width: 640px;
  color: #fcfcfc;
  line-height: 1;
}

/* line 124, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text p {
  font-family: Montserrat, sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  max-width: 640px;
  color: #fcfcfc;
  margin: 10px 0;
}

/* line 133, src/assets/scss/pages/_app-news.scss */
.news-header
  .news-hero-wrapper
  .overlay-layer
  .text-layer
  .hero-text
  .cta-wrap {
  padding: 10px 0;
}

/* line 136, src/assets/scss/pages/_app-news.scss */
.news-header
  .news-hero-wrapper
  .overlay-layer
  .text-layer
  .hero-text
  .cta-wrap
  .button {
  font-size: 1rem;
  height: 47px;
  background: transparent;
  color: #fff;
  border: 1.4px solid #fff;
  min-width: 140px;
  margin-right: 6px;
}

/* line 145, src/assets/scss/pages/_app-news.scss */
.news-header
  .news-hero-wrapper
  .overlay-layer
  .text-layer
  .hero-text
  .cta-wrap
  .button:hover {
  background: #fff;
  color: #393a4f;
}

/* line 156, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 360px;
  height: 100%;
  background: #fff;
}

/* line 164, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* line 171, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 20%;
  border-right: 4px solid transparent;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 20px;
}

/* line 181, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-active {
  border-right-color: #393a4f;
}

/* line 184, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-active:before {
  display: block;
}

/* line 189, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story:before {
  content: "";
  display: none;
  position: absolute;
  top: 47%;
  left: -21px;
  height: 24px;
  width: 24px;
  background: #fff;
  z-index: 2;
  -webkit-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
}

/* line 203, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-primary.is-active {
  border-right-color: #3d70b2;
}

/* line 206, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-primary.is-active .avatar-wrap {
  border-color: #3d70b2;
}

/* line 213, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-accent.is-active {
  border-right-color: #5596e6;
}

/* line 216, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-accent.is-active .avatar-wrap {
  border-color: #5596e6;
}

/* line 223, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-blue.is-active {
  border-right-color: #039be5;
}

/* line 226, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-blue.is-active .avatar-wrap {
  border-color: #039be5;
}

/* line 233, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-green.is-active {
  border-right-color: #1ce589;
}

/* line 236, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-green.is-active .avatar-wrap {
  border-color: #1ce589;
}

/* line 243, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-red.is-active {
  border-right-color: #f71416;
}

/* line 246, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story.is-red.is-active .avatar-wrap {
  border-color: #f71416;
}

/* line 252, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .avatar-wrap {
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
  border: 1.4px solid #cecece;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 263, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .avatar-wrap img {
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

/* line 271, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .meta {
  margin-left: 20px;
}

/* line 274, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .meta span {
  display: block;
}

/* line 277, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .meta span:first-child {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 500;
  color: #999;
}

/* line 284, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .meta span:nth-child(2) {
  font-size: 0.92rem;
  font-family: Montserrat, sans-serif;
  color: #393a4f;
  font-weight: 600;
}

/* line 291, src/assets/scss/pages/_app-news.scss */
.news-header .news-hero-menu .stories .story .meta span:nth-child(3) {
  font-size: 0.8rem;
  font-family: Montserrat, sans-serif;
  color: #999;
  font-weight: 500;
}

/* line 308, src/assets/scss/pages/_app-news.scss */
.is-news {
  padding: 1rem;
}

/* line 312, src/assets/scss/pages/_app-news.scss */
.news-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 316, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card {
  position: relative;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin: 10px;
  width: calc(25% - 20px);
}

/* line 324, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card .category {
  border-radius: 100px;
  padding-left: 0.95em;
  padding-right: 0.95em;
  background: #f0f0f0;
  color: #393a4f;
}

/* line 332, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* line 336, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default.is-inverted {
  background: #212a38;
}

/* line 340, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default.is-inverted .news-content .news-title {
  font-weight: 600;
  color: #fcfcfc;
}

/* line 345, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default.is-inverted .news-content p {
  font-weight: 400;
  color: #e8e8e8;
}

/* line 352, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default.is-inverted
  .news-content
  .button-wrap
  .button {
  background: #161c25;
  border-color: #161c25;
  color: #fff;
}

/* line 357, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default.is-inverted
  .news-content
  .button-wrap
  .button:hover {
  background: #3d70b2;
  border-color: #3d70b2;
}

/* line 365, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default.is-inverted
  .news-content
  .button-wrap
  .actions
  .action:hover {
  background: #2c384b;
}

/* line 368, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default.is-inverted
  .news-content
  .button-wrap
  .actions
  .action:hover
  svg {
  stroke: #fcfcfc;
}

/* line 379, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default:hover .avatar-wrap {
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-color: #3d70b2;
}

/* line 387, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .avatar-wrapper {
  position: absolute;
  top: 20px;
  right: 30px;
  height: 44px;
  width: 44px;
  min-width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 399, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .avatar-wrap {
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
  border: 1.4px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 410, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .avatar-wrap img {
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1.6px solid #fcfcfc;
}

/* line 419, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default img {
  display: block;
  border-radius: 6px 6px 0 0;
  height: 160px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 427, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .category {
  position: absolute;
  top: 30px;
  left: 30px;
}

/* line 433, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content {
  padding: 20px 30px 30px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: calc(100% - 160px);
}

/* line 440, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content .news-title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: #393a4f;
  font-size: 1rem;
  margin: 0 0 10px 0;
}

/* line 448, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content p {
  margin-top: auto;
  color: #999;
  font-size: 0.9rem;
}

/* line 454, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content .button-wrap {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

/* line 461, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content .button-wrap .button:hover {
  background: #3d70b2 !important;
  border-color: #3d70b2 !important;
  color: #fff !important;
}

/* line 468, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content .button-wrap .actions {
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 473, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-default .news-content .button-wrap .actions .action {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 481, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default
  .news-content
  .button-wrap
  .actions
  .action:hover {
  background: #f5f5f5;
}

/* line 484, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default
  .news-content
  .button-wrap
  .actions
  .action:hover
  svg {
  stroke: #393a4f;
}

/* line 491, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default
  .news-content
  .button-wrap
  .actions
  .action.is-like.is-active
  svg {
  stroke: #f71416 !important;
  fill: #f71416 !important;
}

/* line 498, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-default
  .news-content
  .button-wrap
  .actions
  .action
  svg {
  height: 18px;
  width: 18px;
  stroke: #999;
}

/* line 509, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px;
  width: calc(50% - 20px);
}

/* line 516, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image img {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 100%;
  max-width: 45%;
  -o-object-fit: contain;
  object-fit: contain;
}

/* line 526, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image .news-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 55%;
  height: 100%;
}

/* line 532, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image .news-content .category {
  position: absolute;
}

/* line 536, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image .news-content .news-title {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1.3rem;
  margin: 26px 0 10px 0;
}

/* line 544, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image .news-content p {
  margin-top: auto;
  color: #999;
  font-size: 0.9rem;
}

/* line 549, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image .news-content p + p {
  margin-top: 10px;
}

/* line 554, src/assets/scss/pages/_app-news.scss */
.news-grid .news-card.is-wide.has-absolute-image .news-content .button-wrap {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

/* line 561, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .button:hover {
  background: #3d70b2 !important;
  border-color: #3d70b2 !important;
  color: #fff !important;
}

/* line 568, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .actions {
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 573, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .actions
  .action {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 582, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .actions
  .action:hover {
  background: #f5f5f5;
}

/* line 585, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .actions
  .action:hover
  svg {
  stroke: #393a4f;
}

/* line 592, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .actions
  .action.is-like.is-active
  svg {
  stroke: #f71416 !important;
  fill: #f71416 !important;
}

/* line 599, src/assets/scss/pages/_app-news.scss */
.news-grid
  .news-card.is-wide.has-absolute-image
  .news-content
  .button-wrap
  .actions
  .action
  svg {
  height: 18px;
  width: 18px;
  stroke: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  /* line 621, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper {
    width: 100%;
    margin: 0;
  }

  /* line 627, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text {
    padding: 0 30px;
  }

  /* line 630, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text h2 {
    font-size: 2rem;
  }

  /* line 638, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu {
    display: none;
  }

  /* line 645, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-default {
    width: calc(100% - 20px);
  }

  /* line 649, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide {
    width: calc(100% - 20px);
  }

  /* line 653, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide.has-absolute-image img {
    display: none;
  }

  /* line 657, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide.has-absolute-image .news-content {
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 669, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper {
    width: 100%;
    margin-right: 0;
  }

  /* line 675, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text {
    padding: 0 40px;
  }

  /* line 678, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text h2 {
    font-size: 3.4rem;
  }

  /* line 686, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu {
    display: none;
  }

  /* line 693, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-default {
    width: calc(50% - 20px);
  }

  /* line 697, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide {
    width: calc(50% - 20px);
  }

  /* line 701, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide.has-absolute-image img {
    display: none;
  }

  /* line 705, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide.has-absolute-image .news-content {
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 717, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper {
    width: calc(100% - 280px);
    margin-right: 280px;
  }

  /* line 723, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text {
    padding: 0 40px;
  }

  /* line 726, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-wrapper .overlay-layer .text-layer .hero-text h2 {
    font-size: 3.4rem;
  }

  /* line 734, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu {
    width: 280px;
  }

  /* line 739, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu .stories .story .avatar-wrap {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }

  /* line 744, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu .stories .story .avatar-wrap img {
    height: 28px;
    width: 28px;
  }

  /* line 750, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu .stories .story .meta {
    margin-left: 10px;
  }

  /* line 754, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu .stories .story .meta span:first-child {
    font-size: 0.75rem;
  }

  /* line 758, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu .stories .story .meta span:nth-child(2) {
    font-size: 0.8rem;
  }

  /* line 762, src/assets/scss/pages/_app-news.scss */
  .news-header .news-hero-menu .stories .story .meta span:nth-child(3) {
    font-size: 0.7rem;
  }

  /* line 774, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-default {
    width: calc(33.3% - 20px);
  }

  /* line 778, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide {
    width: calc(66.6% - 20px);
  }

  /* line 782, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide.has-absolute-image img {
    max-height: 78%;
  }

  /* line 786, src/assets/scss/pages/_app-news.scss */
  .news-grid .news-card.is-wide.has-absolute-image .news-content {
    max-width: 60%;
  }
}

/*! _app-questions.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 24, src/assets/scss/pages/_app-questions.scss */
.question-tabs {
  margin-bottom: 20px !important;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

/* line 33, src/assets/scss/pages/_app-questions.scss */
.question-tabs.is-nav {
  margin-bottom: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
}

/* line 39, src/assets/scss/pages/_app-questions.scss */
.question-tabs.is-nav ul li a {
  padding: 1.25em;
  border-bottom: 2px solid transparent;
}

/* line 46, src/assets/scss/pages/_app-questions.scss */
.question-tabs ul {
  border-color: transparent;
}

/* line 51, src/assets/scss/pages/_app-questions.scss */
.question-tabs ul li.is-active a {
  color: #5596e6;
  border-bottom-color: #5596e6;
}

/* line 57, src/assets/scss/pages/_app-questions.scss */
.question-tabs ul li a {
  color: #c6c6c6;
  border-bottom: 1px solid transparent;
  font-size: 0.9rem;
  padding: 1em;
}

/* line 71, src/assets/scss/pages/_app-questions.scss */
.questions-wrap,
.questions-demo {
  margin: 60px 0;
}

/* line 74, src/assets/scss/pages/_app-questions.scss */
.questions-wrap.is-smaller,
.questions-demo.is-smaller {
  margin: 20px 0;
}

/* line 78, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu,
.questions-demo .questions-menu {
  margin-right: 40px;
  max-width: 250px;
}

/* line 82, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu.questions-menu-fixed,
.questions-demo .questions-menu.questions-menu-fixed {
  position: fixed;
  width: 100%;
  max-width: 250px;
  margin-top: 40px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* line 91, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu.questions-menu-fixed.is-faded,
.questions-demo .questions-menu.questions-menu-fixed.is-faded {
  opacity: 1;
  pointer-events: all;
}

/* line 99, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu li.is-active a,
.questions-demo .questions-menu li.is-active a {
  background: #fff;
}

/* line 102, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu li.is-active a svg,
.questions-demo .questions-menu li.is-active a svg {
  stroke: #5596e6;
}

/* line 106, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu li.is-active a span,
.questions-demo .questions-menu li.is-active a span {
  color: #5596e6;
}

/* line 112, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu li a,
.questions-demo .questions-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 100px;
}

/* line 119, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu li a svg,
.questions-demo .questions-menu li a svg {
  height: 22px;
  height: 22px;
  margin-right: 10px;
  stroke: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 127, src/assets/scss/pages/_app-questions.scss */
.questions-wrap .questions-menu li a span,
.questions-demo .questions-menu li a span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 143, src/assets/scss/pages/_app-questions.scss */
.question-content {
  max-width: 640px;
  margin: 0 auto;
}

/* line 147, src/assets/scss/pages/_app-questions.scss */
.question-content.is-large {
  max-width: 840px;
}

/* line 151, src/assets/scss/pages/_app-questions.scss */
.question-content .top-single-header {
  margin: 0 0 20px 0;
}

/* line 155, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block {
  border-bottom: 1px solid #e3e3e3;
}

/* line 158, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block.is-top-spaced {
  margin-top: 30px;
}

/* line 162, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-title {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1.6rem;
}

/* line 168, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-title.is-link {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 171, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-title.is-link:hover {
  color: #5596e6;
}

/* line 177, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}

/* line 182, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-author img {
  display: block;
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 50%;
}

/* line 190, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-author .meta {
  margin-left: 12px;
}

/* line 193, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-author .meta span {
  display: block;
}

/* line 196, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-author .meta span:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 203, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-author .meta span:nth-child(2) {
  font-size: 0.85rem;
  color: #999;
}

/* line 212, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-text p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #707298;
  font-size: 0.95rem;
  max-width: 680px;
}

/* line 220, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-text .tags {
  padding-top: 10px;
}

/* line 223, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-text .tags .tag {
  border-radius: 100px;
  line-height: 1;
}

/* line 230, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer {
  padding: 20px 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 700px;
}

/* line 237, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer .likes,
.question-content .question-block .question-footer .report {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 241, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer .likes .like-icon,
.question-content .question-block .question-footer .report .like-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background: #f71416;
  border-radius: 50%;
}

/* line 250, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer .likes .like-icon svg,
.question-content .question-block .question-footer .report .like-icon svg {
  height: 16px;
  width: 16px;
  stroke: #fff;
  fill: #fff;
}

/* line 258, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer .likes .report-icon,
.question-content .question-block .question-footer .report .report-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background: #f5f5f5;
  border-radius: 50%;
}

/* line 267, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer .likes .report-icon svg,
.question-content .question-block .question-footer .report .report-icon svg {
  height: 16px;
  width: 16px;
  stroke: #999;
}

/* line 274, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .question-footer .likes span,
.question-content .question-block .question-footer .report span {
  display: block;
  font-weight: 500;
  margin-left: 10px;
  font-size: 0.9rem;
}

/* line 283, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .answers-count {
  padding: 20px 4px;
}

/* line 286, src/assets/scss/pages/_app-questions.scss */
.question-content .question-block .answers-count span {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 295, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block {
  padding: 10px 0;
  border-bottom: 1px solid #e3e3e3;
}

/* line 301, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block.is-best .answer-author .avatar-wrap {
  border: 1.4px solid #1ce589;
}

/* line 304, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block.is-best .answer-author .avatar-wrap .badge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 312, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block.is-best .answer-footer .upvote .upvote-icon {
  background: #1ce589;
}

/* line 319, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}

/* line 324, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .avatar-wrap {
  position: relative;
  height: 48px;
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 1.4px solid #999;
}

/* line 334, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .avatar-wrap .avatar {
  display: block;
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 50%;
}

/* line 342, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .avatar-wrap .badge {
  position: absolute;
  top: -4px;
  right: -7px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 355, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .avatar-wrap .badge svg {
  height: 12px;
  width: 12px;
  stroke: #fcfcfc;
  stroke-width: 3px;
}

/* line 364, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .meta {
  margin-left: 12px;
}

/* line 367, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .meta span {
  display: block;
}

/* line 370, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .meta span:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 376, src/assets/scss/pages/_app-questions.scss */
.question-content
  .answer-block
  .answer-author
  .meta
  span:first-child
  .best-tag {
  margin-left: 10px;
  padding: 4px 12px;
  border-radius: 100px;
  background: #c1f8df;
  font-weight: 400;
  font-size: 0.75rem;
  color: #1ce589;
  font-family: "Roboto", sans-serif;
}

/* line 388, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-author .meta span:nth-child(2) {
  font-size: 0.85rem;
  color: #999;
}

/* line 397, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-text p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #707298;
  font-size: 0.95rem;
  max-width: 680px;
  margin-bottom: 20px;
}

/* line 405, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-text p.code {
  padding: 30px;
  border-radius: 8px;
  background: #f5f5f8;
  font-size: 0.88rem;
  color: #5b5d7e;
}

/* line 413, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-text p a {
  display: block;
  word-break: break-all;
  color: #039be5;
  text-decoration: underline;
}

/* line 422, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer {
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 700px;
}

/* line 429, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer .upvote,
.question-content .answer-block .answer-footer .report {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 433, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer .upvote .upvote-icon,
.question-content .answer-block .answer-footer .report .upvote-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background: #ebebeb;
  border-radius: 50%;
}

/* line 442, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer .upvote .upvote-icon svg,
.question-content .answer-block .answer-footer .report .upvote-icon svg {
  height: 16px;
  width: 16px;
  stroke: #fff;
  stroke-width: 4px;
}

/* line 450, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer .upvote .report-icon,
.question-content .answer-block .answer-footer .report .report-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background: #f5f5f5;
  border-radius: 50%;
}

/* line 459, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer .upvote .report-icon svg,
.question-content .answer-block .answer-footer .report .report-icon svg {
  height: 16px;
  width: 16px;
  stroke: #999;
}

/* line 466, src/assets/scss/pages/_app-questions.scss */
.question-content .answer-block .answer-footer .upvote span,
.question-content .answer-block .answer-footer .report span {
  display: block;
  font-weight: 500;
  margin-left: 10px;
  font-size: 0.9rem;
}

/* line 476, src/assets/scss/pages/_app-questions.scss */
.question-content .more-answers {
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 680px;
}

/* line 483, src/assets/scss/pages/_app-questions.scss */
.question-content .more-answers a {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #999;
  padding: 6px 20px;
  background: #f2f2f2;
  border-radius: 100px;
  padding: 12px 20px;
  min-width: 188px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 495, src/assets/scss/pages/_app-questions.scss */
.question-content .more-answers a:hover {
  background: #3d70b2;
  color: #fff;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 501, src/assets/scss/pages/_app-questions.scss */
.question-content .more-answers a.is-loading {
  width: 42px;
}

/* line 513, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-title {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #5b5d7e;
  font-size: 1.2rem;
}

/* line 520, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

/* line 526, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .avatar-wrap {
  position: relative;
  height: 58px;
  width: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 1.4px solid #999;
}

/* line 536, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .avatar-wrap .avatar {
  display: block;
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
}

/* line 544, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .avatar-wrap .badge {
  position: absolute;
  top: -4px;
  right: -7px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 557, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .avatar-wrap .badge svg {
  height: 12px;
  width: 12px;
  stroke: #fcfcfc;
  stroke-width: 3px;
}

/* line 566, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .meta {
  margin-left: 12px;
}

/* line 569, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .meta span,
.other-questions .other-question .meta a {
  display: block;
}

/* line 572, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .meta span:first-child,
.other-questions .other-question .meta a:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #5b5d7e;
  font-size: 1rem;
}

/* line 579, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .meta span:nth-child(2),
.other-questions .other-question .meta a:nth-child(2) {
  font-size: 0.9rem;
  color: #999;
}

/* line 585, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .meta a {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 588, src/assets/scss/pages/_app-questions.scss */
.other-questions .other-question .meta a:hover {
  color: #5596e6;
}

/* line 600, src/assets/scss/pages/_app-questions.scss */
.questions-nav {
  position: fixed;
  top: 58px;
  left: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #dedede;
  background: #fff;
  z-index: 18;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 612, src/assets/scss/pages/_app-questions.scss */
.questions-nav.is-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* line 616, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 624, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner.is-scrollable {
  overflow-x: auto !important;
}

/* line 628, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
}

/* line 636, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary img {
  display: block;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}

/* line 644, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .meta {
  margin-left: 12px;
}

/* line 647, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .meta h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1rem;
}

/* line 654, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .meta span {
  display: block;
  font-size: 0.8rem;
  color: #999;
}

/* line 661, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .dropdown {
  margin-left: auto;
}

/* line 665, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .dropdown:hover .button {
  background: #f0f0f0;
}

/* line 668, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .dropdown:hover .button svg {
  stroke: #393a4f;
}

/* line 674, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .dropdown .button {
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 682, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .dropdown .button svg {
  height: 20px;
  width: 20px;
  stroke: #999;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 690, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .question-summary .dropdown .dropdown-menu {
  margin-top: 14px;
}

/* line 696, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 701, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 6px 20px 6px 16px;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 710, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item:hover svg {
  stroke: #5596e6;
}

/* line 714, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item:hover span {
  color: #5596e6;
}

/* line 719, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item.is-active {
  background: #fff;
}

/* line 722, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item.is-active svg {
  stroke: #5596e6;
}

/* line 726, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item.is-active span {
  color: #5596e6;
}

/* line 731, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item svg {
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 738, src/assets/scss/pages/_app-questions.scss */
.questions-nav .inner .questions-nav-menu .menu-item span {
  color: #595b7b;
  font-size: 0.9rem;
  margin-left: 3px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 753, src/assets/scss/pages/_app-questions.scss */
.categories-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}

/* line 761, src/assets/scss/pages/_app-questions.scss */
.categories-header h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1.6rem;
}

/* line 768, src/assets/scss/pages/_app-questions.scss */
.categories-header .control {
  position: relative;
}

/* line 771, src/assets/scss/pages/_app-questions.scss */
.categories-header .control input {
  padding-bottom: 8px;
  padding-left: 36px;
}

/* line 775, src/assets/scss/pages/_app-questions.scss */
.categories-header .control input:focus {
  border-color: #ededed;
}

/* line 779, src/assets/scss/pages/_app-questions.scss */
.categories-header .control input:focus + .search-icon svg {
  stroke: #5596e6;
}

/* line 786, src/assets/scss/pages/_app-questions.scss */
.categories-header .control .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 796, src/assets/scss/pages/_app-questions.scss */
.categories-header .control .search-icon svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 810, src/assets/scss/pages/_app-questions.scss */
.category-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px;
  border: 1px solid #dedede;
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 819, src/assets/scss/pages/_app-questions.scss */
.category-box.is-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* line 824, src/assets/scss/pages/_app-questions.scss */
.category-box.is-row img {
  margin: 0;
  min-height: 160px;
  max-height: 160px;
}

/* line 830, src/assets/scss/pages/_app-questions.scss */
.category-box.is-row .box-content {
  padding-left: 40px;
}

/* line 836, src/assets/scss/pages/_app-questions.scss */
.category-box.is-taller img {
  margin: 0;
  min-height: 140px;
  max-height: 140px;
}

/* line 843, src/assets/scss/pages/_app-questions.scss */
.category-box:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 846, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-primary {
  border-color: #3d70b2;
}

/* line 849, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-primary img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 855, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-primary .box-content h3 {
  color: #3d70b2;
}

/* line 861, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-accent {
  border-color: #5596e6;
}

/* line 864, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-accent img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 870, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-accent .box-content h3 {
  color: #5596e6;
}

/* line 876, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-green {
  border-color: #1ce589;
}

/* line 879, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-green img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 885, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-green .box-content h3 {
  color: #1ce589;
}

/* line 891, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-blue {
  border-color: #039be5;
}

/* line 894, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-blue img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 900, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-blue .box-content h3 {
  color: #039be5;
}

/* line 906, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-red {
  border-color: #f71416;
}

/* line 909, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-red img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 915, src/assets/scss/pages/_app-questions.scss */
.category-box:hover.is-red .box-content h3 {
  color: #f71416;
}

/* line 922, src/assets/scss/pages/_app-questions.scss */
.category-box img {
  display: block;
  margin: 0 auto;
  min-height: 110px;
  max-height: 110px;
  margin-bottom: 30px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.6;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 934, src/assets/scss/pages/_app-questions.scss */
.category-box .box-content h3 {
  font-family: "Montserrat", sans-serif;
  color: #393a4f;
  margin-bottom: 6px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 941, src/assets/scss/pages/_app-questions.scss */
.category-box .box-content p {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #999;
}

/* line 954, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

/* line 964, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .avatar-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 96px;
  width: 96px;
  min-width: 96px;
  border-radius: 50%;
  border: 2px solid #cecece;
}

/* line 975, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .avatar-wrapper .avatar {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

/* line 982, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .avatar-wrapper .badge {
  position: absolute;
  top: 1px;
  right: -1px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 995, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .avatar-wrapper .badge svg {
  height: 13px;
  width: 13px;
  stroke: #fff;
  stroke-width: 4px;
}

/* line 1004, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .user-info {
  margin-left: 20px;
}

/* line 1007, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .user-info h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #393a4f;
  font-size: 1.1rem;
}

/* line 1014, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .user-info p {
  color: #999;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif;
}

/* line 1020, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .user-info .button {
  margin-top: 10px;
  height: 36px;
  min-width: 110px;
  border-radius: 100px;
  padding: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1028, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .user-info .button.is-follow {
  border: 1.6px solid #5596e6;
  color: #5596e6;
}

/* line 1032, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .user-info .button.is-follow:hover {
  background: #5596e6;
  color: #fcfcfc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 1041, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .main-stats {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 1045, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .main-stats .stat-block {
  padding: 0 16px;
  text-align: center;
}

/* line 1049, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .main-stats .stat-block.is-centered {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

/* line 1054, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .main-stats .stat-block h4 {
  text-transform: uppercase;
  font-size: 0.7rem;
  color: #393a4f;
  font-weight: 500;
}

/* line 1061, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .stats-header .main-stats .stat-block p {
  font-weight: bolder;
  font-size: 1.4rem;
  color: #707298;
}

/* line 1071, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}

/* line 1076, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .header h3 {
  font-family: "Montserrat", sans-serif;
  color: #595b7b;
  font-size: 0.9rem;
  font-weight: 600;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1085, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel-wrap {
  position: relative;
  overflow: hidden;
  margin-top: -6px;
}

/* line 1090, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel-wrap .achievements-loader {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

/* line 1104, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .achievements
  .achievements-carousel-wrap
  .achievements-loader.is-active {
  opacity: 1;
  z-index: 1;
}

/* line 1109, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .achievements
  .achievements-carousel-wrap
  .achievements-loader
  .loader {
  height: 2rem;
  width: 2rem;
}

/* line 1116, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel {
  max-height: 250px;
}

/* line 1119, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel .achievement {
  padding: 30px;
  border: 1px solid #dedede;
  background: #fff;
  border-radius: 8px;
  margin: 6px;
  outline: none !important;
  cursor: grab;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1129, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel .achievement:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border-color: #5596e6;
}

/* line 1134, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel .achievement img {
  display: block;
  height: 90px;
  margin: 0 auto;
}

/* line 1142, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel .achievement h3 {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  color: #595b7b;
  font-size: 0.85rem;
  font-weight: 500;
}

/* line 1150, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .achievements .achievements-carousel .achievement p {
  color: #999;
  font-size: 0.8rem;
  min-height: 39px;
}

/* line 1160, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
}

/* line 1166, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .header h3 {
  font-family: "Montserrat", sans-serif;
  color: #595b7b;
  font-size: 0.9rem;
  font-weight: 600;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1175, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .activity-list {
  padding: 10px;
}

/* line 1178, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .activity-list .activity-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding: 16px;
}

/* line 1186, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item.is-best
  .avatar-wrap {
  border: 1.4px solid #1ce589;
}

/* line 1189, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item.is-best
  .avatar-wrap
  .badge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 1195, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .activity-list .activity-item .avatar-wrap {
  position: relative;
  height: 48px;
  width: 48px;
  min-width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 1.4px solid #cecece;
}

/* line 1206, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .avatar-wrap
  .avatar {
  display: block;
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 50%;
}

/* line 1214, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .avatar-wrap
  .badge {
  position: absolute;
  top: -4px;
  right: -7px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 1227, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .avatar-wrap
  .badge
  svg {
  height: 12px;
  width: 12px;
  stroke: #fcfcfc;
  stroke-width: 3px;
}

/* line 1236, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .activity-list .activity-item .meta {
  margin-left: 16px;
}

/* line 1239, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .activity-list .activity-item .meta span {
  display: block;
}

/* line 1242, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .meta
  span:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 1248, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .meta
  span:first-child
  .best-tag {
  margin-left: 10px;
  padding: 4px 12px;
  border-radius: 100px;
  background: #c1f8df;
  font-weight: 400;
  font-size: 0.75rem;
  color: #1ce589;
  font-family: "Roboto", sans-serif;
}

/* line 1260, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .meta
  span:nth-child(2) {
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  color: #999;
  max-width: 450px;
}

/* line 1266, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .meta
  span:nth-child(2)
  a {
  color: #595b7b;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1270, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper
  .quick-activity
  .activity-list
  .activity-item
  .meta
  span:nth-child(2)
  a:hover {
  color: #5596e6;
}

/* line 1277, src/assets/scss/pages/_app-questions.scss */
.stats-wrapper .quick-activity .activity-list .activity-item .meta small {
  margin-top: 6px;
  font-size: 0.75rem;
  color: #999;
}

/* line 1293, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 30px;
}

/* line 1301, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1.6rem;
}

/* line 1308, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header .control {
  position: relative;
}

/* line 1311, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header .control input {
  padding-bottom: 8px;
  padding-left: 36px;
}

/* line 1315, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header .control input:focus {
  border-color: #ededed;
}

/* line 1319, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header .control input:focus + .search-icon svg {
  stroke: #5596e6;
}

/* line 1326, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header .control .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1336, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-header .control .search-icon svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 1347, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-body .switch-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

/* line 1352, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-body .switch-block .meta {
  margin-left: 16px;
}

/* line 1355, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-body .switch-block .meta span {
  display: block;
}

/* line 1358, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-body .switch-block .meta span:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 1365, src/assets/scss/pages/_app-questions.scss */
.questions-settings .settings-body .switch-block .meta span:nth-child(2) {
  font-size: 0.85rem;
  color: #999;
}

/* line 1375, src/assets/scss/pages/_app-questions.scss */
.questions-side-card {
  width: 100%;
  background: #fff;
  padding: 30px;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* line 1383, src/assets/scss/pages/_app-questions.scss */
.questions-side-card img {
  display: block;
  max-width: 80px;
  margin: 0 auto 20px auto;
}

/* line 1389, src/assets/scss/pages/_app-questions.scss */
.questions-side-card h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 1396, src/assets/scss/pages/_app-questions.scss */
.questions-side-card p {
  font-size: 0.9rem;
  color: #999;
}

@media (max-width: 767px) {
  /* line 1409, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .question-summary {
    max-width: 340px !important;
  }

  /* line 1413, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .question-summary .meta h4 {
    font-size: 0.85rem !important;
  }

  /* line 1417, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .question-summary .meta span {
    display: none !important;
  }

  /* line 1424, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .questions-nav-menu .menu-item {
    padding: 6px 16px !important;
  }

  /* line 1427, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .questions-nav-menu .menu-item svg {
    height: 18px !important;
    width: 18px !important;
  }

  /* line 1432, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .questions-nav-menu .menu-item span {
    display: none !important;
  }

  /* line 1439, src/assets/scss/pages/_app-questions.scss */
  .questions-wrap {
    margin: 26px 0;
    padding: 0 10px;
  }

  /* line 1443, src/assets/scss/pages/_app-questions.scss */
  .questions-wrap .column.is-4,
  .questions-wrap .column.is-3 {
    display: none;
  }

  /* line 1449, src/assets/scss/pages/_app-questions.scss */
  .categories-header h2,
  .settings-header h2 {
    font-size: 1.3rem !important;
  }

  /* line 1453, src/assets/scss/pages/_app-questions.scss */
  .categories-header .control,
  .settings-header .control {
    max-width: 200px;
  }

  /* line 1459, src/assets/scss/pages/_app-questions.scss */
  .category-box.is-row {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  /* line 1462, src/assets/scss/pages/_app-questions.scss */
  .category-box.is-row img {
    min-height: 110px !important;
    max-height: 110px !important;
  }

  /* line 1467, src/assets/scss/pages/_app-questions.scss */
  .category-box.is-row .box-content {
    padding-left: 0 !important;
    padding-top: 20px;
  }

  /* line 1474, src/assets/scss/pages/_app-questions.scss */
  .category-box.is-taller img {
    min-height: 110px !important;
    max-height: 110px !important;
  }

  /* line 1479, src/assets/scss/pages/_app-questions.scss */
  .category-box.is-taller .box-content {
    padding-left: 0 !important;
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 1490, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .question-summary {
    margin: 0 auto;
    width: 100%;
    max-width: 640px;
  }

  /* line 1497, src/assets/scss/pages/_app-questions.scss */
  .questions-wrap {
    margin: 30px 0;
    padding: 0 60px;
  }

  /* line 1501, src/assets/scss/pages/_app-questions.scss */
  .questions-wrap .column.is-4,
  .questions-wrap .column.is-3 {
    display: none;
  }

  /* line 1506, src/assets/scss/pages/_app-questions.scss */
  .category-box {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  /* line 1512, src/assets/scss/pages/_app-questions.scss */
  .category-box img {
    margin: 0 !important;
    min-height: auto !important;
    max-height: none !important;
    max-width: 120px !important;
    min-width: 120px !important;
  }

  /* line 1520, src/assets/scss/pages/_app-questions.scss */
  .category-box .box-content {
    padding-left: 40px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 1529, src/assets/scss/pages/_app-questions.scss */
  .questions-nav .question-summary {
    margin: 0 auto;
    width: 100%;
    max-width: 640px;
  }

  /* line 1538, src/assets/scss/pages/_app-questions.scss */
  .categories-tile-grid .tile.is-parent {
    padding: 0.5rem !important;
  }

  /* line 1546, src/assets/scss/pages/_app-questions.scss */
  .category-box .box-content p {
    font-size: 0.8rem !important;
  }

  /* line 1552, src/assets/scss/pages/_app-questions.scss */
  .questions-menu-fixed {
    max-width: 190px !important;
  }

  /* line 1556, src/assets/scss/pages/_app-questions.scss */
  .questions-side-card {
    padding: 20px;
  }

  /* line 1559, src/assets/scss/pages/_app-questions.scss */
  .questions-side-card img {
    max-width: 60px;
  }

  /* line 1563, src/assets/scss/pages/_app-questions.scss */
  .questions-side-card p {
    font-size: 0.8rem;
  }
}

@media (max-width: 1300px) {
  /* line 1572, src/assets/scss/pages/_app-questions.scss */
  .stats-wrapper .stats-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 1575, src/assets/scss/pages/_app-questions.scss */
  .stats-wrapper .stats-header .user-info {
    margin: 0;
  }

  /* line 1579, src/assets/scss/pages/_app-questions.scss */
  .stats-wrapper .stats-header .main-stats {
    margin: 0 auto;
    margin-top: 20px;
  }
}

/* line 1587, src/assets/scss/pages/_app-questions.scss */
#questions-shadow-dom-home {
  max-width: 640px;
  margin: 0 auto;
}

/*! _app-videos.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 19, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar {
  position: fixed;
  top: 57px;
  left: 0;
  height: calc(100% - 56px);
  background: #fafafa;
  border-right: 1px solid #e8e8e8;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 10;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 32, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar.is-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* line 36, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 41, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner .user-block {
  position: relative;
  margin: 20px 20px 0 20px;
  border-bottom: 1px solid #dedede;
  text-align: center;
  height: 200px;
}

/* line 48, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .close-videos-sidebar {
  position: absolute;
  top: -22px;
  right: -22px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 60, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .close-videos-sidebar:hover {
  background: #fff;
}

/* line 63, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .close-videos-sidebar:hover
  svg {
  stroke: #393a4f;
}

/* line 68, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .close-videos-sidebar
  svg {
  height: 16px;
  width: 16px;
  color: #999;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 76, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner .user-block .avatar-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 74px;
  height: 74px;
  min-width: 74px;
  border: 1.4px solid #d8d8d8;
  border-radius: 50%;
  margin: 0 auto 6px auto;
}

/* line 88, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .avatar-wrap
  img {
  display: block;
  width: 58px;
  height: 58px;
  min-width: 58px;
  border-radius: 50%;
}

/* line 96, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .avatar-wrap
  .badge {
  position: absolute;
  bottom: 2px;
  right: -3px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 109, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .avatar-wrap
  .badge
  svg {
  height: 10px;
  width: 10px;
  stroke: #fcfcfc;
  stroke-width: 4px;
}

/* line 118, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner .user-block h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 125, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner .user-block p {
  font-size: 0.8rem;
  color: #999;
}

/* line 130, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner .user-block .user-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
}

/* line 136, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .user-stats
  .stat-block {
  text-align: center;
}

/* line 139, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span {
  display: block;
}

/* line 142, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span:first-child {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.65rem;
  color: #393a4f;
}

/* line 149, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span:last-child {
  font-size: 0.9rem;
  color: #999;
}

/* line 158, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .videos-sidebar .videos-sidebar-inner .user-menu {
  position: absolute;
  top: 200px;
  left: 0;
  width: 100%;
  height: calc(100% - 230px);
}

/* line 165, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}

/* line 172, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .separator {
  margin: 0 30px;
  border-bottom: 1px solid #dedede;
}

/* line 177, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block {
  padding: 20px 0;
}

/* line 181, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 40px;
  border-left: 4px solid transparent;
}

/* line 187, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li.is-active {
  border-color: #5596e6;
}

/* line 191, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 195, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a
  span {
  display: block;
  color: #393a4f;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}

/* line 203, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .videos-sidebar
  .videos-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a
  svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  margin-right: 20px;
}

/* line 224, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home {
  padding-left: 0;
  padding-right: 0;
}

/* line 228, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper {
  position: relative;
  top: -2px;
}

/* line 232, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger {
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 30px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 245, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger.is-home-v2 {
  position: absolute;
  top: 23px;
  left: 34px;
  z-index: 5;
}

/* line 251, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger.is-home-v2 svg {
  stroke: #999;
}

/* line 257, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger:hover svg {
  stroke: #fff;
}

/* line 262, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger svg {
  height: 20px;
  width: 20px;
  color: #fcfcfc;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 270, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header-wrap {
  margin-bottom: 0;
}

/* line 273, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header-wrap .slick-dots {
  bottom: 20px !important;
}

/* line 276, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header-wrap .slick-dots li {
  margin: 0;
}

/* line 280, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header-wrap
  .slick-dots
  li
  button:before {
  color: #fcfcfc;
}

/* line 288, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

/* line 294, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header:hover .cover-overlay {
  background: rgba(57, 58, 79, 0.6);
}

/* line 299, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header .cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 0;
}

/* line 310, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header .cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(57, 58, 79, 0.4);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

/* line 321, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home .home-wrapper .video-header .cover-caption {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

/* line 329, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 30px;
}

/* line 337, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* line 342, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block {
  width: 50%;
}

/* line 345, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fcfcfc;
}

/* line 352, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .video-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 355, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .video-meta
  span {
  display: block;
  color: #fcfcfc;
  padding-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
}

/* line 363, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .video-meta
  span.rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 367, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .video-meta
  span.rating
  svg {
  height: 14px;
  width: 14px;
  stroke: #fcfcfc;
  fill: #fcfcfc;
  margin: 0 2px;
}

/* line 378, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .description {
  font-family: "Roboto", sans-serif;
  font-size: 0.85rem;
  color: #fcfcfc;
  padding: 12px 0;
  max-width: 400px;
}

/* line 386, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
  max-width: 400px;
}

/* line 394, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .button {
  min-width: 130px;
  margin-right: 30px;
}

/* line 398, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .button:hover {
  background: #5596e6;
  border-color: #5596e6;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 405, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .trailer-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 410, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .trailer-button:hover
  svg {
  opacity: 1;
  stroke: #d1e3f8;
}

/* line 415, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .trailer-button:hover
  span {
  margin-left: 5px;
  color: #d1e3f8;
}

/* line 421, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .trailer-button
  svg {
  opacity: 0;
  stroke: #fcfcfc;
  height: 16px;
  width: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 429, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.is-home
  .home-wrapper
  .video-header
  .cover-caption
  .caption-inner
  .video-caption
  .caption-block
  .caption-actions
  .trailer-button
  span {
  color: #fcfcfc;
  font-size: 0.85rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 444, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content {
  padding: 30px 40px;
}

/* line 447, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 450, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-header > a {
  margin-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  color: #cecece;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 460, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-header > a.is-active {
  color: #393a4f;
  border-color: #5596e6;
}

/* line 466, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-header .search-button {
  height: 36px;
  width: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
}

/* line 475, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-header .search-button:hover svg {
  stroke: #393a4f;
}

/* line 480, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-header .search-button svg {
  position: relative;
  top: 2px;
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 491, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-wrap {
  display: none;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
  padding: 30px 0;
}

/* line 496, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-wrap.is-active {
  display: block;
}

/* line 501, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-wrap .collection .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 505, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-wrap .collection .header h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 512, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-wrap .collection .header a {
  padding: 0 24px;
  color: #999;
  font-size: 0.8rem;
}

/* line 519, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper .home-content .collections-wrap .collection .video-collection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 0 20px 0;
  margin-left: -10px;
  margin-right: -10px;
}

/* line 528, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode {
  margin: 10px;
  width: calc(25% - 20px);
}

/* line 532, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail {
  position: relative;
}

/* line 536, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail:hover
  .episode-overlay {
  background: rgba(57, 58, 79, 0.4);
}

/* line 540, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail:hover
  .play-button {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
}

/* line 546, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail
  .episode-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: rgba(57, 58, 79, 0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

/* line 558, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail
  .episode-duration {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 3px 6px;
  background: black;
  border-radius: 4px;
  color: #fcfcfc;
  font-size: 0.7rem;
  z-index: 2;
}

/* line 570, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail
  .play-button {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
  opacity: 0;
  -webkit-transform: scale(0.7) rotate(-180deg);
  transform: scale(0.7) rotate(-180deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 584, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail
  .play-button
  svg {
  height: 38px;
  width: 38px;
  stroke-width: 1px;
  stroke: #fcfcfc;
}

/* line 592, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-thumbnail
  img {
  display: block;
  border-radius: 6px;
}

/* line 598, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
}

/* line 604, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-meta
  img {
  display: block;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}

/* line 612, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-meta
  .info {
  margin-left: 8px;
}

/* line 615, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-meta
  .info
  span {
  display: block;
  line-height: 1.2;
}

/* line 619, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-meta
  .info
  span:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.85rem;
  color: #393a4f;
}

/* line 626, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper
  .home-content
  .collections-wrap
  .collection
  .video-collection
  .episode
  .episode-meta
  .info
  span:last-child {
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  color: #999;
}

/* line 648, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .player-wrapper {
  position: relative;
  top: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 653, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .player-wrapper .video-side {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

/* line 658, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box {
  padding: 30px;
  background: #fff;
}

/* line 662, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 669, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 6px 0;
}

/* line 674, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-subtitle .views-count {
  color: #999;
}

/* line 678, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-subtitle .quick-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 682, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .meta-box
  .video-subtitle
  .quick-actions
  .quick-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
  margin: 0 3px;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 692, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .meta-box
  .video-subtitle
  .quick-actions
  .quick-action:hover {
  background: #f2f2f2;
}

/* line 695, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .meta-box
  .video-subtitle
  .quick-actions
  .quick-action:hover
  svg {
  stroke: #393a4f;
}

/* line 700, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .meta-box
  .video-subtitle
  .quick-actions
  .quick-action.is-mobile {
  display: none;
}

/* line 704, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .meta-box
  .video-subtitle
  .quick-actions
  .quick-action
  svg {
  height: 20px;
  width: 20px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 714, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 718, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner img {
  display: block;
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
}

/* line 726, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner .meta {
  margin-left: 12px;
}

/* line 729, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner .meta span {
  display: block;
}

/* line 732, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner .meta span:first-child {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 739, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner .meta span:nth-child(2) {
  color: #999;
  font-size: 0.8rem;
}

/* line 746, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner .button {
  margin-left: auto;
  background: #5596e6;
  border-color: #5596e6;
  color: #fff;
  border-radius: 100px;
  min-width: 130px;
}

/* line 754, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .meta-box .video-owner .button:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 762, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .video-description hr {
  margin: 1rem 0;
}

/* line 766, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .video-description h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
  margin-bottom: 10px;
}

/* line 774, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .video-description p,
.videos-wrapper.has-player .video-description ul li {
  font-family: "Roboto", sans-serif;
  color: #797a9e;
  font-size: 0.95rem;
  max-width: 640px;
}

/* line 781, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .video-description .additional-description {
  display: none;
  padding-top: 30px;
}

/* line 786, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .video-description .video-description-more {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  text-transform: uppercase;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 794, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .video-description .video-description-more:hover {
  color: #5596e6;
}

/* line 800, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comments-wrap {
  padding: 20px 40px;
}

/* line 804, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comments-count {
  padding: 20px 0;
}

/* line 808, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box {
  max-width: 720px;
}

/* line 813, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap,
.videos-wrapper.has-player .is-comment .avatar-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 54px;
  height: 54px;
  min-width: 54px;
  border: 1.4px solid #cecece;
  border-radius: 50%;
}

/* line 824, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap.is-smaller,
.videos-wrapper.has-player .is-comment .avatar-wrap.is-smaller {
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-width: 1px;
}

/* line 830, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap.is-smaller img,
.videos-wrapper.has-player .is-comment .avatar-wrap.is-smaller img {
  width: 32px;
  height: 32px;
  min-width: 32px;
}

/* line 836, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap.is-smaller .badge,
.videos-wrapper.has-player .is-comment .avatar-wrap.is-smaller .badge {
  right: -3px;
  height: 18px;
  width: 18px;
}

/* line 841, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap.is-smaller .badge svg,
.videos-wrapper.has-player .is-comment .avatar-wrap.is-smaller .badge svg {
  height: 8px;
  width: 8px;
  stroke-width: 4px;
}

/* line 849, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap img,
.videos-wrapper.has-player .is-comment .avatar-wrap img {
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
}

/* line 857, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap .badge,
.videos-wrapper.has-player .is-comment .avatar-wrap .badge {
  position: absolute;
  top: -2px;
  right: -5px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 870, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .avatar-wrap .badge svg,
.videos-wrapper.has-player .is-comment .avatar-wrap .badge svg {
  height: 10px;
  width: 10px;
  stroke: #fcfcfc;
  stroke-width: 4px;
}

/* line 879, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .control,
.videos-wrapper.has-player .is-comment .control {
  position: relative;
}

/* line 882, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .control .textarea,
.videos-wrapper.has-player .is-comment .control .textarea {
  padding-bottom: 58px;
  border-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 887, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .control .textarea:focus,
.videos-wrapper.has-player .is-comment .control .textarea:focus {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 892, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .control .button,
.videos-wrapper.has-player .is-comment .control .button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/* line 897, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .comment-box .control .button:hover,
.videos-wrapper.has-player .is-comment .control .button:hover {
  background: #5596e6;
  border-color: #5596e6;
  color: #fcfcfc;
  border-radius: 100px;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 908, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment {
  padding: 16px 0;
  margin: 0 !important;
}

/* line 914, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-meta h4 a {
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 918, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-meta h4 a:hover {
  color: #5596e6;
}

/* line 923, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-meta h4 small {
  color: #999;
  font-size: 0.85rem;
}

/* line 929, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-meta p {
  max-width: 640px;
  font-size: 0.95rem;
  color: #6a6c93;
}

/* line 934, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-meta p .mention {
  color: #5596e6;
  font-weight: 500;
}

/* line 941, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-stats-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 640px;
}

/* line 948, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-stats-wrap .comment-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 951, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4px;
}

/* line 957, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat.is-likes
  svg {
  position: relative;
  top: -2px;
}

/* line 964, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat.is-dislikes
  svg {
  position: relative;
  top: 2px;
}

/* line 970, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat
  span {
  display: block;
  font-size: 0.9rem;
  color: #999;
}

/* line 976, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat
  svg {
  height: 18px;
  width: 18px;
  min-width: 20px;
  stroke: #cecece;
}

/* line 985, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .comment-stats-wrap .comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 96px;
}

/* line 991, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 0 2px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1004, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action:nth-child(2)
  svg {
  position: relative;
  top: 2px;
}

/* line 1011, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-like:hover {
  border-color: #1ce589;
}

/* line 1014, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-like:hover
  svg {
  stroke: #1ce589;
}

/* line 1021, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-dislike:hover {
  border-color: #f71416;
}

/* line 1024, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-dislike:hover
  svg {
  stroke: #f71416;
}

/* line 1031, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-reply:hover {
  border-color: #393a4f;
}

/* line 1034, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-reply:hover
  svg {
  stroke: #393a4f;
}

/* line 1040, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action
  svg {
  height: 14px;
  width: 14px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 1051, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .nested-replies .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4px;
}

/* line 1057, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .nested-replies .header.is-active svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 1062, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .nested-replies .header span {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 1069, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .nested-replies .header svg {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  stroke: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1078, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .is-comment .nested-replies .nested-comments {
  display: none;
  padding: 10px 0;
}

/* line 1082, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .is-comment
  .nested-replies
  .nested-comments
  .is-nested {
  margin: 0;
}

/* line 1089, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list {
  margin: 0 !important;
  padding: 20px;
}

/* line 1093, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .related-label {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #e8e8e8;
}

/* line 1099, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .autoplay,
.videos-wrapper.has-player .related-list .related-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
}

/* line 1105, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .autoplay .autoplay-title,
.videos-wrapper.has-player .related-list .autoplay .related-title,
.videos-wrapper.has-player .related-list .related-label .autoplay-title,
.videos-wrapper.has-player .related-list .related-label .related-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  text-transform: uppercase;
  font-size: 0.75rem;
}

/* line 1113, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .autoplay .autoplay-toggle,
.videos-wrapper.has-player .related-list .related-label .autoplay-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1117, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .autoplay .autoplay-toggle span,
.videos-wrapper.has-player .related-list .related-label .autoplay-toggle span {
  position: relative;
  top: -1px;
  display: block;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: #999;
}

/* line 1126, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .autoplay .autoplay-toggle .f-switch,
.videos-wrapper.has-player
  .related-list
  .related-label
  .autoplay-toggle
  .f-switch {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

/* line 1130, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .autoplay
  .autoplay-toggle
  .close-related-videos,
.videos-wrapper.has-player
  .related-list
  .related-label
  .autoplay-toggle
  .close-related-videos {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1139, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .autoplay
  .autoplay-toggle
  .close-related-videos:hover,
.videos-wrapper.has-player
  .related-list
  .related-label
  .autoplay-toggle
  .close-related-videos:hover {
  background: #fff;
}

/* line 1142, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .autoplay
  .autoplay-toggle
  .close-related-videos:hover
  svg,
.videos-wrapper.has-player
  .related-list
  .related-label
  .autoplay-toggle
  .close-related-videos:hover
  svg {
  stroke: #393a4f;
}

/* line 1147, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .autoplay
  .autoplay-toggle
  .close-related-videos
  svg,
.videos-wrapper.has-player
  .related-list
  .related-label
  .autoplay-toggle
  .close-related-videos
  svg {
  height: 16px;
  width: 16px;
  color: #999;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 1158, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .related-card + .media {
  border-top: none;
  margin-top: 0;
}

/* line 1163, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .related-card .media-left {
  margin-right: 0.75rem;
}

/* line 1166, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .related-card .media-left img {
  display: block;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 130px;
  min-width: 130px;
  height: auto;
  max-height: 88px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1179, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .related-title {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  max-width: 200px;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1188, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .related-title:hover {
  color: #5596e6;
}

/* line 1193, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player .related-list .related-card .video-meta .submeta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1197, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  img {
  display: block;
  height: 26px;
  width: 26px;
  min-width: 26px;
  border-radius: 50%;
}

/* line 1205, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  .info {
  margin-left: 6px;
}

/* line 1208, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  .info
  span {
  display: block;
}

/* line 1212, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  .info
  .video-views,
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  .info
  .video-account {
  display: block;
  color: #999;
}

/* line 1217, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  .info
  .video-account {
  font-size: 0.7rem;
}

/* line 1221, src/assets/scss/pages/_app-videos.scss */
.videos-wrapper.has-player
  .related-list
  .related-card
  .video-meta
  .submeta
  .info
  .video-views {
  font-size: 0.6rem;
}

@media (min-width: 1301px) {
  /* line 1238, src/assets/scss/pages/_app-videos.scss */
  .videos-sidebar {
    width: 280px;
  }

  /* line 1244, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper {
    width: calc(100% - 280px);
    margin-left: 280px;
  }

  /* line 1251, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper {
    width: calc(100% - 280px);
    margin-left: 280px;
  }
}

@media (max-width: 1300px) {
  /* line 1261, src/assets/scss/pages/_app-videos.scss */
  .videos-sidebar {
    width: 250px;
  }

  /* line 1267, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
  }

  /* line 1274, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
}

@media (max-width: 767px) {
  /* line 1287, src/assets/scss/pages/_app-videos.scss */
  .videos-sidebar .videos-sidebar-inner .user-block .close-videos-sidebar {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 1296, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1300, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1303, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger.is-home-v2 {
    left: 20px;
  }

  /* line 1310, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner {
    padding: 0;
  }

  /* line 1314, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block {
    width: 90%;
  }

  /* line 1317, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    h2 {
    font-size: 1.4rem;
  }

  /* line 1323, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    .video-meta
    span.rating {
    display: none;
  }

  /* line 1329, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    .description,
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    .caption-actions {
    display: none;
  }

  /* line 1339, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-content {
    padding: 30px;
  }

  /* line 1343, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-content .collections-header.is-home-v2 {
    padding-left: 38px;
  }

  /* line 1351, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-content
    .collections-wrap
    .collection
    .video-collection
    .episode {
    width: calc(100% - 20px);
  }

  /* line 1361, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1366, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .video-side .meta-box {
    margin: 0;
  }

  /* line 1370, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box.video-description
    p {
    font-size: 0.85rem;
  }

  /* line 1376, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box.video-meta
    .video-title {
    font-size: 1rem;
  }

  /* line 1381, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box
    .video-subtitle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 1384, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box
    .video-subtitle
    .quick-actions {
    margin-left: -10px;
  }

  /* line 1388, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box
    .video-subtitle
    .quick-actions
    .quick-action.is-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1396, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box
    .video-owner
    .button {
    display: none;
  }

  /* line 1402, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .video-side .comments-wrap {
    padding: 20px;
  }

  /* line 1407, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .comments-wrap
    .is-comment
    .comment-meta
    h4
    a {
    font-size: 0.85rem;
  }

  /* line 1411, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .comments-wrap
    .is-comment
    .comment-meta
    p {
    font-size: 0.85rem;
  }

  /* line 1419, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .related-side {
    position: fixed;
    right: 0;
    max-height: calc(100% - 58px);
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  /* line 1427, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .related-side.is-opened {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  /* line 1431, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .related-side
    .close-related-videos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 1445, src/assets/scss/pages/_app-videos.scss */
  .videos-sidebar .videos-sidebar-inner .user-block .close-videos-sidebar {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 1454, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1458, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-wrapper .mobile-sidebar-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1466, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block {
    width: 65%;
  }

  /* line 1469, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    h2 {
    font-size: 1.3rem;
  }

  /* line 1473, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    .description {
    font-size: 0.75rem;
  }

  /* line 1485, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home .home-content .collections-header.is-home-v2 {
    padding-left: 52px;
  }

  /* line 1493, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-content
    .collections-wrap
    .collection
    .video-collection
    .episode {
    width: calc(33% - 20px);
  }

  /* line 1496, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-content
    .collections-wrap
    .collection
    .video-collection
    .episode:nth-last-child(4) {
    display: none;
  }

  /* line 1507, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper {
    width: 100%;
    margin: 0;
  }

  /* line 1516, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box
    .video-subtitle
    .quick-actions
    .quick-action.is-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1525, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .related-side {
    position: fixed;
    right: 0;
    max-height: calc(100% - 58px);
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  /* line 1533, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .related-side.is-opened {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  /* line 1537, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .related-side
    .close-related-videos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 1555, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block {
    width: 60%;
  }

  /* line 1558, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    h2 {
    font-size: 1.8rem;
  }

  /* line 1562, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-wrapper
    .video-header
    .cover-caption
    .caption-inner
    .video-caption
    .caption-block
    .description {
    font-size: 0.75rem;
  }

  /* line 1576, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-content
    .collections-wrap
    .collection
    .video-collection
    .episode {
    width: calc(33% - 20px);
  }

  /* line 1579, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.is-home
    .home-content
    .collections-wrap
    .collection
    .video-collection
    .episode:nth-last-child(4) {
    display: none;
  }

  /* line 1596, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .video-side
    .meta-box
    .video-subtitle
    .quick-actions
    .quick-action.is-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1605, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .related-side {
    position: fixed;
    right: 0;
    max-height: calc(100% - 58px);
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  /* line 1613, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player
    .player-wrapper
    .related-side
    .close-related-videos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 1617, src/assets/scss/pages/_app-videos.scss */
  .videos-wrapper.has-player .player-wrapper .related-side.is-opened {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/*! _app-stories.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar {
  position: fixed;
  top: 57px;
  left: 0;
  height: calc(100% - 56px);
  background: #fafafa;
  border-right: 1px solid #e8e8e8;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 10;
}

/* line 30, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar.is-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* line 34, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar .stories-sidebar-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 39, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar .stories-sidebar-inner .user-block {
  position: relative;
  margin: 20px 20px 0 20px;
  border-bottom: 1px solid #dedede;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* line 46, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .close-stories-sidebar {
  position: absolute;
  top: -22px;
  right: -22px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 58, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .close-stories-sidebar:hover {
  background: #fff;
}

/* line 61, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .close-stories-sidebar:hover
  svg {
  stroke: #393a4f;
}

/* line 66, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .close-stories-sidebar
  svg {
  height: 16px;
  width: 16px;
  stroke: #999;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 74, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .avatar-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 74px;
  height: 74px;
  min-width: 74px;
  border: 1.4px solid #d8d8d8;
  border-radius: 50%;
  margin: 0 auto 6px auto;
}

/* line 86, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .avatar-wrap
  img {
  display: block;
  width: 58px;
  height: 58px;
  min-width: 58px;
  border-radius: 50%;
}

/* line 94, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .avatar-wrap
  .badge {
  position: absolute;
  bottom: 2px;
  right: -3px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 107, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .avatar-wrap
  .badge
  svg {
  height: 10px;
  width: 10px;
  stroke: #fcfcfc;
  stroke-width: 4px;
}

/* line 116, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar .stories-sidebar-inner .user-block h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 123, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar .stories-sidebar-inner .user-block p {
  font-size: 0.8rem;
  color: #999;
}

/* line 128, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .user-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
}

/* line 134, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .user-stats
  .stat-block {
  text-align: center;
}

/* line 137, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span {
  display: block;
}

/* line 140, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span:first-child {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.65rem;
  color: #393a4f;
}

/* line 147, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span:last-child {
  font-size: 0.8rem;
  color: #999;
}

/* line 156, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .stories-sidebar .stories-sidebar-inner .user-menu {
  width: 100%;
  height: calc(100% - 324px);
}

.stories-wrapper .stories-sidebar .stories-sidebar-inner.hide-top-section .user-menu {
  height: calc(100% - 108px);
}

  /* line 163, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 68px;
}

.stories-wrapper .stories-sidebar .stories-sidebar-inner .side-menu-footer {
  padding: 14px 16px;
}

.stories-wrapper .stories-sidebar .stories-sidebar-inner .side-menu-footer p {
  font-size: 13px;
}

/* line 170, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .separator {
  margin: 0 30px;
  border-bottom: 1px solid #dedede;
}

/* line 175, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block {
  padding: 20px 0;
}

/* line 179, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 40px;
  border-left: 4px solid transparent;
}

/* line 185, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li.is-active {
  border-color: #5596e6;
}

/* line 189, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 193, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a
  span {
  display: block;
  color: #393a4f;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}

/* line 201, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .stories-sidebar
  .stories-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a
  svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  margin-right: 20px;
}

/* line 221, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper {
  padding-left: 0;
  padding-right: 0;
}

/* line 225, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper {
  position: relative;
  top: -2px;
}

/* line 229, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .mobile-sidebar-trigger {
  position: absolute;
  z-index: 9;
  top: 30px;
  left: 30px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 242, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .mobile-sidebar-trigger.is-home-v2 {
  position: absolute;
  top: 30px;
  left: 34px;
  z-index: 5;
}

/* line 248, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .mobile-sidebar-trigger.is-home-v2 svg {
  stroke: #999;
}

/* line 254, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .mobile-sidebar-trigger:hover svg {
  stroke: #fff;
}

/* line 259, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .mobile-sidebar-trigger svg {
  height: 20px;
  width: 20px;
  color: #fcfcfc;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 267, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .stories-content {
  padding: 30px 40px;
}

/* line 270, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .stories-content .section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 275, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .stories-content .section-title h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  color: #393a4f;
}

/* line 282, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .stories-content .section-title .view-all {
  font-size: 0.8rem;
  padding: 4px 12px;
  border-radius: 100px;
  border: 1px solid transparent;
  background: #f0f0f0;
  color: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 291, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .section-title
  .view-all:hover {
  border-color: #488ee4;
  background: #fff;
  color: #5596e6;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 301, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .section-title
  .dropdown:hover
  .button {
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 307, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .section-title
  .dropdown
  .button {
  border-color: transparent;
}

/* line 311, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .section-title
  .dropdown
  .dropdown-menu {
  margin-top: 6px;
}

/* line 317, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .stories-content .stories-container {
  padding: 20px 0;
}

/* line 320, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 323, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner
  .story {
  margin: 8px;
  width: calc(10% - 16px);
}

/* line 327, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner
  .story
  .story-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 70px;
  width: 70px;
  background: #fff;
  border-radius: 50%;
  border: 1.6px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 339, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner
  .story
  .story-avatar:hover {
  border-color: #3d70b2;
}

/* line 343, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner
  .story
  .story-avatar
  .add-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 58px;
  width: 58px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba(85, 150, 230, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 356, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner
  .story
  .story-avatar
  .add-overlay
  svg {
  height: 16px;
  width: 16px;
  stroke-width: 3px;
  stroke: #fff;
}

/* line 364, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-container
  .container-inner
  .story
  .story-avatar
  img {
  display: block;
  height: 58px;
  width: 58px;
  border-radius: 50%;
}

/* line 375, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper .inner-wrapper .stories-content .stories-feed {
  padding: 20px 0;
}

/* line 378, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item {
  margin-bottom: 0.75rem;
}

/* line 381, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item:last-child {
  margin-bottom: 0;
}

/* line 386, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item.is-medium
  .featured-image {
  min-height: 250px;
}

/* line 392, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item.is-large
  .featured-image {
  min-height: 370px;
}

/* line 397, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .featured-image {
  display: block;
  border-radius: 16px;
  min-height: 190px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* line 404, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0;
}

/* line 410, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 414, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .user-info
  .small-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 425, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .user-info
  .small-avatar
  img {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 434, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .user-info
  span {
  display: block;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 444, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .item-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* line 449, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .item-stats
  .stat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4px;
}

/* line 454, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .item-stats
  .stat-item
  svg {
  height: 16px;
  width: 16px;
  stroke-width: 1.4px;
  stroke: #393a4f;
}

/* line 461, src/assets/scss/pages/_app-stories.scss */
.stories-wrapper
  .inner-wrapper
  .stories-content
  .stories-feed
  .story-feed-item
  .item-meta
  .item-stats
  .stat-item
  span {
  display: block;
  margin-left: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 482, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar {
  position: fixed;
  top: 58px;
  right: 0;
  height: calc(100% - 58px);
  width: 360px;
  background: #f2f2f2;
}

/* line 490, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .header {
  position: relative;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 30px;
}

/* line 497, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .header h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 505, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts {
  position: relative;
  height: calc(100% - 80px);
  width: 100%;
  padding: 0 30px;
}

/* line 511, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

/* line 515, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post:last-child {
  margin-bottom: 0;
}

/* line 519, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post img {
  display: block;
  height: 56px;
  width: 56px;
  min-width: 56px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 12px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 529, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post .meta {
  margin-left: 12px;
}

/* line 532, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post .meta h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  line-height: 1.3;
  font-weight: 600;
  color: #42435b;
  margin-bottom: 6px;
}

/* line 541, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post .meta .user-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 545, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post .meta .user-line img {
  display: block;
  height: 20px;
  width: 20px;
  min-width: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

/* line 554, src/assets/scss/pages/_app-stories.scss */
.story-post-sidebar .related-posts .related-post .meta .user-line span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: #999;
  margin-left: 6px;
}

/* line 568, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper {
  width: calc(100% - 360px);
  margin-right: 360px;
}

/* line 572, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  padding: 10px 40px 40px 40px;
}

/* line 578, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0;
}

/* line 584, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-title h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 593, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-image-wrap .post-image {
  display: block;
  border-radius: 24px;
}

/* line 599, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
}

/* line 605, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 609, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author .story-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  width: 46px;
  background: #fff;
  border-radius: 50%;
  border: 1.6px solid #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 621, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author .story-avatar:hover {
  border-color: #3d70b2;
}

/* line 625, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author .story-avatar img {
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

/* line 633, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author .meta {
  margin-left: 12px;
}

/* line 636, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author .meta span {
  display: block;
  line-height: 1.3;
}

/* line 640, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-author .meta span:first-child {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 647, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .post-meta
  .post-author
  .meta
  span:nth-child(2) {
  font-size: 0.9rem;
  color: #999;
}

/* line 655, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* line 660, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-stats .stat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4px;
}

/* line 665, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-stats .stat-item svg {
  height: 24px;
  width: 24px;
  stroke-width: 1.4px;
  stroke: #393a4f;
}

/* line 672, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-meta .post-stats .stat-item span {
  display: block;
  margin-left: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 684, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-text {
  padding: 0 12px 12px 12px;
  margin-bottom: 0;
}

/* line 688, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-text p {
  font-size: 0.95rem;
}

/* line 693, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 12px 12px 12px;
}

/* line 699, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-tags .tags .tag {
  cursor: pointer;
}

/* line 705, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-compose {
  margin: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 713, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-compose .control .textarea {
  border: none;
  resize: none;
}

/* line 719, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-compose .compose-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 12px 0 0 0;
}

/* line 725, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-compose .compose-controls img {
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

/* line 732, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .post-compose
  .compose-controls
  .compose-actions {
  padding: 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 737, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .post-compose
  .compose-controls
  .compose-actions
  .action {
  height: 36px;
  width: 36px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f5f5f5;
  margin: 0 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 748, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .post-compose
  .compose-controls
  .compose-actions
  .action:hover {
  background: #ededed;
}

/* line 751, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .post-compose
  .compose-controls
  .compose-actions
  .action:hover
  svg {
  stroke: #393a4f;
}

/* line 756, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .post-compose
  .compose-controls
  .compose-actions
  .action
  svg {
  height: 14px;
  width: 14px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 765, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .post-compose .compose-controls .button {
  margin-left: auto;
}

/* line 771, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap {
  padding: 12px;
}

/* line 774, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment {
  padding: 16px 0;
  margin: 0 !important;
}

/* line 778, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment .avatar-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 54px;
  height: 54px;
  min-width: 54px;
  border: 1.4px solid #cecece;
  border-radius: 50%;
}

/* line 789, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .avatar-wrap.is-smaller {
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-width: 1px;
}

/* line 795, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .avatar-wrap.is-smaller
  img {
  width: 32px;
  height: 32px;
  min-width: 32px;
}

/* line 801, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .avatar-wrap.is-smaller
  .badge {
  right: -3px;
  height: 18px;
  width: 18px;
}

/* line 806, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .avatar-wrap.is-smaller
  .badge
  svg {
  height: 8px;
  width: 8px;
  stroke-width: 4px;
}

/* line 814, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment .avatar-wrap img {
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
}

/* line 822, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment .avatar-wrap .badge {
  position: absolute;
  top: -2px;
  right: -5px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 835, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .avatar-wrap
  .badge
  svg {
  height: 10px;
  width: 10px;
  stroke: #fcfcfc;
  stroke-width: 4px;
}

/* line 846, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment .comment-meta h4 a {
  font-weight: 600;
  font-size: 0.9rem;
  color: #393a4f;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 852, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-meta
  h4
  a:hover {
  color: #5596e6;
}

/* line 857, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-meta
  h4
  small {
  color: #999;
  font-size: 0.85rem;
}

/* line 863, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment .comment-meta p {
  max-width: 640px;
  font-size: 0.95rem;
  color: #6a6c93;
}

/* line 868, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-meta
  p
  .mention {
  color: #5596e6;
  font-weight: 500;
}

/* line 875, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper .story-post .comments-wrap .is-comment .comment-stats-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 640px;
}

/* line 882, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 885, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4px;
}

/* line 891, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat.is-likes
  svg {
  position: relative;
  top: -2px;
}

/* line 898, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat.is-dislikes
  svg {
  position: relative;
  top: 2px;
}

/* line 904, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat
  span {
  display: block;
  font-size: 0.9rem;
  color: #999;
}

/* line 910, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-stats
  .stat
  svg {
  height: 18px;
  width: 18px;
  min-width: 20px;
  stroke: #cecece;
}

/* line 919, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 96px;
}

/* line 925, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 0 2px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 938, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action:nth-child(2)
  svg {
  position: relative;
  top: 2px;
}

/* line 945, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-like:hover {
  border-color: #1ce589;
}

/* line 948, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-like:hover
  svg {
  stroke: #1ce589;
}

/* line 955, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-dislike:hover {
  border-color: #f71416;
}

/* line 958, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-dislike:hover
  svg {
  stroke: #f71416;
}

/* line 965, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-reply:hover {
  border-color: #393a4f;
}

/* line 968, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action.is-reply:hover
  svg {
  stroke: #393a4f;
}

/* line 974, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .comment-stats-wrap
  .comment-actions
  .comment-action
  svg {
  height: 14px;
  width: 14px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 985, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .nested-replies
  .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 4px;
}

/* line 991, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .nested-replies
  .header.is-active
  svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 996, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .nested-replies
  .header
  span {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
}

/* line 1003, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .nested-replies
  .header
  svg {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  stroke: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1012, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .nested-replies
  .nested-comments {
  display: none;
  padding: 10px 0;
}

/* line 1016, src/assets/scss/pages/_app-stories.scss */
.story-post-wrapper
  .story-post
  .comments-wrap
  .is-comment
  .nested-replies
  .nested-comments
  .is-nested {
  margin: 0;
}

@media (min-width: 1301px) {
  /* line 1032, src/assets/scss/pages/_app-stories.scss */
  .stories-sidebar {
    width: 280px;
  }

  /* line 1037, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper {
    width: calc(100% - 280px);
    margin-left: 280px;
  }
}

@media (max-width: 1300px) {
  /* line 1046, src/assets/scss/pages/_app-stories.scss */
  .stories-sidebar {
    width: 250px;
  }

  /* line 1050, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
}

@media (max-width: 767px) {
  /* line 1061, src/assets/scss/pages/_app-stories.scss */
  .stories-sidebar .stories-sidebar-inner .user-block .close-stories-sidebar {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 1068, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1072, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1076, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .mobile-sidebar-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1079, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .mobile-sidebar-trigger.is-home-v2 {
    left: 20px;
  }
  .stories-wrapper.is-home .inner-wrapper .mobile-sidebar-trigger.is-home-v2 {
    top: 20px;
    left: 10px;
  }
  /* line 1083, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .mobile-sidebar-trigger.is-story-post {
    top: 24px;
    left: 10px;
  }

  /* line 1089, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .stories-content {
    padding: 30px 20px;
  }

  /* line 1093, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .stories-content .main-section-title h2 {
    padding-left: 38px !important;
  }

  /* line 1099, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper
    .inner-wrapper
    .stories-content
    .stories-container
    .container-inner {
    overflow-x: auto;
  }

  /* line 1102, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper
    .inner-wrapper
    .stories-content
    .stories-container
    .container-inner
    .story {
    margin-right: 14px;
  }

  /* line 1105, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper
    .inner-wrapper
    .stories-content
    .stories-container
    .container-inner
    .story
    .story-avatar {
    border-color: transparent;
  }

  /* line 1115, src/assets/scss/pages/_app-stories.scss */
  .story-post-sidebar {
    display: none;
  }

  /* line 1119, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper {
    width: 100%;
    margin-right: 0;
  }

  /* line 1123, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post {
    padding: 10px 16px 16px 16px;
  }

  /* line 1126, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title {
    padding-left: 32px;
    font-size: 1.4rem;
  }

  /* line 1130, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title h2 {
    padding-left: 0;
    font-size: 1.4rem;
  }

  /* line 1135, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title .dropdown {
    display: none;
  }

  /* line 1141, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-image-wrap .post-image {
    min-height: 230px;
  }

  /* line 1148, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-compose .compose-controls img {
    display: none;
  }

  /* line 1152, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper
    .story-post
    .post-compose
    .compose-controls
    .compose-actions {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* line 1165, src/assets/scss/pages/_app-stories.scss */
  .stories-sidebar .stories-sidebar-inner .user-block .close-stories-sidebar {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 1172, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1176, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }

  /* line 1180, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .mobile-sidebar-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1183, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .mobile-sidebar-trigger.is-home-v2 {
    left: 20px;
  }

  /* line 1187, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .mobile-sidebar-trigger.is-story-post {
    top: 25px;
    left: 90px;
  }

  /* line 1195, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .stories-content .main-section-title h2 {
    padding-left: 38px !important;
  }

  /* line 1201, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .stories-content .stories-feed .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1209, src/assets/scss/pages/_app-stories.scss */
  .story-post-sidebar {
    display: none;
  }

  /* line 1213, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper {
    width: 100%;
    margin-right: 0;
  }

  /* line 1217, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post {
    max-width: 610px;
  }

  /* line 1220, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title {
    padding-left: 32px;
  }

  /* line 1223, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title h2 {
    padding-left: 0;
    font-size: 1.4rem;
  }

  /* line 1228, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title .dropdown {
    display: none;
  }

  /* line 1234, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-image-wrap .post-image {
    min-height: 230px;
  }

  /* line 1241, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-compose .compose-controls img {
    display: none;
  }

  /* line 1245, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper
    .story-post
    .post-compose
    .compose-controls
    .compose-actions {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* line 1257, src/assets/scss/pages/_app-stories.scss */
  .stories-wrapper .inner-wrapper .stories-content {
    padding: 30px;
  }

  /* line 1263, src/assets/scss/pages/_app-stories.scss */
  .story-post-sidebar {
    width: 280px;
  }

  /* line 1266, src/assets/scss/pages/_app-stories.scss */
  .story-post-sidebar .header {
    padding: 0 20px;
  }

  /* line 1270, src/assets/scss/pages/_app-stories.scss */
  .story-post-sidebar .related-posts {
    padding: 0 20px;
  }

  /* line 1274, src/assets/scss/pages/_app-stories.scss */
  .story-post-sidebar .related-posts .related-post img {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }

  /* line 1283, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper {
    width: calc(100% - 280px);
    margin-right: 280px;
  }

  /* line 1287, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* line 1292, src/assets/scss/pages/_app-stories.scss */
  .story-post-wrapper .story-post .post-title h2 {
    font-size: 1.4rem;
  }
}

/*! _app-commerce.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 20, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

/* line 26, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 30, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .img-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 90px;
  width: 90px;
  min-width: 90px;
}

/* line 39, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .img-container .follow-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  border: 2px solid #f6f7f9;
  background: #447abf;
}

/* line 52, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .img-container .follow-badge svg {
  height: 10px;
  width: 10px;
  stroke-width: 5px;
  stroke: #fff;
}

/* line 61, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block img {
  display: block;
  height: 90px;
  width: 90px;
  min-width: 90px;
  border-radius: 16px;
}

/* line 69, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .store-meta {
  margin-left: 16px;
}

/* line 72, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .store-meta h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #393a4f;
  font-size: 1.3rem;
}

/* line 78, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .store-meta h3 a {
  text-align: center;
  margin-left: 5px;
}

/* line 82, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .store-meta h3 a:first-child {
  margin-left: 10px;
}

/* line 88, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .store-block .store-meta > span {
  display: block;
  color: #abb0be;
  font-size: 0.9rem;
  line-height: 1;
}

/* line 97, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .activity-block {
  padding-top: 10px;
}

/* line 100, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .activity-block h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
  margin-bottom: 16px;
  margin-left: 16px;
}

/* line 109, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .activity-block .inner-wrap {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 115, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .activity-block .inner-wrap .stat-block {
  padding: 0 20px;
}

/* line 118, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .activity-block .inner-wrap .stat-block.is-bordered {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

/* line 123, src/assets/scss/pages/_app-commerce.scss */
.shop-header
  .header-inner
  .activity-block
  .inner-wrap
  .stat-block
  .stat-number {
  font-weight: bold;
  font-size: 1.6rem;
  color: #393a4f;
  line-height: 24px;
}

/* line 130, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .activity-block .inner-wrap .stat-block span {
  display: block;
  font-family: "Open Sans", sans-serif;
  color: #abb0be;
  font-weight: 500;
  font-size: 0.8rem;
}

/* line 141, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .about-block {
  padding-top: 10px;
  max-width: 30%;
}

/* line 145, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .about-block h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

/* line 153, src/assets/scss/pages/_app-commerce.scss */
.shop-header .header-inner .about-block .ellipse-text {
  color: #999;
  font-size: 0.8rem;
}

/* line 160, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 260px;
}

/* line 165, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs .tab-control {
  display: block;
  font-size: 0.8rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-align: center;
  width: 33.33%;
  color: #abb0be;
  padding-bottom: 10px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 176, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs .tab-control.is-active {
  color: #393a4f;
}

/* line 180, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs .tab-control.is-active:first-child ~ .store-naver {
  margin-left: 0;
}

/* line 186, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs .tab-control.is-active:nth-child(2) ~ .store-naver {
  margin-left: 33.33%;
}

/* line 192, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs .tab-control.is-active:nth-child(3) ~ .store-naver {
  margin-left: 66.66%;
}

/* line 199, src/assets/scss/pages/_app-commerce.scss */
.shop-header .store-tabs .store-naver {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.8px;
  width: 33.33%;
  border-radius: 50px;
  background: #5596e6;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 212, src/assets/scss/pages/_app-commerce.scss */
.store-sections {
  padding: 20px 0;
}

/* line 215, src/assets/scss/pages/_app-commerce.scss */
.store-sections .store-tab-pane {
  display: none;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

/* line 219, src/assets/scss/pages/_app-commerce.scss */
.store-sections .store-tab-pane.is-active {
  display: block;
}

/* line 224, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card {
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 14px;
}

/* line 231, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .quickview-trigger {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 32px;
  width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}

/* line 242, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .quickview-trigger:hover {
  background: #f5f5f5;
}

/* line 245, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .quickview-trigger:hover svg {
  stroke: #393a4f;
}

/* line 250, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .quickview-trigger svg {
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 258, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-image {
  margin-bottom: 6px;
}

/* line 261, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-image img {
  display: block;
  width: 100%;
  max-width: 150px;
  margin: 16px auto 0 auto;
}

/* line 269, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-info {
  margin-bottom: 6px;
}

/* line 272, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-info h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 279, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-info p {
  font-size: 0.85rem;
  color: #999;
}

/* line 285, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 12px;
}

/* line 291, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-actions .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 295, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-actions .left svg {
  height: 16px;
  width: 16px;
  margin-right: 6px;
  stroke: #ff533d;
  fill: #ff533d;
}

/* line 303, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-actions .left span {
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
  color: #393a4f;
  line-height: 1.2;
}

/* line 314, src/assets/scss/pages/_app-commerce.scss */
.store-sections .product-card .product-actions .right .button svg {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

/* line 324, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card {
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 14px;
}

/* line 331, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card img {
  display: block;
  height: 80px;
  width: 80px;
  margin: 0 auto;
}

/* line 338, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .meta {
  text-align: center;
}

/* line 341, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .meta h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 348, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .meta p {
  font-size: 0.85rem;
  color: #535473;
}

/* line 354, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .brand-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  margin: 12px;
  background: #fff;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 362, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .brand-stats .brand-stat {
  width: 33.33%;
  text-align: center;
  margin: 10px 0;
}

/* line 367, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .brand-stats .brand-stat:not(:first-child) {
  border-left: 1px solid #e8e8e8;
}

/* line 371, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .brand-stats .brand-stat span {
  text-align: center;
  display: block;
}

/* line 375, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .brand-stats .brand-stat span:first-child {
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 381, src/assets/scss/pages/_app-commerce.scss */
.store-sections .brand-card .brand-stats .brand-stat span:nth-child(2) {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 500;
  color: #999;
}

/* line 392, src/assets/scss/pages/_app-commerce.scss */
.store-sections .followers-wrap {
  padding: 20px 0;
}

/* line 397, src/assets/scss/pages/_app-commerce.scss */
.store-sections .follower-block .avatar-container {
  height: 90px;
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto 6px auto;
  border-radius: 50%;
  border: 2px solid #cecece;
}

/* line 407, src/assets/scss/pages/_app-commerce.scss */
.store-sections .follower-block .avatar-container img {
  display: block;
  height: 76px;
  width: 76px;
  border-radius: 50%;
}

/* line 415, src/assets/scss/pages/_app-commerce.scss */
.store-sections .follower-block h3 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 423, src/assets/scss/pages/_app-commerce.scss */
.store-sections .follower-block p {
  text-align: center;
  color: #999;
  font-size: 0.8rem;
}

/* line 435, src/assets/scss/pages/_app-commerce.scss */
.products-navigation {
  position: fixed;
  top: 58px;
  left: 0;
  height: 58px;
  width: 100%;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 18;
}

/* line 447, src/assets/scss/pages/_app-commerce.scss */
.products-navigation.is-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* line 451, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner {
  position: relative;
  height: 58px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 459, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 463, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-info img {
  display: block;
  height: 42px;
  width: 42px;
  border-radius: 10px;
}

/* line 470, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-info h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  color: #393a4f;
  margin-left: 8px;
}

/* line 479, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 483, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-actions .shop-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 6px;
  padding: 8px 16px;
  border-radius: 100px;
  background: #f5f5f5;
}

/* line 492, src/assets/scss/pages/_app-commerce.scss */
.products-navigation
  .navigation-inner
  .shop-actions
  .shop-action.is-active
  svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 497, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-actions .shop-action span {
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 502, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-actions .shop-action svg {
  height: 16px;
  width: 16px;
  stroke: #393a4f;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 510, src/assets/scss/pages/_app-commerce.scss */
.products-navigation .navigation-inner .shop-actions .button {
  margin-left: 6px;
}

/* line 517, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel {
  position: fixed;
  top: 116px;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: none;
}

/* line 526, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-categories {
  z-index: 18;
}

/* line 529, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-categories .navigation-panel-inner {
  min-height: 190px;
}

/* line 534, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters {
  z-index: 17;
}

/* line 538, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters .navigation-panel-inner .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 543, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters .navigation-panel-inner .search-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

/* line 548, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters .navigation-panel-inner .search-filter .control {
  width: 100%;
  margin-right: 30px;
}

/* line 554, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters .navigation-panel-inner .filter-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 0;
}

/* line 559, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters .navigation-panel-inner .filter-group .control {
  min-width: 170px;
  margin: 0 4px;
}

/* line 563, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters
  .navigation-panel-inner
  .filter-group
  .control
  .combo-box {
  height: 36px;
  margin-top: 0;
}

/* line 567, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters
  .navigation-panel-inner
  .filter-group
  .control
  .combo-box
  .combo-item {
  height: 36px;
}

/* line 571, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel.is-filters
  .navigation-panel-inner
  .filter-group
  .control
  .combo-box
  .box-chevron {
  height: 36px;
  width: 36px;
}

/* line 581, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel .navigation-panel-inner {
  position: relative;
  width: 100%;
}

/* line 585, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel .navigation-panel-inner .panel-title {
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 590, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel .navigation-panel-inner .panel-title h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 598, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel .navigation-panel-inner .shop-categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
}

/* line 603, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel .navigation-panel-inner .shop-categories .category-item {
  position: relative;
  width: 16.66%;
}

/* line 607, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

/* line 618, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  input:checked
  + .item-inner {
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 622, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  input:checked
  + .item-inner
  img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* line 627, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  input:checked
  + .item-inner
  h4 {
  color: #393a4f;
}

/* line 634, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  .item-inner {
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 640, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  .item-inner
  img {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.4;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 650, src/assets/scss/pages/_app-commerce.scss */
.navigation-panel
  .navigation-panel-inner
  .shop-categories
  .category-item
  .item-inner
  h4 {
  margin-top: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #cecece;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 669, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 673, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .quickview-loader,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .quickview-loader,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .quickview-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
}

/* line 687, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .quickview-loader.is-active,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .quickview-loader.is-active,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .quickview-loader.is-active {
  opacity: 1;
  pointer-events: all;
}

/* line 692, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .quickview-loader .loader,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .quickview-loader
  .loader,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .quickview-loader
  .loader {
  height: 4rem;
  width: 4rem;
}

/* line 698, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .left,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .left,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .left {
  width: 55%;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 705, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .left .product-image,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .left
  .product-image,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .left
  .product-image {
  position: relative;
  display: none;
  width: 100%;
  max-width: 360px;
}

/* line 711, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .left .product-image.is-active,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .left
  .product-image.is-active,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .left
  .product-image.is-active {
  display: block;
}

/* line 715, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .left .product-image img,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .left
  .product-image
  img,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .left
  .product-image
  img {
  display: block;
  width: 100%;
}

/* line 722, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right {
  width: 45%;
  padding: 20px;
}

/* line 726, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .header,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .header,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}

/* line 733, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .header .product-info h3,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .header
  .product-info
  h3,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .header
  .product-info
  h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 740, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .header .product-info p,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .header
  .product-info
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .header
  .product-info
  p {
  font-size: 0.9rem;
  color: #999;
}

/* line 746, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .header .price,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .header
  .price,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .header
  .price {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 752, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .header .price:before,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .header
  .price:before,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .header
  .price:before {
  content: "$";
}

/* line 759, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .properties .property-group,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group {
  margin-bottom: 12px;
}

/* line 762, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .properties .property-group h4,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  h4,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 6px;
}

/* line 771, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .properties .property-group .property-box,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 780, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item {
  position: relative;
  width: 25%;
  margin: 16px 0;
}

/* line 785, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item:not(:first-child),
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item:not(:first-child),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item:not(:first-child) {
  border-left: 1px solid #e8e8e8;
}

/* line 789, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  input,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 800, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  input:checked
  + .item-inner
  .color-dot,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  input:checked
  + .item-inner
  .color-dot,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  input:checked
  + .item-inner
  .color-dot {
  border-color: #d3d3d3;
}

/* line 807, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner {
  padding: 0 16px;
}

/* line 810, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot {
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1.4px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 820, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #cecece;
}

/* line 826, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-red,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-red,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-red {
  background: #ff533d;
}

/* line 830, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-blue,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-blue,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-blue {
  background: #039be5;
}

/* line 834, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-green,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-green,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-green {
  background: #1ce589;
}

/* line 838, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-yellow,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-yellow,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-colors
  .property-item
  .item-inner
  .color-dot
  .dot-inner.is-yellow {
  background: #ff9700;
}

/* line 848, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item {
  position: relative;
  width: 25%;
  margin: 16px 0;
}

/* line 853, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item:not(:first-child),
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item:not(:first-child),
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item:not(:first-child) {
  border-left: 1px solid #e8e8e8;
}

/* line 857, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  input,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  input,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 868, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  input:checked
  + .item-inner
  .size-label,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  input:checked
  + .item-inner
  .size-label,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  input:checked
  + .item-inner
  .size-label {
  color: #393a4f;
}

/* line 875, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  .item-inner,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  .item-inner,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  .item-inner {
  padding: 0 16px;
}

/* line 878, src/assets/scss/pages/_app-commerce.scss */
.product-quickview
  .card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  .item-inner
  .size-label,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  .item-inner
  .size-label,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .properties
  .property-group
  .property-box.is-sizes
  .property-item
  .item-inner
  .size-label {
  display: block;
  text-align: center;
  font-size: 0.95rem;
  font-weight: 500;
  color: #cecece;
}

/* line 892, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .quickview-description,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .quickview-description,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .quickview-description {
  margin: 24px 0;
  max-height: 220px;
  padding-right: 16px;
  overflow-y: auto;
}

/* line 898, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .quickview-description p,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .quickview-description
  p,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .quickview-description
  p {
  font-size: 0.85rem;
}

/* line 903, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .quickview-controls,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .quickview-controls,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .quickview-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 909, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .quickview-controls .button span,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .quickview-controls
  .button
  span,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .quickview-controls
  .button
  span {
  margin-right: 8px;
}

/* line 913, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .quickview-controls .button var,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .quickview-controls
  .button
  var,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .quickview-controls
  .button
  var {
  font-weight: 500;
}

/* line 916, src/assets/scss/pages/_app-commerce.scss */
.product-quickview .card .right .quickview-controls .button var:before,
.product-quickview
  .shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .right
  .quickview-controls
  .button
  var:before,
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .product-quickview
  .summary-card
  .right
  .quickview-controls
  .button
  var:before {
  content: "$";
}

/* line 931, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container {
  max-width: 1040px;
  margin: 30px auto;
}

/* line 936, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-header .header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 941, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-header .header-inner h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: #393a4f;
}

/* line 950, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content {
  padding: 30px 0;
}

/* line 953, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .checkout-section {
  display: none;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

/* line 957, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .checkout-section.is-active {
  display: block;
}

/* line 961, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .checkout-section .address-box {
  position: relative;
}

/* line 964, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .address-box
  input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 974, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .address-box
  input:checked
  + .address-box-inner {
  border-color: #5596e6;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 981, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .address-box
  .address-box-inner {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 988, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .address-box
  .address-box-inner
  p {
  font-family: "Roboto", sans-serif;
}

/* line 991, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .address-box
  .address-box-inner
  p:not(:first-child) {
  font-size: 0.9rem;
  color: #797a9e;
}

/* line 996, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .address-box
  .address-box-inner
  p:first-child {
  font-weight: 500;
  color: #393a4f;
}

/* line 1005, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .add-address-link
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 1009, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .add-address-link
  a
  svg {
  width: 18px;
  height: 18px;
}

/* line 1014, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .add-address-link
  a
  span {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  margin-left: 4px;
}

/* line 1022, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .checkout-section .shipping-box {
  position: relative;
}

/* line 1025, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 1035, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  input:checked
  + .shipping-box-inner {
  border-color: #5596e6;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1039, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  input:checked
  + .shipping-box-inner
  img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/* line 1044, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  input:checked
  + .shipping-box-inner
  p {
  color: #5596e6;
}

/* line 1051, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  .shipping-box-inner {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fff;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1059, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  .shipping-box-inner
  img {
  max-width: 60px;
  margin: 0 auto 10px auto;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.6;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1068, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .checkout-section
  .shipping-box
  .shipping-box-inner
  p {
  font-family: "Roboto", sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #797a9e;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 1081, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .flex-table .spinner {
  height: 32px;
  min-width: 80px;
  padding: 0 2px;
}

/* line 1086, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .flex-table .spinner .add,
.shop-wrapper .cart-container .cart-content .flex-table .spinner .remove {
  height: 32px;
  width: 32px;
}

/* line 1090, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .flex-table .spinner .add svg,
.shop-wrapper .cart-container .cart-content .flex-table .spinner .remove svg {
  height: 12px;
  width: 12px;
}

/* line 1096, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .flex-table .spinner .value {
  font-size: 0.8rem;
}

/* line 1102, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .continue-shopping {
  padding: 16px 0;
}

/* line 1105, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .continue-shopping a {
  font-size: 0.9rem;
  color: #999;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1114, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .continue-shopping a:hover {
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border-color: #5596e6;
  color: #5596e6;
}

/* line 1122, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary {
  padding-left: 20px;
}

/* line 1126, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-header h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: #999;
  text-transform: uppercase;
  padding-bottom: 14px;
}

/* line 1136, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card {
  position: relative;
  padding: 20px;
  border: 1px solid #e0e0e0;
}

/* line 1144, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card > h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: #999;
  text-transform: uppercase;
  padding-bottom: 14px;
}

/* line 1153, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card > p {
  color: #797a9e !important;
}

/* line 1157, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .shipping-logo {
  display: block;
  position: absolute;
  top: 12px;
  right: 16px;
  width: 28px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1167, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .shipping-logo.is-active {
  opacity: 1;
}

/* line 1172, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 1177, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line:not(:last-child) {
  margin-bottom: 12px;
}

/* line 1181, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span {
  display: block;
  font-family: "Montserrat", sans-serif;
}

/* line 1185, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:first-child {
  font-size: 0.8rem;
  font-weight: 600;
  color: #999;
}

/* line 1190, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:first-child.is-total {
  color: #393a4f;
}

/* line 1195, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 1200, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:nth-child(2):before {
  content: "$";
}

/* line 1204, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:nth-child(2).is-total {
  font-weight: 700;
  font-size: 0.9rem;
}

/* line 1209, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:nth-child(2).is-text {
  font-weight: 400 !important;
  font-size: 0.7rem;
  color: #999;
}

/* line 1214, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .order-line
  span:nth-child(2).is-text:before {
  content: "";
}

/* line 1222, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card img {
  display: block;
  max-width: 60px;
  margin: 0 auto 20px auto;
}

/* line 1228, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
  font-size: 0.9rem;
}

/* line 1235, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper .cart-container .cart-content .cart-summary .summary-card p {
  font-size: 0.9rem;
  color: #999;
}

/* line 1241, src/assets/scss/pages/_app-commerce.scss */
.shop-wrapper
  .cart-container
  .cart-content
  .cart-summary
  .summary-card
  .button.is-loading {
  color: transparent !important;
}

/* line 1255, src/assets/scss/pages/_app-commerce.scss */
.checkout-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1040px;
  margin: 0 auto;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

/* line 1262, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left,
.checkout-container .right {
  width: 49%;
}

/* line 1267, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
}

/* line 1275, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

/* line 1280, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-inner .avatar {
  display: block;
  height: 70px;
  width: 70px;
  min-width: 70px;
  border-radius: 8px;
}

/* line 1288, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-inner .separator {
  height: 34px;
  width: 1px;
  margin: 0 24px;
  border-right: 1px solid #d9d9d9;
  display: none;
}

/* line 1296, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-inner .logo {
  display: block;
  max-width: 110px;
  max-height: 40px;
}

/* line 1302, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-inner .title-wrap {
  margin-left: 12px;
  line-height: 1.3;
}

/* line 1306, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .left
  .left-header
  .header-inner
  .title-wrap
  .payment-title {
  font-family: "Roboto", sans-serif;
  color: #393a4f;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0;
}

/* line 1314, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .left
  .left-header
  .header-inner
  .title-wrap
  .payment-subtitle {
  font-family: "Roboto", sans-serif;
  color: #999;
  font-size: 0.9rem;
  margin-bottom: 0;
}

/* line 1323, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-coupon {
  padding: 20px 0 0 0;
  border-top: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

/* line 1330, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .left
  .left-header
  .header-coupon
  .field.is-grouped
  .control:first-child {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

/* line 1337, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}

/* line 1345, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-foot span {
  display: block;
  font-family: "Roboto", sans-serif;
}

/* line 1349, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-foot span.label {
  font-size: 1rem;
  font-weight: 500;
  color: #393a4f;
  margin-bottom: 0;
}

/* line 1356, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-foot span.amount {
  font-size: 1.3rem;
  font-weight: 600;
  color: #393a4f;
}

/* line 1361, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-header .header-foot span.amount:before {
  content: attr(data-currency);
  position: relative;
  left: -5px;
  font-weight: 500;
  font-size: 0.75rem;
  color: #999;
}

/* line 1374, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .left .left-body {
  padding: 20px 0;
}

/* line 1381, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1388, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .provider-form {
  display: none;
}

/* line 1391, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .provider-form.is-active {
  display: block;
}

/* line 1398, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .form-section.has-margin-bottom {
  margin-bottom: 30px;
}

/* line 1402, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .form-section .form-section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 8px;
}

/* line 1407, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .form-section .form-section-header h3 {
  font-family: "Roboto", sans-serif;
  color: #393a4f;
  font-size: 1.1rem;
  font-weight: 500;
}

/* line 1414, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .right
  .payment-form
  .form-section
  .form-section-header
  span {
  display: block;
  color: #abb0be;
}

/* line 1418, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .right
  .payment-form
  .form-section
  .form-section-header
  span
  a {
  color: #3d70b2;
  font-weight: 600;
  cursor: pointer;
  padding-left: 5px;
}

/* line 1428, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form label {
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 0.7rem;
  color: #999;
  letter-spacing: 1px;
}

/* line 1436, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .select {
  width: 100%;
}

/* line 1439, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .select:after {
  border-color: #abb0be;
  border-width: 2px;
}

/* line 1444, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .select select {
  width: 100%;
}

/* line 1449, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .select + .icon .mdi {
  position: relative;
  top: 2px;
  font-size: 1.2rem;
}

/* line 1457, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .StripeElement {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  background-color: white;
}

/* line 1465, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .StripeElement.empty {
  color: #a2a5b9;
}

/* line 1470, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .StripeElement--focus {
  -webkit-box-shadow: 0 1px 3px 0 #cfd7df;
  box-shadow: 0 1px 3px 0 #cfd7df;
}

/* line 1474, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .StripeElement--invalid {
  border-color: #fa755a;
}

/* line 1478, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* line 1482, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-disclaimer {
  padding-top: 10px;
}

/* line 1485, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-disclaimer p {
  color: #abb0be;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
}

/* line 1490, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-disclaimer p a {
  color: #3d70b2;
  font-weight: 500;
}

/* line 1497, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-button {
  text-align: center;
  margin-top: 20px;
}

/* line 1501, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-button .buttons {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 1505, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-button .button {
  min-height: 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 1509, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-button .button:nth-child(2) {
  border-left: 1px solid #6e98cd;
}

/* line 1513, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .right .payment-form .is-button .button.disabled {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.4;
  pointer-events: none;
}

/* line 1523, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  padding: 24px;
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 1533, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box > svg {
  display: block;
  margin: 0 auto;
}

/* line 1538, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box > h3 {
  font-family: "Roboto", sans-serif;
  color: #393a4f;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
}

/* line 1547, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box > p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #999;
  font-size: 0.9rem;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
}

/* line 1557, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box .order-summary {
  padding-bottom: 10px;
}

/* line 1560, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box .order-summary h4 {
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 0.85rem;
  color: #999;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

/* line 1570, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box .order-summary .order-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 20px;
}

/* line 1577, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .confirmation-box
  .order-summary
  .order-line
  .item.is-total
  span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #393a4f !important;
}

/* line 1584, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box .order-summary .order-line .item span {
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  display: block;
  color: #abb0be;
}

/* line 1594, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .confirmation-box
  .order-summary
  .order-line
  .amount.is-total
  span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #393a4f !important;
}

/* line 1601, src/assets/scss/pages/_app-commerce.scss */
.checkout-container .confirmation-box .order-summary .order-line .amount span {
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  display: block;
  color: #abb0be;
}

/* line 1607, src/assets/scss/pages/_app-commerce.scss */
.checkout-container
  .confirmation-box
  .order-summary
  .order-line
  .amount
  span:before {
  content: attr(data-currency);
  position: relative;
  left: -5px;
  font-weight: 500;
  font-size: 0.75rem;
  color: #abb0be;
}

@media (max-width: 767px) {
  /* line 1628, src/assets/scss/pages/_app-commerce.scss */
  .products-navigation .navigation-inner {
    padding-left: 12px;
  }

  /* line 1632, src/assets/scss/pages/_app-commerce.scss */
  .products-navigation .navigation-inner .shop-info h3 {
    display: none;
  }

  /* line 1642, src/assets/scss/pages/_app-commerce.scss */
  .navigation-panel.is-categories .navigation-panel-inner .shop-categories {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  /* line 1645, src/assets/scss/pages/_app-commerce.scss */
  .navigation-panel.is-categories
    .navigation-panel-inner
    .shop-categories
    .category-item {
    width: 33.33%;
  }

  /* line 1654, src/assets/scss/pages/_app-commerce.scss */
  .navigation-panel.is-filters .navigation-panel-inner .search-filter {
    width: 100%;
    padding: 6px 12px;
  }

  /* line 1658, src/assets/scss/pages/_app-commerce.scss */
  .navigation-panel.is-filters .navigation-panel-inner .search-filter .control {
    margin: 0;
  }

  /* line 1663, src/assets/scss/pages/_app-commerce.scss */
  .navigation-panel.is-filters .navigation-panel-inner .filter-group {
    display: none;
  }

  /* line 1670, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper {
    overflow-x: hidden;
  }

  /* line 1674, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 1677, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner .activity-block {
    padding-top: 20px;
  }

  /* line 1680, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner .activity-block h3 {
    margin-left: 0;
  }

  /* line 1685, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner .about-block {
    max-width: 100%;
    padding-top: 20px;
  }

  /* line 1691, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .store-tabs {
    max-width: 100%;
  }

  /* line 1694, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .store-tabs .tab-control {
    font-size: 0.9rem;
  }

  /* line 1703, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .store-sections .store-tab-pane .columns .column {
    width: 100%;
  }

  /* line 1712, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .cart-container .cart-content .cart-summary {
    padding-left: 0;
  }

  /* line 1720, src/assets/scss/pages/_app-commerce.scss */
  .product-quickview .card,
  .product-quickview
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .product-quickview
    .summary-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 1723, src/assets/scss/pages/_app-commerce.scss */
  .product-quickview .card .left,
  .product-quickview
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .left,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .product-quickview
    .summary-card
    .left {
    width: 100%;
  }

  /* line 1727, src/assets/scss/pages/_app-commerce.scss */
  .product-quickview .card .left .product-image img,
  .product-quickview
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .left
    .product-image
    img,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .product-quickview
    .summary-card
    .left
    .product-image
    img {
    max-width: 190px;
  }

  /* line 1733, src/assets/scss/pages/_app-commerce.scss */
  .product-quickview .card .right,
  .product-quickview
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .right,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .product-quickview
    .summary-card
    .right {
    width: 100%;
  }

  /* line 1736, src/assets/scss/pages/_app-commerce.scss */
  .product-quickview .card .right .quickview-description,
  .product-quickview
    .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .summary-card
    .right
    .quickview-description,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .product-quickview
    .summary-card
    .right
    .quickview-description {
    max-height: 120px;
  }

  /* line 1743, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 1746, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left,
  .checkout-container .right {
    width: 100%;
  }

  /* line 1750, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left {
    margin-bottom: 20px;
  }

  /* line 1754, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .header-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 1757, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .header-inner .avatar {
    margin-bottom: 20px;
  }

  /* line 1761, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .header-inner .separator {
    display: none;
  }

  /* line 1765, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .header-inner .title-wrap {
    margin-left: 0;
    text-align: center;
  }

  /* line 1774, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .right .payment-form {
    padding: 20px;
  }

  /* line 1779, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .confirmation-box {
    padding: 20px;
  }

  /* line 1783, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .confirmation-box .order-summary .order-line {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* line 1793, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper {
    overflow-x: hidden;
  }

  /* line 1799, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner .store-block .img-container {
    height: 50px;
    width: 50px;
    min-width: 50px;
  }

  /* line 1804, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner .store-block .img-container img {
    height: 50px;
    width: 50px;
    min-width: 50px;
    border-radius: 12px;
  }

  /* line 1815, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper
    .shop-header
    .header-inner
    .activity-block
    .inner-wrap
    .stat-block {
    padding: 0 12px;
  }

  /* line 1818, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper
    .shop-header
    .header-inner
    .activity-block
    .inner-wrap
    .stat-block
    .stat-number {
    font-size: 1.2rem;
  }

  /* line 1826, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .shop-header .header-inner .about-block .ellipse-text {
    font-size: 0.7rem;
  }

  /* line 1835, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .store-sections .store-tab-pane .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1839, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .store-sections .store-tab-pane .columns .column .product-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 318px;
  }

  /* line 1852, src/assets/scss/pages/_app-commerce.scss */
  .product-quickview .modal-content {
    max-width: 740px;
  }

  /* line 1859, src/assets/scss/pages/_app-commerce.scss */
  .cart-container.is-payment {
    max-width: 500px;
  }

  /* line 1864, src/assets/scss/pages/_app-commerce.scss */
  .cart-container .cart-content .cart-summary {
    padding-left: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 1868, src/assets/scss/pages/_app-commerce.scss */
  .cart-container .cart-content .cart-summary h3 {
    display: none;
  }

  /* line 1872, src/assets/scss/pages/_app-commerce.scss */
  .cart-container .cart-content .cart-summary .summary-card {
    width: calc(50% - 16px);
    margin: 0 8px;
  }

  /* line 1880, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 500px;
  }

  /* line 1884, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left,
  .checkout-container .right {
    width: 100%;
  }

  /* line 1890, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .avatar {
    height: 60px;
    width: 60px;
  }

  /* line 1895, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .separator {
    margin: 0 16px;
  }

  /* line 1899, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .left .left-header .logo {
    display: block;
    max-width: 90px;
    max-height: 28px;
  }

  /* line 1907, src/assets/scss/pages/_app-commerce.scss */
  .checkout-container .right {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* line 1914, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper {
    overflow-x: hidden;
  }

  /* line 1921, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .store-sections .store-tab-pane .columns .column .product-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 318px;
  }

  /* line 1934, src/assets/scss/pages/_app-commerce.scss */
  .shop-wrapper .cart-container .cart-content .cart-summary {
    padding-left: 0 !important;
  }
}

/*! _app-landing.scss | Friendkit | © Css Ninja. 2019-2020 */ /*! _special-mixins.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 23, src/assets/scss/pages/_app-landing.scss */
.landing-hero-wrapper {
  position: relative;
  background-color: #fff;
  background-image: -webkit-linear-gradient(40deg, #fff 50%, #a985fc 50%);
}

/* line 29, src/assets/scss/pages/_app-landing.scss */
.landing-hero-wrapper #particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* line 39, src/assets/scss/pages/_app-landing.scss */
.landing-hero-wrapper .landing-caption h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  color: #fcfcfc;
  line-height: 1;
  text-shadow: 5px 5px #3180e1, 10px 10px #3180e1;
}

/* line 48, src/assets/scss/pages/_app-landing.scss */
.landing-hero-wrapper .landing-caption h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fcfcfc;
  padding: 0 10px;
  text-shadow: 2px 2px #3180e1, 5px 5px #3180e1;
}

/* line 57, src/assets/scss/pages/_app-landing.scss */
.landing-hero-wrapper .landing-caption .button {
  margin: 20px 10px;
  height: 48px;
  text-transform: uppercase;
  font-size: 0.95rem;
  min-width: 190px;
  color: #5596e6;
}

/* line 65, src/assets/scss/pages/_app-landing.scss */
.landing-hero-wrapper .landing-caption .button:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 72, src/assets/scss/pages/_app-landing.scss */
.header-logo {
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #5596e6;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  background: #5596e6;
  margin: 10px auto;
}

/* line 84, src/assets/scss/pages/_app-landing.scss */
.header-logo img {
  display: block;
  height: 32px;
  width: 32px;
}

/* line 96, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper.is-grey {
  background: #fafafa;
}

/* line 100, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .made-with {
  max-width: 640px;
  margin: 0 auto 3rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 107, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .made-with .made-block {
  width: calc(25% - 10px);
  margin: 10px;
}

/* line 111, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .made-with .made-block .block-icon {
  height: 90px;
  width: 90px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 122, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .made-with .made-block .block-icon img {
  display: block;
  max-width: 60px;
}

/* line 128, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .made-with .made-block .block-title {
  text-align: center;
  margin-top: 12px;
}

/* line 132, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .made-with .made-block .block-title h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #393a4f;
}

/* line 141, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .screen-wrapper {
  border-bottom: 1.4px solid #e8e8e8;
  overflow: hidden;
}

/* line 145, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .screen-wrapper img {
  display: block;
  border-radius: 8px 8px 0 0;
  border: 1px solid #e8e8e8;
  border-bottom: none;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  max-width: 940px;
  margin: 0 auto;
}

/* line 156, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .landing-icon-box {
  text-align: center;
  margin-bottom: 20px;
}

/* line 160, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .landing-icon-box svg {
  height: 30px;
  width: 30px;
  stroke-width: 1.4px;
  margin: 0 auto;
}

/* line 167, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .landing-icon-box img {
  display: block;
  height: 54px;
  width: 54px;
  margin: 0 auto 4px auto;
}

/* line 174, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .landing-icon-box h3 {
  font-family: "Montserrat", sans-serif;
  color: #393a4f;
  font-weight: 500;
  font-size: 0.95rem;
}

/* line 181, src/assets/scss/pages/_app-landing.scss */
.landing-wrapper .landing-icon-box p {
  font-size: 0.9rem;
  color: #999;
}

/* line 189, src/assets/scss/pages/_app-landing.scss */
.section.is-grey {
  background: #fafafa;
}

/* line 199, src/assets/scss/pages/_app-landing.scss */
.side-feature h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #393a4f;
  font-size: 1.5rem;
}

/* line 206, src/assets/scss/pages/_app-landing.scss */
.side-feature p {
  color: #999;
  font-size: 1rem;
  max-width: 440px;
}

/* line 212, src/assets/scss/pages/_app-landing.scss */
.side-feature img {
  display: block;
  max-width: 460px;
  margin: 0 auto;
}

/* line 223, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper {
  background: #fafafa;
}

/* line 227, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-title h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  color: #393a4f;
}

/* line 235, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list {
  padding: 80px 0;
}

/* line 238, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item {
  position: relative;
  display: block;
  overflow: hidden;
}

/* line 244, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 250, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item:hover .go-arrow {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* line 255, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item .go-arrow {
  position: absolute;
  bottom: -20px;
  right: -20px;
  height: 80px;
  width: 80px;
  background: #3f88e3;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(80px, 80px);
  transform: translate(80px, 80px);
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 2;
}

/* line 271, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item .go-arrow:hover {
  background: #3d70b2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 276, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item .go-arrow svg {
  position: relative;
  top: -6px;
  left: -6px;
  height: 22px;
  width: 22px;
  stroke: #fff;
}

/* line 286, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list .demo-item img {
  display: block;
  border: 1px solid #e8e8e8;
  -webkit-filter: grayscale(0.8);
  filter: grayscale(0.8);
  opacity: 0.85;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 295, src/assets/scss/pages/_app-landing.scss */
.demos-wrapper .demos-list h3 {
  padding: 16px 0;
  color: #393a4f;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 312, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title {
  text-align: center;
}

/* line 315, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  color: #393a4f;
}

/* line 322, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title .custom-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px auto;
  max-width: 200px;
  border: 2px solid #d3f4ed;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 331, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title .custom-button:hover {
  background: #d3f4ed;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 335, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title .custom-button:hover span {
  color: #393a4f;
}

/* line 340, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title .custom-button img {
  display: block;
  height: 50px;
  width: 50px;
}

/* line 346, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .cta-title .custom-button span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #8edfcf;
  margin-left: 12px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 358, src/assets/scss/pages/_app-landing.scss */
.cta-wrapper .people-img {
  display: block;
  max-width: 480px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  /* line 372, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption {
    text-align: center;
  }

  /* line 375, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption h2 {
    margin-top: 40px;
    font-size: 2.6rem;
    color: #5596e6;
    text-shadow: 5px 5px #fff, 10px 10px #fff;
  }

  /* line 382, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption h3 {
    color: #5596e6;
    text-shadow: 2px 2px #fff, 4px 4px #fff;
  }

  /* line 387, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption .buttons .button {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /* line 395, src/assets/scss/pages/_app-landing.scss */
  .side-feature img {
    max-width: 340px;
  }

  /* line 401, src/assets/scss/pages/_app-landing.scss */
  .landing-wrapper .made-with {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  /* line 404, src/assets/scss/pages/_app-landing.scss */
  .landing-wrapper .made-with .made-block {
    width: calc(45% - 10px);
  }

  /* line 410, src/assets/scss/pages/_app-landing.scss */
  .landing-wrapper .screen-wrapper img {
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 420, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption {
    text-align: center;
  }

  /* line 423, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption img {
    max-width: 580px;
  }

  /* line 427, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption h2 {
    color: #5596e6;
    text-shadow: 5px 5px #fff, 10px 10px #fff;
  }

  /* line 432, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption h3 {
    color: #5596e6;
    text-shadow: 2px 2px #fff, 4px 4px #fff;
  }

  /* line 437, src/assets/scss/pages/_app-landing.scss */
  .landing-hero-wrapper .landing-caption .buttons .button {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /* line 446, src/assets/scss/pages/_app-landing.scss */
  .landing-wrapper.icons-section .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 452, src/assets/scss/pages/_app-landing.scss */
  .landing-wrapper .screen-wrapper img {
    max-width: 100%;
  }

  /* line 459, src/assets/scss/pages/_app-landing.scss */
  .side-feature h3,
  .side-feature p {
    text-align: center;
    margin: 0 auto;
  }

  /* line 464, src/assets/scss/pages/_app-landing.scss */
  .side-feature img {
    max-width: 450px;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 475, src/assets/scss/pages/_app-landing.scss */
  .cta-wrapper .people-img {
    max-width: 600px;
  }

  /* line 480, src/assets/scss/pages/_app-landing.scss */
  #tour-start {
    display: none !important;
  }
}

/*! _app-signup.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/pages/_app-signup.scss */
.fake-nav {
  height: 55px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
}

/* line 25, src/assets/scss/pages/_app-signup.scss */
.fake-nav img {
  display: block;
  max-width: 48px;
}

/* line 31, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper {
  min-height: 100vh;
  position: relative;
}

/* line 35, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar-wrap {
  padding: 30px 0 0 0;
}

/* line 39, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar {
  height: 18px;
  background: #fff;
  border-radius: 100px;
  max-width: 520px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 48, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap {
  position: relative;
  margin: 0 12px;
  width: calc(100% - 24px);
  height: 6px;
  border-radius: 100px;
}

/* line 57, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .track,
.signup-wrapper .process-bar .progress-wrap .bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 100px;
}

/* line 65, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .track {
  background: #eaeaea;
  width: 100%;
}

/* line 70, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .bar {
  background: #5596e6;
  width: 0;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
}

/* line 76, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot {
  position: absolute;
  top: calc(50% - 19px);
  height: 38px;
  width: 38px;
  background: #fff;
  border-radius: 50%;
  border: 1.4px solid #e8e8e8;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
}

/* line 90, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-first {
  left: -19px;
}

/* line 94, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-second {
  left: calc(25% - 19px);
}

/* line 98, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-third {
  left: calc(50% - 19px);
}

/* line 102, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-fourth {
  left: calc(75% - 19px);
}

/* line 106, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-fifth {
  right: -19px;
}

/* line 110, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-active {
  border-color: #5596e6;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 114, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-active svg {
  stroke: #5596e6;
}

/* line 119, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-current {
  border-color: #3d70b2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(61, 112, 178, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(61, 112, 178, 0.2) !important;
}

/* line 123, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot.is-current svg {
  stroke: #3d70b2;
}

/* line 128, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-bar .progress-wrap .dot svg {
  height: 16px;
  width: 16px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 138, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .outer-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 133px);
}

/* line 143, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .outer-panel .outer-panel-inner {
  width: 100%;
}

/* line 148, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-title {
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
}

/* line 153, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-title h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400 !important;
  font-size: 1.2rem;
}

/* line 159, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-title .step-title {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
  display: none;
}

/* line 163, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-title .step-title.is-active {
  display: block;
}

/* line 169, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap {
  display: none;
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
  max-width: 940px;
  margin: 0 auto;
  padding: 20px 0;
}

/* line 176, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap.is-active {
  display: block;
}

/* line 180, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap.is-narrow {
  max-width: 540px;
}

/* line 184, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .account-type {
  width: 100%;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
}

/* line 192, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .account-type img {
  display: block;
  margin: 0 auto 20px auto;
  max-height: 140px;
}

/* line 198, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .account-type h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400 !important;
  font-size: 0.9rem;
}

/* line 204, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .account-type p {
  color: #999;
  font-size: 0.9rem;
}

/* line 209, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .account-type .button {
  margin-top: 20px;
  border-width: 1.4px;
  color: #999;
}

/* line 214, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .account-type .button:hover,
.signup-wrapper .process-panel-wrap .account-type .button.is-loading {
  border-color: #5596e6;
  background: #5596e6;
  color: #fff;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 223, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel {
  width: 100%;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 30px;
}

/* line 230, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .field {
  border: 1px solid #e8e8e8;
  padding: 4px 8px 8px 8px;
  border-radius: 6px;
  background: #fcfcfc;
  margin-bottom: 20px;
}

/* line 237, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .field:last-child {
  margin-bottom: 0;
}

/* line 241, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .field label {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  color: #757a91;
  padding-left: 8px;
}

/* line 250, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .field .control input {
  border: none;
  height: 30px;
  background: #fcfcfc;
}

/* line 258, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .success-image {
  display: block;
  max-width: 120px;
  margin: 0 auto;
}

/* line 264, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .success-text {
  text-align: center;
  margin: 12px auto;
  max-width: 370px;
}

/* line 269, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .success-text h3 {
  font-weight: 500;
  color: #393a4f;
}

/* line 274, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .success-text p {
  font-size: 0.9rem;
  color: #999;
}

/* line 279, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .success-text .button {
  margin: 20px auto 0 auto;
  max-width: 280px;
  border: 1.4px solid #5596e6;
  color: #5596e6;
}

/* line 285, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .form-panel .success-text .button:hover,
.signup-wrapper
  .process-panel-wrap
  .form-panel
  .success-text
  .button.is-loading {
  border-color: #5596e6;
  background: #5596e6;
  color: #fff;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 296, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .photo-upload .preview {
  position: relative;
  height: 120px;
  width: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 1.4px solid #cecece;
  margin: 0 auto;
}

/* line 307, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .photo-upload .preview img {
  display: block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

/* line 314, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .photo-upload .preview .upload-button {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #cecece;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 328, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper
  .process-panel-wrap
  .photo-upload
  .preview
  .upload-button:hover {
  background: #3d70b2;
}

/* line 332, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .photo-upload .preview .upload-button svg {
  height: 14px;
  width: 14px;
  stroke: #fff;
  stroke-width: 3px;
}

/* line 341, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .photo-upload .limitation {
  margin-top: 20px;
  text-align: center;
}

/* line 345, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .photo-upload .limitation small {
  color: #999;
}

/* line 351, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .buttons {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* line 357, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .buttons .button {
  min-width: 90px;
}

/* line 360, src/assets/scss/pages/_app-signup.scss */
.signup-wrapper .process-panel-wrap .buttons .button.is-next:hover {
  border-color: #5596e6;
  background: #5596e6;
  color: #fcfcfc;
  -webkit-box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
  box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}

/* line 376, src/assets/scss/pages/_app-signup.scss */
.crop-modal .modal-card {
  max-width: 100%;
}

/* line 380, src/assets/scss/pages/_app-signup.scss */
.crop-modal .modal-card-body {
  position: relative;
  min-height: 330px;
  overflow: hidden;
}

/* line 385, src/assets/scss/pages/_app-signup.scss */
.crop-modal .modal-card-body .cropper-wrapper {
  margin: 0 auto;
}

/* line 388, src/assets/scss/pages/_app-signup.scss */
.crop-modal .modal-card-body .cropper-wrapper .button {
  min-width: 70px;
  height: 34px;
  border-radius: 100px;
  outline: none !important;
  background: #3d70b2;
  border-color: #3d70b2;
  color: #fff;
}

@media (max-width: 767px) {
  /* line 408, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner {
    max-width: 330px;
    margin: 0 auto;
  }

  /* line 412, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner .account-type {
    position: relative;
    text-align: left;
  }

  /* line 416, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner .account-type img {
    display: none;
  }

  /* line 420, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner .account-type .button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  /* line 433, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .process-bar-wrap {
    max-width: 320px;
    margin: 0 auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 443, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner {
    max-width: 740px;
    margin: 0 auto;
  }

  /* line 447, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 450, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner .columns .account-type {
    padding: 20px;
  }

  /* line 453, src/assets/scss/pages/_app-signup.scss */
  .signup-wrapper .outer-panel .outer-panel-inner .columns .account-type img {
    height: 110px;
  }
}

/*! _app-login.scss | Crowdkit | © Css Ninja. 2019-2020 */ /* line 19, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.is-login {
  position: relative;
  background: #5596e6;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#9cc2f0),
    to(#5596e6)
  );
  background: linear-gradient(180deg, #9cc2f0, #5596e6);
  background-size: 400% 400%;
  -webkit-animation: gradientShift 12s ease infinite;
  animation: gradientShift 12s ease infinite;
}

/* line 26, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.is-login .left-caption {
  max-width: 400px;
  margin: 0 auto;
}

/* line 30, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.is-login .left-caption h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  color: #fcfcfc;
  line-height: 1.3;
  text-shadow: 4px 4px #3180e1, 8px 8px #3180e1;
}

/* line 41, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.form-hero {
  position: relative;
}

/* line 44, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.form-hero .logo-wrap {
  position: absolute;
  top: calc(50% - 50px);
  left: -50px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(32deg);
  transform: rotate(32deg);
}

/* line 57, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.form-hero .logo-wrap .wrap-inner {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background: #5596e6;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#9cc2f0),
    to(#5596e6)
  );
  background: linear-gradient(180deg, #9cc2f0, #5596e6);
  background-size: 400% 400%;
  -webkit-animation: gradientShift 45s ease infinite;
  animation: gradientShift 45s ease infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 69, src/assets/scss/pages/_app-login.scss */
.login-wrapper .hero.form-hero .logo-wrap .wrap-inner img {
  display: block;
  max-width: 54px;
}

/* line 78, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper {
  max-width: 540px;
  min-width: 380px;
  margin: 0 auto;
}

/* line 83, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .avatar {
  position: relative;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  border: 1.4px solid #cecece;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 94, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .avatar .badge {
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 107, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .avatar .badge svg {
  height: 14px;
  width: 14px;
  stroke: #fff;
  stroke-width: 4px;
}

/* line 115, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .avatar img {
  display: block;
  height: 94px;
  width: 94px;
  border-radius: 50%;
}

/* line 125, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .login-form .field .control {
  position: relative;
}

/* line 128, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .login-form .field .control .input {
  height: 40px;
  padding-left: 40px;
}

/* line 132, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .login-form .field .control .input:focus {
  border-color: #5596e6;
}

/* line 136, src/assets/scss/pages/_app-login.scss */
.login-wrapper
  .form-wrapper
  .login-form
  .field
  .control
  .input:focus
  + .input-icon
  svg {
  stroke: #5596e6;
}

/* line 143, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .login-form .field .control .input-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 153, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .login-form .field .control .input-icon svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 161, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .login-form .field .control button,
.login-wrapper .form-wrapper .login-form .field .control .button {
  height: 46px;
  font-size: 0.95rem;
}

/* line 169, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .forgot-password {
  padding: 1.5rem !important;
}

/* line 172, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .forgot-password a {
  color: #999;
  font-size: 0.9rem;
}

/* line 176, src/assets/scss/pages/_app-login.scss */
.login-wrapper .form-wrapper .forgot-password a:hover {
  color: #5596e6;
}

/* line 188, src/assets/scss/pages/_app-login.scss */
.login-container {
  height: calc(100vh - 100px);
  padding: 20px;
}

/* line 192, src/assets/scss/pages/_app-login.scss */
.login-container .login-image {
  display: block;
  max-width: 620px;
  margin: 0 auto;
}

/* line 198, src/assets/scss/pages/_app-login.scss */
.login-container .form-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  color: #393a4f;
  font-weight: 300;
}

/* line 205, src/assets/scss/pages/_app-login.scss */
.login-container .form-subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #abb0be;
  margin-bottom: 1rem;
}

/* line 212, src/assets/scss/pages/_app-login.scss */
.login-container .login-form {
  max-width: 380px;
}

/* line 216, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0 16px 0;
}

/* line 222, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex .switch-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 226, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex .switch-block .f-switch {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

/* line 230, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex .switch-block .meta {
  margin-left: 6px;
}

/* line 233, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex .switch-block .meta p {
  font-size: 0.9rem;
  color: #999;
}

/* line 240, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex a {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: #abb0be;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 246, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .field.is-flex a:hover {
  color: #3d70b2;
}

/* line 254, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .account-link a {
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  color: #abb0be;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 260, src/assets/scss/pages/_app-login.scss */
.login-container .login-form .account-link a:hover {
  color: #3d70b2;
}

@media (max-width: 767px) {
  /* line 274, src/assets/scss/pages/_app-login.scss */
  .login-wrapper .logo-wrap {
    display: none !important;
  }

  /* line 278, src/assets/scss/pages/_app-login.scss */
  .login-wrapper .form-wrapper {
    min-width: 320px !important;
  }

  /* line 284, src/assets/scss/pages/_app-login.scss */
  .login-container .image-column {
    display: none;
  }

  /* line 288, src/assets/scss/pages/_app-login.scss */
  .login-container .form-title,
  .login-container .form-subtitle {
    text-align: center;
  }

  /* line 292, src/assets/scss/pages/_app-login.scss */
  .login-container .form-title {
    padding-top: 60px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 301, src/assets/scss/pages/_app-login.scss */
  .login-wrapper .hero.is-login .left-caption h2 {
    font-size: 2.5rem;
  }

  /* line 308, src/assets/scss/pages/_app-login.scss */
  .login-container .columns {
    padding-top: 60px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 316, src/assets/scss/pages/_app-login.scss */
  .login-wrapper .logo-wrap {
    display: none !important;
  }

  /* line 322, src/assets/scss/pages/_app-login.scss */
  .login-container .image-column {
    display: none;
  }

  /* line 326, src/assets/scss/pages/_app-login.scss */
  .login-container .column {
    min-width: 50%;
  }

  /* line 330, src/assets/scss/pages/_app-login.scss */
  .login-container .form-title,
  .login-container .form-subtitle {
    text-align: center;
  }

  /* line 334, src/assets/scss/pages/_app-login.scss */
  .login-container .form-title {
    padding-top: 60px;
  }

  /* line 338, src/assets/scss/pages/_app-login.scss */
  .login-container .login-form {
    max-width: 360px;
    margin: 0 auto;
  }
}

/*! _app-settings.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 16, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar {
  position: fixed;
  top: 57px;
  left: 0;
  height: calc(100% - 56px);
  background: #fafafa;
  border-right: 1px solid #e8e8e8;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 10;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 29, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar.is-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* line 33, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* line 38, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block {
  position: relative;
  margin: 20px 20px 0 20px;
  border-bottom: 1px solid #dedede;
  text-align: center;
  height: 140px;
}

/* line 45, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .close-settings-sidebar {
  position: absolute;
  top: -22px;
  right: -22px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 57, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-block
  .close-settings-sidebar:hover {
  background: #fff;
}

/* line 60, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-block
  .close-settings-sidebar:hover
  svg {
  stroke: #393a4f;
}

/* line 65, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-block
  .close-settings-sidebar
  svg {
  height: 16px;
  width: 16px;
  color: #999;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 73, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .avatar-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 74px;
  height: 74px;
  min-width: 74px;
  border: 1.4px solid #d8d8d8;
  border-radius: 50%;
  margin: 0 auto 6px auto;
}

/* line 85, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .avatar-wrap img {
  display: block;
  width: 58px;
  height: 58px;
  min-width: 58px;
  border-radius: 50%;
}

/* line 93, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .avatar-wrap .badge {
  position: absolute;
  bottom: 2px;
  right: -3px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1ce589;
}

/* line 106, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .avatar-wrap .badge svg {
  height: 10px;
  width: 10px;
  stroke: #fcfcfc;
  stroke-width: 4px;
}

/* line 115, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  color: #393a4f;
}

/* line 122, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block p {
  font-size: 0.8rem;
  color: #999;
}

/* line 127, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .user-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 20px 0;
}

/* line 133, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-block .user-stats .stat-block {
  text-align: center;
}

/* line 136, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span {
  display: block;
}

/* line 139, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span:first-child {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.65rem;
  color: #393a4f;
}

/* line 146, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-block
  .user-stats
  .stat-block
  span:last-child {
  font-size: 0.8rem;
  color: #999;
}

/* line 155, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-menu {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: calc(100% - 140px);
}

/* line 162, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar .settings-sidebar-inner .user-menu .user-menu-inner {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}

/* line 169, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .separator {
  margin: 0 30px;
  border-bottom: 1px solid #dedede;
}

/* line 174, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block {
  padding: 20px 0;
}

/* line 178, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 40px;
  border-left: 4px solid transparent;
}

/* line 184, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li.is-active {
  border-color: #5596e6;
}

/* line 188, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li.is-active
  a
  span {
  color: #393a4f;
}

/* line 194, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 198, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a
  span {
  display: block;
  color: #cecece;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 207, src/assets/scss/pages/_app-settings.scss */
.settings-sidebar
  .settings-sidebar-inner
  .user-menu
  .user-menu-inner
  .menu-block
  ul
  li
  a
  svg {
  height: 18px;
  width: 18px;
  stroke: #cecece;
  margin-right: 20px;
}

/* line 222, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper {
  padding: 30px;
  width: calc(100% - 280px);
  margin-left: 280px;
}

/* line 227, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section {
  display: none;
}

/* line 231, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section.is-active {
  display: block;
}

/* line 235, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section .settings-panel {
  background: #fff;
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

/* line 241, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section .settings-panel .title-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 245, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .title-wrap
  .mobile-sidebar-trigger {
  z-index: 9;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 258, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .title-wrap
  .mobile-sidebar-trigger
  svg {
  height: 20px;
  width: 20px;
  color: #999;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 267, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section .settings-panel h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #999;
}

/* line 274, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section .settings-panel .settings-form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 277, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form-wrapper
  .illustration {
  padding: 40px;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

/* line 281, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form-wrapper
  .illustration
  img {
  display: block;
  max-width: 200px;
  // margin: 0 auto
  // width:150px;
}

/* line 287, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form-wrapper
  .illustration
  p {
  max-width: 280px;
  color: #7f80a2;
  font-size: 0.8rem;
  margin: 20px auto;
}

/* line 293, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form-wrapper
  .illustration
  p
  a {
  text-decoration: underline;
}

/* line 300, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper .settings-section .settings-panel .settings-form {
  padding-top: 20px;
  width: 100%;
  max-width: 1000px;
}

/* line 305, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .field-group {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  position: relative;
  margin-bottom: 23px;
}

/* line 309, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .field-group
  label {
  text-transform: uppercase;
  font-size: 0.65rem;
  color: #999;
  padding-left: 6px;
}

/* line 316, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .field-group
  .input,
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .field-group
  .textarea {
  border: none !important;
  font-family: "Roboto", sans-serif;
  padding-top: 0;
  color: #5b5d7e;
  border-radius: 8px;
}

/* line 324, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .field-group
  .form-icon {
  left: 10px;
  height: 26px;
  width: 26px;
}

/* line 329, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .field-group
  .form-icon
  svg {
  height: 17px;
  width: 17px;
}

/* line 336, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .sub-heading {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #999;
  padding: 16px 0;
}

/* line 343, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .spaced-field {
  padding-bottom: 10px;
}

/* line 347, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .switch-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 351, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .switch-block
  .f-switch {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

/* line 355, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .switch-block
  .meta {
  margin-left: 10px;
}

/* line 358, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .switch-block
  .meta
  h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: #393a4f;
}

/* line 365, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .switch-block
  .meta
  p {
  font-size: 0.8rem;
  color: #999;
}

/* line 372, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 382, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink:hover
  .link-content
  .link-icon {
  background: #fff;
  border-color: #e0e0e0;
  -webkit-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

/* line 387, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink:hover
  .link-content
  .link-icon
  svg {
  stroke: #5596e6;
}

/* line 392, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink:hover
  .link-content
  h4 {
  color: #5596e6;
}

/* line 399, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink
  .link-content
  .link-icon {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid transparent;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 410, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink
  .link-content
  .link-icon
  svg {
  height: 18px;
  width: 18px;
  stroke: #6f7592;
  stroke-width: 1.2px;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}

/* line 419, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink
  .link-content
  h4 {
  margin-top: 4px;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #393a4f;
  line-height: 1;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 429, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .setting-sublink
  .link-content
  p {
  color: #999;
  font-size: 0.75rem;
}

/* line 437, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .form-text
  p {
  color: #64668a;
  font-size: 0.9rem;
  max-width: 450px;
}

/* line 444, src/assets/scss/pages/_app-settings.scss */
.settings-wrapper
  .settings-section
  .settings-panel
  .settings-form
  .form-button {
  height: 44px;
  min-width: 160px;
}

@media (min-width: 1301px) {
  /* line 459, src/assets/scss/pages/_app-settings.scss */
  .settings-sidebar {
    width: 280px;
  }

  /* line 464, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper .inner-wrapper {
    width: calc(100% - 280px);
    margin-left: 280px;
  }
}

@media (max-width: 1300px) {
  /* line 473, src/assets/scss/pages/_app-settings.scss */
  .settings-sidebar {
    width: 250px;
  }

  /* line 477, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
}

@media (max-width: 767px) {
  /* line 488, src/assets/scss/pages/_app-settings.scss */
  .settings-sidebar
    .settings-sidebar-inner
    .user-block
    .close-settings-sidebar {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 495, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper {
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }

  /* line 503, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper
    .settings-section
    .settings-panel
    .title-wrap
    .mobile-sidebar-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 508, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper .settings-section .settings-panel .settings-form-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 513, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper
    .settings-section
    .settings-panel
    .settings-form-wrapper
    .settings-form
    .buttons
    .button {
    min-width: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* line 528, src/assets/scss/pages/_app-settings.scss */
  .settings-sidebar
    .settings-sidebar-inner
    .user-block
    .close-settings-sidebar {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 535, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper {
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }

  /* line 543, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper
    .settings-section
    .settings-panel
    .title-wrap
    .mobile-sidebar-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 548, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper .settings-section .settings-panel .settings-form-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 553, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper
    .settings-section
    .settings-panel
    .settings-form-wrapper
    .settings-form
    .columns.flex-portrait {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* line 557, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper
    .settings-section
    .settings-panel
    .settings-form-wrapper
    .settings-form
    .columns.flex-portrait
    .column.is-4 {
    min-width: 33.33%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* line 577, src/assets/scss/pages/_app-settings.scss */
  .settings-wrapper
    .settings-section
    .settings-panel
    .settings-form-wrapper
    .illustration {
    display: none;
  }
}

/*! _app-demo.scss | Friendkit | © Css Ninja. 2019-2020 */ /* line 17, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar {
  position: fixed;
  top: 58px;
  left: 0;
  width: 300px;
  height: calc(100% - 58px);
  background: #fff;
  border-right: 1px solid #e8e8e8;
}

/* line 26, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .header {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
}

/* line 32, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .header h3 {
  text-transform: uppercase;
}

/* line 37, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list {
  position: relative;
  height: calc(100% - 60px);
  overflow-y: auto;
}

/* line 42, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu {
  border-top: 1px solid #e8e8e8;
}

/* line 45, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
}

/* line 51, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger:hover,
.elements-sidebar .elements-list .element-menu .menu-trigger.is-active {
  background: #f5f5f5;
}

/* line 54, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger:hover span,
.elements-sidebar .elements-list .element-menu .menu-trigger.is-active span {
  color: #393a4f;
}

/* line 58, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger:hover svg,
.elements-sidebar .elements-list .element-menu .menu-trigger.is-active svg {
  stroke: #393a4f;
}

/* line 64, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger.is-active svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 69, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger span {
  display: block;
  color: #999;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

/* line 75, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .menu-trigger svg {
  height: 16px;
  width: 16px;
  stroke: #999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 83, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .submenu-wrap {
  display: none;
  padding: 10px 20px;
}

/* line 87, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .submenu-wrap ul {
  padding-left: 30px;
}

/* line 91, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .submenu-wrap ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 0;
  color: #999;
  font-size: 0.9rem;
}

/* line 97, src/assets/scss/pages/_app-demo.scss */
.elements-sidebar .elements-list .element-menu .submenu-wrap ul li a.is-active {
  color: #5596e6;
}

/* line 112, src/assets/scss/pages/_app-demo.scss */
.elements-wrap {
  width: calc(100% - 300px);
  min-height: 100vh;
  margin-left: 300px;
  padding: 56px 30px 30px 30px;
}

/* line 118, src/assets/scss/pages/_app-demo.scss */
.elements-wrap .element-block {
  margin-top: 30px;
  width: 100%;
  padding: 30px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

/* line 126, src/assets/scss/pages/_app-demo.scss */
.elements-wrap .element-block h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
  color: #393a4f;
}

/* line 133, src/assets/scss/pages/_app-demo.scss */
.elements-wrap .element-block p {
  margin: 10px 0;
  font-size: 0.9rem;
  color: #999;
}

/* line 139, src/assets/scss/pages/_app-demo.scss */
.elements-wrap .element-block .ui-elements {
  margin-top: 20px;
}

/* line 144, src/assets/scss/pages/_app-demo.scss */
.elements-wrap .code-block {
  margin-top: 30px;
  width: 100%;
  background: #344258;
  border: 1px solid #344258;
  border-radius: 8px;
}

@media (max-width: 767px) {
  /* line 158, src/assets/scss/pages/_app-demo.scss */
  .elements-sidebar {
    display: none;
  }

  /* line 162, src/assets/scss/pages/_app-demo.scss */
  .elements-wrap {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 169, src/assets/scss/pages/_app-demo.scss */
  .elements-sidebar {
    display: none;
  }

  /* line 173, src/assets/scss/pages/_app-demo.scss */
  .elements-wrap {
    width: 100%;
    margin-left: 0;
  }
}

/*! _reponsive.scss | Friendkit | © Css Ninja. 2019-2020 */
@media screen and (min-width: 1297px) {
  /* line 19, src/assets/scss/layout/_layout-responsive.scss */
  .container.is-custom {
    max-width: 1240px;
    width: 1240px;
  }
}

@media screen and (min-width: 1088px) {
  /* line 26, src/assets/scss/layout/_layout-responsive.scss */
  .container.is-custom {
    max-width: 1140px;
    width: 1140px;
  }
}

@media (max-width: 767px) {
  /* line 39, src/assets/scss/layout/_layout-responsive.scss */
  .view-wrap.is-headless {
    padding-top: 10px !important;
  }

  /* line 45, src/assets/scss/layout/_layout-responsive.scss */
  .column.no-padding-left {
    padding-left: 0.75rem;
  }

  /* line 49, src/assets/scss/layout/_layout-responsive.scss */
  .column.no-padding-right {
    padding-right: 0.75rem;
  }

  /* line 53, src/assets/scss/layout/_layout-responsive.scss */
  .column.no-side-padding-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* line 61, src/assets/scss/layout/_layout-responsive.scss */
  .mobile-navbar .nav-drop {
    position: fixed !important;
    top: 58px !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }

  /* line 72, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container {
    padding: 0 20px;
  }

  /* line 76, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container .explorer-header .control {
    max-width: 200px;
  }

  /* line 81, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container .explore-list {
    max-height: 528px;
  }

  /* line 84, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container .explore-list .explore-item {
    padding: 4px 0;
    width: calc(33.3% - 16px);
  }

  /* line 88, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container .explore-list .explore-item img {
    height: 50px;
    width: 50px;
  }

  /* line 100, src/assets/scss/layout/_layout-responsive.scss */
  .card.is-post .likers-text,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .is-post.summary-card
    .likers-text {
    display: none;
  }

  /* line 104, src/assets/scss/layout/_layout-responsive.scss */
  .card.is-post .post-link,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .is-post.summary-card
    .post-link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  /* line 107, src/assets/scss/layout/_layout-responsive.scss */
  .card.is-post .post-link .link-image,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .is-post.summary-card
    .post-link
    .link-image {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 180px;
  }

  /* line 113, src/assets/scss/layout/_layout-responsive.scss */
  .card.is-post .post-link .link-image > img,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .is-post.summary-card
    .post-link
    .link-image
    > img {
    width: 100% !important;
    height: 100% !important;
    max-height: 180px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  /* line 121, src/assets/scss/layout/_layout-responsive.scss */
  .card.is-post .post-link .link-content,
  .shop-wrapper
    .cart-container
    .cart-content
    .cart-summary
    .is-post.summary-card
    .post-link
    .link-content {
    margin-left: 0 !important;
    padding: 16px 6px;
  }

  /* line 131, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-search {
    display: none !important;
  }

  /* line 135, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-body {
    width: calc(100% - 60px) !important;
  }

  /* line 139, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-body-inner {
    padding: 20px 10px 60px 10px !important;
  }

  /* line 143, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-action {
    padding: 0 10px !important;
  }

  /* line 147, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-panel {
    width: calc(100% - 60px) !important;
  }

  /* line 153, src/assets/scss/layout/_layout-responsive.scss */
  .modal-content {
    max-height: calc(100vh - 80px) !important;
  }

  /* line 158, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-wrapper {
    overflow: hidden;
  }

  /* line 161, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-wrapper .reply-wrapper {
    padding: 20px 10px !important;
  }

  /* line 164, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-wrapper .reply-wrapper .reply-wrapper-inner {
    padding: 10px !important;
  }

  /* line 170, src/assets/scss/layout/_layout-responsive.scss */
  .control.is-grouped {
    margin-bottom: 8px;
  }

  /* line 174, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-left-sidebar {
    width: 260px !important;
    -webkit-transform: translateX(-260px);
    transform: translateX(-260px);
  }

  /* line 178, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-left-sidebar.is-opened-mobile {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  /* line 183, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-center-container {
    width: 100% !important;
    left: 0 !important;
  }

  /* line 187, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-center-container .action-buttons {
    padding: 10px 20px !important;
  }

  /* line 192, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container {
    width: 100% !important;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  /* line 196, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container.is-opened-mobile {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  /* line 200, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container .message-body {
    padding: 1.25em 0.5em !important;
  }

  /* line 204, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container .message-body .message-preview .header {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    text-align: center !important;
  }

  /* line 208, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container .message-body .message-preview .header .meta-right {
    margin: 10px 0 !important;
    text-align: center !important;
  }

  /* line 219, src/assets/scss/layout/_layout-responsive.scss */
  .cover-bg .cover-image {
    min-height: 180px !important;
  }

  /* line 224, src/assets/scss/layout/_layout-responsive.scss */
  .profile-subheader {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin-top: 50px !important;
  }

  /* line 229, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: -30px;
  }

  /* line 233, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  /* line 238, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  /* line 243, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner .menu-item {
    padding: 0 10px;
  }

  /* line 246, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner .menu-item .menu-icon {
    padding: 0 16px;
  }

  /* line 250, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner .menu-item span {
    display: none;
  }

  /* line 257, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .right-content {
    padding: 0;
  }

  /* line 264, src/assets/scss/layout/_layout-responsive.scss */
  .about-timeline .timeline-item .button {
    display: none;
  }

  /* line 271, src/assets/scss/layout/_layout-responsive.scss */
  .about-card .video-wrapper {
    width: calc(50% - 8px) !important;
  }

  /* line 275, src/assets/scss/layout/_layout-responsive.scss */
  .about-card .place-wrapper {
    width: calc(100% - 8px) !important;
  }

  /* line 282, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  /* line 286, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-head .head-text {
    padding-bottom: 20px;
  }

  /* line 291, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-body {
    padding: 1rem !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* line 303, src/assets/scss/layout/_layout-responsive.scss */
  .container {
    max-width: 100%;
  }

  /* line 306, src/assets/scss/layout/_layout-responsive.scss */
  .container .column {
    padding: 0.5rem;
  }

  /* line 311, src/assets/scss/layout/_layout-responsive.scss */
  .is-hidden-landscape {
    display: none !important;
  }

  /* line 319, src/assets/scss/layout/_layout-responsive.scss */
  .mobile-navbar .navbar-brand .navbar-item:nth-child(2) {
    margin-left: auto !important;
  }

  /* line 329, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container .explorer-header {
    padding-left: 40px;
    padding-right: 40px;
  }

  /* line 338, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-body-inner {
    padding: 20px 20px 60px 20px !important;
  }

  /* line 342, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-action {
    padding: 0 20px !important;
  }

  /* line 348, src/assets/scss/layout/_layout-responsive.scss */
  .reply-wrapper {
    padding: 20px 0 !important;
  }

  /* line 351, src/assets/scss/layout/_layout-responsive.scss */
  .reply-wrapper .reply-wrapper-inner {
    padding: 10px !important;
  }

  /* line 357, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-left-sidebar .compose {
    padding: 20px 10px !important;
  }

  /* line 362, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-left-sidebar .left-menu a {
    margin: 0 10px !important;
    padding: 10px !important;
  }

  /* line 369, src/assets/scss/layout/_layout-responsive.scss */
  .action-buttons .button {
    margin-bottom: 4px !important;
  }

  /* line 376, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu {
    max-width: 220px;
    min-width: 220px;
  }

  /* line 387, src/assets/scss/layout/_layout-responsive.scss */
  .friend-cards-list .is-friend-card .friend-item .text-content a {
    font-size: 0.8rem !important;
  }

  /* line 391, src/assets/scss/layout/_layout-responsive.scss */
  .friend-cards-list .is-friend-card .friend-item .text-content span {
    font-size: 0.65rem !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* line 407, src/assets/scss/layout/_layout-responsive.scss */
  .columns.has-portrait-padding {
    padding: 0 40px;
  }

  /* line 413, src/assets/scss/layout/_layout-responsive.scss */
  .column.no-padding-left {
    padding-left: 0.75rem;
  }

  /* line 417, src/assets/scss/layout/_layout-responsive.scss */
  .column.no-padding-right {
    padding-right: 0.75rem;
  }

  /* line 426, src/assets/scss/layout/_layout-responsive.scss */
  .mobile-navbar .navbar-brand .navbar-item:nth-child(2) {
    margin-left: auto !important;
  }

  /* line 436, src/assets/scss/layout/_layout-responsive.scss */
  .explorer-menu .explorer-container .explorer-header {
    padding-left: 40px;
    padding-right: 40px;
  }

  /* line 445, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-body {
    width: calc(100% - 60px) !important;
  }

  /* line 449, src/assets/scss/layout/_layout-responsive.scss */
  .chat-wrapper .chat-body-inner {
    padding: 20px 40px 60px 40px !important;
  }

  /* line 455, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-wrapper {
    overflow: hidden;
  }

  /* line 459, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-left-sidebar {
    width: 260px !important;
    -webkit-transform: translateX(-260px);
    transform: translateX(-260px);
  }

  /* line 463, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-left-sidebar.is-opened-mobile {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  /* line 468, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-center-container {
    width: 100% !important;
    left: 0 !important;
  }

  /* line 472, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-center-container .action-buttons {
    padding: 10px 20px !important;
  }

  /* line 477, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container {
    width: 100% !important;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  /* line 481, src/assets/scss/layout/_layout-responsive.scss */
  .inbox-message-container.is-opened-mobile {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  /* line 488, src/assets/scss/layout/_layout-responsive.scss */
  .cover-bg .cover-image {
    min-height: 180px !important;
  }

  /* line 493, src/assets/scss/layout/_layout-responsive.scss */
  .profile-subheader {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin-top: 50px !important;
  }

  /* line 498, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: -30px;
  }

  /* line 502, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  /* line 507, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  /* line 512, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner .menu-item {
    padding: 0 10px;
  }

  /* line 515, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .left-menu .left-menu-inner .menu-item .menu-icon {
    padding: 0 16px;
  }

  /* line 522, src/assets/scss/layout/_layout-responsive.scss */
  .profile-about .right-content {
    padding: 0;
  }

  /* line 529, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-head {
    padding: 1rem !important;
  }

  /* line 533, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2rem 1rem !important;
  }

  /* line 537, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-body .column.is-4 {
    width: 42%;
    max-width: 42%;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  /* line 543, src/assets/scss/layout/_layout-responsive.scss */
  .footer-light-medium .footer-body .is-offset-2 .columns {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

/* line 3, node_modules/slick-carousel/slick/slick.scss */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

/* line 17, node_modules/slick-carousel/slick/slick.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

/* line 24, node_modules/slick-carousel/slick/slick.scss */
.slick-list:focus {
  outline: none;
}

/* line 28, node_modules/slick-carousel/slick/slick.scss */
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

/* line 33, node_modules/slick-carousel/slick/slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* line 42, node_modules/slick-carousel/slick/slick.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* line 50, node_modules/slick-carousel/slick/slick.scss */
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

/* line 56, node_modules/slick-carousel/slick/slick.scss */
.slick-track:after {
  clear: both;
}

/* line 60, node_modules/slick-carousel/slick/slick.scss */
.slick-loading .slick-track {
  visibility: hidden;
}

/* line 64, node_modules/slick-carousel/slick/slick.scss */
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

/* line 68, node_modules/slick-carousel/slick/slick.scss */
[dir="rtl"] .slick-slide {
  float: right;
}

/* line 71, node_modules/slick-carousel/slick/slick.scss */
.slick-slide img {
  display: block;
}

/* line 74, node_modules/slick-carousel/slick/slick.scss */
.slick-slide.slick-loading img {
  display: none;
}

/* line 80, node_modules/slick-carousel/slick/slick.scss */
.slick-slide.dragging img {
  pointer-events: none;
}

/* line 84, node_modules/slick-carousel/slick/slick.scss */
.slick-initialized .slick-slide {
  display: block;
}

/* line 88, node_modules/slick-carousel/slick/slick.scss */
.slick-loading .slick-slide {
  visibility: hidden;
}

/* line 92, node_modules/slick-carousel/slick/slick.scss */
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* line 98, node_modules/slick-carousel/slick/slick.scss */
.slick-arrow.slick-hidden {
  display: none;
}

/* line 45, node_modules/slick-carousel/slick/slick-theme.scss */


@font-face {
  font-family: "slick";
  src: url("../../../Assets/main/fonts/slick.eot");
  src: url("../../../Assets/main/fonts/slick.eot?#iefix")
      format("embedded-opentype"),
    url("../../../Assets/main/fonts/slick.woff") format("woff"),
    url("../../../Assets/main/fonts/slick.ttf") format("truetype"),
    url("../../../Assets/main/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* line 63, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

/* line 81, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

/* line 85, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

/* line 89, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

/* line 92, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 103, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev {
  left: -25px;
}

/* line 105, node_modules/slick-carousel/slick/slick-theme.scss */
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

/* line 109, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev:before {
  content: "←";
}

/* line 111, node_modules/slick-carousel/slick/slick-theme.scss */
[dir="rtl"] .slick-prev:before {
  content: "→";
}

/* line 117, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-next {
  right: -25px;
}

/* line 119, node_modules/slick-carousel/slick/slick-theme.scss */
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

/* line 123, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-next:before {
  content: "→";
}

/* line 125, node_modules/slick-carousel/slick/slick-theme.scss */
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* line 133, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

/* line 137, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

/* line 146, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

/* line 154, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

/* line 166, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

/* line 168, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

/* line 172, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #000;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 189, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots li.slick-active button:before {
  color: #000;
  opacity: 0.75;
}

.tag.hightlight.square {
  background-color: #8955ff;
  color: white;
  border-radius: 0;
}

.description-title.hightlight {
  color: black;
  font-weight: 500;
  font-size: 14px;
}

.calendar-date i {    
  color: #037695;
  margin-right: 5px;
}

.icon-hightlight {
  color: #037695;
}

.image.avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}


.comments-box {
  margin: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
}

.comments-box textarea {
  border: none;
  resize: none;
}

.comments-box .button.submit {
  margin-left: auto;
}


/*# sourceMappingURL=../../../scss */
@media (max-width: 991px) {
  .story-post-wrapper .story-post .post-meta {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
