/*! _colors.scss | Friendkit | © Css Ninja. 2019-2020 */

/* ==========================================================================
Main color and shadow variables
========================================================================== */

/*
    1. Main colors
    2. State colors
    3. Social colors
    4. Box Shadows
*/

/* ==========================================================================
1. Main colors
========================================================================== */

$white: #fff;
$white-smoke: #fcfcfc;
$white-light: #fafafa;
$smokey: #f5f5f5;
$accent-grey: #ccc;
$grey-text: #888da8;
$medium-text: #757a91;
$blueish-grey: #ecf0f7;
$med-grey: #6f7592;
$dark-text: #393a4f;
$light-text: #abb0be;

//$primary: #ff5f59;
$primary: #3d70b2;
$secondary: #41d6c3;
$accent: #5596e6;

$sidebar: #344258;
$lightbox: #181e28;
$header: #f6f7f9;
$muted-grey: #999;
$cream-grey: #eee;
$medium-grey: #ddd;
$placeholder: #cecece;

$fade-grey: #e8e8e8;
$muted-grey: #999;
$light-grey: #f5f6f7;

/* ==========================================================================
2. State colors
========================================================================== */

$red: #f71416;
$blue: #039be5;
$green: #1ce589;
$orange: #ff9700;
$fire: #ff533d;
$purple: #9c2bed;

/* ==========================================================================
3. Social colors
========================================================================== */

$facebook: #3b5998;
$twitter: #1dcaff;
$linkedin: #007bb6;
$tumblr: #35465c;
$github: #444;
$dribbble: #ea4c89;
$google-plus: #d34836;
$youtube: #cc181e;
$reddit: #ff4500;

/* ==========================================================================
4. Box shadows
========================================================================== */
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba($primary, 0.42);
$primary-shadow-to: rgba($primary, 0.2);
$primary-box-shadow: 0 14px 26px -12px $primary-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba($secondary, 0.42);
$secondary-shadow-to: rgba($secondary, 0.2);
$secondary-box-shadow: 0 14px 26px -12px $secondary-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba($accent, 0.42);
$accent-shadow-to: rgba($accent, 0.2);
$accent-box-shadow: 0 14px 26px -12px $accent-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;

//Green box shadow
$green-shadow-from: rgba($green, 0.42);
$green-shadow-to: rgba($green, 0.2);
$green-box-shadow: 0 14px 26px -12px $green-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $green-shadow-to !important;

//Red box shadow
$red-shadow-from: rgba($red, 0.42);
$red-shadow-to: rgba($red, 0.2);
$red-box-shadow: 0 14px 26px -12px $red-shadow-from, 0 4px 23px 0px $base-shadow,
  0 8px 10px -5px $red-shadow-to !important;

//Blue box shadow
$blue-shadow-from: rgba($blue, 0.42);
$blue-shadow-to: rgba($blue, 0.2);
$blue-box-shadow: 0 14px 26px -12px $blue-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $blue-shadow-to !important;

//Light box shadow
$light-box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);

//Heavy box shadow
$heavy-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);



.stories-list {
  padding: 20px 0;
  max-width: 980px;
  margin: 0 auto;

  .container-inner {
    display: flex;

    .story {
      margin: 8px;
      width: calc(10% - 16px);
      display: flex;
      justify-content: center;

      .story-avatar {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        min-width: 60px;
        background: $white;
        border-radius: 50%;
        border: 1.6px solid $placeholder;
        transition: all 0.3s;

        &:hover {
          border-color: $primary;
        }

        .add-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 50px;
          width: 50px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: rgba($accent, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 15px;
            width: 15px;
            stroke-width: 3px;
            stroke: $white;
          }
        }

        img {
          display: block;
          height: 50px;
          width: 50px;
          border-radius: 50%;
        }
      }
    }
  }
}

.posts-feed-wrapper {
  padding: 0 0 20px 0;

  &.is-2x {
    max-width: 1040px;
    margin: 0 auto;
  }

  .post-feed-item {
    border: none;
    margin-bottom: 0.75rem;

    .card-image {
      img {
        object-fit: cover;
      }
    }

    .card-content {
      padding: 0;

      .media {
        padding: 10px 0;
        align-items: center;

        .media-left {
          margin-right: 0.5rem;

          .post-avatar {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            background: $white;
            border-radius: 50%;
            border: 1.6px solid $placeholder;
            transition: all 0.3s;

            &:hover {
              border-color: $primary;
            }

            &.is-smaller {
              height: 36px;
              width: 36px;

              img {
                height: 28px;
                width: 28px;
                min-width: 28px;
              }
            }

            .add-overlay {
              position: absolute;
              top: 50%;
              left: 50%;
              height: 44px;
              width: 44px;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              background: rgba($accent, 0.6);
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                height: 15px;
                width: 15px;
                stroke-width: 3px;
                stroke: $white;
              }
            }

            img {
              display: block;
              height: 36px;
              width: 36px;
              min-width: 36px;
              border-radius: 50%;
            }
          }
        }

        .media-content {
          line-height: 1.2;

          &.is-smaller {
            span {
              &:first-child {
                font-size: 0.9rem;
              }

              &:nth-child(2) {
                font-size: 0.75rem;
              }
            }
          }

          span {
            display: block;
            font-family: "Roboto", sans-serif;

            &:first-child {
              font-weight: 500;
              color: $dark-text;
            }

            &:nth-child(2) {
              font-size: 0.85rem;
              color: $light-text;
            }
          }
        }

        .media-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          > span {
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            font-size: 0.85rem;
            color: $dark-text;
            margin-right: 12px;
          }

          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            padding: 0;
            margin-left: 2px;
            border-color: transparent;

            &.is-liked {
              svg {
                stroke: $red;
                fill: $red;
              }
            }

            &:hover {
              border-color: darken($fade-grey, 3%);
              box-shadow: $light-box-shadow;
            }

            svg {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
2. Feed Profile
========================================================================== */

.feed-profile-wrapper {
  display: flex;
  align-items: stretch;
  padding: 30px 0;
  max-width: 1280px;
  margin: 0 auto;

  .profile-wrapper {
    width: 340px;
    padding: 0 40px;

    .profile-avatar-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        width: 38px;
        height: 38px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border-color: transparent;

        &:first-child {
          svg {
            position: relative;
            left: -1px;
          }
        }

        &:last-child {
          svg {
            position: relative;
            right: -1px;
          }
        }

        &:hover {
          border-color: darken($fade-grey, 3%);
          box-shadow: $light-box-shadow;

          svg {
            stroke: $dark-text;
          }
        }

        svg {
          width: 18px;
          height: 18px;
          stroke: $light-text;
          stroke-width: 3px;
        }
      }

      .avatar-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 74px;
        min-width: 74px;
        border: 1.4px solid lighten($placeholder, 4%);
        border-radius: 50%;
        margin: 0 auto 6px auto;

        img {
          display: block;
          width: 58px;
          height: 58px;
          min-width: 58px;
          border-radius: 50%;
        }

        .badge {
          position: absolute;
          bottom: 2px;
          right: -3px;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          border: 2px solid $white-smoke;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $green;

          svg {
            height: 10px;
            width: 10px;
            stroke: $white-smoke;
            stroke-width: 4px;
          }
        }
      }
    }

    .user-stats {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 20px 0;

      .stat-block {
        text-align: center;

        span {
          display: block;

          &:first-child {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1rem;
            color: $dark-text;
          }

          &:last-child {
            font-size: 0.7rem;
            color: $muted-grey;
          }
        }
      }
    }

    .user-bio {
      padding: 40px 0;

      h4 {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 0.9rem;
        color: $dark-text;
        margin-bottom: 8px;
      }

      p {
        font-family: "Roboto", sans-serif;
        font-size: 0.85rem;
        color: $light-text;
        margin-bottom: 8px;
      }

      a {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 0.85rem;
        color: $accent;
      }
    }

    .connections {
      display: flex;
      flex-wrap: wrap;

      .connection {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px;
        width: calc(33.3% - 16px);

        .connection-avatar {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          min-width: 40px;
          background: $white;
          border-radius: 50%;
          border: 1.4px solid $placeholder;
          transition: all 0.3s;

          &:hover {
            border-color: $primary;
          }

          img {
            display: block;
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }
        }
      }
    }

    .more-connections {
      text-align: center;
      padding: 20px 0;

      a {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 0.85rem;
        color: $accent;
      }
    }
  }

  .posts-wrapper {
    width: calc(100% - 340px);

    .column {
      padding: 0.5rem;

      .profile-post {
        margin-bottom: 0;

        img {
          object-fit: cover;
        }
      }
    }
  }
}

/* ==========================================================================
3. Feed Slider
========================================================================== */

.feed-slider-wrapper {
  position: relative;
  padding: 30px 0;
  max-width: 1280px;
  margin: 0 auto;

  .feed-slider-inner {
    .slick-list {
      min-height: 500px;
      margin-top: 0;
    }

    .slick-custom {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $dark-text;
      transition: all 0.3s;
      z-index: 1;

      i {
        position: relative;
        font-size: 3.6rem;
        color: $light-text;
        transition: color 0.3s;
      }

      &:hover {
        i {
          color: $dark-text;
        }
      }

      &.is-prev {
        left: -10px;

        i {
          right: 2px;
        }
      }

      &.is-next {
        right: -10px;

        i {
          left: 2px;
        }
      }
    }

    .feed-slider-item {
      border: none;
      margin-bottom: 0.75rem;
      margin: 60px 20px 20px 20px;
      filter: grayscale(1);
      opacity: 0.6;
      outline: none !important;
      transition: all 0.3s;

      &.slick-center {
        filter: grayscale(0);
        opacity: 1;
        transform: scale(1.4);
        z-index: 9999;

        .card-content,
        .card-header {
          .media,
          .liked-by {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      .card-image {
        img {
          object-fit: cover;
        }
      }

      .card-content,
      .card-header {
        padding: 0;
        box-shadow: none;
background-color: #ffffff;
        .media {
          width: 100%;
          padding: 10px 0;
          align-items: center;
          margin-bottom: 0;
          opacity: 0;
          pointer-events: none;
          transition: all 0.3s;

          .media-left {
            margin-right: 0.5rem;

            .post-avatar {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 36px;
              width: 36px;
              background: $white;
              border-radius: 50%;
              border: 1.2px solid $placeholder;
              transition: all 0.3s;

              &:hover {
                border-color: $primary;
              }

              img {
                display: block;
                height: 28px;
                width: 28px;
                min-width: 28px;
                border-radius: 50%;
              }
            }
          }

          .media-content {
            line-height: 1.2;

            span {
              display: block;
              font-family: "Roboto", sans-serif;

              &:first-child {
                font-weight: 500;
                font-size: 0.75rem;
                color: $dark-text;
              }

              &:nth-child(2) {
                font-size: 0.55rem;
                color: $light-text;
              }
            }
          }

          .media-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            > span {
              font-family: "Roboto", sans-serif;
              font-weight: 500;
              font-size: 0.65rem;
              color: $dark-text;
              margin-right: 12px;
            }

            .button {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 24px;
              width: 24px;
              border-radius: 50%;
              padding: 0;
              margin-left: 2px;
              border-color: transparent;

              &.is-liked {
                svg {
                  stroke: $red;
                  fill: $red;
                }
              }

              &:hover {
                border-color: darken($fade-grey, 3%);
                box-shadow: $light-box-shadow;
              }

              svg {
                height: 13px;
                width: 13px;
              }
            }
          }
        }

        .liked-by {
          display: flex;
          align-items: center;
          padding: 6px 0;
          opacity: 0;
          pointer-events: none;
          transition: all 0.3s;

          img {
            display: block;
            height: 20px;
            width: 20px;
            border-radius: 50%;
          }

          > span {
            display: block;
            font-family: "Roboto", sans-serif;
            font-size: 0.6rem;
            margin-left: 8px;
            color: $light-text;

            span {
              color: $dark-text;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
4. Media Queries
========================================================================== */

@media only screen and (max-width: 767px) {
  .stories-list {
    max-width: 100%;
    width: 100%;
    margin: 0;

    .container-inner {
      overflow-x: auto;

      .story {
        min-width: 60px;
      }
    }
  }

  .posts-feed-wrapper {
    .post-feed-item {
      .card-content {
        .media {
          .media-right {
            > span {
              display: none;
            }
          }
        }
      }
    }
  }

  .feed-profile-wrapper {
    flex-direction: column;

    .profile-wrapper,
    .posts-wrapper {
      width: 100%;
    }
  }

  .feed-slider-wrapper {
    .feed-slider-inner {
      .feed-slider-item {
        .card-header {
          .media {
            flex-direction: column;

            .media-left {
              margin: 0 0 10px 0;
            }

            .media-content {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .feed-slider-wrapper {
    .feed-slider-inner {
      .feed-slider-item {
        .card-header {
          .media {
            flex-direction: column;

            .media-left {
              margin: 0 0 10px 0;
            }

            .media-content {
              text-align: center;
            }
          }
        }
      }

      .slick-custom {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .feed-profile-wrapper {
    .profile-wrapper {
      width: 280px;
      padding: 0 30px;
    }

    .posts-wrapper {
      width: calc(100% - 280px);
    }
  }

  .feed-slider-wrapper {
    .feed-slider-inner {
      .feed-slider-item {
        .card-header {
          .media {
            .media-right {
              > span {
                display: none;
              }
            }
          }
        }
      }

      .slick-custom {
        top: 42%;

        i {
          position: relative;
          font-size: 3rem;
          color: $light-text;
          transition: color 0.3s;
        }

        &:hover {
          i {
            color: $dark-text;
          }
        }

        &.is-prev {
          left: -10px;

          i {
            right: 2px;
          }
        }

        &.is-next {
          right: -10px;

          i {
            left: 2px;
          }
        }
      }
    }
  }
}







// .button.is-liked .fa-star{color:#ff0000;}
.facebookIcon i {
    color: #385698;
}

.twitterIcon i {
    color: #5ba4d6;
}

.instaIcon i {
    color: #996d4e;
}

.cstmStar{
  font-size: 18px!important;

}
.button.is-liked .fa-star.cstmStar{
   color: #ff0000;
}
