.event-buttons {
    display: flex;
}

.event-buttons > .default-btn:not(:last-child) {
    margin-right: 20px;
}

.event-btn a {
    color: white;
}