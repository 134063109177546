.ramp-section {
  background-image: url("../../../Assets/img/CommunityPannel_bg_image.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
//   background-attachment: fixed;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.77;
  }
}

.ramp-item {
  background-color: #a985fc;
  padding: 100px;
  max-width: 700px;
  margin: auto;
  text-align: center;

  span {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
  }

  h3 {
    font-size: 45px;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #fff;
  }

  p {
    color: #fff;
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    padding: 12px 25px;
    background-color: #fff;
    color: #ed272a;
    font-size: 10px;
    border-radius: 5px;
    transition: 0.5s;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 30px;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.default-btn:hover {
  background-color: #ffffff;
  color: #037695;
}
.about-area-content.donor-form iframe {
  max-width: 500px;
  min-width: 250px;
  max-height: none;
  height:540px;
}
@media only screen and (max-width: 1024px){
    .ramp-section.ptb-100 {
        padding-top: 130px;
    }
}
@media only screen and (max-width: 767px){
    .ramp-item{padding:30px 10px;}
    .ramp-section.ptb-100 {
        padding-top: 100px;
    }
}
@media (max-width: 992px) {
  #donate {
    padding-top: 50px;
  }
}
