.events-page {
    .events-search {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .reset-button {
      margin-right: auto;
      margin-left: 1rem;
    }

    .blog-section {
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .container:not(:last-child) {
        margin-bottom: 50px;
    }

    form {
        display: flex;
        padding: 1rem;
    }

    .field-group {
        display: flex;
        align-items: center;
        border: 1px solid #e0e0e0;
        box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        background: #fff;
        position: relative;
    }
    
    input {
        border: 0;
        width: 100%;
    }
    
    .form-button {
        margin-left: .5rem;
    }

    .events-fallback {
        width: 100%;
        padding:  0 1rem 1rem;
    }

    .fa-calendar {
        margin-top: 4px;
        margin-right: 8px !important;
    }

    .attendees {
        position: absolute;
        bottom: 25px;
        right: 25px;
    }

    .blog-item img {
        height: 260px;
    }

    .search-icon {
      position: absolute;
      left: 0;
      z-index: 100;
    }

    .weather-widget-placeload .body .inner-wrap .rect {
      height: 38px;
    }

    .weather-widget-placeload .body  {
      height: 66px;
    }
}

@media only screen and (max-width: 767px) {
    .events-page {

        .events-banner-image {
            margin-top: 60px;
            padding-top: 180px;
        }

        .events-search {
            justify-content: flex-start;
        }

        .reset-button {
          margin-right: 0;
        }
    }
    
}