.not-found-user-page {
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4 {
    color: #929292;
    font-weight: 500 !important;
    margin-top: 20px;
  }
}
