.imageCustmValidation {
  position: relative;
  .errors-msg {
    transform: none;
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    opacity: 0;
    &.show-error {
      opacity: 1;
    }
  }
}
.markAsComplete .control.has-icon {
  margin-left: 17px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.custmuser-img-wrapper {
  .user-img {
    border-radius: 0;
  }
}
.illustration .user-img-wrapper {
  img {
    width: 100%;
  }
}
.illustration .user--main-img-wrapper {
  width: 150px;
}
.illustration h2 {
  margin-bottom: 25px !important;
}
.illustration img {
  width: 100%;
}
.Custmsettings-form-wrappers {
  .illustration {
    padding-top: 0 !important;
  }
  .settings-form {
    padding-top: 0 !important;
  }
  .title-wrap h2 {
    margin-bottom: 25px;
  }
}

.for-multiple-images {
  width: 100%;
  min-height: 150px;
  border: 2px solid #a885fb;
  border-radius: 5px;

  .not-select-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a885fb;
    font-weight: 600;
  }

  .upload-img-icon {
    right: 5px;
    bottom: 5px;
  }

  .file-list {
    padding: 10px;

    .file-list-item {
      margin-bottom: 6px;
      border-radius: 5px;
      padding: 0 5px;
      width: max-content;
      background: #e3e3e3;
      color: #000;
      user-select: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .custmuser-img-wrapper .user-img {
    border-radius: 0;
    width: 100% !important;
    max-width: 300px !important;
    height: inherit !important;
  }
}
