.stories-wrapper .inner-wrapper .mobile-sidebar-trigger:hover svg {
    stroke: #999;
} 

.resources-wrapper {
    padding: 30px 40px;

    form {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
    }

    .field-group {
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
        outline: none;  
        display: flex;
        margin-right: 10px;

        .search-icon {
            display: flex;
            align-items: center;
        }
    }

    input {
        border: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 6px 10px;
        width: 100%;
    }

    .resources-category {
        margin: 1rem 0;
    }   
}

.resources-container {
    display: flex;
    flex-wrap: wrap;

    .resources-item {
        background: #fff;
        border: 1px solid #eee;
        width: 40%;
        padding: 1.62rem;
        margin-bottom: 10px;
        border-radius: 16px;
        display: flex;

        a {
            padding-left: 1rem;
            align-self: center;
            
        }

        &:hover {
            box-shadow: 2px 2px 10px rgba(0,0,0,0.09);
        }

        &:nth-child(odd) {
            width: calc(40% - 10px);
            margin-right: 10px;
        }
    }
}

.icon-wrapper {
    padding: 0.8rem;
    border-radius: 50px;
    background: #e6ddfd;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-content: center;
    
    svg {
        height: 1.2rem;
        width: 1.2rem;
        fill: #7643ee;
    }
}

@media screen and (max-width: 767px) {
    .resources-wrapper {
        padding: 70px 20px;

        form {
            justify-content: flex-start;
        }
    }

    .resources-container .resources-item {
        width: 100%;

        &:nth-child(odd) {
            width: 100%;
            margin-right: 0;
        }
    }
}